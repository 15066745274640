//external
import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
//internal
import Route from '../../routes/Route';
import LogErrorDetails from './details';
import LogError from './list';

export const FilterLogError = createContext({});

export default function Routes() {
  const [filter, setFilter] = useState({
    user: null,
    page: 1,
    initialDate: null,
    finalDate: null,
    type: null,
  });

  let location = useLocation();

  useEffect(() => {
    const split = location?.pathname.split('/');
    if (split[1] !== "log_error") {
      setFilter({
        user: null,
        page: 1,
        initialDate: null,
        finalDate: null,
        type: null,
      });
    }
  }, [location, setFilter]);

  return (
    <FilterLogError.Provider value={{ filter, setFilter }}>
      <Route path="/log_error" exact component={LogError} isPrivate/>
      <Route path="/log_error/details/:id" exact component={LogErrorDetails} isPrivate/>
    </FilterLogError.Provider>
  )
}  