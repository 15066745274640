import api from '../api'

export async function getAllows() {
  return api.get('product_family/allows');
}

export async function getFamilys(description, numOfRegisters = 10, page = 1, order) {
  const params = {
    order: order ? order : 'description',
  }
  if (description) {
    params.query = description.trim(); 
  }

  return api.get('product_family', {
    params,
    headers: {
      registerperpage: numOfRegisters,
      page
    }
  });
}

export async function getFamily(familyId) {
  return api.get(`product_family/protheus/${familyId}`);
}

export async function putFamily(familyId, data) {
  return api.put(`product_family/protheus/${familyId}`, data);
}
