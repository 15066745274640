import api from '../../api'

export async function getAllows() {
  return api.get('rtpi_label_logs/allows');
}

export async function getRtpis(query, numOfRegisters = 10, page = 1, order, branch) {
  const params = {
    order: order ? order : 'protheusCode',
    only_registered: true,
  }
  if (query) {
    params.query = query.trim(); 
  }
  if (branch) {
    params.branch = branch; 
  }
  
  return api.get('rtpi_label_logs', {
    params,
    headers: {
      registerperpage: numOfRegisters,
      page
    }
  });
}