//external
import React, { createContext, useEffect, useState } from 'react';
import { Route, Redirect, useLocation, Prompt } from 'react-router-dom';
//internal
import { store } from '../store';

export const Warning = createContext({});

export function UseUnsavedChangeWarning() {
  const [isWarning, setIsWarning] = useState(false);
  let location = useLocation();

  useEffect(() => {    
    if (isWarning) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  }, [isWarning]);

  useEffect(() => {
    setIsWarning(false);
  }, [location]);

  const prompt = <Prompt when={isWarning} message={(loc) => loc.pathname !== location.pathname ? "Deseja realmente sair da tela sem salvar as alterações?" : false} />

  return [prompt, () => setIsWarning(true), () => setIsWarning(false)];
} 

export default function RouteWrapper({
  component: Component,
  isPrivate = false,
  isPublic = false,
  ...rest
}) {
  const { signed } = store.getState().auth;
  const [ Prompt, setIsWarning, setNotWarning ] = UseUnsavedChangeWarning();
    
  if (!signed && isPrivate) {
    return <Redirect to='/login' />
  }

  /* if (signed && !isPrivate && !isPublic) {
    return <Redirect to='/' />
  } */
  
  return (
    <Warning.Provider value={{ Prompt, setIsWarning, setNotWarning }}>
      <Route 
        {...rest}
        component={Component}
      />
      {setIsWarning && (
        Prompt
      )}
    </Warning.Provider>
  )
}