import React from 'react';
import { LoadingStyled } from './styled'

import loadingGif from '../../assets/images/loading.gif'

export default function Loading() {
  return (
    <LoadingStyled style={{zIndex: 9999}}>
      <img src={loadingGif} alt="loading" />
    </LoadingStyled>
  );
}