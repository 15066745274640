import * as Yup from 'yup';

export const intentionSchema = Yup.object().shape({
  id: Yup.number().required('Id é obrigatório').nullable(),
  //productDescription: Yup.string().required('Descrição é obrigatória.').nullable(),
  revision: Yup.string().required('Revisão é obrigatória.').nullable(),
  conservationCondition: Yup.string(),
  productEnrichment: Yup.string(),
  finishedProductDescription: Yup.string(),
  productClassification: Yup.string().required('Classificação é obrigatória.').nullable(),
  productPhysicalForm: Yup.string().required('Forma física é obrigatória.').nullable(),
  productPackageType: Yup.string().required('Tipo de embalagem é obrigatório.').nullable(),
  productPackageForm: Yup.string().required('Forma da embalagem é obrigatória.').nullable(),
  /*ProductRestriction: Yup.object().shape({
    id: Yup.number().integer().required('Restrição do produto acabado é obrigatória.'),
  }).required('Restrição do produto acabado é obrigatória.').typeError('Restrição do produto acabado é obrigatória.'),*/
  productIndication: Yup.string().required('Indicação é obrigatória.').nullable(),
  productUseMode: Yup.string().required('Modo de uso é obrigatório.').nullable(),
  consumptionMin: Yup.number().required('Consumo Minimo (gr/Cab/Dia) é obrigatório.').typeError('Consumo Minimo (gr/Cab/Dia) é obrigatório.'),
  consumptionMax: Yup.number().required('Consumo Maximo (gr/Cab/Dia) é obrigatório.').typeError('Consumo Minimo (gr/Cab/Dia) é obrigatório.'),
  /*RtpiIntentionWarrantyLevels: Yup.array().optional(),
  RtpiIntentionStructures: Yup.array().optional(),
  RtpiIntentionStructureSubstitutives: Yup.array().optional(),*/
});