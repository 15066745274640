//external
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Typography } from '@material-ui/core';
//internal
import history from 'services/history';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import { getCommissionsPayedDetails } from 'services/commission/service';
import { setMessage } from 'store/modules/message/action';
import { sanitizeErrorUI } from 'utils/errorUtil';
import { formatCurrency, formatDate } from 'utils/formatUtil';
import ContentPanel from 'components/ContentPanel';
import GridContainer from 'components/v2/grid';
import DataTableReact from 'components/v2/dataTableReact';
//style
import { Button, ButtonContainer } from 'styles/components';

export default function OperationView() {
  const message = useSelector(state => state.message.data);
  const salesman = useSelector(state => state.user.id);  
  
  const {id, month, year} = useParams();
  const {state} = useLocation();

  const [details, setDetails] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (salesman && id && month && year) {
      dispatch(startRequest());
      getCommissionsPayedDetails('001324', id, month, year).then(data => {        
        setDetails(data?.data);
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, salesman, id, month, year]);

  async function handleBack () {
    history.goBack();
  }

  const columns = [
    {
      name: 'Data',
      selector: row => formatDate(row.dueDate),
      sortable: false
    },
    {
      name: 'Pedido',
      selector: row => row.idOrder,
      sortable: false
    },
    {
      name: 'Valor',
      selector: row => formatCurrency(row.payed),
      sortable: false
    }
  ];

  return (
    <ContentPanel title='Lançamento' message={message}>   
      {state && (
        <>
          <div style={{backgroundColor: state?.payed > 0 ? '#dc3545' : '#008000'}}>
            <Box p={6} style={{color: 'white'}}>              
              <GridContainer>      
                <Grid item xs={12}>                  
                  <Typography variant="h6" align="center">
                    {state?.payed > 0 ? 'Comissão paga' : 'Commissão gerada'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>                  
                  <Typography variant="h6" align="center">
                    {state?.payed > 0 ? formatCurrency(state?.payed * -1 || 0) : formatCurrency(state?.value || 0)}
                  </Typography>
                </Grid>
              </GridContainer>              
            </Box>
          </div>
          <br />
          <GridContainer>      
            <Grid item xs={12}>                  
              <Typography color="textPrimary" variant="subtitle1">
                Saldo após este lançamento:
              </Typography>

              <Box style={{color: state?.balance < 0 ? '#dc3545' : '#008000'}}>
                <Typography variant="subtitle1">
                  {formatCurrency(state?.balance || 0)}
                </Typography>
              </Box>
            </Grid>
             
            <Grid item xs={12}>                  
              <Typography color="textPrimary" variant="subtitle1">
                Identificação:
              </Typography>

              <Typography variant="subtitle1">
                {state?.payed > 0 ? `NF ${state?.idInvoice}` : `Pedido de compra ${state?.idOrder}`}
              </Typography>
            </Grid>       

            {!state?.idOrder && (
              <>
                <Grid item xs={12}>                  
                  <Typography color="textPrimary" variant="subtitle1">
                    Pagamentos:
                  </Typography>
                </Grid>    
                <Grid item xs={12}>
                  <DataTableReact
                    noHeader
                    columns={columns}
                    data={details}
                  />
                </Grid>
              </>
            )}
          </GridContainer>          
        </>
      )}
      <ButtonContainer>
        <br />
        <Button left={true} cancel={true} onClick={handleBack}>
          Voltar        
        </Button>        
      </ButtonContainer>
    </ContentPanel>
  );
}
