//external
import React, { useCallback, useEffect, useState } from 'react' ;
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import DeleteIcon from '@material-ui/icons/Delete';
//internal
import Modal from 'components/v2/modal';
import { findNutrient } from 'services/nutrient/service';
import { setMessage } from 'store/modules/message/action';
import GridContainer from 'components/v2/grid';
import InputText from 'components/v2/inputText';
import SelectCreatable from 'components/SelectCreatable';
import { validateForm } from 'components/Form/validate';
import { referenceValuesEditSchema } from '../schemas';
import { showConfirm } from 'components/AlertDialog';
import { sanitizeErrorUI } from 'utils/errorUtil';
import SelectAutocomplete from 'components/v2/autocomplete';
import { useDebounce } from 'utils/uiUtils';
import DataTableReact from 'components/v2/dataTableReact';
import { IconContainer } from 'pages/user/list/styled';
import TableAction from 'components/v2/tableAction';
import ContentPanel from 'components/ContentPanel';

const initialState = {
  id: "",
  group: "",
  order: "",
  label: "",
  value: "",
  nutrients: {
    id: "",
    description: "",
  }
};

export default function SearchNutrient({isOpen, onClickSave, onHandleClose, onClickDelete, item, groups}) {
  const [nutrient, setNutrient] = useState(null);
  const [form, setForm] = useState(initialState);
  const [errors, setErrors] = useState([]);
  const [options, setOptions] = useState([]);
  const [searchNutrient, setSearcNutrient] = useState("");
  const [selectedNutrients, setSelectedNutrients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const debouncedSearchNutrient = useDebounce(searchNutrient);
  
  const dispatch = useDispatch();

  const loadNutrient = useCallback((searchValue) => {
    setIsLoading(true)
    findNutrient(searchValue).then(result => {
      const nutrients = result.data.map(element => {
        return { value: element, label: `${element.description}` }
      });
      if (nutrient) {
        const nutrientIndex = nutrients.findIndex(item => item.id === nutrient.value.id);
        if (nutrientIndex === -1) {
          nutrients.push(nutrient)
        }    
      }
      setOptions(nutrients);
      setIsLoading(false)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      setIsLoading(false);
    });
  }, [nutrient, dispatch])

  useEffect(() => {
    if(loadNutrient) loadNutrient(debouncedSearchNutrient)
  },[debouncedSearchNutrient, loadNutrient]);

  useEffect(() => {
    setForm(initialState);
    setErrors([]);

    if (!_.isEmpty(item)) {
      setNutrient(null);
      setSelectedNutrients(item.nutrients)
      setForm({...item});
    }
  }, [item]);

  const columnsUserGroup = [
    {
      maxWidth: "75%",
      name: 'Descrição',
      selector: 'description',
      sortable: true,
    },
    {
      maxWidth: "25%",
      style: {justifyContent: "flex-end"},
      cell: row => 
      <>
        <div>
          <IconContainer>
            <TableAction 
            title={"Excluir"} 
            onClick={() => handleRemoveUserGroup(row)}>
              <DeleteIcon/>
            </TableAction>
          </IconContainer>
        </div>
      </>,
    }
  ];

  function handleNutrientInputChange(data, event) {
    if (data) {
      const nutrientIndex = selectedNutrients.findIndex(item => item.id === data?.value?.optimixCode);
      setNutrient(data);
      if (nutrientIndex >= 0) return;
      setSelectedNutrients([...selectedNutrients, {
        id: data.value.optimixCode,
        description: data.value.description
      }]); 
    } else {
      setNutrient(null);
      loadNutrient("");
    }
  }

  function handleRemoveUserGroup(nutrients) {    
    var nutrientIndex = selectedNutrients.indexOf(nutrients);
    selectedNutrients.splice(nutrientIndex, 1);
    setSelectedNutrients([...selectedNutrients]);    
  }

  const clickSave = async () => {
    const errorsList = await validateForm(form, referenceValuesEditSchema);
    if (errorsList.length > 0) {
      setErrors(errorsList);
      return;
    } 
    const payload = {...form, nutrients: selectedNutrients}
    onClickSave(payload);
    
  }

  const handleDeleteClick = async () => {
    if (!await showConfirm(`Deseja realmente excluir o valor ${form.label}?`)) {
      return;
    }

    onClickDelete(form);
  }

  const onChangeGroup = (event, newValue) => {
    if (typeof newValue === 'string') {
      setForm({...form, group: newValue});
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      setForm({...form, group: newValue.inputValue});
    } else {
      setForm({...form, group: newValue ? newValue : ""});
    }
  };
  
  return (    
    <Modal 
      isOpen={isOpen} 
      title={item ? "Editar" : "Adicionar"}
      labelCancel="Cancelar"
      labelSave="Confirmar"
      labelDelete="Excluir"
      onClose={onHandleClose}
      onClickCancel={onHandleClose}
      onClickSave={clickSave}
      onClickDelete={item ? handleDeleteClick : null}
    >         

      <div style={{padding: '5px 0 0 0', overflow: 'hidden'}}>
        <GridContainer>   
          <div style={{width: '100%', margin: '0px 16px', height: '100%'}}>
            <ContentPanel> 
              <SelectAutocomplete
                xs={12}
                sm={12}
                value={nutrient}
                label="Adicionar Nutriente"
                optionSelected={(option, value) => option.label === value.label}
                optionLabel={(option) => option.label}
                onChangeSelected={(event, value) => handleNutrientInputChange(value)}
                onChange={(event) => setSearcNutrient(event.target.value)}
                options={options}
                name="nutrient"
                isLoading={isLoading}
              />

              <DataTableReact
                noHeader
                columns={columnsUserGroup}
                data={selectedNutrients}
              />
            </ContentPanel>  
          </div>  
      
          {/* <InputText
            xs={8}
            name="value"
            label="Nutrientes"
            type="text"
            placeholder="Nutrientes"
            value={form.nutrient}
            onChange={(e) => setForm({...form, nutrient: e.target.value})}
            required
            errors={errors}
          />  */}

          <InputText
            xs={4}
            name="value"
            label="Quantidade"
            type="number"
            placeholder="Quantidade"
            value={form.value.toString()}
            onChange={(e) => setForm({...form, value: e.target.value})}
            required
            errors={errors}
          /> 
          <SelectCreatable 
            xs={8}
            label="Grupo"
            value={form.group}
            options={groups}
            onChange={onChangeGroup}
          />   
          <InputText
            xs={9}
            name="label"
            label="Descrição"
            type="text"
            placeholder="Descrição"
            value={form.label}
            onChange={(e) => setForm({...form, label: e.target.value})}
            required
            errors={errors}
          />

          <InputText
            xs={3}
            name="value"
            label="Unidade"
            type="text"
            placeholder="Unidade"
            value={form.unity}
            onChange={(e) => setForm({...form, unity: e.target.value})}
            required
            errors={errors}
          /> 
        </GridContainer>
      </div>
    </Modal>
  )
} 
