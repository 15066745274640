//external
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//internal
import { deleteContact, getContact } from 'services/contact/service';
import history from 'services/history';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import ContentPanel from 'components/ContentPanel';
import { setMessage } from 'store/modules/message/action';
import { sanitizeErrorUI } from 'utils/errorUtil';
//style
import { Button, ButtonContainer } from 'styles/components';
import { Attr, AttrName } from 'pages/appversion/delete/styled';

export default function ContactDelete({match}) {
  const contactId = match.params.id;
  const message = useSelector(state => state.message.data);
  const [contact, setContact] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    if (contactId) {
      dispatch(startRequest());
      getContact(contactId).then(data => {
        setContact(data.data);
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, contactId]);

  async function handleDelete () {
    dispatch(startRequest());
    deleteContact(contactId).then(data => {
      history.push('/schedule/contact')
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }

  async function handleBack () {
    history.goBack();
  }

  return (
    <>
      <ContentPanel title="Deseja excluir este contato?" message={message}>
        {contact && <>
          <AttrName>Nome:</AttrName>
          <Attr>{contact.name}</Attr>
          <AttrName>Função:</AttrName>
          <Attr>{contact.contactRole}</Attr>
          <AttrName>Telefone:</AttrName>
          <Attr>{contact.phone}</Attr>
          <ButtonContainer>
            <br />
            <Button left={true} cancel={true} onClick={handleBack}>
              Voltar
            </Button>
            <Button onClick={handleDelete}>
              Excluir
            </Button>
          </ButtonContainer>
        </>}
      </ContentPanel>
    </>
  )
}