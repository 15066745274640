//external
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconExpandMore from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import Popper from '@material-ui/core/Popper';
import { Link } from 'react-router-dom';
import { Divider, List, ListItem, ListItemIcon, ListItemText, IconButton  } from '@material-ui/core';
//internal
import { getMenuList } from '../../utils/allowedUtil';
import MyCollapse from '../Collapse';
import InputText from 'components/v2/inputText';
//style
import { Container, Search, IconSearchMenu, InputIcon } from './styles';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    overflowX: 'hidden',
    position: 'absolute',
    bottom: 0,
    top: '143px',
    width: '100%'
  },
}));

export default function SideMenu({location, open}) {
  const alloweds = useSelector(state => state.user.alloweds);
  const [filter, setFilter] = useState('');
  const [allowedMenu, setAllowedMenu] = useState([]);
  const [menuOptions, setMenuOptions] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setMenuOptions(allowedMenu);
  }, [allowedMenu]);

  function handleChange(event) {
    setFilter(event.target.value);
    filterMenu(allowedMenu, event.target.value)
  }

  function filterMenu(itens, filterText) {
    let submenu = [];
    const teste = itens.map(menu => {
      if(menu.items) {
        menu.items.forEach(subMenu => {
          submenu.push(subMenu)
        });
      }
      return menu; 
    })
    const fullMenu = teste.concat(submenu);

    if (!filterText || filterText.trim() === '') {
      return setMenuOptions(allowedMenu)
    } else {
      const lo = fullMenu.filter(item => item.label.toLocaleLowerCase().includes(filterText.toLocaleLowerCase()))
      setMenuOptions(lo)
    }
  }

  function clickItem(item, index, event) {

    if (Array.isArray(item.items) && item.items.length) {
      item.open = !item.open;

      if(item.items[0].hasOwnProperty("subSection")) {
        const indexSubItem = allowedMenu.findIndex(i => {
          if(i.items?.length > 0) {
            return (i.items[0].section === item.items[0].section)
          }
          return null
        }) 
        let newArr = [...allowedMenu];      
        newArr[indexSubItem]["items"][index] = item;
        setAllowedMenu(newArr);
        return;
      }
      
      let newArr = [...allowedMenu];
      newArr[index] = item;
      setAllowedMenu(newArr);
    }
    setIsSearchOpen(false);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  }

  const isActive = function(to) {
    const pathName = window.location.pathname + '/';
    const itemPath = to + '/';

    return pathName.startsWith(itemPath);
  }

  const handleClick = (event) => {
    setIsSearchOpen(!isSearchOpen)
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  useEffect(() => {
    let list = getMenuList(alloweds);
    setAllowedMenu(list);
  }, [alloweds]);

  const createNewSubmenu = (item, idx) => {
    return (
      <List style={{paddingLeft: '16px'}} disablePadding dense component="nav">
        <Fragment key={item.label}>
          <ListItem button selected={isActive(item.to)} onClick={(event) => clickItem(item, idx, event)}>
            <ListItemIcon>
              <Icon>{item.icon}</Icon>
            </ListItemIcon>
            <ListItemText>{item.label}</ListItemText>
            {item.items && item.items.length && (
              item.open ? <IconExpandLess /> : <IconExpandMore />
            )}
          </ListItem>
          <MyCollapse item={item}>    
            <Divider />      
            <List style={{marginLeft: '-5px'}} disablePadding dense>
              {item.items?.map((subSection, index) => {
                return (
                  <Link key={index} style={{color: 'inherit', textDecoration: 'none'}} to={subSection.to}>
                    <ListItem 
                      key={'sub_subSection_'+index} 
                      button selected={isActive(subSection.to)} 
                      onClick={(event) => clickItem(subSection, index, event)} style={{marginLeft: 16}}
                    >
                      <ListItemIcon>
                        <Icon>{subSection.icon}</Icon>
                      </ListItemIcon>
                      <ListItemText>{subSection.label}</ListItemText>
                    </ListItem>
                  </Link>  
                )
              })}
            </List>   
          </MyCollapse>
        </Fragment>
      </List>
    ) 
  }

  return (
    <Container>
      <Search> 
        {
          open ? (
            <InputText type="text" placeholder="Busca" value={filter} onChange={handleChange}/>
          ) : (
            <IconSearchMenu>
              <IconButton onClick={handleClick}>
                <SearchIcon/>
              </IconButton>  
                <Popper  open={isSearchOpen} anchorEl={anchorEl} transition  placement={"right"}>
                  {({ TransitionProps }) => (
                    <InputIcon>
                      <InputText 
                        {...TransitionProps} timeout={350} 
                        type="text" 
                        placeholder="Busca" 
                        value={filter} 
                        onChange={handleChange}
                      />
                    </InputIcon>  
                  )}
                </Popper>
              
            </IconSearchMenu>
            
          )
        } 
        
      </Search>
      <List className={classes.root} disablePadding dense component="nav">
        {menuOptions.map((item, index) => (
          <Fragment key={item.label}>
              {item.section ? (
                <Link style={{color: 'inherit', textDecoration: 'none'}} to={item.to}>
                  <ListItem button selected={isActive(item.to)} onClick={(event) => clickItem(item, index, event)}>
                    <ListItemIcon>
                      <Icon>{item.icon}</Icon>
                    </ListItemIcon>
                    <ListItemText>{item.label}</ListItemText>
                    {item.items && item.items.length && (
                      item.open ? <IconExpandLess /> : <IconExpandMore />
                    )}
                  </ListItem>
                </Link>
              ) : (
                <ListItem button selected={isActive(item.to)} onClick={(event) => clickItem(item, index, event)}>
                <ListItemIcon>
                  <Icon>{item.icon}</Icon>
                </ListItemIcon>
                <ListItemText>{item.label}</ListItemText>
                {item.items && item.items.length && (
                  item.open ? <IconExpandLess /> : <IconExpandMore />
                )}
              </ListItem>
              )}
              
         
            <MyCollapse item={item}>
              {item.items && (
                <>
                  <Divider />
                  {Array.isArray(item.items) ? (              
                    <List style={{marginLeft: '-7px'}} disablePadding dense>
                      {item.items.map((subItem, idx) => (
                        <div key={'sub_'+idx} >
                          {!subItem.items ? (
                            <Link style={{color: 'inherit', textDecoration: 'none'}} to={subItem.to}>
                              <ListItem 
                                button selected={isActive(subItem.to)} 
                                onClick={(event) => clickItem(subItem, idx, event)} style={{marginLeft: 16}}
                              >
                                <ListItemIcon>
                                  <Icon>{subItem.icon}</Icon>
                                </ListItemIcon>
                                <ListItemText>{subItem.label}</ListItemText>
                              </ListItem>
                            </Link>  
                          ): (    
                            createNewSubmenu(subItem, idx)
                          )}
                        </div>
                      ))}
                    </List>              
                  ) : null}
                  <Divider />
                </>
              )}
            </MyCollapse>
          </Fragment>
        ))}
      </List>
    </Container>
  )
}