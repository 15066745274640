//external
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionActions from '@material-ui/core/AccordionActions';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '10px 2px 10px 2px',
  }
}));

export default function Accordions({...props}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>      
      <Accordion TransitionProps={{ unmountOnExit: true }} className={classes.MuiAccordion} {...props}>
        <AccordionSummary
          className={classes.MuiAccordionSummary}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"          
        >
          <FormControlLabel
            aria-label="Acknowledge"
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={props.control}
            label={props.label}
          />
        </AccordionSummary>
        <AccordionDetails className={classes.MuiAccordionDetails}>
          {props.children}
        </AccordionDetails>
        <Divider />
        <AccordionActions>
          {props.actions}
        </AccordionActions>
      </Accordion>
    </div>
  );
}