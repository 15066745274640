//external
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from '@material-ui/icons/Refresh';
//internal
import ContentPanel from 'components/ContentPanel';
import DataTableLoading from 'components/DataTableLoading';
import { getNutrients, getAllows } from 'services/nutrient/service';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import { setMessage } from 'store/modules/message/action';
import { useDebounce } from 'utils/uiUtils';
import GridContainer from 'components/v2/grid';
import InputText from 'components/v2/inputText';
import TableAction from 'components/v2/tableAction';
import { sanitizeErrorUI } from 'utils/errorUtil';
import DataTableReact from 'components/v2/dataTableReact';
import { FilterNutrient } from '..';
//style
import { IconContainer } from './styled';

export default function Nutrient() {
  const message = useSelector(state => state.message.data);
  
  const [nutrients, setNutrients] = useState();
  const [allows, setAllows] = useState({});
  //Pagination
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sort, setSort] = useState();
  const [reload, setReload] = useState(0);

  const { filter, setFilter } = useContext(FilterNutrient);

  const debouncedSearchTerm = useDebounce(filter.search);
  
  const dispatch = useDispatch();

  const columns = [
    {
      name: 'Código',
      selector: 'optimixCode',
      sortable: false,
      maxWidth: "10%",
    },
    {
      name: 'Descrição',
      selector: 'description',
      sortable: true,
      maxWidth: "25%"
    },
    {
      name: 'Unidade',
      selector: 'unity',
      sortable: false,
      maxWidth: "10%"
    },
    {
      name: 'Rótulo',
      selector: 'descriptionAlias',
      sortable: false,
      maxWidth: "25%"
    },
    {
      name: 'Grupo',
      selector: 'group.description',
      sortable: false,
      maxWidth: "15%"
    },
    {
      maxWidth: "15%",
      style: {justifyContent: "flex-end"},
      cell: row => 
        <IconContainer>
          <TableAction 
            title={"Editar"} 
            disabled={!allows.put} 
            to={`/premixlabel/nutrient/edit/${row.optimixCode}`}>
            <EditIcon/>
          </TableAction>
        </IconContainer>
    }
  ];

  const actions = [
    <div key='refresh'>
      <TableAction 
      title={"Atualizar"} 
      onClick={refreshHandler}
      isLink={false}>
        <RefreshIcon/>
      </TableAction>
    </div>
  ];

  function refreshHandler() {
    setReload(reload + 1);
  }

  useEffect(() => {
    getAllows().then(data => {
      setAllows(data.data)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    });
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);
    dispatch(startRequest());    
    getNutrients(debouncedSearchTerm, perPage, filter.page, sort).then(data => {
      setTotalRows(parseInt(data.headers['recordcount']));
      setNutrients(data.data);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
      setLoading(false);
    });
  }, [debouncedSearchTerm, dispatch, filter.page, perPage, sort, reload]);

  const handlePageChange = (page) => {
    setFilter({...filter, page});
  };

  const handlePerRowsChange = (perPage) => {
    setPerPage(perPage);
  };

  const handleSort = (column, sortDirection) => {
    setSort(`${column.selector} ${sortDirection}`);
  };

  return (
    <>
      <ContentPanel message={message} title="Nutrientes" actions={actions}> 
        <GridContainer>
          <InputText
            xs={12}
            name="name"
            label="Pesquisar"
            type="text"
            placeholder="Pesquisar"
            value={filter.search}
            onChange={(e) => setFilter({page: 1, search: e.target.value})}
            hasWarning={false}
          />
        </GridContainer>

        <DataTableReact
          noHeader
          fixedHeader
          progressPending={loading}
          progressComponent={<DataTableLoading />}
          title="Nutrientes"
          columns={columns}
          data={nutrients}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          sortServer
          onSort={handleSort}
          paginationDefaultPage={filter.page}
        />
      </ContentPanel>
    </>
  )
}