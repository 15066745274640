//external
import { Grid, makeStyles } from '@material-ui/core';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import React from 'react';
import { Fragment } from 'react';
//internal
import Thumb from 'components/v2/thumb';
//styled
import './styled.scss';
import { Separator } from 'pages/schedule/list/styled';

const useStyles = makeStyles((theme) => ({
  MuiSvgIcon: {
    height: '95px',
    cursor: 'pointer'
  }
}));

export default function ImageUpload({
  id,
  name,
  fieldName,
  onChange,
  multiple,
  xs,
  sm,
  disabled,
  accept,
  value,
  removeImage,
  errors,
  idItem
}) {

  const classes = useStyles();

  return (
    <>
      <Grid item xs={12} sm={12}>
        <Separator>{fieldName}</Separator>
      </Grid>
      <div style={{display: 'flex', flexWrap: 'wrap', padding: '0px 8px'}}>
        <Grid item xs={xs} sm={sm} style={{marginRight: '8px'}}>
          <div style={{color: 'red'}} className="img-upload">
            <label>          
              <AddAPhotoIcon className={classes.MuiSvgIcon} fontSize="large" color="action" />          
              <input 
                name={name}
                multiple={multiple} 
                type="file"
                accept={accept} 
                onChange={(event) => onChange(event)}
                disabled={disabled}
              />
            </label>
          </div>
        </Grid> 
        {Array.isArray(value) ? (
          <>
            {value?.map((image, index) => (
              <Thumb 
                item 
                key={index} 
                src={image?.url} 
                removeImage={() => removeImage(id, index)}
              />
            ))}
          </>
        ) : (
          <>
            {value?.url && (
              <Thumb 
                item 
                src={value?.url} 
                removeImage={() => removeImage(id)}
              />
            )}
          </> 
        )}     
        <div style={{width: '100%'}}>
          {errors.map(item => {
            if(item.field === idItem.toString()) {
              return (
                <Fragment key={fieldName}>
                  <div className="error-message">{item.message}</div>
                </Fragment>
              )
            }
            return null
          })}
        </div>         
      </div>  
    </>
  )
}