import produce from 'immer';
import * as constants from './constants';

const INITIAL_STATE = {
  profile: null,
  alloweds: [],
  id: null,
  name: null,
  isUserExist: false,
  nivel: null,
  me: false
};

export default function user(state = INITIAL_STATE, action) {
  switch (action.type) {
    // case '@user/STORE_REQUEST':
    //   return produce(state, draft => {})
    // case '@user/STORE_SUCCESS':
    //   return produce(state, draft => {})
    // case '@user/STORE_FAILURE':
    //   return produce(state, draft => {})
    // case '@user/CONFIRM_REQUEST':
    //   return produce(state, draft => {})
    // case '@user/CONFIRM_SUCCESS':
    //   return produce(state, draft => {})
    // case '@user/CONFIRM_FAILURE':
    //   return produce(state, draft => {})
    // case '@user/ALLOWED_MODULES_REQUEST':
    //   return produce(state, draft => {})
    case '@user/ALLOWED_MODULES_SUCCESS':
      return produce(state, draft => {
        draft.alloweds = action.data;
      })
    // case '@user/ALLOWED_MODULES_FAILURE':
    //   return produce(state, draft => {})
    case '@auth/LOGIN_SUCCESS':
      return produce(state, draft => {
        draft.profile = action.payload.user.username;
      }) 
    case constants.SET_FILTER_USER:
      return produce(state, draft => {
        draft.id = action.payload.id;
        draft.name = action.payload.name;
        draft.nivel = action.payload.nivel;
        draft.me = action.payload.me
      })  
    case constants.IS_USER_EXIST:
      return produce(state, draft => {
        draft.isUserExist = action.payload.isUserExist;
      })    
    default: 
      return state;
  }
}