//external
import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
//internal
import Route from '../../routes/Route';
import Commissions from './list';
import BilletView from '../billet/view';
import OperationView from './operation';
import DocumentCommission from './document';
import DocumentCommissionEdit from './document/edit';
import { monthList } from 'utils/formatUtil';

export const FilterCommission = createContext({});

const getCurrentMonth = () => {
  return monthList.find(month => month.value === new Date().getMonth() + 1)
};

export default function Routes() {
  const [filter, setFilter] = useState({
    month: getCurrentMonth(),
    year: new Date().getFullYear(),
    selectedIndex: 0,
    page: 1,
    status: "",
    type: "",
    initialDate: null,
    finalDate: null,
  });

  let location = useLocation();

  useEffect(() => {
    const split = location?.pathname.split('/');
    if (split[1] !== "commission") {
      setFilter({
        month: getCurrentMonth(),
        year: new Date().getFullYear(),
        selectedIndex: 0,
        page: 1,
        status: "",
        type: "",
        initialDate: null,
        finalDate: null,
      });
    }
  }, [location, setFilter]);

  return (
    <FilterCommission.Provider value={{ filter, setFilter }}>
      <Route path="/commission" exact component={Commissions} isPrivate/>
      <Route path="/commission/view/:branch/:number/:prefix" exact component={BilletView} isPrivate/>
      <Route path="/commission/view/:branch/:number/:prefix/:parcel" exact component={BilletView} isPrivate/>
      <Route path="/commission/operation/:id/:month/:year" exact component={OperationView} isPrivate/>
      <Route path="/commission/document" exact component={DocumentCommission} isPrivate/>
      <Route path="/commission/document/edit/:id" exact component={DocumentCommissionEdit} isPrivate/>
    </FilterCommission.Provider>
  )
}