import styled from 'styled-components';
import * as cor from '../../../styles/colors';

export const AttrName = styled.div`
  font-weight: bold;
  font-size: 14;
`
export const Attr = styled.div`
  font-size: 14;
`

export const ProfilesContainer = styled.div`
  border-radius: 5px;
  border: solid ${cor.corBorda} 1px;
  display: flex;
  flex-direction: row;
  height: 120px;
  padding: 10px;
  justify-content: space-between;
`

export const List = styled.div`
  overflow-x: none;
  overflow-y: scroll;
  min-width: 230px;
  display: flex;
  flex-direction: column;
`

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px 3px;
  border-bottom: 1px solid ${cor.corBorda}
`

export const Input = styled.input`
  margin-top: 8px;
  width: 100%;
  background: #fff;
  border: 0px;
  border-radius: 4px;
  height: 30px;
  padding: 0 15px;
  font-size: 16px;
  color: #666;
  flex: 1;
  border: 1px solid #CCC;

  &:focus {
    border-color: ${props => (props.error ? '#eb4034' : '#87cbff')};
  }

  transition: border-color 0.1s linear;
`

export const IconContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`