import { Auth } from 'aws-amplify';
import { postMeName } from './user/service';

export async function storeUser({ name, email: username, password }) {
  return Auth.signUp({
      username,
      password,
      attributes: {
          name,
          email: username,
      },
      validationData: []  //optional
      });
};

export async function login({ email, password }) {
  return Auth.signIn(email, password);
}

export async function confirm({ email, confirmationCode }) {  
  return Auth.confirmSignUp(email, confirmationCode);
}

export async function resendConfirm(email) {
  return Auth.resendSignUp(email);
}

export async function forgotPassword(email) {
  return Auth.forgotPassword(email);
}

export async function confirmPassword(email, confirmationCode, newPassword) {
  return Auth.forgotPasswordSubmit(email, confirmationCode, newPassword);
}

export async function logout() {
  return Auth.signOut();
}

export async function updateAttributes({ name }) {
  let user = await Auth.currentAuthenticatedUser();

  let result = "";
  await postMeName(name).then(async data => {
     result = await Auth.updateUserAttributes(user, { name });  
  })
  
  if (result === 'SUCCESS') {
    return name;
  }
}

export async function changePassword(user, newPassword) {  
  return await Auth.completeNewPassword(user, newPassword);
}

/* export async function updatePicture(picture) {
  const file = await uploadBase64File(picture);

  const pictureURL = file.key;//getUrlFromFile(file.key);
  
  let user = await Auth.currentAuthenticatedUser();

  const result = await Auth.updateUserAttributes(user, { picture: pictureURL });
  if (result === 'SUCCESS') {
    return pictureURL;
  }

  return;
} */
export async function currentUser() {
  return await Auth.currentAuthenticatedUser();
}

export async function getCurrentSession() {
  return await new Promise((resolve, reject) => {
    Auth.currentSession().then((data) => {
      resolve(data)
    }).catch((err) => {
      reject(err);
    });
  });
}