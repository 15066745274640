import api from '../api'

export async function getAllows() {
  return api.get('branch/allows');
}

export async function getBranchs(description, numOfRegisters = 10, page = 1, order) {
  const params = {
    order: order ? order : 'description',
  }
  if (description) {
    params.description = description.trim(); 
  }

  return api.get('branch', {
    params,
    headers: {
      registerperpage: numOfRegisters,
      page
    }
  });
}

export async function getBranch(branchId) {
  return api.get(`branch/protheus/${branchId}`);
}

export async function putBranch(branchId, data) {
  return api.put(`branch/protheus/${branchId}`, data);
}

export async function findBranch(description, numOfRegisters = 6) {
  return api.get('branch', {
    params: {
      description,
      order: 'description'
    },
    headers: {
      registerperpage: numOfRegisters,
      page: 1
    }
  });
}
