//external
import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
//internal
import Route from '../../routes/Route';
import ProspectDelete from './delete';
import ProspectEdit from './edit';
import Prospect from './list';

export const FilterProspect = createContext({});

export default function Routes() {
  const [filter, setFilter] = useState({
    search: "",
    page: 1,
  });

  let location = useLocation();

  useEffect(() => {
    const split = location?.pathname.split('/');
    if (split[2] !== "prospect") {
      setFilter({
        search: "",
        page: 1,
      });
    }
  }, [location, setFilter]);

  return (
    <FilterProspect.Provider value={{ filter, setFilter }}>
      <Route path="/schedule/prospect" exact component={Prospect} isPrivate/>
      <Route path="/schedule/prospect/edit/:id" exact component={ProspectEdit} isPrivate/>
      <Route path="/schedule/prospect/add" exact component={ProspectEdit} isPrivate/>
      <Route path="/schedule/prospect/delete/:id" exact component={ProspectDelete} isPrivate/>
    </FilterProspect.Provider>
  )
}