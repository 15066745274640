import api from '../api'

export async function getAllows() {
  return api.get('user_history/allows');
}

export async function getUserHistory(query, numOfRegisters = 10, page = 1, order) {
  const params = {
    order: order ? order : 'createdAt desc'
  }

  if (query) {
    params.query = query.trim(); 
  }

  return api.get('user_history', {
    params,
    headers: {
      registerperpage: numOfRegisters,
      page
    }
  });
}