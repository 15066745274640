export function validateEmail(email) {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
}

export function getErrorMessage(field, state, validationArray, validationIndex) {
  let fieldName = field;
  if (validationArray && validationIndex !== undefined) {
    fieldName = `${validationArray}[${validationIndex}].${fieldName}`
  }
  const errs = state.filter(err => err.field === fieldName);
  return errs.map(error => error.message);
}

export function getIsError(field, state, validationArray, validationIndex) {
  let fieldName = field;
  if (validationArray && validationIndex !== undefined) {
    fieldName = `${validationArray}[${validationIndex}].${fieldName}`
  }

  return state.findIndex(err => err.field === fieldName) > -1 
}
