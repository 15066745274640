//external
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import _ from 'lodash';
//internal
import { getRevisions, getRtpi, getRtpiReport, getWarrantyLevelReport } from 'services/rtpi/rtpi/service';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import history from 'services/history';
import ContentPanel from 'components/ContentPanel';
import { setMessage } from 'store/modules/message/action';
import GridContainer from 'components/v2/grid';
import InputSelect from 'components/v2/inputSelect';
import { ellipsis, formatDateTime, formatNumber } from 'utils/formatUtil';
import { showReport } from 'utils/reportUtil';
import { sanitizeErrorUI } from 'utils/errorUtil';
import { showAlert } from 'components/AlertDialog';
import DataTableReact from 'components/v2/dataTableReact';
//style
import { Button, ButtonContainer } from 'styles/components';

export default function Rtpi({match, location}) {
  const message = useSelector(state => state.message.data);
  const {branch, protheusCode} = match.params;
  const [revision, setRevision] = useState(null);
  const [revisions, setRevisions] = useState([]);
  const [dataRtpi, setDataRtpi] = useState({
    productDescription: '',
    revision: '',
    createdAt: '',
    conservationCondition: '',
    productEnrichment: '',
    finishedProductDescription: '',
    productClassification: '',
    productPhysicalForm: '',
    productPackageType: '',
    productPackageForm: '',
    ProductRestriction: '',
    productIndication: '',
    productUseMode: '',
    RtpiWarrantyLevels: [],
    RtpiStructures: [],
    RtpiStructureSubstitutives: [],
    LabelType: [],
    RtpiDiluitions: [],
    RtpiBranch: {
      establishmentName: '',
      cifNumber: '',
      cnpj: '',
      ie: '',
      address: '',
      neighborhood: '',
      city: '',
      zip: '',
      phone: '',
      activity: '',
    },
    allowEdit: '',
  });
  const [referenceValues, setReferenceValues] = useState([])

  const dispatch = useDispatch();

  useEffect(() => {
    if (branch && protheusCode) {
      dispatch(startRequest());
      getRtpi(branch, protheusCode, revision).then(data => {
        const formData = data.data;
        formData.createdAt = formatDateTime(new Date(formData.createdAt));
        setDataRtpi({...formData});
        let newGroups = [];
        formData.ReferenceValues.forEach((item, index) => {
          if (item.group !== formData.ReferenceValues[index > 0 ? index -1 : 0].group) {
            if (item.group !== "") {
              newGroups.push({ 
                group: "",
                label: item.group,
                groupHeader: true,
                diluitionOrder: item.diluitionOrder
              })
            } 
          }
          newGroups.push(item)
        });
        const groupByDiluition = _.mapValues(_.groupBy(newGroups, 'diluitionOrder'));
        setReferenceValues(groupByDiluition)
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, branch, protheusCode, revision]);

  useEffect(() => {
    if (branch && protheusCode) {
      dispatch(startRequest());
      getRevisions(branch, protheusCode).then(data => {
        if (data.data && Array.isArray(data.data)) {
          let arr = [];
          data.data.forEach(element => {
            arr.push(element.revision.toString());
          });
          setRevisions([...arr]);
        }
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, branch, protheusCode]);

  async function handleBack () {
    history.goBack();
  }

  async function handlePrint () {
    if (branch && protheusCode) {
      dispatch(startRequest());
      getRtpiReport(branch, protheusCode, revision).then(res => {
        showReport(res.data);
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }

  async function handlePrintWarrantyLevel () {
    if (branch && protheusCode) {
      dispatch(startRequest());
      getWarrantyLevelReport(branch, protheusCode, revision).then(res => {
        showReport(res.data);
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }

  function handleClickEdit() {
    if (dataRtpi.allowEdit !== true) {
      showAlert('Esta revisão não pode ser editada, selecione a última revisão para conseguir editar.');
      return
    }
    
    history.push(`/premixlabel/rtpi/rtpi/edit/${branch}/${protheusCode}/${dataRtpi.revision}`);    
  }

  const columnsComponents = [
    {
      // maxWidth: "70%",
      maxWidth: "100%",
      name: 'Descrição',
      selector: (row) => ellipsis(row.descriptionComponent, 50),
      sortable: true,
    },
    // {
    //   maxWidth: "30%",
    //   name: 'Quantidade',
    //   selector: (row) => formatNumber(row.quantityComponent, 4),
    //   sortable: false,
    //   right: true,
    // },
  ];

  const columnsComponentsSubs = [
    {
      maxWidth: "100%",
      name: 'Descrição',
      selector: (row) => ellipsis(row.descriptionComponent, 50),
      sortable: true,
    },
  ];

  const columnsNutrients = [
    {
      maxWidth: "50%",
      name: 'Descrição',
      selector: (row) => ellipsis(row.descriptionNutrient, 50),
      sortable: true,
    },
    {
      maxWidth: "25%",
      name: 'Quantidade',
      selector: (row) => formatNumber(row.quantityNutrient, 2),
      right: true,
      sortable: false,
    },
    {
      maxWidth: "25%",
      name: 'Unidade',
      selector: 'unity',
      sortable: false,
    },
  ];

  const columnsReferenceValues = [
    {
      maxWidth: "40%",
      name: 'Descrição',
      selector: 'label',
      sortable: true,
    },
    {
      maxWidth: "20%",
      name: 'Valor de Referência',
      selector: (row) => formatNumber(row.value, 1, true),
      sortable: false,
    },
    {
      maxWidth: "20%",
      name: 'Quantidade por 100g de suplemento',
      selector: (row) => formatNumber(row.qtyHundredGrams, 1, true),
      sortable: false,
    },
    {
      maxWidth: "20%",
      name: 'Quantidade em % do VR por 100g de suplemento',
      selector: (row) => formatNumber(row.percentByHundredGrams, 1, true),
      sortable: false,
    },
  ];

  const conditionalRowStyles = [
    {
      when: row => row.groupHeader,
      style: {
        fontSize: '15px',
        fontWeight: 'bold',
      },
    },
  ];

  const keys = Object.keys(referenceValues)

  return (
    <ContentPanel title='RTPI' message={message}>
      <GridContainer>
        <Grid item xs={12} sm={6}>
          <InputSelect
            xs={12}
            name="revisions"
            label="Revisões"
            value={revision || dataRtpi.revision}
            onChange={(e) => setRevision(e.target.value)}
            defaultValue={null}
            options={revisions}
          />
        </Grid>
      </GridContainer>
      
      <Button style={{marginBottom: '30px', marginTop: '-50px'}} onClick={handleClickEdit}>Editar</Button>

      <GridContainer>
        <Grid item xs={12} sm={3}>
          <Typography color="textPrimary" variant="h6">Filial:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{branch}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography color="textPrimary" variant="h6">Código:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{protheusCode}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography color="textPrimary" variant="h6">Descrição:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{dataRtpi.productDescription}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography color="textPrimary" variant="h6">Data:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{dataRtpi.createdAt}</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography color="textPrimary" variant="h6">Nome estab.:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{dataRtpi.RtpiBranch?.establishmentName}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography color="textPrimary" variant="h6">SIF:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{dataRtpi.RtpiBranch?.cifNumber}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography color="textPrimary" variant="h6">CNPJ:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{dataRtpi.RtpiBranch?.cnpj}</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography color="textPrimary" variant="h6">Endereço:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{dataRtpi.RtpiBranch?.address}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography color="textPrimary" variant="h6">Bairro:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{dataRtpi.RtpiBranch?.neighborhood}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography color="textPrimary" variant="h6">IE:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{dataRtpi.RtpiBranch?.ie}</Typography>
        </Grid>

        <Grid item xs={12} sm={3}>
          <Typography color="textPrimary" variant="h6">Município/UF:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{dataRtpi.RtpiBranch?.city}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography color="textPrimary" variant="h6">CEP:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{dataRtpi.RtpiBranch?.zip}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography color="textPrimary" variant="h6">Telefone:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{dataRtpi.RtpiBranch?.phone}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography color="textPrimary" variant="h6">Atividade:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{dataRtpi.RtpiBranch?.activity}</Typography>
        </Grid>
        
        <Grid item xs={12} sm={6}>
          <Typography color="textPrimary" variant="h6">Condição de conservação:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{dataRtpi.conservationCondition || ''}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography color="textPrimary" variant="h6">Enriquecimento do produto:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{dataRtpi.productEnrichment || ''}</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography color="textPrimary" variant="h6">Indicação:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{dataRtpi.productIndication}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography color="textPrimary" variant="h6">Modo de uso:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{dataRtpi.productUseMode}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography color="textPrimary" variant="h6">Descrição do produto acabado:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{dataRtpi.finishedProductDescription || ''}</Typography>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography color="textPrimary" variant="h6">Classificação:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{dataRtpi.productClassification}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography color="textPrimary" variant="h6">Forma física:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{dataRtpi.productPhysicalForm}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography color="textPrimary" variant="h6">Tipo de embalagem:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{dataRtpi.productPackageType}</Typography>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Typography color="textPrimary" variant="h6">Forma da embalagem:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{dataRtpi.productPackageForm}</Typography>
        </Grid>
        <Grid item xs={6} sm={2}>
          <Typography color="textPrimary" variant="h6">Revisão:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{dataRtpi.revision}</Typography>
        </Grid>   
        <Grid item xs={6} sm={2}>
          <Typography color="textPrimary" variant="h6">Validade:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{dataRtpi.productExpirationDate}</Typography>
        </Grid>   
        <Grid item xs={12} sm={4}>
          <Typography color="textPrimary" variant="h6">Restrição:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{`${dataRtpi.productRestrictionDescription || ""} - ${dataRtpi.productRestrictionRestrictiveText || ""}`}</Typography>
        </Grid>

        <Grid item xs={12} sm={12}>
          <ContentPanel title="Tipo de Etiqueta">
            <Grid item xs={12} sm={12}>
              <Typography color="textPrimary" variant="subtitle2">{dataRtpi.LabelType.description}</Typography>
            </Grid>
            {dataRtpi.RtpiDiluitions.map((item, index) => 
              <div key={index} style={{display: 'flex', margin: '20px 0'}}>
                <Grid item xs={12} sm={3}>
                  <Typography color="textPrimary" variant="h6">Porcentagem:</Typography>
                  <Typography color="textPrimary" variant="subtitle2">{item.percent}</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Typography color="textPrimary" variant="h6">Texto:</Typography>
                  <Typography color="textPrimary" variant="subtitle2">{item.text}</Typography>
                </Grid>
              </div>  
            )}
          </ContentPanel>
        </Grid>  

        {keys.map((item, index) => (
          <Fragment key={index}>
            <Grid item xs={12} sm={6}>
              <ContentPanel 
                message={message} 
                title={dataRtpi.RtpiDiluitions[index]?.text ? `Valor de Referência ${dataRtpi.RtpiDiluitions[index].text}` : 'Valor de Referência'}>
                <DataTableReact
                  noHeader 
                  columns={columnsReferenceValues}
                  data={referenceValues[item]}
                  conditionalRowStyles={conditionalRowStyles}
                />
              </ContentPanel>
            </Grid>
          </Fragment>
        ))}      
         
        <Grid item xs={12} sm={6}>
          <ContentPanel message={message} title="Matéria Prima">
            <DataTableReact
              noHeader
              columns={columnsComponents}
              data={dataRtpi.RtpiStructures}
            />
          </ContentPanel>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ContentPanel message={message} title="Substitutivos">
            <DataTableReact
              noHeader
              columns={columnsComponentsSubs}
              data={dataRtpi.RtpiStructureSubstitutives}
            />
          </ContentPanel>
        </Grid>
        <Grid item xs={12}>
          <ContentPanel message={message} title="Níveis de Garantia">
            <DataTableReact
              noHeader
              columns={columnsNutrients}
              data={dataRtpi.RtpiWarrantyLevels}
            />
          </ContentPanel>
        </Grid>
      </GridContainer>
      <ButtonContainer>
        <br />
        <Button left={true} cancel={true} onClick={handleBack}>
          Voltar
        </Button>
        <Button onClick={handlePrint}>
          Imprimir RTPI
        </Button>
        <Button onClick={handlePrintWarrantyLevel} style={{marginRight: '10px'}}>
          Níveis de Garantia
        </Button>
      </ButtonContainer>
    </ContentPanel>
  )
}