import React, { useContext, useState } from 'react';
import RichTextEditor from 'react-rte';
import { Warning } from 'routes/Route';
import './style.css'

export default function Editor({label, markup, onChange, showLink, hasWarning = true}) {
  const [value, setValue] = useState(!markup ? RichTextEditor.createEmptyValue() : RichTextEditor.createValueFromString(markup, 'html'));

  const { setIsWarning } = useContext(Warning);

  function handleChange(value) {
    setValue(value);
    if (hasWarning) {
      setIsWarning();
    }
    if (onChange && typeof onChange === 'function') {
      // Send the changes up to the parent component as an HTML string.
      // This is here to demonstrate using `.toString()` but in a real app it
      // would be better to avoid generating a string on each change.
      onChange(value.toString('html'));
    }
  };

  let arrDisplay = ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_TYPE_DROPDOWN'];
  if (showLink) {
    arrDisplay.push("LINK_BUTTONS");
  }

  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: arrDisplay,
    INLINE_STYLE_BUTTONS: [
      {label: 'Negrito', style: 'BOLD', className: 'custom-css-class'},
      {label: 'Itálico', style: 'ITALIC'},
      {label: 'Sublinhado', style: 'UNDERLINE'}
    ],
    BLOCK_TYPE_DROPDOWN: [
      {label: 'Normal', style: 'unstyled'},
      {label: 'Grande', style: 'header-one'},
      {label: 'Médio', style: 'header-two'},
      {label: 'Pequeno', style: 'header-three'}
    ],
    BLOCK_TYPE_BUTTONS: [
      {label: 'Lista', style: 'unordered-list-item'},
      {label: 'Lista Ordenada', style: 'ordered-list-item'}
    ],
  };

  return (
    <>
      <p style={{marginBottom: 8, marginTop: 12, color: "#979797" }}>{label}</p>
      <RichTextEditor 
        style={{backgroundColor: 'red'}}
        value={value}
        onChange={handleChange}
        toolbarConfig={toolbarConfig}
      />
    </>        
  );
};
