import React from 'react'
import Route from '../../routes/Route'

import ReferenceValuesEdit from './edit'

export default function Routes() {
  return (
    <>
      <Route path="/premixlabel/rtpi/product_rv" exact component={ReferenceValuesEdit} isPrivate/>
    </>
  )
}