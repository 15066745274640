import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import App from './App';

const themeOptions = {
  palette: {
    primary: { 
        light: '#00AEE2',
        main: '#3F8ED4',
        dark: '#0071B4' 
    },
    secondary: { 
        light: '#FF6F62',
        main: '#EF4F24',
        dark: '#CA2D04'
    },
  },
  overrides: {
    MuiAccordionSummary: {
     content: {
        flexDirection: 'column'     
      } 
    },  
    MuiAccordion: {
      root: {
        "&$expanded":{
          borderLeft: 'solid 6px #3F8ED4',
          borderRadius: '5px'
        }  
      }  
    },
    MuiAccordionDetails: {
      root: {
        padding: '8px 6px 16px'
      }
    } 
  },
};

const themeOptionsStaging = {
  palette: {
    primary: { 
      light: '#FF6F62',
      main: '#EF4F24',
      dark: '#CA2D04'
    },
    secondary: { 
        light: '#00AEE2',
        main: '#3F8ED4',
        dark: '#0071B4' 
    },
  },
  overrides: {
    MuiAccordionSummary: {
     content: {
        flexDirection: 'column'     
      } 
    },  
    MuiAccordion: {
      root: {
        "&$expanded":{
          borderLeft: 'solid 6px #EF4F24',
          borderRadius: '5px'
        }  
      }  
    },
    MuiAccordionDetails: {
      root: {
        padding: '8px 6px 16px'
      }
    } 
  },
};

const theme = process.env.REACT_APP_ENVIRONMENT === 'prod' ? createMuiTheme(themeOptions) : createMuiTheme(themeOptionsStaging);

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <>
      <CssBaseline />
      <App />
    </>
  </MuiThemeProvider>,
  document.getElementById('root')
);
