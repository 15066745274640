import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllowedsRequest } from '../../store/modules/user/actions';
import { getModulesList } from '../../utils/allowedUtil';

export default function AllowedModules() {
  const alloweds = useSelector(state => state.user.alloweds);
  const [allowedModules, setAllowedModules] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    let list = getModulesList(alloweds);
    setAllowedModules(list);
  }, [alloweds]);

  useEffect(() => {    
    dispatch(getAllowedsRequest());
  }, [dispatch]);

  return (
    <React.Fragment>
      {allowedModules}
    </React.Fragment>
  )
}
