import React, { useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core'
import { Switch } from '@material-ui/core'

export default function Input({ name, label, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue } = useField(name);

  const [checked, setChecked] = useState(false) ;

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current.querySelector('input'),
      path: 'checked',
      setValue(ref, value) {
        setChecked(value);
      },
      clearValue(ref) {
        setChecked(false);
      }
    });
  }, [fieldName, registerField]);

  return (
    <>
      <Switch ref={inputRef} checked={checked} onClick={() => setChecked(!checked)} defaultValue={defaultValue} {...rest} />
      {label}
    </>
  )
}