//external
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  ListItemSecondaryAction
} from "@material-ui/core";
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import LabelIcon from '@material-ui/icons/Label';
import InboxIcon from "@material-ui/icons/Inbox";
import EditIcon from "@material-ui/icons/Edit";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Typography from '@material-ui/core/Typography';
import DragAndDrop from 'components/v2/dragAndDrop';
import _ from 'lodash';

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[200]})`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: '50px',
    listStyleType: "none",
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
}));

export function StyledLabelItem(props) {
  const { primary, secondary, group, edit, onClick, item } = props;

  return (
    <>
      {group ? 
        <List style={{padding: '0px'}}>
          <ListItem dense>
            <ListItemIcon>
            {secondary ? <InboxIcon /> : <LabelIcon /> }
            </ListItemIcon>
            <ListItemText
              primary={primary}
              secondary={secondary || "Grupo"}
            />
            <ListItemSecondaryAction>
              {secondary && edit && (
                <IconButton onClick={() => onClick(item)}>
                  <EditIcon />
                </IconButton>
              )}
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      :        
        <ListItem dense>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText
            primary={primary}
            secondary={secondary}
          />          
          <ListItemSecondaryAction>
            {edit && (
              <IconButton onClick={() => onClick(item)}>
                <EditIcon />
              </IconButton>
            )}
          </ListItemSecondaryAction>          
        </ListItem>
      }
    </>
  )
}
function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const { labelText, labelInfo, edit, onClick, item, ...other } = props;

  return (
    <TreeItem
      label={
        <StyledLabelItem 
          primary={labelText}
          secondary={labelInfo}
          group={true}
          edit={edit}
          onClick={onClick}
          item={item}
        />
      }
      classes={{
        root: classes.root,
        expanded: classes.expanded,
        // content: classes.content,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  labelInfo: PropTypes.object,
  labelText: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    maxWidth: 400,
  },
  inline: {
    display: 'flex',
  },
});

export function Tree({data, edit, onClick, reorder}) {
  const [groups, setGroups] = useState({})
  const classes = useStyles();

  useEffect(() => {
    setGroups(data)
    const groupBy = _.mapValues(_.groupBy(data, 'group'));
    setGroups(groupBy);
  }, [data]);

  useEffect(() => {
    reorder(groups)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups]);

  function renderChildren(item) {
    return (
      <StyledLabelItem 
        primary={item.label} 
        secondary={
          <React.Fragment>
            <Typography
              component="span"
              variant="body2"
              className={classes.inline}
              color="textPrimary"
            >
              {item.value}
            </Typography>
            {item.nutrient?.description}
          </React.Fragment>
        } 
        edit={edit} 
        onClick={onClick} 
        item={item} 
      />
    )  
  }

  return (
    <TreeView
      className={classes.root}
      defaultExpanded={[]}
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
    >
      {Object.keys(groups).map((groupItem, index) => (
        <React.Fragment key={`frag-${index}`}>
          {groupItem === '' 
            ? 
              <>
                {groups[groupItem].map((item, idx) => (

                  <StyledTreeItem 
                    key={`nogroup-${idx}`} 
                    nodeId={`idx-${idx}`} 
                    labelText={item.label} 
                    labelInfo={
                      <React.Fragment>
                        <Typography
                          style={{display: 'grid'}}
                          component="span"
                          variant="body2"
                          color="textPrimary"
                        >
                          {item.value.toString()}
                        </Typography>
                        {item.nutrient?.description}
                      </React.Fragment>
                    }
                    edit={edit} 
                    onClick={onClick} 
                    item={item} 
                  /> 
                ))}
              </>
            :    
              <>           
                <StyledTreeItem key={`group-${index}`} nodeId={`index-${index}`} labelText={groupItem}>  
                  <DragAndDrop 
                    xs={12}
                    sm={12}
                    array={groups[groupItem]} 
                    renderChildCallback={(item, index) => renderChildren(item, index)}                    
                    reorderCallback={(newItems) => setGroups({...groups, [groupItem]: newItems})}
                  />  
                </StyledTreeItem>
              </>
          }
        </React.Fragment>
      ))}
    </TreeView>
  );
}
