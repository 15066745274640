//external
import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
//internal
import Route from '../../routes/Route';
import Branch from './list';
import BranchEdit from './edit';

export const FilterBranch = createContext({});

export default function Routes() {
  const [filter, setFilter] = useState({
    search: "",
    page: 1,
  });

  let location = useLocation();

  useEffect(() => {
    const split = location?.pathname.split('/');
    if (split[2] !== "branch") {
      setFilter({
        search: "",
        page: 1,
      });
    }
  }, [location, setFilter]);

  return (
    <FilterBranch.Provider value={{ filter, setFilter }}>
      <Route path="/premixlabel/branch" exact component={Branch} isPrivate/>
      <Route path="/premixlabel/branch/edit/:id" exact component={BranchEdit} isPrivate/>
    </FilterBranch.Provider>
  )
}