//external
import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'inset 0px 0px 2px 0px #b6b6b6',
    borderRadius: '5px',
    width: '97%',
    margin: 'auto',
    marginBottom: '23px'
  },
  header: {
    boxShadow: 'none',
    borderRadius: '6px 6px 0px 0px'
  }
}));

export default function TabComponent({tabs, children, onChangeTabs, value, variant}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar className={classes.header} position="static" color="default">
        <Tabs
          value={value}
          onChange={onChangeTabs}
          indicatorColor="primary"
          textColor="primary"
          variant={variant}
          scrollButtons="auto"
          aria-label="full width tabs example"
        >
          {tabs}
        </Tabs>
      </AppBar>
      <SwipeableViews>
        {children}
      </SwipeableViews>
    </div>
  );
}