//external
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment'
import RefreshIcon from '@material-ui/icons/Refresh';
import 'moment/locale/pt-br'; 
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { Grid, Typography } from '@material-ui/core';
import TodayIcon from '@material-ui/icons/Today';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PrintIcon from '@material-ui/icons/Print';
import VisibilityIcon from '@material-ui/icons/Visibility';
//internal
import ContentPanel from 'components/ContentPanel';
import { showAlert, showConfirm } from 'components/AlertDialog';
import { showDialog } from 'components/Dialog';
import { getAllows, getSchedules, putMoveEvent, getScheduleUsers, getSchedule, deleteSchedule, getScheduleReport } from 'services/schedule/schedule/service';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import { setMessage } from 'store/modules/message/action';
import { sanitizeErrorUI } from 'utils/errorUtil';
import { showReport } from 'utils/reportUtil';
import TableAction from 'components/v2/tableAction';
import { translateSchedule } from 'utils/formatSchedule';
import SelectAutocomplete from 'components/v2/autocomplete';
import { useDebounce } from 'utils/uiUtils';
import Modal from 'components/v2/modal';
import SchedulingEdit from '../edit';
import { formatDateTime } from 'utils/formatUtil';
//style
import './style.sass';
import { Button, ButtonContainer } from 'styles/components';
import { Separator, SeparatorBottom, ContainerModal, ButtonsAlert } from './styled';

export default function Scheduling() {
  const [dates, setDates] = useState(null);
  const message = useSelector(state => state.message.data);
  const [reload, setReload] = useState(0);
  const [events, setEvents] = useState([]);
  const [allows, setAllows] = useState({});
  const [draggedEvent, setDraggedEvent] = useState(null);
  const [eventsApi, setEventsApi] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [scheduleId, setScheduleId] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentView, setCurrentView] = useState("week");
  const [filterDate, setFilterDate] = useState(null);
  //select users
  const [userSelected, setUserSelected] = useState(null);
  const [searchUsers, setSearchUsers] = useState("");
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const debouncedUsers = useDebounce(searchUsers);
  let closeDialogHandler;

  const dispatch = useDispatch();

  const DragAndDropCalendar = withDragAndDrop(Calendar);

  const loadUsers = useCallback((searchValue) => {
    setIsLoading(true)
    getScheduleUsers(searchValue).then(result => {
      const users = result.data.map(element => {
        return { value: element, label: `${element.name}` }
      });
      setOptions(users);
      setIsLoading(false)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      setIsLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    if(loadUsers) loadUsers(debouncedUsers)
  },[debouncedUsers, loadUsers]);

  const actions = [
    <div style={{display: 'flex'}} key='refresh'>
      <TableAction 
      title={"Atualizar"} 
      onClick={refreshHandler}
      isLink={false}>
        <RefreshIcon/>
      </TableAction>
    </div>
  ];

  useEffect(() => {
    updateTimes(currentDate, currentView);
  }, [currentDate, currentView]);

  useEffect(() => {
    if (filterDate) {
      dispatch(startRequest());
      getSchedules(userSelected?.value?.username, filterDate.startDate, filterDate.endDate).then(data => {
        const formData = data.data;
        setEventsApi(formData);
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
      dispatch(startRequest());
      getAllows().then(data => {
        setAllows(data.data)
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }  
  }, [dispatch, reload, filterDate, userSelected]);

  useEffect(() => {
    const normalizeEvents = eventsApi.map(event => ({
      id: event.id,
      title: event.subject,
      allDay: event.allDay,
      start: new Date(event.initialDate),
      end: new Date(event.finalDate),
    }));

    setEvents(normalizeEvents);
  }, [eventsApi])

  const localizer = momentLocalizer(moment);

  function refreshHandler() {
    setReload(reload + 1);
  }

  function getEvent(event) {
    dispatch(startRequest());
    getSchedule(event.id).then(data => {
      const formData = data.data;
      const dialog = showDialog(detailsAlertDialog(event, formData), "Detalhes do evento");
      closeDialogHandler = dialog.removeDialog;
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    }); 
  }

  function detailsAlertDialog(event, formData) {
    return (
      <>
        <Typography color="textPrimary" variant="subtitle2">Data inicial: {formatDateTime(event.start)}</Typography>
        <Typography color="textPrimary" variant="subtitle2">Data final: {formatDateTime(event.end)}</Typography>
        <Typography color="textPrimary" variant="subtitle2">Local: {formData.place}</Typography>
        <Separator/>
        <Typography style={{fontWeight: 'bold'}} variant="subtitle2">Assunto: </Typography>
        <Typography variant="subtitle2">{event.title}</Typography>
        <br/>
        <Typography style={{fontWeight: 'bold'}} variant="subtitle2">Método: </Typography>
        <Typography variant="subtitle2">{formData.method}</Typography>

        <SeparatorBottom/>

        <ButtonsAlert>
          {formData.status === "FINISHED" || formData.status === "CANCELED" ? (
            <TableAction 
            key='viewAlert'
            title={"Visualizar"} 
            onClick={() => handleOkClick(event)}
            isLink={false}>
              <VisibilityIcon />
            </TableAction>
          ) : (
            <>
              <TableAction 
              key='editAlert'
              title={"Editar"} 
              onClick={() => handleOkClick(event)}
              isLink={false}>
                <EditIcon/>
              </TableAction>              
              <TableAction 
              key='deleteAert'
              title={"Excluir"} 
              onClick={() => handleDeleteClick(event)}
              isLink={false}>
                <DeleteIcon/>
              </TableAction>
            </>
          )} 
          <TableAction 
            title={"Imprimir"} 
            disabled={!allows.get} 
            onClick={() => handleClickPrint(event.id)}
            isLink={false}
          >
            <PrintIcon />
          </TableAction>
        </ButtonsAlert>
      </>
    )
  }

  function onDropFromOutside({ start, end, allDay }) {
    const event = {
      id: draggedEvent.id,
      title: draggedEvent.title,
      start: start,
      end: end,
      allDay: allDay,
    }
    setDraggedEvent(null)
    moveEvent({ event, start, end })
  }
  
  function dragFromOutsideItem() {
    return draggedEvent 
  }

  function moveEvent({ event, start, end, isAllDay: droppedOnAllDaySlot })  {
    getSchedule(event.id).then(data => {
      const formData = data.data;
      if (formData.status === "FINISHED" || formData.status === "CANCELED") {
        showAlert('Eventos finalizados ou cancelados não podem ser movidos');
        return 
      }
      const newMoveEvent = {
        "id": event.id,
        "oldInitialDate": event.start,
        "oldFinalDate": event.end,
        "initialDate": start,
        "finalDate": end
      }
  
      putMoveEvent(event.id, newMoveEvent).then(data => {
        let allDay = event.allDay
  
        if (!event.allDay && droppedOnAllDaySlot) {
          allDay = true
        } else if (event.allDay && !droppedOnAllDaySlot) {
          allDay = false
        }
  
        const nextEvents = events.map(existingEvent => {
          return existingEvent.id === event.id
            ? { ...existingEvent, start, end, allDay }
            : existingEvent
        })
      setEvents(nextEvents)
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    }); 
  } 

  function resizeEvent({ event, start, end }) {
    getSchedule(event.id).then(data => {
      const formData = data.data;
      if (formData.status === "FINISHED" || formData.status === "CANCELED") {
        showAlert('Eventos finalizados ou cancelados não podem ser alterados');
        return 
      }

      const newMoveEvent = {
        "id": event.id,
        "oldInitialDate": event.start,
        "oldFinalDate": event.end,
        "initialDate": start,
        "finalDate": end
      }

      putMoveEvent(event.id, newMoveEvent).then(data => {
        const nextEvents = events.map(existingEvent => {
          return existingEvent.id === event.id
            ? { ...existingEvent, start, end }
            : existingEvent
        })

        setEvents(nextEvents)

      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      }); 
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });  
  }

  function handleDragStart(event) {
    setDraggedEvent(event)
  }

  function handleOkClick(event) {
    setScheduleId(event.id);
    setIsOpenModal(true);
    if (closeDialogHandler) {
      closeDialogHandler();
    }
  }
  
  function handleSelectedUser(user) {
    if (user) {
      setUserSelected(user);
    }else {
      setUserSelected(null);
      loadUsers("");
      refreshHandler();
    }
  }

  function handleClickPrint(id) {
    if (id) {
      dispatch(startRequest());
      getScheduleReport(id).then(res => {
        showReport(res.data);
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }

  function handleOpenModal(event) {
    const checkDates = moment(new Date()).isAfter(event.end);

    if (checkDates) {
      return 
    }

    if (event.start) {
      setDates({
        start: new Date(event.start),
        end: new Date(event.end)
      });
    }
    setIsOpenModal(true);
  }

  function handleCloseModal() {
    setIsOpenModal(false);
    setScheduleId(null);
    setDates(null);
  }

  async function handleDeleteClick(event) {
    if (!await showConfirm('Deseja realmente excluir este evento?')) {
      return;
    }

    dispatch(startRequest());
    deleteSchedule(event.id).then(data => {
      refreshHandler();
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      if (closeDialogHandler) {
        closeDialogHandler();
      }
      dispatch(finishRequest());
    });
  }

  function onView(view) {
    setCurrentView(view);
    updateTimes(currentDate, view);
  }

  function onNavigate(date, view){
    const newDate = new Date(date);
    setCurrentDate(newDate);
    updateTimes(newDate, view);
  }

  function updateTimes(date, view) {
    let start, end;
    if(view === 'day'){
      start = moment(date).startOf('day');
      end   = moment(date).endOf('day');
    }else if(view === 'week'){
      start = moment(date).startOf('week');
      end   = moment(date).endOf('week');
    }else if(view === 'month'){
      start = moment(date).startOf('month');
      end   = moment(date).endOf('month');
    }else if(view === 'agenda'){
      start = moment(date).startOf('day');
      end   = moment(date).endOf('day').add(1, 'month');
    }
    setFilterDate({
      startDate: new Date(start),
      endDate: new Date(end)
    }); 
  }

  return (
    <ContentPanel title="Agendamento" message={message} actions={actions}>
      <div style={{display: 'flex'}}>
        <SelectAutocomplete
          xs={12}
          sm={6}
          value={userSelected}
          label="Buscar Usuários"
          optionSelected={(option, value) => option.label === value.label}
          optionLabel={(option) => option.label}
          onChangeSelected={(event, value) => handleSelectedUser(value)}
          onChange={(event) => setSearchUsers(event.target.value)}
          options={options}
          name="users"
          isLoading={isLoading}
        />
       
        <Grid item xs={12} sm={6}>
          <ButtonContainer>
            <Button 
              style={{display: 'flex', alignItems: 'center'}}  
              onClick={handleOpenModal}
            >
              <TodayIcon style={{color: 'white'}}/>
              Agendar
            </Button>
          </ButtonContainer>  
        </Grid>   
      </div>

      <Modal
        isOpen={isOpenModal} 
        key={scheduleId}
      >
        <>
          <ContainerModal> 
            <TableAction 
            key='closeModal'
            title={"Fechar"} 
            disabled={!allows.post} 
            onClick={handleCloseModal}
            isLink={false}>
              <CloseIcon/>
            </TableAction>

            <SchedulingEdit 
              refreshCallback={refreshHandler}
              scheduleId={scheduleId} 
              dates={dates} 
              setIsOpenModal={(changeOpen) => setIsOpenModal(changeOpen)}
            />
          </ContainerModal>  
         </>
      </Modal>
      
      <div style={{marginTop: '15px'}}>
        <DragAndDropCalendar 
          events={events}
          resizable={true}
          localizer={localizer} 
          messages={translateSchedule}
          startAccessor="start" 
          endAccessor="end" 
          style={{height : 500}} 
          onSelectSlot={handleOpenModal}
          selectable
          onSelectEvent={(data, event) => getEvent(data, event)}
          defaultView={currentView}
          popup={true}
          onDropFromOutside={onDropFromOutside}
          handleDragStart={handleDragStart}
          onEventDrop={moveEvent}
          onEventResize={resizeEvent}
          dragFromOutsideItem={dragFromOutsideItem}
          onNavigate={(date, view) => {onNavigate(date, view)}} 
          onView={(view) => {onView(view)}}
          defaultDate={currentDate}
        />
      </div>
    </ContentPanel>   
  )
}