//external
import React, { useEffect, useState, useCallback } from 'react';
//internal
import GridContainer from 'components/v2/grid';
import { validateForm } from 'components/Form/validate';
import * as checklistUtil from '@premix/checklist-util';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import { postFiles } from 'services/files/service';
import { useDispatch } from 'react-redux';
import { setMessage } from 'store/modules/message/action';
import { sanitizeErrorUI } from 'utils/errorUtil';
import { showConfirm } from 'components/AlertDialog';
import ChecklistField from 'components/ChecklistField';
//style
import { Button, ButtonContainer } from 'styles/components';
import { Separator } from 'pages/schedule/list/styled';

export default function ChecklistAnswer({dataAnswer, answerList, onSaveAnswer}) {
  const [formValue, setFormValue] = useState([]);
  const [errors, setErrors] = useState([]);
  const [justify, setJustify] = useState([]);
  const [formValueJustify, setFormValueJustify] = useState({});
  const [items, setItems] = useState([]);

  const dispatch = useDispatch();

  const schema = checklistUtil.getChecklistSchema(dataAnswer?.answer?.checklist?.items);

  const setLocalAnswers = useCallback((answers) => {
    if (answerList?.length > 0) {
      const index = answerList.findIndex(item => item.checklistId === dataAnswer.answer.checklist.id)
      answerList[index].items.forEach((i) => {
        const index = answers.findIndex(item => item.id === i.id);
        answers[index].value = i.value;          
      });
    }
    return answers;
  }, [answerList, dataAnswer]);

  useEffect(() => {
    const items = dataAnswer?.answer?.answer?.items || [];
    if (items && items.length) { 
      items.forEach((item, index) => {
        if (item.justify !== null) {
          setJustify(j => [...j, {
            inputId: item.id, 
            index
          }]);
        }
      });
      const questions = dataAnswer.answer.checklist.items;
      const answers = items.map(i => {
        const index = questions.findIndex(item => i.id === item.id);
        return {
          id: i.id,
          fieldType: questions[index]?.fieldType,
          value: i.value,
          justify: i.justify
        }
      });

      const newAnswers = setLocalAnswers(answers);
      setFormValue(newAnswers);
    } else {
      const answers = dataAnswer?.answer?.checklist?.items?.map(i => ({
        id: i.id,
        fieldType: i.fieldType,
        value: i.fieldType === 'BOOLEAN' ? false : i.fieldType === 'IMAGE_LIST' ? [] : null,
        justify: null,
      }));

      const newAnswers = setLocalAnswers(answers);
      setFormValue(newAnswers);
    } 
  }, [dataAnswer, answerList, setLocalAnswers]);

  const postFile = useCallback((file) => {
    return new Promise((resolve, reject) => {
      dispatch(startRequest());
      postFiles(file).then(data => {
        resolve(data.data);
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
        reject(err);
      }).finally(() => {
        dispatch(finishRequest());
      });
    });
  }, [dispatch]);

  const handleChangeImage = useCallback(async (e, id) => {
    const { name, files } = e.target;
    const index = formValue.findIndex(i => i.id === parseInt(name));
    const newFormValue = [...formValue];
    if (index > -1) {
      const file = files[0];      
      const data = await postFile(file);
      const itemFile = {
        id: data.id,
        uuid: data.fileUuid,
        url: URL.createObjectURL(file),
      } 
      newFormValue[index].value = itemFile;      
      setFormValue(newFormValue);
      const filter = errors.filter(i => i.field !== id)
      setErrors(filter);
    }
  }, [errors, formValue, postFile]);

  const handleChangeImageMultiple = useCallback(async (e, id) => {
    const { name, files } = e.target;
    const index = formValue.findIndex(i => i.id === parseInt(name));
    const newFormValue = [...formValue];
    if (index > -1) {
      const arrFiles = Array.from(files);
      arrFiles.forEach(async (file) => {
        const data = await postFile(file);
        const itemFile = {
          id: data.id,
          url: URL.createObjectURL(file),
          uuid: data.fileUuid,
        }        
        newFormValue[index].value.push(itemFile);
      });      
      setFormValue(newFormValue);
      const filter = errors.filter(i => i.field !== id)
      setErrors(filter);
    }
  }, [errors, formValue, postFile]);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    const index = formValue.findIndex(i => i.id === parseInt(name));
    const newFormValue = [...formValue];
    if (index > -1) {
      newFormValue[index].value = value;
      setFormValue(newFormValue);
    }
  }, [formValue]);

  const handleChecked = useCallback((e) => {
    const { name, checked } = e.target;
    const index = formValue.findIndex(i => i.id === parseInt(name));
    const newFormValue = [...formValue];
    if (index > -1) {
      newFormValue[index].value = checked;
      setFormValue(newFormValue);
    }
  }, [formValue]);

  const handleChangeDate = useCallback((value, index) => {
    const newFormValue = [...formValue];
    newFormValue[index].value = value;
    setFormValue(newFormValue);
  }, [formValue]);

  const handleChangeJustify = useCallback((e) => {
    const { name, value } = e.target;
    const index = formValue.findIndex(i => i.id === parseInt(name));
    const newFormValue = [...formValue];
    if (index > -1) {
      newFormValue[index].justify = value;
      setFormValueJustify(newFormValue);
    }
  }, [formValue]);

  const handleChangeInputSelect = useCallback((index, event, id) => {
    const {value} = event.target;
    const objectSelect = dataAnswer.answer?.checklist.items.find(i => i.id === id);
    const option = objectSelect.selectOptions.find(item => item.option === value);
    if (option?.hasJustify) {
      const indexJustify = justify.findIndex(i => i.inputId === id);
      if (indexJustify === -1) {
        setJustify([...justify, {
          inputId: id, 
          index
        }]);
      }
    } else {
      setJustify(justify.filter(i => i.inputId !== id));
      if (justify.length === 0) {
        const newObject = {...formValueJustify}
        delete newObject[id]
        setFormValueJustify(newObject);
      }
    }
    const answerIndex = formValue.findIndex(i => i.id === parseInt(id));
    const newFormValue = [...formValue];
    newFormValue[answerIndex].value = value;
    setFormValue(newFormValue);
    setFormValueJustify(newFormValue[answerIndex].justify = null);
  }, [dataAnswer.answer, formValue, formValueJustify, justify]);  

  const handleSaveAnswer = useCallback(async () => {
    const result = formValue.reduce((obj, cur) => (
      {...obj, [cur.id]: cur.fieldType !== 'BOOLEAN' ? cur.value : cur.value === null || cur.value === false ? false : true} 
    ), {});

    const yupSchema = checklistUtil.getYupSchema(schema);
    const errorsList = await validateForm(result, yupSchema);

    if (errorsList.length > 0) {
      setErrors(errorsList);
      return;
    }
    
    if (formValue) {
      const answer = {
        checklistId: dataAnswer.answer?.checklist.id,
        items: formValue 
      }
      onSaveAnswer(answer);
    } 
  }, [dataAnswer.answer, formValue, onSaveAnswer, schema]);

  const removeImage = useCallback(async (id, idx = -1) => {
    if (!await showConfirm(`Deseja realmente remover a imagem ${idx === -1 ? '1' : idx}?`)) {
      return;
    }
    const index = formValue.findIndex(i => i.id === id);
    const newFormValue = [...formValue];
    if (index > -1) {
      if (idx === -1) {
        newFormValue[index].value = null;
      } else {
        const arrayImg = Array.from(newFormValue[index].value);
        arrayImg.splice(idx, 1);
        newFormValue[index].value = arrayImg;
      }
      setFormValue(newFormValue);
    }
  }, [formValue]);

  const getAnswer = useCallback((i) => {
    return formValue?.find(item => item.id === i.id);
  }, [formValue]);

  useEffect(() => {
    setItems(dataAnswer?.answer?.checklist?.items || []);
  }, [dataAnswer]);

  return (
    <div style={{width: '100%', minHeight: '500px'}}>
      <Separator>Checklist</Separator>
      <GridContainer>
        {items?.map((item, index) => {          
          const answer = getAnswer(item); 
          return (
            <ChecklistField
              key={item.id}
              id={item.id}
              index={index}
              fieldType={item.fieldType}
              fieldName={item.fieldName}
              required={item.required}              
              answer={answer}
              errors={errors}
              selectOptions={item?.selectOptions || []}             
              handleChange={handleChange}
              handleChecked={handleChecked}
              handleChangeDate={handleChangeDate}
              handleChangeImage={handleChangeImage}
              handleChangeImageMultiple={handleChangeImageMultiple}
              handleChangeInputSelect={handleChangeInputSelect}              
              handleChangeJustify={handleChangeJustify}
              removeImage={removeImage}
              disabled={!onSaveAnswer}
            />
          );
        })}
      </GridContainer>
      <ButtonContainer>        
        {onSaveAnswer && (
          <Button type="submit" onClick={handleSaveAnswer}>Salvar</Button>
        )}
      </ButtonContainer>
    </div>
  )
}