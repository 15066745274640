import api from '../api'

export async function getAllows() {
  return api.get('appversion/allows');
}

export async function getAllVersions(numOfRegisters = 10, page = 1, order) {
  const params = {
    order: order ? order : 'appname',
  }
  return api.get('appversion', {
    params,
    headers: {
      registerperpage: numOfRegisters,
      page
    }
  });
}

export async function getVersion(id) {
  return api.get(`appversion/${id}`);
}

export async function putVersion(id, version) {
  return api.put(`appversion/${id}`, version);
}

export async function postVersion(version) {
  return api.post(`appversion/`, version);
}

export async function deleteVersion(id) {
  return api.delete(`appversion/${id}`);
}