//external
import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
//internal
import Route from '../../routes/Route';
import AppVersions from './list';
import AppVersionEdit from './edit';
import AppVersionDelete from './delete';

export const FilterAppversion = createContext({});

export default function Routes() {
  const [filter, setFilter] = useState({
    page: 1,
  });

  let location = useLocation();

  useEffect(() => {
    const split = location?.pathname.split('/');
    if (split[2] !== "appversion") {
      setFilter({
        page: 1,
      });
    }
  }, [location, setFilter]);

  return (
    <FilterAppversion.Provider value={{ filter, setFilter }}>
      <Route path="/premixapp/appversion" exact component={AppVersions} isPrivate/>
      <Route path="/premixapp/appversion/add" exact component={AppVersionEdit} isPrivate/>
      <Route path="/premixapp/appversion/edit/:id" exact component={AppVersionEdit} isPrivate/>
      <Route path="/premixapp/appversion/delete/:id" exact component={AppVersionDelete} isPrivate/>
    </FilterAppversion.Provider>
  )
}