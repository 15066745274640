//external
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import { MenuItem } from '@material-ui/core';
//internal
import ContentPanel from 'components/ContentPanel';
import { setMessage } from 'store/modules/message/action';
import { sanitizeErrorUI } from 'utils/errorUtil';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import GridContainer from 'components/v2/grid';
import InputText from 'components/v2/inputText';
import { getContact, findProspect, findCustomer, getContactQualifier, getContactRole, putContact, postContact } from 'services/contact/service';
import history from 'services/history';
import { validateForm } from 'components/Form/validate';
import { useDebounce } from 'utils/uiUtils';
import SelectAutocomplete from 'components/v2/autocomplete';
import { IconContainer } from 'pages/user/list/styled';
import TableAction from 'components/v2/tableAction';
import DataTableReact from 'components/v2/dataTableReact';
import InputSelectChildren from 'components/v2/inputSelectChildren';
import Toggle from 'components/v2/toggle';
import { contactSchema } from '../schemas';
import { Warning } from 'routes/Route';
//style
import { Button, ButtonContainer } from 'styles/components';

export default function ContactEdit({match}) {
  const contactId = match.params.id;
  const message = useSelector(state => state.message.data);
  const [dataContact, setDataContact] = useState({
    ContactQualifier: "",
    CustomerContacts: "",
    ContactRole: ""
  });
  const [errors, setErrors] = useState([]);
  const [contactQualifierOptions, setContactQualifierOptions] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [contactRoleOptions, setContactRoleOptions] = useState([]);
  //select Prospect
  const [selectedProspect, setSelectedProspect] = useState([]);
  const [searchProspect, setSearchProspect] = useState("");
  const [optionsProspect, setOptionsProspect] = useState([]);
  const [itemSelectedProspect, setItemSelectedProspect] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  //select Customer Contacts
  const [selectedCustomerContacts, setSelectedCustomerContacts] = useState([]);
  const [searchCustomerContacts, setSearchCustomerContacts] = useState("");
  const [optionsCustomerContacts, setOptionsCustomerContacts] = useState([]);
  const [itemSelectedCustomerContacts, setItemSelectedCustomerContacts] = useState(null);
  const [isLoadingCustomerContacts, setIsLoadingCustomerContacts] = useState(false); 

  const { setNotWarning } = useContext(Warning);

  const debouncedProspect = useDebounce(searchProspect);
  const debouncedCustomerContacts = useDebounce(searchCustomerContacts);

  const dispatch = useDispatch();

  const loadProspect = useCallback((searchValue) => {
    setIsLoading(true)
    findProspect(searchValue).then(result => {
      const prospect = result.data.map(element => {
        return { value: element, label: `${element.customerName}` }
      });
      setOptionsProspect(prospect);
      setIsLoading(false)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      setIsLoading(false);
    });
  }, [dispatch])

  useEffect(() => {
    if(loadProspect) loadProspect(debouncedProspect)
  },[debouncedProspect, loadProspect]);

  const loadCustomerContact = useCallback((searchValue) => {
    setIsLoadingCustomerContacts(true)
    findCustomer(searchValue).then(result => {
      const customerContacts = result.data.map(element => {
        return { value: element, label: `${element.nome}` }
      });
      setOptionsCustomerContacts(customerContacts);
      setIsLoadingCustomerContacts(false)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      setIsLoadingCustomerContacts(false);
    });
  }, [dispatch])

  useEffect(() => {
    if(loadCustomerContact) loadCustomerContact(debouncedCustomerContacts)
  },[debouncedCustomerContacts, loadCustomerContact]); 

  useEffect(() => {
    dispatch(startRequest());
    getContactQualifier().then(data => {
      setContactQualifierOptions(data.data);
    });
    getContactRole().then(data => {
      setContactRoleOptions(data.data);
    });
    dispatch(finishRequest());
    if (contactId) {
      dispatch(startRequest());
      getContact(contactId).then(data => {
        const formData = data.data;
        setDataContact({
          id: formData.id,
          name: formData.name,
          phone: formData.phone, 
          email: formData.email,
          ContactRole: formData.ContactRole || null,
          notes: formData.notes,
          ContactQualifier: formData.ContactQualifier
        });
        setIsActive(formData.active,)
        setSelectedProspect(formData.ProspectContacts);
        setSelectedCustomerContacts(formData.CustomerContacts);
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, contactId]);

  const columnsProspect = [
    {
      maxWidth: "75%",
      name: 'Nome',
      selector: 'Prospect.customerName',
      sortable: true,
    },
    {
      maxWidth: "75%",
      name: 'Fazenda',
      selector: 'Prospect.farmName',
      sortable: true,
    },
    {
      maxWidth: "25%",
      style: {justifyContent: "flex-end"},
      cell: row => 
      <>
        <div>
          <IconContainer>
            <TableAction 
            title={"Excluir"} 
            onClick={() => handleRemoveProspect(row)}>
              <DeleteIcon/>
            </TableAction>
          </IconContainer>
        </div>
      </>,
    }
  ];

  const columnsClient = [
    {
      maxWidth: "75%",
      name: 'Nome',
      selector: 'name',
      sortable: true,
    },
    {
      maxWidth: "75%",
      name: 'Fazenda',
      selector: 'farmName',
      sortable: true,
    },
    {
      maxWidth: "75%",
      name: 'Loja',
      selector: 'customerStore',
      sortable: true,
    },
    {
      maxWidth: "25%",
      style: {justifyContent: "flex-end"},
      cell: row => 
      <>
        <div>
          <IconContainer>
            <TableAction 
            title={"Excluir"} 
            onClick={() => handleRemoveClient(row)}>
              <DeleteIcon/>
            </TableAction>
          </IconContainer>
        </div>
      </>,
    }
  ]; 

  function handleRemoveProspect(prospect) {
    var prospectIndex = selectedProspect.indexOf(prospect);
    selectedProspect.splice(prospectIndex, 1);
    setSelectedProspect([...selectedProspect]);
  }

  function handleRemoveClient(client) {
    var clientIndex = selectedCustomerContacts.indexOf(client);
    selectedCustomerContacts.splice(clientIndex, 1);
    setSelectedCustomerContacts([...selectedCustomerContacts]);
  }

  function handleProspectInputChange(data, event) {
    if (data) {
      const prospectIndex = selectedProspect.findIndex(item => item.Prospect.id === data?.value?.id);
      setItemSelectedProspect(data);
      if (prospectIndex >= 0) return;

    setSelectedProspect([...selectedProspect, { Prospect: {
      customerName: data.value.customerName,
      id: data.value.id,
      farmName: data.value.farmName
    }}]); 
    } else {
      setItemSelectedProspect(null);
      loadProspect("");
    } 
  }

  function handleCustomerContactsInputChange(data, event) {
    if (data) {
      const clientIndex = selectedCustomerContacts.findIndex(item => item.customerId === data?.value?.codigo);
      setItemSelectedCustomerContacts(data);
      if (clientIndex >= 0) return;
      setSelectedCustomerContacts([...selectedCustomerContacts, {
        name: data.value.nome,
        customerId: data.value.codigo,
        customerStore: data.value.loja,
        farmName: data.value.fazenda
      }]); 
    } else {
      setItemSelectedCustomerContacts(null);
      loadCustomerContact("");
    }
  } 

  function handleChangeContactQualifier(value) {
    const contactQualifier = contactQualifierOptions.find(item => item.id === value)
    setDataContact({...dataContact, ContactQualifier: contactQualifier});
  }

  function handleChangeContactRoler(value) {
    const contactRole = contactRoleOptions.find(item => item.id === value)
    setDataContact({...dataContact, ContactRole: contactRole});
  }

  async function handleSubmit() {
    setNotWarning();
    const objCopy = JSON.parse(JSON.stringify(dataContact));
    delete objCopy.ContactQualifier;
    delete objCopy.ContactRole;
    objCopy.active = isActive;
    objCopy.contactQualifierId = dataContact.ContactQualifier.id;
    objCopy.contactRoleId = dataContact.ContactRole.id;
    const selectedProspectsIds = selectedProspect.map(item => ({id: item.Prospect.id}));
    objCopy.prospects = selectedProspectsIds;
    const selectedClients = selectedCustomerContacts.map(item => ({id: item.customerId, store: item.customerStore}));
    objCopy.customers = selectedClients;

    const errorsList = await validateForm(objCopy, contactSchema);
    if (errorsList.length > 0) {
      setErrors(errorsList);
      return
    }
    
    if (contactId) {
      dispatch(startRequest());
      putContact(contactId, objCopy).then(data => {
        history.push('/schedule/contact')
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    } else {
      dispatch(startRequest());
      postContact(objCopy).then(data => {
        history.push('/schedule/contact')
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }

  return (
    <ContentPanel title="Contato" message={message}>
      <GridContainer>
        <Toggle
          xs={12}
          name="active"
          checked={isActive}
          onChange={(e) => setIsActive(e.target.checked) }
          label="Ativo"
        />
        <InputText
          xs={12}
          sm={6}
          required
          name="name"
          label="Nome"
          type="text"
          value={dataContact.name}
          onChange={(e) => setDataContact({...dataContact, name: e.target.value})}
          errors={errors}
        />
        {contactRoleOptions.length > 0 && (
          <InputSelectChildren
            xs={12}
            sm={6}
            required
            name="contactRoleId"
            label="Cargo"
            value={dataContact.ContactRole?.id}
            onChange={(e) => handleChangeContactRoler(e.target.value)}
            defaultValue={null}
            errors={errors}
          >
            {contactRoleOptions.map(contactRole => (
              <MenuItem key={contactRole.id} value={contactRole.id}>{contactRole.description}</MenuItem>
            ))}
          </InputSelectChildren>
        )}  
        <InputText
          xs={12}
          sm={6}
          required
          name="phone"
          label="Telefone"
          type="text"
          value={dataContact.phone}
          onChange={(e) => setDataContact({...dataContact, phone: e.target.value})}
          errors={errors}
        />
        <InputText
          xs={12}
          sm={6}
          name="email"
          label="E-mail"
          type="text"
          value={dataContact.email}
          onChange={(e) => setDataContact({...dataContact, email: e.target.value})}
          errors={errors}
        />
        <InputText
          xs={12}
          sm={6}
          name="notes"
          label="Notas"
          type="text"
          value={dataContact.notes}
          onChange={(e) => setDataContact({...dataContact, notes: e.target.value})}
          errors={errors}
        />

        <InputSelectChildren
          xs={12}
          sm={6}
          required
          name="contactQualifierId"
          label="Qualificação"
          value={dataContact.ContactQualifier.id}
          onChange={(e) => handleChangeContactQualifier(e.target.value)}
          defaultValue={null}
          errors={errors}
        >
          {contactQualifierOptions.map(ContactQualifier => (
            <MenuItem key={ContactQualifier.id} value={ContactQualifier.id}>{ContactQualifier.description}</MenuItem>
          ))}
        </InputSelectChildren>

        <SelectAutocomplete
          xs={12}
          sm={12}
          value={itemSelectedProspect}
          label="Buscar Prospect"
          optionSelected={(option, value) => option.label === value.label}
          optionLabel={(option) => option.label}
          onChangeSelected={(event, value) => handleProspectInputChange(value)}
          onChange={(event) => setSearchProspect(event.target.value)}
          options={optionsProspect}
          name="prospects"
          isLoading={isLoading}
        />

        <DataTableReact
          noHeader
          columns={columnsProspect}
          data={selectedProspect}
        />

        <SelectAutocomplete
          xs={12}
          sm={12}
          value={itemSelectedCustomerContacts}
          label="Buscar Cliente"
          optionSelected={(option, value) => option.label === value.label}
          optionLabel={(option) => option.label}
          onChangeSelected={(event, value) => handleCustomerContactsInputChange(value)}
          onChange={(event) => setSearchCustomerContacts(event.target.value)}
          options={optionsCustomerContacts}
          name="CustomerContacts"
          isLoading={isLoadingCustomerContacts}
        />

        <DataTableReact
          noHeader
          columns={columnsClient}
          data={selectedCustomerContacts}
        />

      </GridContainer>

      <ButtonContainer>
        <Button type="submit" onClick={handleSubmit}>Salvar</Button>
      </ButtonContainer>
    </ContentPanel>
  );
}