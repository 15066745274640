import React from 'react'
import Route from '../../routes/Route'

import List from './list'
import Edit from './edit'

export default function Routes() {
  return (
    <>
      <Route path="/user" exact component={List} isPrivate/>
      <Route path="/user/add" exact component={Edit} isPrivate/>
      <Route path="/user/edit/:email" exact component={Edit} isPrivate/>
    </>
  )
}