import * as Yup from 'yup';

export const loginSchema = Yup.object().shape({
  email: Yup.string().required('Nome é obrigatório.'),
  password: Yup.string().required('Informe a senha').min(6, 'A senha precisa de no minimo 6 caracteres.')
});

export const storeSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório.'),
  email: Yup.string().email('E-Mail inválido.').required('E-Mail é obrigatório.'),
  password: Yup.string().required('Informe a senha').min(6, 'A senha precisa de no minimo 6 caracteres.'),
  confirmPassword: Yup.string().oneOf([Yup.ref('password')], 'As senhas não conferem.')
});

export const confirmSchema = Yup.object().shape({
  email: Yup.string().email('E-Mail inválido.').required('E-Mail é obrigatório.'),
  confirmationCode: Yup.string().required('O código de confirmação')
});

export const forgotMailSchema = Yup.object().shape({
  email: Yup.string().email('E-Mail inválido.').required('E-Mail é obrigatório.'),
});

export const forgotPassSchema = Yup.object().shape({
  email: Yup.string().email('E-Mail inválido.').required('E-Mail é obrigatório.'),
  code: Yup.string().required('Código de ativação é obrigatório.'),
  password: Yup.string().required('Informe a senha').min(6, 'A senha precisa de no minimo 6 caracteres.'),
  confirmPassword: Yup.string().oneOf([Yup.ref('password')], 'As senhas não conferem.')
});

export const changePassSchema = Yup.object().shape({
  email: Yup.string().email('E-Mail inválido.').required('E-Mail é obrigatório.'),
  password: Yup.string().required('Informe a senha').min(6, 'A senha precisa de no minimo 6 caracteres.'),
  confirmPassword: Yup.string().oneOf([Yup.ref('password')], 'As senhas não conferem.')
});