import api from '../api'

export async function getCustomers(id_salesman, query, numOfRegisters = 10, page = 1, order) {
  const params = {
    id_salesman,
    order: order ? order : 'nome'
  }
  if (query) {
    params.query = query.trim();
  }

  return api.get('customer', {
    params,
    headers: {
      registerperpage: numOfRegisters,
      page
    }
  });
}

export async function getCustomerById(id) {
  return api.get(`customer/${id}`);
}

export async function getCustomer(id, store) {
  return api.get(`customer/${id}/${store}`);
}

export async function getContactQualifier() {
  return api.get('customer/contactQualifier');
}

export async function getContact(query, numOfRegisters = 10) {
  return api.get('customer/contact', {
    params: {
      query,
      order: 'name'
    },
    headers: {
      registerperpage: numOfRegisters,
      page: 1
    }
  });
}

export async function getContactRole() {
  return api.get('customer/contactRole');
}

export async function putCustomer(id, store, data) {
  return api.put(`customer/${id}/${store}`, data);
}