//external
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import _ from 'lodash';
//internal
import { getAllows, getReferenceValues, putReferenceValues } from 'services/rtpi/referenceValues/service';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import ContentPanel from 'components/ContentPanel';
import { Tree } from 'components/Tree';
import { validateForm } from 'components/Form/validate';
import { setMessage } from 'store/modules/message/action';
import { referenceValuesSchema } from '../schemas';
import GridContainer from 'components/v2/grid';
import Errors from 'components/Errors';
import SearchNutrient from '../searchNutrient';
import { sanitizeErrorUI } from 'utils/errorUtil';
import { showAlert } from 'components/AlertDialog';
//style
import { Button, ButtonContainer } from 'styles/components';

export default function ReferenceValues({match}) {
  const message = useSelector(state => state.message.data);

  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  
  const [allows, setAllows] = useState({});
  const [data, setData] = useState([])
  const [errors, setErrors] = useState([]);
  const [newData, setNewData] = useState([])

  const [groups, setGroups] = useState([]);

  const dispatch = useDispatch();

  const actions = [
    <div style={{display: 'flex'}} key="actionsReferenceValues">
      <Tooltip title="Adicionar" style={{float: 'right'}}>
        <div>
          <IconButton onClick={onClickAdd} disabled={!allows.put}>
            <AddIcon />
          </IconButton>
        </div>
      </Tooltip>
    </div>
  ];

  useEffect(() => {
    getAllows().then(data => {
      setAllows(data.data)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    });
  }, [dispatch]);

  useEffect(() => {    
    dispatch(startRequest());
    getReferenceValues().then(data => {
      const formData = data.data;
      if (Array.isArray(formData)) {
        for (const values of formData) {
          if (values.nutrients) {
            values.nutrient = values.nutrients.map(elem => elem.id).join();
          }
        }
      }
      setData([...formData]);
      
      if (Array.isArray(formData)) {
        let items = [];
        formData.forEach(element => {
          if (element.group && element.group !== "") {
            let itemIndex = _.findIndex(items, (e) => { 
              return e === element.group; 
            });

            if (itemIndex < 0) {
              items.push(element.group);
            }
          }
        });
        setGroups([...items]);
      }
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });    
  }, [dispatch])

  async function handleSubmit (event) {
    event.preventDefault();
    const errorsList = await validateForm(data, referenceValuesSchema);
    if (errorsList.length > 0) {
      setErrors(errorsList);
      return;
    } 
    dispatch(startRequest());
    putReferenceValues(newData).then(result => {
      setData(newData);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });

    showAlert('Dados salvos com sucesso');
  }

  const handleClose = () => {    
    setOpen(false);    
  }

  function handleSave(item) {
    const list = Array.from(data);
    let itemIndex = _.findIndex(list, (e) => { 
      return e.id === item.id; 
    });
    
    item.value = Number(item.value);

    if (itemIndex < 0) {
      list.push(item);
    } else {
      list[itemIndex] = item;
    }

    changeOrders(list);    
    setData([...list]);
    setOpen(false);    
  }

  function handleDelete(item) {
    const list = Array.from(data);
    let itemIndex = _.findIndex(list, (e) => { 
      return e.id === item.id; 
    });    
    list.splice(itemIndex, 1);
    
    changeOrders(list);    
    setData([...list]);
    setOpen(false);    
  }

  function onClickEdit(item) {
    setSelectedItem(item);
    setOpen(true);
  }

  function onClickAdd() {
    setSelectedItem(null);
    setOpen(true);
  }

  function changeOrders(list) {
    var order = 1;
    list.forEach(element => {
      element.order = order;
      order++;
    });
  }

  function newListItems(newItems) {
    var order = 0
    let newArray = []
    Object.keys(newItems).forEach(i => {
      newItems[i].forEach(item => {
        item.order = ++order
        newArray.push(item)
      })
    });
    setNewData(newArray)

  }
  
  return (    
    <ContentPanel title='RTPI - Valor de Referência' message={message} actions={actions}>  
      <SearchNutrient 
        isOpen={open}
        onClickSave={handleSave}
        onClickDelete={handleDelete}
        onHandleClose={handleClose}
        item={selectedItem}
        groups={groups}
      /> 
      {errors && Array.isArray(errors) && errors.length > 0 && (
        <Errors errors={errors} />  
      )}           

      <GridContainer>
        <Grid item xs={10}>
          <Tree data={data} edit={allows.put} onClick={onClickEdit} reorder={(newItems) => newListItems(newItems)}/>
        </Grid> 
      </GridContainer>

      <ButtonContainer>
        <Button type="submit" onClick={handleSubmit} disabled={!allows.put}>Salvar</Button>
      </ButtonContainer>        
    </ContentPanel>    
  )
}