import api from '../api'

export async function findSalesman(name, numOfRegisters = 6) {
  return api.get('salesman', {
    params: {
      name,
      order: 'nome'
    },
    headers: {
      registerperpage: numOfRegisters,
      page: 1
    }
  });
}

export async function getHierarchy() {
  return api.get('salesman/hierarchy');
}

export async function getMe() {
  return api.get('salesman/me');
}