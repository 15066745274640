//external
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
//internal
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import { getCustomer } from 'services/customer/service';
import ContentPanel from 'components/ContentPanel';
import { setMessage } from 'store/modules/message/action';
import { sanitizeErrorUI } from 'utils/errorUtil';
import GridContainer from 'components/v2/grid';
import DataTableReact from 'components/v2/dataTableReact';
//style
import { Separator } from './styled';

export default function CustomerView({match}) {
  const customerId = match.params.id;
  const customerStore = match.params.store;

  const message = useSelector(state => state.message.data);
  const [customer, setCustomer] = useState({
    CustomerContacts: [],
  });

  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(startRequest());
    getCustomer(customerId, customerStore).then(data => {
      const formData = data.data
      setCustomer(formData)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }, [dispatch, customerId, customerStore]);

  const columnsContacts = [
    {
      maxWidth: "40%",
      name: 'Nome',
      selector: "Contact.name",
      sortable: true,
    },
    {
      maxWidth: "40%",
      name: 'Função',
      selector: "Contact.contactRole",
      sortable: true,
    },
  ];

  return (
    <ContentPanel message={message} title="Cliente">
      {customer &&
        <>
          <Separator>Informações da conta</Separator>
          <GridContainer>
            <Grid item xs={12} sm={6}>
              <Typography color="textPrimary" variant="h6">Razão Social:</Typography>
              <Typography color="textPrimary" variant="subtitle2">{customer.nome}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography color="textPrimary" variant="h6">Fazenda:</Typography>
              <Typography color="textPrimary" variant="subtitle2">{customer.fazenda}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography color="textPrimary" variant="h6">Codigo cliente ERP:</Typography>
              <Typography color="textPrimary" variant="subtitle2">{customer.codigo}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography color="textPrimary" variant="h6">Codigo fazenda ERP:</Typography>
              <Typography color="textPrimary" variant="subtitle2">{customer.loja}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography color="textPrimary" variant="h6">Classificação:</Typography>
              <Typography color="textPrimary" variant="subtitle2">{customer.classificacao}</Typography>
            </Grid>
          </GridContainer>
          <Separator>Informações fiscais</Separator>
          <GridContainer>
            <Grid item xs={12} sm={6}>
              <Typography color="textPrimary" variant="h6">Tipo de clientel:</Typography>
              <Typography color="textPrimary" variant="subtitle2">{customer.tipo}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography color="textPrimary" variant="h6">Fisica/Juridica:</Typography>
              <Typography color="textPrimary" variant="subtitle2">{customer.fisJur}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography color="textPrimary" variant="h6">CPF:</Typography>
              <Typography color="textPrimary" variant="subtitle2">{customer.cpf}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography color="textPrimary" variant="h6">CNPJ:</Typography>
              <Typography color="textPrimary" variant="subtitle2">{customer.cnpj}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography color="textPrimary" variant="h6">Inscrição estadual:</Typography>
              <Typography color="textPrimary" variant="subtitle2">{customer.iestadual}</Typography>
            </Grid>
          </GridContainer>
          
          {customer.cobranca && 
            <>
              <Separator>Informações de cobrança</Separator>
              <GridContainer>
                <Grid item xs={12} sm={6}>
                  <Typography color="textPrimary" variant="h6">Responsável pela cobrança:</Typography>
                  <Typography color="textPrimary" variant="subtitle2">{customer.cobranca.contato}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography color="textPrimary" variant="h6">Teledone de cobrança:</Typography>
                  <Typography color="textPrimary" variant="subtitle2">{customer.cobranca.telefone}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography color="textPrimary" variant="h6">E-mail de cobrança:</Typography>
                  <Typography color="textPrimary" variant="subtitle2">{customer.cobranca.email}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography color="textPrimary" variant="h6">Endereço de cobrança:</Typography>
                  <Typography color="textPrimary" variant="subtitle2">{customer.cobranca.endereco}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography color="textPrimary" variant="h6">Cidade da cobrança:</Typography>
                  <Typography color="textPrimary" variant="subtitle2">{customer.cobranca.cidade}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography color="textPrimary" variant="h6">CEP:</Typography>
                  <Typography color="textPrimary" variant="subtitle2">{customer.cobranca.cep}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography color="textPrimary" variant="h6">Bairro:</Typography>
                  <Typography color="textPrimary" variant="subtitle2">{customer.cobranca.bairro}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography color="textPrimary" variant="h6">Estado:</Typography>
                  <Typography color="textPrimary" variant="subtitle2">{customer.cobranca.estado}</Typography>
                </Grid>
              </GridContainer>
            </>  
          }
          {customer.entrega &&
          <>
            <Separator>Informações de Entrega</Separator>
            <GridContainer>
              <Grid item xs={12} sm={6}>
                <Typography color="textPrimary" variant="h6">Endereço de entrega:</Typography>
                <Typography color="textPrimary" variant="subtitle2">{customer.entrega.endereco}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography color="textPrimary" variant="h6">Cidade de entrega:</Typography>
                <Typography color="textPrimary" variant="subtitle2">{customer.entrega.cidade}</Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography color="textPrimary" variant="h6">CEP:</Typography>
                <Typography color="textPrimary" variant="subtitle2">{customer.entrega.cep}</Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography color="textPrimary" variant="h6">Bairro:</Typography>
                <Typography color="textPrimary" variant="subtitle2">{customer.entrega.bairro}</Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography color="textPrimary" variant="h6">Estado:</Typography>
                <Typography color="textPrimary" variant="subtitle2">{customer.entrega.estado}</Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography color="textPrimary" variant="h6">Observações:</Typography>
                <Typography color="textPrimary" variant="subtitle2">{customer.entrega.obs}</Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography color="textPrimary" variant="h6">Roteiro:</Typography>
                <Typography color="textPrimary" variant="subtitle2">{customer.entrega.roteiro}</Typography>
              </Grid>
              </GridContainer>
            </>
          }
          <Separator>Contatos</Separator>
          <Grid item xs={12} sm={12}>
          <ContentPanel message={message}>
            <DataTableReact
              noHeader
              columns={columnsContacts}
              data={customer.CustomerContacts}
            />
          </ContentPanel>
        </Grid>
        </> 
      } 
    </ContentPanel>
  )
}