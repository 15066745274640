//external
import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
//internal
import Route from '../../routes/Route';
import Group from './list';
import GroupEdit from './edit';

export const FilterGroups = createContext({});

export default function Routes() {
  const [filter, setFilter] = useState({
    search: "",
    page: 1,
  });

  let location = useLocation();

  useEffect(() => {
    const split = location?.pathname.split('/');
    if (split[2] !== "groups") {
      setFilter({
        search: "",
        page: 1,
      });
    }
  }, [location, setFilter]);

  return (
    <FilterGroups.Provider value={{ filter, setFilter }}>
      <Route path="/premixlabel/product_group" exact component={Group} isPrivate/>
      <Route path="/premixlabel/product_group/edit/:id" exact component={GroupEdit} isPrivate/>
    </FilterGroups.Provider>
  )
}