export const showReport = (pdf) => {
  if (pdf) {
    const file = new Blob([pdf], {
      type: "application/pdf"
    });
    const fileURL = URL.createObjectURL(file);

    var win = window.open(fileURL, "_blank");
    if (win) {
      win.focus();
    }
  }
}
