import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { confirmSchema } from './schemas'
import logo from '../../assets/images/logo-cinza.png'
import { Container, Content, ContainerButton, Input, TextButton } from './styled'
import { Form } from '@unform/web'

import Loading from '../../components/Loading'

import { confirmRequest } from '../../store/modules/user/actions'
import { validateEmail } from '../../utils/validationUtil'

import * as cognito from '../../services/cognito';
import Message from '../../components/Message'
import { translate } from '../../services/translateCognitoErrors'
import { setMessage } from '../../store/modules/message/action'

export default function ConfirmAccount({ location }) {
  let { email } = location.state ? location.state : '';
  const message = useSelector(state => state.message.data);

  const dispatch = useDispatch();
  const loading = useSelector(state => state.loading.loading);

  function handleSubmit ({ email, confirmationCode }, { resetForm }) {
    dispatch(confirmRequest(email, confirmationCode));
  };

  function handleResendClick (email) {
    if (!validateEmail(email)) {
      dispatch(setMessage({error: true, text: 'E-Mail inválido, verifique!'}))
      return;
    }

    try {
      cognito.resendConfirm(email); 
      dispatch(setMessage({info: true, text: 'Código de confirmação reenviado com sucesso!'}));
    } catch (err) {
      dispatch(setMessage({error: true, text: translate(err)}));
    }
  }

  return (
    <>
      {loading && <Loading />}
      <Container>
        <Content>
          <img src={logo} alt="Premix" />
          <Message message={message} />
          <Form schema={confirmSchema} onSubmit={handleSubmit}>
            <Input name="email" type="email" placeholder="Seu e-mail" value={email} onChange={(event) => email = event.target.value} />
            <Input name="confirmationCode" type="text" placeholder="Codigo de confirmação" />
            <ContainerButton>
              <button type="submit">
                {loading ? 'Carregando...' : 'Confirmar'}
              </button>
            </ContainerButton>
            <TextButton onClick={() => handleResendClick(email)}>Reenviar código de ativação</TextButton>
          </Form>
        </Content>
      </Container>
    </>
  )
}