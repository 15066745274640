//external
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//internal
import { deleteEmailTemplate, getEmailTemplate } from 'services/emailTemplate/service';
import history from 'services/history';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import ContentPanel from 'components/ContentPanel';
import { setMessage } from 'store/modules/message/action';
import { Button, ButtonContainer } from 'styles/components';
//style
import { Attr, AttrName } from './styled';
import { sanitizeErrorUI } from 'utils/errorUtil';

export default function EmailTemplateDelete({match}) {
  const emailTemplateId = match.params.id;
  const message = useSelector(state => state.message.data);
  const [emailTemplate, setEmailTemplate] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    if (emailTemplateId) {
      dispatch(startRequest());
      getEmailTemplate(emailTemplateId).then(data => {
        setEmailTemplate(data.data);
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, emailTemplateId]);

  async function handleDelete () {
    dispatch(startRequest());
    deleteEmailTemplate(emailTemplateId).then(data => {
      history.push('/email_template')
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }

  async function handleBack () {
    history.goBack();
  }

  return (
    <>
      <ContentPanel title="Deseja excluir este modelo de e-mail?" message={message}>
        {emailTemplate && <>
          <AttrName>Nome do Modelo:</AttrName>
          <Attr>{emailTemplate.templateName}</Attr>
          <AttrName>Assunto:</AttrName>
          <Attr>{emailTemplate.subject}</Attr>
          <AttrName>Corpo do e-mail HTML:</AttrName>
          <Attr>{emailTemplate.bodyHtml}</Attr>
          <AttrName>Corpo do e-mail texto:</AttrName>
          <Attr>{emailTemplate.bodyText}</Attr>

          <ButtonContainer>
            <br />
            <Button left={true} cancel={true} onClick={handleBack}>
              Voltar
            </Button>
            <Button onClick={handleDelete}>
              Excluir
            </Button>
          </ButtonContainer>
        </>}
      </ContentPanel>
    </>
  )
}