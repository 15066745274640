//external
import React, { useCallback, useEffect, useState, useContext, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import PrintIcon from '@material-ui/icons/Print';
import RefreshIcon from '@material-ui/icons/Refresh';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
//internal
import { getDocuments, getAllows, findDocumentTypes, printDocuments as printMerge } from 'services/document/service';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import ContentPanel from 'components/ContentPanel';
import { setMessage } from 'store/modules/message/action';
import TableAction from 'components/v2/tableAction';
import { sanitizeErrorUI } from 'utils/errorUtil.js';
import DataTableReact from 'components/v2/dataTableReact/index.js';
import GridContainer from 'components/v2/grid';
import { useDebounce } from 'utils/uiUtils';
import InputText from 'components/v2/inputText';
import SelectAutocomplete from 'components/v2/autocomplete';
import DateAndTimePickers from 'components/v2/dateTimePicker';
import { FilterDocument } from '../index';
import { formatStatusDocument } from 'utils/formatUtil';
import { formatDateTime } from 'utils/formatUtil';
import { getFileId } from 'services/files/service.js';
import { showReport } from 'utils/reportUtil';
import Modal from 'components/v2/modal';
import { showAlert, showConfirm } from 'components/AlertDialog';
//style
import { IconContainerProd } from '../styled.js';
import { ContainerModal } from 'pages/schedule/list/styled';

export const CustomStatus = ({ row }) => (
	<div>
		<div 
      data-tag="allowRowEvents"
			style={{ 
        overflow: 'hidden', 
        whiteSpace: 'pre-wrap', 
        textOverflow: 'ellipses',
      }}
    >
      <span style={{
        color: row.status === 'APPROVED' ? "#008000" : row.status === 'REPROVED' ? "#dc3545" : "#ffcc00",
        fontWeight: 'bold',
      }}>
        {formatStatusDocument(row.status)}
      </span>
    </div>
	</div>
);

export default function Document() {
  const message = useSelector(state => state.message.data);
  
  const [documents, setDocuments] = useState();
  const [allows, setAllows] = useState({});
  const [reload, setReload] = useState(0);
  //pagination
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  //select types
  const [searchTypes, setSearchTypes] = useState("");
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  //
  const [printDocuments, setPrintDocuments] = useState([]);
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [hideColumnSelect, setHideColumnSelect] = useState(true);

  const { filter, setFilter } = useContext(FilterDocument);

  const debouncedTypes = useDebounce(searchTypes);
  const debouncedSearch = useDebounce(filter.search);

  const dispatch = useDispatch();

  const loadTypes = useCallback((searchValue) => {
    setIsLoading(true)
    findDocumentTypes(searchValue).then(result => {
      const types = result.data.map(element => {
        return { value: element, label: `${element.name}` }
      });
      setOptions(types);
      setIsLoading(false)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      setIsLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    if(loadTypes) loadTypes(debouncedTypes)
  },[debouncedTypes, loadTypes]);

  const findSelectedId = (document) => {
    return printDocuments.findIndex((elem) => elem.id === document.id) > -1
  }

  const removeDocument = useCallback((index) => {
    printDocuments.splice(index, 1);
    setPrintDocuments([...printDocuments]); 
  }, [printDocuments]);

  const handlePrintAllChange = function (event) {
    var docToAdd = [];

    for (let document of documents) {
      var index = printDocuments.findIndex((elem) => elem.id === document.id);
      if (index === -1) {
        docToAdd.push(document);
      }
    }
    setPrintDocuments([...printDocuments, ...docToAdd]);
  }

  const handleChangeCheckbox = useCallback(async (event, document) => {
    const index = printDocuments.findIndex((elem) => elem.id === document.id);
    if (index > -1) {
      if (!openPrintModal) {
        removeDocument(index); 
      } else {
        if (await showConfirm(`Deseja realmente remover o documento ${document.name} da lista de impressão?`)) {
          removeDocument(index);
        }
      }
    } else {
      setPrintDocuments([...printDocuments, document]);
    }

    if (!printDocuments.length) {
      handleClosePrintModal();
    }
  }, [openPrintModal, printDocuments, removeDocument]);

  const columnsPrint = [
    {
      name: 'Nome',
      selector: 'name',
      sortable: false,
    },
    {
      name: 'Usuário',
      selector: 'username',
      sortable: false,
    },
    {
      name: 'Vendedor',
      selector: row => `${row.salesmanId} - ${row.salesmanName}`,
      sortable: false,
    },
    {
      name: 'Status',
      cell: row => <CustomStatus row={row} />,
      sortable: false,
    },
    {
      name: 'Tipo',
      selector: row => row?.DocumentType?.name || '',
      sortable: false,
    },
    {
      style: {justifyContent: "flex-end"},
      cell: row => 
      <div>
        <IconContainerProd>
          <TableAction 
            title={"Remover"}  
            onClick={(e) => handleChangeCheckbox(e, row)}
          >
            <DeleteIcon />
          </TableAction>         
        </IconContainerProd>
      </div>
    }
  ];

  const columns = [
    {
      selector: 'enabled',
      sortable: false,
      cell: row => <input type='checkbox' checked={findSelectedId(row)} onChange={(e) => handleChangeCheckbox(e, row)} />,
      name: <input type='checkbox' onChange={(e) => handlePrintAllChange(e)}/>,
      center: true,
      width: '5%',
      omit: hideColumnSelect,
    },
    {
      name: 'Nome',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Usuário',
      selector: 'username',
      sortable: true,
    },
    {
      name: 'Vendedor',
      selector: row => `${row.salesmanId} - ${row.salesmanName}`,
      sortable: true,
    },
    {
      name: 'Status',
      cell: row => <CustomStatus row={row} />,
      sortable: true,
    },
    {
      name: 'Tipo',
      selector: row => row?.DocumentType?.name || '',
      sortable: true,
    },
    {
      name: 'Data',
      selector: 'createdAt',
      sortable: true,
    },
    {
      name: 'Observação',
      cell: row => <div title={row.notes}>{row.notes}</div>
    },
    {
      style: {justifyContent: "flex-end"},
      cell: row => 
      <div>
        <IconContainerProd>
          <TableAction 
            title={"Editar"} 
            disabled={!allows.put} 
            to={`/document/edit/${row.id}`}>
              <EditIcon />
          </TableAction>
          <TableAction 
            title={"Imprimir"} 
            disabled={!allows.get} 
            onClick={() => openDocument(row?.File)}
            isLink={false}
          >
            <PrintIcon />
          </TableAction>          
        </IconContainerProd>
      </div>
    }
  ];

  const actions = [
    <div style={{display: 'flex'}} key='actionsDocument'>
      <TableAction 
        title={"Imprimir documentos"}
        disabled={!allows.get} 
        onClick={() => handlePrintDocuments()}
        isLink={false}
      >
        <PrintIcon/>
      </TableAction>
      <TableAction 
        title={"Atualizar"} 
        onClick={refreshHandler}
        isLink={false}>
          <RefreshIcon/>
      </TableAction>
    </div>
  ];

  const handlePrintDocuments = async () => {
    if (!printDocuments.length) {      
      await showAlert('Selecione um ou mais documentos para impressão.', 'Imprimir documentos', 'Ok');
      setHideColumnSelect(false);
    } else {
      setOpenPrintModal(true);
    }
  }

  const confirmPrintDocuments = useCallback(() => {
    const printIds = printDocuments.map((item) => item.id);
    dispatch(startRequest());
    printMerge(printIds).then(async (response) => {   
      if (response?.data) {
        showReport(response.data);
      }
      handleClosePrintModal();
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }, [dispatch, printDocuments]);

  function refreshHandler() {
    setReload(reload + 1);
  }

  function openDocument(file) {
    if (!file) {
      return;
    }

    const {id, fileUuid} = file;
    
    if (id && fileUuid) {
      dispatch(startRequest());
      getFileId(id, fileUuid).then(async (response) => {   
        if (response?.data) {
          showReport(response.data)
        }
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }

  useEffect(() => {
    dispatch(startRequest());
    getDocuments(debouncedSearch, filter.status?.value, filter.type?.value?.id, filter?.initialDate, filter?.finalDate, perPage, filter.page).then(data => {
      setTotalRows(parseInt(data.headers['recordcount']));
      setDocuments(data.data);
      setDocuments(data.data.map(value => {
        value.createdAt = formatDateTime(new Date(value.createdAt));

        return value;
      }));
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
    dispatch(startRequest());
    getAllows().then(data => {
      setAllows(data.data)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, reload, perPage, filter.page, debouncedSearch, filter.type, filter.status, filter.initialDate, filter.finalDate]);

  const handlePerRowsChange = (perPage) => {
    setPerPage(perPage);
  };

  const handlePageChange = (page) => {
    setFilter({...filter, page});
  };

  function handleSelectedType(type) {
    if (type) {
      setFilter({...filter, page: 1, type: type});
    }else {
      setFilter({...filter, page: 1, type: null});
      loadTypes("");
      refreshHandler();
    }
  }

  function handleSelectedStatus(status) {
    if (status) {
      setFilter({...filter, page: 1, status: status});
    }else {
      setFilter({...filter, page: 1, status: null});
      refreshHandler();
    }
  }

  const updateInitalDateField = (date) => {
    setFilter({...filter, initialDate: date});
  }

  const updateFinalDateField = (date) => {
    setFilter({...filter, finalDate: date});
  }

  function handleClosePrintModal() {
    setOpenPrintModal(false);
  }

  const handleRemoveAll = useCallback(async () => {
    if (!await showConfirm(`Deseja realmente remover todos os documentos selecionados?`)) {
      return;
    }
    setPrintDocuments([]);
    handleClosePrintModal();
  }, []);

  const ModalMemo = useMemo(() => {
    return (
      <Modal
        isOpen={openPrintModal} 
        maxWidth="md"
        onClickDelete={handleRemoveAll}
        labelDelete="Remover todos"
        onClickCancel={handleClosePrintModal}
        labelCancel="Cancelar"
        onClickSave={confirmPrintDocuments}
        labelSave="Imprimir"
        titleClose
        title={"Imprimir documentos"}
        onClose={handleClosePrintModal}
      >
        <ContainerModal> 
          <DataTableReact          
            style={{overflow: 'hidden'}}
            noHeader
            columns={columnsPrint}
            data={printDocuments}
          />
        </ContainerModal>  
      </Modal>
    )
  }, [columnsPrint, confirmPrintDocuments, handleRemoveAll, openPrintModal, printDocuments]);

  return (
    <ContentPanel title="Documentos" message={message} actions={actions}>
      <GridContainer>
        <InputText
          xs={12}
          name="search"
          label="Pesquisar"
          type="text"
          placeholder="Pesquisar"
          value={filter.search}
          onChange={(e) => setFilter({...filter, page: 1, search: e.target.value})}
          hasWarning={false}
        />
        <SelectAutocomplete
          xs={12}
          sm={6}
          value={filter.type}
          label="Buscar Tipo de Documento"
          optionSelected={(option, value) => option.label === value.label}
          optionLabel={(option) => option?.label || ''}
          onChangeSelected={(event, value) => handleSelectedType(value)}
          onChange={(event) => setSearchTypes(event.target.value)}
          options={options}
          name="types"
          isLoading={isLoading}
          hasWarning={false}
        />
        <SelectAutocomplete
          xs={12}
          sm={6}
          value={filter.status}
          label="Buscar Status"
          optionSelected={(option, value) => option.label === value.label}
          optionLabel={(option) => option?.label || ''}
          onChangeSelected={(event, value) => handleSelectedStatus(value)}
          options={[
            {value: 'PENDING' , label: 'Pendente' },
            {value: 'APPROVED', label: 'Aprovado' },
            {value: 'REPROVED', label: 'Reprovado'},
          ]}
          name="status"
          hasWarning={false}
        />
        <DateAndTimePickers
          xs={12}
          sm={6}
          name="initialDate"
          label="Filtrar por data inicial"
          value={filter.initialDate}
          onChange={updateInitalDateField}
          cancelLabel="Cancelar"
          okLabel="Salvar"
          hasWarning={false}
        />
        <DateAndTimePickers
          xs={12}
          sm={6}
          name="finalDate"
          label="Filtrar por data Final"
          value={filter.finalDate}
          onChange={updateFinalDateField}
          cancelLabel="Cancelar"
          okLabel="Salvar"
          hasWarning={false}
        />

        <DataTableReact
          noHeader
          columns={columns}
          data={documents}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationDefaultPage={filter.page}
        />
      </GridContainer>
      
      {openPrintModal && ModalMemo}
    </ContentPanel>
  )
}