import styled from 'styled-components';

export const CheckboxContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 15px;
  border: solid 1px #c4c4c4;
  border-radius: 5px;
  margin: 6px;
  align-items: center;
  justify-content: space-between;
`