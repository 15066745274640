import api from '../api';

export async function getFileId(id, uuid) {
  return api.get(`files/${id}/${uuid}`, {
    responseType: 'blob',
  });
}
export async function getFile(url, id, uuid) {
  return api.get(`files/${url}/${id}/${uuid}`, {
    responseType: 'blob',
  });
}

export async function postFiles(files) {
  const formData = new FormData()
  formData.append('file', files);
  return api.post(`files/upload/`, formData, {
    headers: {
      'content-type': 'multipart/form-data'
    }
  })  
}
