import styled from 'styled-components'

export const Input = styled.input`
  margin-top: 8px;
  width: 100%;
  background: #fff;
  border: 0px;
  border-radius: 4px;
  height: 30px;
  padding: 0 15px;
  font-size: 16px;
  color: #666;
  flex: 1;
  border: 1px solid #CCC;

  &:focus {
    border-color: ${props => (props.error ? '#eb4034' : '#87cbff')};
  }

  transition: border-color 0.1s linear;
`

export const IconContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`