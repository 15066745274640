import api from '../../api'

export async function getAllows() {
  return api.get('rtpi_intention/allows');
}

export async function getStrings() {
  return api.get('rtpi_intention/stringvalidations');
}

export async function getLabelTypes() {
  return api.get('rtpi_intention/labeltypes');
}

export async function getRestrictions(description, numOfRegisters = 15) {
  return api.get('rtpi_intention/restrictions', {
    params: {
      description,
      order: 'description'
    },
    headers: {
      registerperpage: numOfRegisters,
      page: 1
    }
  });
}

export async function getIntentions(query, numOfRegisters = 10, page = 1, order) {
  const params = {
    order,
    only_registered: true,
  }
  if (query) {
    params.query = query.trim(); 
  }
  
  return api.get('rtpi_intention', {
    params,
    headers: {
      registerperpage: numOfRegisters,
      page
    }
  });
}

export async function getIntention(intentionId) {
  return api.get(`rtpi_intention/${intentionId}`);
}

export async function putIntention(intentionId, data) {
  return api.put(`rtpi_intention/${intentionId}`, data);
}

export async function action(nameAction, intentionId) {
  const body = {
    "id": intentionId,
    "action": nameAction,
  };
  return api.post(`rtpi_intention/action/${intentionId}`, body);
}
