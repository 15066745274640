import api from '../api';

export async function getAllows() {
  return api.get('schedule_todo/allows');
}
  
export async function getScheduleTodos(query, numOfRegisters = 10, page = 1, order) {
  const params = {
    order: order ? order : 'initialDate desc',
  }
  if (query) {
    params.query = query.trim(); 
  }

  return api.get('schedule_todo', {
    params,
    headers: {
      registerperpage: numOfRegisters,
      page
    }
  });
}

export async function getScheduleTodo(id) {
  return api.get(`schedule_todo/${id}`);
}

export async function getSchedulesTodoChecklist(id) {
  return api.get(`schedule_todo/full/${id}`);
}

export async function getScheduleTodoUsers(username) {
  const params = {};

  if (username) {
    params.query = username; 
  }

  return api.get('schedule_todo/users', {
    params,
    headers: {
      registerperpage: 6,
      page: 1
    }
  });
}

export async function getScheduleTypesTodo() {
  return api.get('schedule_todo/scheduleTypes');
}

export async function getScheduleTypesIdTodo(id) {
  return api.get(`schedule_todo/scheduleTypes/${id}`);
}

export async function getTodoCustomers(nome, numOfRegisters = 6) {
  return api.get('schedule_todo/customers', {
    params: {
      nome,
      order: 'nome'
    },
    headers: {
      registerperpage: numOfRegisters,
      page: 1
    }
  });
} 

export async function getTodoCustomersId(id, store) {
  return api.get(`schedule_todo/customers/${id}/${store}`);
}

export async function getTodoProspect(customerName, numOfRegisters = 6) {
  return api.get('schedule_todo/prospect', {
    params: {
      customerName,
      order: 'customerName'
    },
    headers: {
      registerperpage: numOfRegisters,
      page: 1
    }
  });
}

export async function getTodoProspectId(id) {
  return api.get(`schedule_todo/prospect/${id}`);
}

export async function postScheduleTodo(schedule_todo, data) {
  return api.post(`schedule_todo/`, schedule_todo, data);
}

export async function putScheduleTodo(id, data) {
  return api.put(`schedule_todo/${id}`, data);
}

export async function putStart(id, data) {
  return api.put(`schedule_todo/checkin/${id}`, data);
}

export async function putFinish(id, data) {
  return api.put(`schedule_todo/checkout/${id}`, data);
}

export async function putConfirm(id, data) {
  return api.put(`schedule_todo/confirm/${id}`, data);
}

export async function putCancel(id, data) {
  return api.put(`schedule_todo/cancel/${id}`, data);
}

export async function putMoveEventTodo(id, data) {
  return api.put(`schedule_todo/time/${id}`, data);
}

export async function deleteScheduleTodo(id) {
  return api.delete(`schedule_todo/${id}`);
}

export async function getScheduleReport(id) {
  return api.get(`schedule_todo/report/${id}`, {responseType: 'blob'});
}

export async function revertStatusScheduleTodo(id) {
  return api.put(`schedule_todo/revert-status/${id}`);
}