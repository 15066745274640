//external
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Button as MuiButton, Grid, Typography} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
//internal
import { getFileId } from 'services/files/service';
import { getDocument, putStatusDocument } from 'services/document/service';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import history from 'services/history';
import ContentPanel from 'components/ContentPanel'
import { setMessage } from 'store/modules/message/action'
import { sanitizeErrorUI } from 'utils/errorUtil';
import InputMultiline from 'components/v2/inputMultiline';
import { validateForm } from 'components/Form/validate';
import { reproveDocumentSchema, approveDocumentSchema } from '../schemas';
import GridContainer from 'components/v2/grid';
import { formatStatusDocument, formatDateTime } from 'utils/formatUtil';
//style
import { Button, ButtonContainer } from 'styles/components';

export default function DocumentEdit({match}) {
  const message = useSelector(state => state.message.data);
  const {id} = match.params;  
  
  const [errors, setErrors] = useState([]);
  const [url, setUrl] = useState(null);
  const [data, setData] = useState(null);
  //dialog
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(null);

  const dispatch = useDispatch();

  const handleOpen = (status) => {
    setStatus(status);
    setOpen(true);
  }

  const handleClose = () => {
    setStatus(null);
    setOpen(false);
  }

  useEffect(() => {
    if (id) {
      dispatch(startRequest());
      getDocument(id).then(async (response) => {
        setData(response?.data);
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (data?.File) {
      const {id, fileUuid} = data?.File;
      if (id && fileUuid) {
        dispatch(startRequest());
        getFileId(id, fileUuid).then(async (response) => {           
          const blob = new Blob([response.data], {type: 'application/pdf'});        
          const url = URL.createObjectURL(blob);
          setUrl(url);        
        }).catch(err => {
          dispatch(setMessage(sanitizeErrorUI(err)));
        }).finally(() => {
          dispatch(finishRequest());
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, data?.File]);

  async function updateDocument() {
    if (!data) {
      return;
    }    
    
    const formData = {
      id,
      status,
      reason: data.reason || '',
    }    
        
    const errorsList = await validateForm(formData, status === 'REPROVED' ? reproveDocumentSchema : approveDocumentSchema);

    if (errorsList.length > 0) {
      setErrors(errorsList);
      return;
    }    

    dispatch(startRequest());
    putStatusDocument(id, formData).then((response) => {
      handleBack();
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
      handleClose();
    });
  }

  async function handleBack () {
    history.goBack();
  }
  
  return (
    <ContentPanel title='Documento' message={message}>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>          
          <DialogTitle style={{padding: '16px 0px'}} id="alert-dialog-title">
            Confirmação
          </DialogTitle>          
          <DialogContentText id="alert-dialog-description">
            {`Deseja realmente ${status === 'APPROVED' ? 'aprovar' : 'reprovar'} o documento "${data?.name}"?`}
          </DialogContentText>
          {status === 'REPROVED' && (
            <InputMultiline
              type="text"
              name="reason"
              label="Motivo"
              value={data?.reason || ''}
              onChange={(e) => setData({...data, reason: e.target.value})}
              errors={errors}
              hasWarning={false}
            />
          )}
        </DialogContent>        
        <DialogActions>
          <MuiButton onClick={handleClose} color="secondary">
            Cancelar
          </MuiButton>
          <MuiButton onClick={updateDocument} color="primary" autoFocus>
            {status === 'REPROVED' ? 'Reprovar' : 'Aprovar'}
          </MuiButton>
        </DialogActions>        
      </Dialog>
      <GridContainer>
        <Grid item xs={12} sm={3}>
          <Typography color="textPrimary" variant="h6">Nome:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{data?.name}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography color="textPrimary" variant="h6">Tipo:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{data?.DocumentType?.name}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography color="textPrimary" variant="h6">Data:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{formatDateTime(data?.createdAt)}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography color="textPrimary" variant="h6">Status:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{formatStatusDocument(data?.status)}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography color="textPrimary" variant="h6">Usuário:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{data?.username}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography color="textPrimary" variant="h6">Vendedor:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{data?.salesmanId} - {data?.salesmanName}</Typography>
        </Grid>
        {data?.status === 'APPROVED' && (
          <>
            <Grid item xs={12} sm={6}>
              <Typography color="textPrimary" variant="h6">Aprovado por:</Typography>
              <Typography color="textPrimary" variant="subtitle2">{data?.UserApprove?.name}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography color="textPrimary" variant="h6">Aprovado em:</Typography>
              <Typography color="textPrimary" variant="subtitle2">{formatDateTime(data?.approvedAt)}</Typography>
            </Grid>
          </>
        )}
        {data?.status === 'REPROVED' && (
          <>
            <Grid item xs={12} sm={6}>
              <Typography color="textPrimary" variant="h6">Reprovado por:</Typography>
              <Typography color="textPrimary" variant="subtitle2">{data?.UserReprove?.name}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography color="textPrimary" variant="h6">Reprovado em:</Typography>
              <Typography color="textPrimary" variant="subtitle2">{formatDateTime(data?.reprovedAt)}</Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography color="textPrimary" variant="h6">Motivo:</Typography>
              <Typography color="textPrimary" variant="subtitle2">{data?.reason}</Typography>
            </Grid>
          </>
        )}
      </GridContainer>
      <br />
      {url && (
        <iframe title="Documento" src={url} width="100%" height="600" />          
      )}
      <ButtonContainer>
        <Button left={true} cancel={true} onClick={handleBack}>
          Voltar
        </Button>
        <Button checkin={true} onClick={() => handleOpen('APPROVED')} style={{marginLeft: '10px'}} disabled={data?.status !== 'PENDING'}>
          Aprovar
        </Button>
        <Button cancel={true} onClick={() => handleOpen('REPROVED')} disabled={data?.status !== 'PENDING'}>
          Reprovar
        </Button>            
      </ButtonContainer>
    </ContentPanel>
  )
}
