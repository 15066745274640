import * as Yup from 'yup';

export const branchSchema = Yup.object().shape({
  protheusCode: Yup.string().required('Código é obrigatório.'),
  description: Yup.string().required('Descrição é obrigatória.'),
  descriptionAlias: Yup.string().required('Rótulo é obrigatório'),
  establishmentName: Yup.string(),
  cifNumber: Yup.string(),
  cnpj: Yup.string(),
  ie: Yup.string(),
  address: Yup.string(),
  neighborhood: Yup.string(),
  city: Yup.string(),
  zip: Yup.string(),
  phone: Yup.string(),
  activity: Yup.string(),
  responsibleEmail: Yup.string(),
  techManagerName: Yup.string(),
  techManagerRegister: Yup.string()
});