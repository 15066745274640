//external
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
//internal
import ContentPanel from 'components/ContentPanel';
import InputText from 'components/v2/inputText';
import { Button, ButtonContainer } from 'styles/components'
import { updateAttributes } from 'services/cognito';
import { updateUserNameAttribute, updateUserPictureAttribute } from 'store/modules/auth/actions'
import { startRequest, finishRequest } from 'store/modules/loading/actions'
import { postAvatar } from 'services/user/service';
import { setMessage } from 'store/modules/message/action';
import { translate } from 'services/translateCognitoErrors';
//style
import GridContainer from 'components/v2/grid';
import { AvatarContainer, Avatar, AvatarPreview } from './styled'
import { myProfileSchema } from '../schemas';
import { validateForm } from 'components/Form/validate';

export default function Profile() {
  const message = useSelector(state => state.message.data);
  const userAttributes = useSelector(state => state.auth.userAttributes);

  const dispatch = useDispatch();
  const [preview, setPreview] = useState();
  const [textInput, setTextInput] = useState("");
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setTextInput(userAttributes.name)
  }, [userAttributes])

  async function handleSubmit () {
    const errorsList = await validateForm({name: textInput}, myProfileSchema);
    if (errorsList.length > 0) {
      setErrors(errorsList);
      return;
    } 

    try {
      dispatch(startRequest());

      const nameResult = await updateAttributes({ name: textInput });
      if (nameResult) {
        dispatch(updateUserNameAttribute({ name: nameResult }));
      }
      
      if (preview) {
        const picResult = await postAvatar(preview);
        if (picResult.picture) {
          dispatch(updateUserPictureAttribute({ picture: picResult }));
        }
      }

      dispatch(setMessage({success: true, text: 'Operação realizada com sucesso.'}));
    } catch (err) {
      dispatch(setMessage({error: true, text: translate(err)}));
    } finally {
      dispatch(finishRequest());
    }
  };

  function onCloseHandler() {
    setPreview(null);
  }
  
  function onCropHandler(preview) {
    setPreview(preview);
  }

  function onBeforeFileLoadHandler(elem) {
    if(elem.target.files[0].size > 71680){
      alert("O arquivo é muito grande!");
      elem.target.value = "";
    };
  }

  return (
    <>
      <ContentPanel message={message} title="Meu perfil">
        <div>
          <AvatarContainer>
            <Avatar
              width={120}
              height={120}
              onCrop={onCropHandler}
              onClose={onCloseHandler}
              onBeforeFileLoad={onBeforeFileLoadHandler}
            />
            {preview ? <AvatarPreview src={preview} alt="Preview" /> : <AvatarPreview alt="" />}
          </AvatarContainer>
          
          <GridContainer>
            <InputText 
              xs={12}
              name="name" 
              type="text" 
              label="Nome"
              required={true} 
              value={textInput}
              onChange={(e) => setTextInput(e.target.value)}
              errors={errors}
            />
          </GridContainer>

          <ButtonContainer>
            <Button type="submit" onClick={handleSubmit}>Salvar</Button>
          </ButtonContainer>
        </div>
      </ContentPanel>
    </>
  )
}