import styled from 'styled-components';

export const SeparatorSync = styled.div`
  padding-top: 15px;
  font-weight: bold;
  font-size: 16px;
  border-bottom: solid #a2a2a2 1px;
  margin-bottom: 24px;
  color: #58585d;
  width: 100%;
  margin: 15px;
`

export const ContainerLogError = styled.pre`
  width: 100%;
  padding: 15px;
`