//external
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//internal
import { deleteGroupTemplate, getNutrientGroup } from 'services/nutrientGroup/service';
import history from 'services/history';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import ContentPanel from 'components/ContentPanel';
import { setMessage } from 'store/modules/message/action';
import { sanitizeErrorUI } from 'utils/errorUtil';
//style
import { Attr, AttrName } from './styled';
import { Button, ButtonContainer } from 'styles/components';

export default function NutrientGroupDelete({match}) {
  const nutrientGroupId = match.params.id;
  const message = useSelector(state => state.message.data);
  const [nutrientGroup, setNutrientGroup] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    if (nutrientGroupId) {
      dispatch(startRequest());
      getNutrientGroup(nutrientGroupId).then(data => {
        setNutrientGroup(data.data);
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, nutrientGroupId]);

  async function handleDelete () {
    dispatch(startRequest());
    deleteGroupTemplate(nutrientGroupId).then(data => {
      history.push('/premixLabel/nutrient_group')
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }

  async function handleBack () {
    history.goBack();
  }

  return (
    <>
      <ContentPanel title="Deseja excluir este nutriente?" message={message}>
        {nutrientGroup && <>
          <AttrName>Descrição:</AttrName>
          <Attr>{nutrientGroup.description}</Attr>
          <AttrName>Ordem:</AttrName>
          <Attr>{nutrientGroup.order}</Attr>
          <ButtonContainer>
            <br />
            <Button left={true} cancel={true} onClick={handleBack}>
              Voltar
            </Button>
            <Button onClick={handleDelete}>
              Excluir
            </Button>
          </ButtonContainer>
        </>}
      </ContentPanel>
    </>
  )
}