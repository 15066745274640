//external
import React, { useCallback, useEffect, useState } from 'react' ;
import { useDispatch } from 'react-redux';
import FileCopyIcon from '@material-ui/icons/FileCopy';
//internal
import DataTableLoading from 'components/DataTableLoading';
import Modal from 'components/v2/modal';
import { findBranch } from 'services/rtpi/products/service';
import { getRtpi, getRtpis } from 'services/rtpi/rtpi/service';
import { formatDateTime } from 'utils/formatUtil';
import { useDebounce } from 'utils/uiUtils';
import { finishRequest, startRequest } from 'store/modules/loading/actions';
import { setMessage } from 'store/modules/message/action';
import GridContainer from 'components/v2/grid';
import InputText from 'components/v2/inputText';
import TableAction from 'components/v2/tableAction';
import { sanitizeErrorUI } from 'utils/errorUtil';
import SelectAutocomplete from 'components/v2/autocomplete';
import DataTableReact from 'components/v2/dataTableReact';
//style
import { IconContainer } from 'pages/appversion/styled';

const CustomTitle = ({ row }) => (
	<div>
		{}
		<div 
      data-tag="allowRowEvents"
			style={{ overflow: 'hidden', whiteSpace: 'pre-wrap', textOverflow: 'ellipses' }}
    >
      {row.productDescription}
    </div>
	</div>
);

export default function SearchRtpi({isOpen, onClickSave, isCopy, onHandleClose, onHandleCopy}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [rtpis, setRtpis] = useState();
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState();
  const [branch, setBranch] = useState(null);
  const [selectRtpi, setSelectRtpi] = useState({protheusCode: null, protheusBranch: null})
  const [loaded, setLoaded] = useState(false);
  const [optionsBranch, setOptionsBranch] = useState([]);
  const [searchBranch, setSearchBranch] = useState("");

  const debouncedSearchTerm = useDebounce(searchTerm);
  const debouncedSearchBranch = useDebounce(searchBranch);

  const dispatch = useDispatch();

  const loadBranch = useCallback((searchValue) => {
    findBranch(searchValue).then(result => {
      const branchs = result.data.map(element => {
        return { value: element, label: `${element.protheusCode} - ${element.description}` }
      });
      setOptionsBranch(branchs);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    });
  }, [dispatch]);

  useEffect(() => {
    if(loadBranch) loadBranch(debouncedSearchBranch)
  },[debouncedSearchBranch, loadBranch]);

  const columns = [
    {
      maxWidth: "8%",
      minWidth: "10%",
      name: 'Filial',
      selector: 'protheusBranch',
      sortable: true,
    },
    {
      maxWidth: "20%",
      name: 'Código',
      selector: 'protheusCode',
      sortable: true,
    },
    {
      maxWidth: "50%",
      name: 'Produto',
      selector: 'productDescription',
      sortable: true,
      cell: row => <CustomTitle row={row} />,
    },
    {
      maxWidth: "8%",
      minWidth: "10%",
      name: 'Revisão',
      selector: 'revision',
      sortable: true,
    },
    {
      maxWidth: "10%",
      style: {justifyContent: "flex-end"},
      cell: row => 
        <IconContainer>
          <TableAction 
            title={"Copiar"}
            onClick={() => handleCopy(row)} 
          >
            <FileCopyIcon />
          </TableAction>
        </IconContainer>
    }
  ];  

  useEffect(() => {
    if(isOpen) {
      setLoaded(true);
      dispatch(startRequest());    
      getRtpis(debouncedSearchTerm, perPage, page, sort, branch?.value?.protheusCode).then(data => {
        setTotalRows(parseInt(data.headers['recordcount']));
        setRtpis(data.data);
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
        setLoaded(false);
      });
    }
  }, [isOpen, dispatch, debouncedSearchTerm, branch, perPage, page, sort])


  function getRtpiDetail({protheusBranch, protheusCode}) {
    dispatch(startRequest());
    getRtpi(protheusBranch, protheusCode, null).then(data => {
      const formData = data.data;
      formData.createdAt = formatDateTime(new Date(formData.createdAt));
      onClickSave({
        conservationCondition:  formData.conservationCondition || '',
        productEnrichment: formData.productEnrichment || '',
        finishedProductDescription: formData.finishedProductDescription || '',
        productIndication: formData.productIndication,
        productUseMode: formData.productUseMode,
        productClassification: formData.productClassification,
        productPhysicalForm: formData.productPhysicalForm,
        productPackageType: formData.productPackageType,
        productPackageForm: formData.productPackageForm,
        LabelType: formData.LabelType,
        RtpiDiluitions: formData.RtpiDiluitions,
        consumptionMin: formData.consumptionMin,
        consumptionMax: formData.consumptionMax
      })
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }

  function handleCopy(rtpi) {
    setSelectRtpi(rtpi)
    onHandleCopy()
  }

  function handlePageChange(page)  {
    setPage(page);
  };

  function handlePerRowsChange(perPage) {
    setPerPage(perPage);
  };

  function handleSort(column, sortDirection) {
    setSort(`${column.selector} ${sortDirection}`);
  };

  function handleBranchChange(data) {
    if (data) {
      setBranch(data);
    } else {
      setBranch(null);
      loadBranch("");
    }   
  }

  function handleSearchChange(event) {
    setSearchTerm(event.target.value);
  }

  return (
    <div>
      <Modal 
        isOpen={isOpen} 
        title={isCopy ? "Copiar dados" : "Pesquisar RTPI'S"}
        labelCancel={isCopy ? "Cancelar" : "Cancelar"}
        labelSave={isCopy ? "Confirmar" : null}
        onClickCancel={onHandleClose}
        onClose={onHandleClose}
        onClickSave={isCopy ? () => getRtpiDetail(selectRtpi) : null}
      >
        <div style={{padding: '5px 0 18px 0', overflow: 'hidden'}}>
          {
            isCopy ? (
              <>
                <span>{`Deseja copiar os dados do produto ${selectRtpi.productDescription}?`}</span>
              </>
            ) : (
              <>
                <SelectAutocomplete
                  xs={12}
                  sm={12}
                  value={branch}
                  label="Buscar filial"
                  optionSelected={(option, value) => option.label === value.label}
                  optionLabel={(option) => option.label}
                  onChangeSelected={(event, value) => handleBranchChange(value)}
                  onChange={(event) => setSearchBranch(event.target.value)}
                  options={optionsBranch}
                  name="branch"
                />
                <br/>
                <GridContainer>
                  <InputText
                    xs={12}
                    name="search"
                    label="Pesquisar"
                    type="text"
                    placeholder="Pesquisar"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                  <DataTableReact
                    style={{overflowX: 'hidden'}}
                    noHeader
                    progressPending={loaded}
                    progressComponent={<DataTableLoading />}
                    columns={columns}
                    data={rtpis}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    sortServer
                    onSort={handleSort}
                    paginationPerPage={5}
                  />
                </GridContainer>
              </>
            )
          }
        </div>
      </Modal>
    </div>
    
  )
} 
