//external
import React, { useEffect, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import PrintIcon from '@material-ui/icons/Print';
import RefreshIcon from '@material-ui/icons/Refresh';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
//internal
import { getPriceTables, getAllows } from 'services/priceTable/service';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import ContentPanel from 'components/ContentPanel';
import { setMessage } from 'store/modules/message/action';
import TableAction from 'components/v2/tableAction';
import { sanitizeErrorUI } from 'utils/errorUtil.js';
import DataTableReact from 'components/v2/dataTableReact/index.js';
import GridContainer from 'components/v2/grid';
import { useDebounce } from 'utils/uiUtils';
import InputText from 'components/v2/inputText';
import { FilterPriceTable } from '../index';
import { formatDateTime } from 'utils/formatUtil';
import { getFileId } from 'services/files/service.js';
import { showReport } from 'utils/reportUtil';
//style
import { IconContainerProd } from '../styled.js';

export default function Document() {
  const message = useSelector(state => state.message.data);
  
  const [priceTables, setPriceTables] = useState();
  const [allows, setAllows] = useState({});
  const [reload, setReload] = useState(0);
  //pagination
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sort, setSort] = useState();

  const { filter, setFilter } = useContext(FilterPriceTable);

  const debouncedSearch = useDebounce(filter.search);

  const dispatch = useDispatch();

  const columns = [
    {
      name: 'Descrição',
      selector: 'description',
      sortable: false,
    },
    {
      name: 'Ativo',
      selector: 'active',
      sortable: false,
      cell: row => <input type='checkbox' checked={row.active} readOnly />,
      maxWidth: "29%",
    },    
    {
      name: 'Data',
      selector: 'updatedAt',
      sortable: false,
    },
    {
      style: {justifyContent: "flex-end"},
      cell: row => 
      <div>
        <IconContainerProd>
          <TableAction 
            title={"Editar"} 
            disabled={!allows.put} 
            to={`/premixapp/product_price_table/edit/${row.id}`}>
              <EditIcon />
          </TableAction>
          <TableAction 
            title={"Excluir"} 
            disabled={!allows.delete} 
            to={`/premixapp/product_price_table/delete/${row.id}`}>
              <DeleteIcon/>
          </TableAction>
          <TableAction 
            title={"Imprimir"} 
            disabled={!allows.get} 
            onClick={() => openDocument(row?.File)}
            isLink={false}
          >
            <PrintIcon />
          </TableAction>          
        </IconContainerProd>
      </div>
    }
  ];

  const actions = [
    <div style={{display: 'flex'}} key='actionsDocument'>
      <TableAction 
        title={"Adicionar"} 
        disabled={!allows.post} 
        to='/premixapp/product_price_table/add'
      >
        <AddIcon/>
      </TableAction>
      <TableAction 
        title={"Atualizar"} 
        onClick={refreshHandler}
        isLink={false}
      >
        <RefreshIcon/>
      </TableAction>
    </div>
  ];

  function refreshHandler() {
    setReload(reload + 1);
  }

  function openDocument(file) {
    if (!file) {
      return;
    }

    const {id, fileUuid} = file;
    
    if (id && fileUuid) {
      dispatch(startRequest());
      getFileId(id, fileUuid).then(async (response) => {   
        if (response?.data) {
          showReport(response.data)
        }
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }

  useEffect(() => {
    dispatch(startRequest());
    getPriceTables(debouncedSearch, perPage, filter.page, sort).then(data => {
      setTotalRows(parseInt(data.headers['recordcount']));      
      setPriceTables(data.data.map(value => {
        value.updatedAt = formatDateTime(new Date(value.updatedAt));

        return value;
      }));
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
    dispatch(startRequest());
    getAllows().then(data => {
      setAllows(data.data)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }, [dispatch, perPage, filter.page, sort, debouncedSearch, reload]);

  const handlePerRowsChange = (perPage) => {
    setPerPage(perPage);
  };

  const handlePageChange = (page) => {
    setFilter({...filter, page});
  };

  const handleSort = (column, sortDirection) => {
    setSort(`${column.selector} ${sortDirection}`);
  };

  return (
    <ContentPanel title="Tabelas de Preços" message={message} actions={actions}>
      <GridContainer>
        <InputText
          xs={12}
          name="search"
          label="Pesquisar"
          type="text"
          placeholder="Pesquisar"
          value={filter.search}
          onChange={(e) => setFilter({...filter, page: 1, search: e.target.value})}
          hasWarning={false}
        />        
        <DataTableReact
          noHeader
          columns={columns}
          data={priceTables}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationDefaultPage={filter.page}
          sortServer
          onSort={handleSort}
        />
      </GridContainer>
    </ContentPanel>
  )
}