/* eslint-disable react-hooks/exhaustive-deps */
//external
import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import { Divider, Grid, MenuItem } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CloseIcon from '@material-ui/icons/Close';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
//internal
import ContentPanel from 'components/ContentPanel';
import { setMessage } from 'store/modules/message/action';
import { sanitizeErrorUI } from 'utils/errorUtil';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import GridContainer from 'components/v2/grid';
import InputText from 'components/v2/inputText';
import history from 'services/history';
import { validateForm } from 'components/Form/validate';
import { getChecklist, getFieldTypes, postChecklist, putChecklist } from 'services/checklist/service';
import TableAction from 'components/v2/tableAction';
import InputSelectChildren from 'components/v2/inputSelectChildren';
import { checklistSchema } from '../schemas';
import Accordions from 'components/v2/accordion';
import { Warning } from 'routes/Route';
import CheckboxComponent from 'components/v2/checkbox';
//style
import { Button, ButtonContainer, Session } from 'styles/components';

export default function ChecklistEdit({match}) {
  const checklistId = match.params.id;
  const message = useSelector(state => state.message.data);
  const [contentPanels, setContentPanels] = useState({
    id: '',
    description: '',
  });
  const [items, setItems] = useState([]);
  const [listFieldTypes, setListFieldType] = useState([]);
  const [errors, setErrors] = useState([]);

  const { setIsWarning, setNotWarning } = useContext(Warning);
  
  const dispatch = useDispatch();

  const scrollToTop = () => {
    const elContent = document.getElementById("content");
    elContent.scrollTo(0,0);
  };

  useEffect(() => {
    function normalizeFieldTypes(fields) {      
      const newFields = fields.map(field => {
        const findType = listFieldTypes.find(item => item.fieldType === field.fieldType);
        return {
          ...field, 
          hasMax: findType?.hasMax,
          hasMin: findType?.hasMin,
          hasRequired: findType?.hasRequired,
          hasSelectOptions: findType?.hasSelectOptions,
        }
      });
      return newFields;
    }

    if (listFieldTypes.length === 0) {
      dispatch(startRequest());
      getFieldTypes().then(data => {
        setListFieldType(data.data);
      });
      dispatch(finishRequest());
    }

    if (checklistId) {
      dispatch(startRequest());
      getChecklist(checklistId).then(data => {
        const formData = data.data;
        const newItems = normalizeFieldTypes(formData.ChecklistItems);
        setContentPanels({
          id: formData.id,
          description: formData.description,
          items: newItems,
        });
        setItems(newItems);
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    } else {
      const items = [
        {
          fieldName: "",
          fieldType: "",
          hasJustify: null,
          infoText: "",
          max: null,
          min: null,
          required: false,
          selectOptions: [],
        }
      ]
      setContentPanels({items});
      setItems(items);
    }
  }, [dispatch, checklistId, listFieldTypes]);

  const handleAddPanels = useCallback((index) => {
    setItems(prevItems => {
      const newItems = [...prevItems];
      const newPanel = {
        fieldName: "",
        fieldType: "",
        hasJustify: null,
        infoText: "",
        max: null,
        min: null,
        required: false,
        selectOptions: [],
        hasMin: false,
        hasMax: false,
        hasRequired: false,
        ContentPanelhasSelectOptions: false,
      }
      if (index === -1) {
        newItems.push(newPanel)
      } else {
        insertAt(newItems, index + 1, newPanel)
      }
      return newItems;
    });
    setIsWarning();
  }, []);

  const handleAddOptions = useCallback((index) => {
    setItems(prevItems => {
      const newItems = [...prevItems];
      const selectOptions = [...newItems[index].selectOptions];
      
      selectOptions.push({
        default: false,
        hasJustify: false,
        option: "",
        order: selectOptions.length + 1,
      });
      newItems[index].selectOptions = selectOptions;
      return newItems;
    });
    setIsWarning();
  }, []);

  const handleFieldType = useCallback((index, value) => {
    setListFieldType(prevState => {
      const findTypeObject = prevState.find(f => f.fieldType === value);
      setItems(prevItems => prevItems.map((item, idx) => {
        if (index === idx) {        
          item.fieldType = value;
          //Limpando os campos depois de selecionados
          item.min = null; 
          item.max = null;
          item.required = null;
          
          //Criando propriedades dos tipos
          item.hasMin = findTypeObject?.hasMin || false;
          item.hasMax = findTypeObject?.hasMax || false;
          item.hasRequired = findTypeObject?.hasRequired || false;
          item.hasSelectOptions = findTypeObject?.hasSelectOptions || false;
  
          if (findTypeObject?.hasSelectOptions) {
            item.selectOptions = [{
              default: false,
              hasJustify: false,
              option: "",
              order: 1
            }];
          } else {
            item.selectOptions = []
          }
        }
        return item;
      }));
      return prevState;
    })      
  }, []);

  const handleDeleteItems = useCallback((index) => {
    setItems(prevItems => {
      const newItems = [...prevItems];
      newItems.splice(index, 1);
      return newItems;
    });
    setIsWarning();
  }, []);

  const handleDeleteOptions = useCallback((indexContentPanels, index) => {
    setItems(prevItems => {
      const newItems = [...prevItems];
      const selectOptions = [...newItems[indexContentPanels].selectOptions];
      
      selectOptions.splice(index, 1);
      newItems[indexContentPanels].selectOptions = selectOptions;
      return newItems;
    });
  }, []);

  function insertAt(array, index, ...elementsArray) {
    array.splice(index, 0, ...elementsArray);
  }

  const handleCopyItem = useCallback((item, index) => {
    setItems(prevItems => {
      const newItems = [...prevItems];
      insertAt(newItems, index, {
        fieldName: item.fieldName,
        fieldType: item.fieldType,
        hasJustify: item.hasJustify,
        infoText: item.infoText,
        hasMax: item.hasMax,
        hasMin: item.hasMin,
        hasRequired: item.hasRequired,
        min: item.min,
        max: item.max,
        required: item.required,
        selectOptions : item.selectOptions,
      });
      return newItems;
    });
    setIsWarning();
  }, []);

  async function handleSubmit () {
    setNotWarning();
    scrollToTop();
    const newItems = items.map(item => ({
      id: item.id,
      fieldName: item.fieldName,
      fieldType: item.fieldType,
      hasJustify: item.hasJustify,
      infoText: item.infoText,
      max: item.max === "0" ? 0 : isNaN(parseInt(item.max)) ? null : parseInt(item.max),
      min: item.min === "0" ? 0 : isNaN(parseInt(item.min)) ? null : parseInt(item.min),
      required: item.required || false,
      selectOptions: item.selectOptions
    }));

    const newContentPanels = {
      ...contentPanels,
      items: newItems
    };

    const errorsList = await validateForm(newContentPanels, checklistSchema);
    if (errorsList.length > 0) {
      setErrors(errorsList);
      return
    }

    if (checklistId) {
      dispatch(startRequest());
      putChecklist(checklistId, newContentPanels).then(data => {
        history.push('/checklist')
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    } else {
      dispatch(startRequest());
      postChecklist(newContentPanels).then(data => {
        history.push('/checklist')
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }   
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    setItems(newItems);
    setIsWarning();
  };

  const handleItemChange = useCallback((index, field, value) => {    
    if (field === 'fieldType') {
      handleFieldType(index, value);
    } else {      
      setItems(prevItems => {
        const newItems = [...prevItems];
        newItems[index][field] = value;
        return newItems;
      })
    }
  }, []);

  const handleOptionChange = useCallback((indexItem, index, field, value) => {
    setItems(prevItems => {
      const newItems = [...prevItems];
      const selectOptions = [...newItems[indexItem].selectOptions];
      
      selectOptions[index][field] = value;
      newItems[indexItem].selectOptions = selectOptions;
      return newItems;
    });
  }, [])
  
  return (
    <ContentPanel title="Cadastro de Checklist" message={message}>
      <GridContainer>
        <InputText
          xs={12}
          sm={12}
          name="description"
          label="Descrição"
          type="text"
          value={contentPanels.description}
          onChange={(e) => setContentPanels({...contentPanels, description: e.target.value})}
          errors={errors}
        />
      </GridContainer>      

      {items.length > 0 && (        
        <DragDrop 
          items={items} 
          onDragEnd={onDragEnd} 
          onChange={handleItemChange}
          errors={errors} 
          listFieldTypes={listFieldTypes}
          handleAddPanels={handleAddPanels}
          handleCopyItem={handleCopyItem}
          handleDeleteItems={handleDeleteItems}
          handleAddOptions={handleAddOptions}
          handleDeleteOptions={handleDeleteOptions}
          handleOptionChange={handleOptionChange}
        />
      )}

      <ButtonContainer>
        <Button type="submit" onClick={handleSubmit}>Salvar</Button>
      </ButtonContainer> 
    </ContentPanel>
  )
}

const ItemActions = React.memo(({ 
  required,
  hasRequired,
  item,
  index,
  expanded,
  onChange,
  handleAddPanels,
  handleCopyItem,
  handleDeleteItems,
}) => {
  return (
    <div style={{display: 'flex'}}>
      <TableAction 
        title={"Adicionar"}  
        onClick={() => handleAddPanels(index)}
      >
        <AddIcon/>
      </TableAction>
      <TableAction 
        title={"Copiar"}  
        onClick={() => handleCopyItem(item, index)}
      >
        <FileCopyIcon/>
      </TableAction>
      <TableAction 
        title={"Deletar"}  
        onClick={() => handleDeleteItems(index)}
      >
        <DeleteIcon/>
      </TableAction>

      {expanded && hasRequired && (
          <>
            <div style={{borderLeft: '1px solid #dadce0', height: '32px', margin: '0 16px', width: '0'}}></div>
            <CheckboxComponent 
              xs={12}
              sm={2}
              checked={required || false}
              onChange={(e) => onChange(index, 'required', e.target.checked)}
              name="required"
              label="Obrigatório"
            />
          </> 
      )}
    </div>      
  )
});

const Item = React.memo(({ 
  fieldType,
  fieldName,
  infoText,
  required,
  hasRequired,
  min,
  hasMin,
  max,
  hasMax,
  selectOptions,
  hasSelectOptions,
  item,
  index,
  onChange,
  errors,
  listFieldTypes,
  handleAddPanels,
  handleCopyItem,
  handleDeleteItems,
  handleAddOptions,
  handleDeleteOptions,
  handleOptionChange,
}) => {
  const [expanded, setExpanded] = useState(false);

  const setExpandedCallback = useCallback((value) => {
    setExpanded(value);
  }, []);

  return (
    <div style={{width: '100%'}} id={`id-${index}`}>
      {fieldType === 'SESSION' && (
        <Session/>
      )}
      <Accordions 
        id={index} 
        control={ 
          <div style={{width: '100%'}}>
            <GridContainer>
              <InputText
                xs={12}
                sm={8} 
                name="fieldName"
                label="Nome"
                type="text"
                placeholder="Nome"
                value={fieldName}
                onChange={(e) => onChange(index, 'fieldName', e.target.value)}
                errors={errors}
                validationArray="items"
                validationIndex={index}                
              />    
              <InputSelectChildren
                  xs={12}
                  sm={4}
                  name="fieldType"
                  label="Tipo"
                  value={fieldType}
                  onChange={(e) => onChange(index, 'fieldType', e.target.value)}
                  defaultValue={null}
                  errors={errors}
                  validationArray="items"
                  validationIndex={index}
                >
                  {listFieldTypes && listFieldTypes.map((i, index) => (
                    <MenuItem key={index} value={i.fieldType}>{i.fieldAlias}</MenuItem>
                  ))}
                </InputSelectChildren>          
            </GridContainer>
            {!expanded && (
              <>
                <div style={{width: '100%', margin: '26px 0px 8px 0px'}}>
                  <Divider />
                </div>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                  <ItemActions 
                    item={item}
                    index={index}
                    required={required}
                    hasRequired={hasRequired}
                    expanded={expanded}
                    onChange={onChange}
                    handleAddPanels={handleAddPanels}
                    handleCopyItem={handleCopyItem}
                    handleDeleteItems={handleDeleteItems}
                  />
                </div>
              </>  
            )}
          </div>
        }
        expanded={expanded}
        onChange={(event, isExpanded) => setExpandedCallback(isExpanded)}
        actions={
          <ItemActions 
            item={item}
            index={index}
            required={required}
            hasRequired={hasRequired}
            expanded={expanded}
            onChange={onChange}
            handleAddPanels={handleAddPanels}
            handleCopyItem={handleCopyItem}
          />
        }
      >
        <GridContainer>
          {hasMin && (
            <InputText
              xs={12}
              sm={4}
              name="min"
              label="Mínimo"
              maxLength={100}
              minLength={0}
              type="number"
              placeholder="Mínimo"
              value={min === 0 ? "0" : min}
              onChange={(e) => onChange(index, 'min', e.target.value)}
              autoFocus
              errors={errors}
              validationArray="items"
              validationIndex={index}
            />  
          )}

          {hasMax && (
            <InputText
              xs={12}
              sm={4}
              name="max"
              label="Máximo"
              type="number"
              maxLength={100}
              minLength={0}
              placeholder="Máximo"
              value={max === 0 ? "0" : max}
              onChange={(e) => onChange(index, 'max', e.target.value)}
              errors={errors}
              validationArray="items"
              validationIndex={index}
            />
          )}

          {hasSelectOptions && (            
            <>
              {selectOptions?.map((opt, optIndex) => (
                <div style={{width: '100%'}} id={`id-${index}`} key={optIndex}>
                  <div style={{display: 'flex', padding: '7px'}}>                    
                    <InputText
                      xs={12}
                      sm={10}
                      name="option"
                      label="Opção"
                      type="text"
                      placeholder="Opção"
                      value={opt.option}
                      onChange={(e) => handleOptionChange(index, optIndex, 'option', e.target.value)}
                      errors={errors}
                      validationArray="items"
                      validationIndex={index}
                    />
                    <div style={{padding: '8px'}}>
                      <CheckboxComponent 
                        xs={12}
                        sm={2}
                        checked={opt.hasJustify || false}
                        onChange={(e) => handleOptionChange(index, optIndex, 'hasJustify', e.target.checked)}
                        name="hasJustify"
                        label="Justificar"
                      />
                    </div>
                    {selectOptions.length > 1 && (
                      <TableAction 
                        title={"Excluir"}  
                        onClick={() => handleDeleteOptions(index, optIndex)}
                      >
                        <CloseIcon/>
                      </TableAction>
                    )}
                  </div>
                </div>
              ))}

              <Grid item xs={12}>
                <ButtonContainer>
                  <Button type="submit" onClick={() => handleAddOptions(index)}>Adicionar opção</Button>
                </ButtonContainer>
              </Grid>
            </>
          )}
        
          <InputText
            xs={12}
            sm={12}
            name="infoText"
            label="Informações"
            type="text"
            placeholder="Informações"
            value={infoText}
            onChange={(e) => onChange(index, 'infoText', e.target.value)}
            errors={errors}
            validationArray="items"
            validationIndex={index}
          /> 
        </GridContainer>
      </Accordions>
    </div> 
  )
});

const DragDrop = ({ 
  items,
  onDragEnd,
  onChange,
  xs = 12,
  sm = 12,
  errors,
  listFieldTypes,
  handleAddPanels,
  handleCopyItem,
  handleDeleteItems,
  handleAddOptions,
  handleDeleteOptions,
  handleOptionChange,
}) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>        
      <Droppable droppableId="droppable">  
        {(provided) => (
          <Grid item xs={xs} sm={sm}>
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {items.map((item, index) => (             
                <Draggable draggableId={`id-${index}`} index={index} key={index}>
                  {(provided) => (                      
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >                                    
                      <Item 
                        fieldType={item.fieldType}
                        fieldName={item.fieldName}
                        infoText={item.infoText}
                        required={item.required}
                        hasRequired={item.hasRequired} 
                        min={item.min}
                        hasMin={item.hasMin}
                        max={item.max}
                        hasMax={item.hasMax}
                        selectOptions={item.selectOptions}
                        hasSelectOptions={item.hasSelectOptions}
                        item={item}
                        index={index}
                        onChange={onChange}
                        errors={errors}
                        listFieldTypes={listFieldTypes}
                        handleAddPanels={handleAddPanels}
                        handleCopyItem={handleCopyItem}
                        handleDeleteItems={handleDeleteItems}
                        handleAddOptions={handleAddOptions}
                        handleDeleteOptions={handleDeleteOptions}
                        handleOptionChange={handleOptionChange}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          </Grid>
        )}
      </Droppable>
    </DragDropContext>
  );
}
