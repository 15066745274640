import * as Yup from 'yup';

export const referenceValuesSchema = Yup.array().of(Yup.object().shape({
  group: Yup.string(),
  order: Yup.number().integer().required('Informe a ordem.'),
  label: Yup.string().required('Informe a descrição.'),
  value: Yup.number(),
  unity: Yup.string().required('Informe a unidade.'),
  nutrients: Yup.array().of(Yup.object().shape({
    id: Yup.string().required('Informe o nutriente')
  }))
}));

export const referenceValuesEditSchema = Yup.object().shape({
  group: Yup.string(),
  label: Yup.string().required('Informe a descrição.'),
  value: Yup.string().required('Informe a quantidade.'),
  unity: Yup.string().required('Informe a unidade.'),
  nutrient: Yup.string().required('Informe o nutriente.')
});