import api from '../api'

export async function getAllows() {
  return api.get('additives/allows');
}

export async function getAdditives(description, numOfRegisters = 10, page = 1, order) {
  const params = {
    order: order ? order : 'description',
  }
  if (description) {
    params.query = description.trim(); 
  }

  return api.get('additives', {
    params,
    headers: {
      registerperpage: numOfRegisters,
      page
    }
  });
}

export async function getAdditive(additiveId) {
  return api.get(`additives/protheus/${additiveId}`);
}

export async function putAdditive(additiveId, data) {
  return api.put(`additives/protheus/${additiveId}`, data);
}
