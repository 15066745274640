//external
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableAction from 'components/v2/tableAction';
import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import PrintIcon from '@material-ui/icons/Print';
//internal
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import ContentPanel from 'components/ContentPanel';
import { setMessage } from 'store/modules/message/action';
import { sanitizeErrorUI } from 'utils/errorUtil';
import { formatDateTime } from 'utils/formatUtil';
import { showReport } from 'utils/reportUtil';
import DataTableReact from 'components/v2/dataTableReact';
import { getScheduleTodos, getAllows, getScheduleReport} from 'services/todo/service';
import GridContainer from 'components/v2/grid';
import { useDebounce } from 'utils/uiUtils';
import InputText from 'components/v2/inputText';
import DataTableLoading from 'components/DataTableLoading';
import { FilterScheduleTodo } from '..'
//style
import { IconContainer } from 'pages/nutrient/list/styled';

export default function Todo() {
  const message = useSelector(state => state.message.data);
  const [reload, setReload] = useState(0);
  const [dataTodo, setDataTodo] = useState();
  const [allows, setAllows] = useState({});
   //pagination
   const [totalRows, setTotalRows] = useState(0);
   const [perPage, setPerPage] = useState(10);
   const [sort, setSort] = useState();
 
  const dispatch = useDispatch();

  const { filter, setFilter } = useContext(FilterScheduleTodo);
 
  const debouncedSearchTodo = useDebounce(filter.search);

  const columns = [
    {
      name: 'Assunto',
      selector: 'subject',
      sortable: true,
    },
    {
      name: 'Data Inicial',
      selector: 'initialDate',
      sortable: true,
    },
    {
      name: 'Data Final',
      selector: 'finalDate',
      sortable: true,
    },
    {
      name: 'Usuário',
      selector: 'user',
      sortable: true,
    },
    {
      style: {justifyContent: "flex-end"},
      cell: row => 
        <div>
          <IconContainer>
            <TableAction 
            title={"Editar"} 
            disabled={!allows.put} 
            to={`/schedule/schedule_todo/edit/${row.id}`}>
              <EditIcon/>
            </TableAction>
            <TableAction 
              title={"Imprimir"} 
              disabled={!allows.get} 
              onClick={() => handleClickPrint(row.id)}
              isLink={false}
            >
              <PrintIcon />
            </TableAction>
            <TableAction 
            title={"Excluir"} 
            disabled={!allows.delete} 
            to={`/schedule/schedule_todo/delete/${row.id}`}>
              <DeleteIcon/>
            </TableAction>
          </IconContainer>
        </div>
    }
  ]

  const actions = [
    <div style={{display: 'flex'}} key="actionsTodo">
      <TableAction 
      key='addTodo'
      title={"Adicionar"} 
      disabled={!allows.post} 
      to='/schedule/schedule_todo/add'>
        <AddIcon/>
      </TableAction>
      <TableAction 
      key='refresh'
      title={"Atualizar"} 
      onClick={refreshHandler}
      isLink={false}>
        <RefreshIcon/>
      </TableAction>
    </div>
  ];

  useEffect(() => {
    dispatch(startRequest());
    getScheduleTodos(debouncedSearchTodo, perPage, filter.page, sort).then(data => {
      setTotalRows(parseInt(data.headers['recordcount']));
      setDataTodo(data.data.map(value => {
        value.initialDate = formatDateTime(value.initialDate);
        value.finalDate = formatDateTime(value.finalDate);
        return value;
      }));
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
    dispatch(startRequest());
    getAllows().then(data => {
      setAllows(data.data)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }, [dispatch, reload, debouncedSearchTodo, perPage, filter, sort]);

  function refreshHandler() {
    setReload(reload + 1);
  }

  const handlePerRowsChange = (perPage) => {
    setPerPage(perPage);
  };

  const handlePageChange = (page) => {
    setFilter({...filter, page});
  };

  const handleSort = (column, sortDirection) => {
    setSort(`${column.selector} ${sortDirection}`);
  };

  function handleClickPrint(id) {
    if (id) {
      dispatch(startRequest());
      getScheduleReport(id).then(res => {
        showReport(res.data);
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }

  return (
    <ContentPanel title="Tarefas" message={message} actions={actions}>
      <GridContainer>
        <InputText
          xs={12}
          name="name"
          label="Pesquisar"
          type="text"
          placeholder="Pesquisar"
          value={filter.search}
          onChange={(e) => setFilter({page: 1, search: e.target.value})}
          hasWarning={false}
        />
        <DataTableReact
          noHeader
          columns={columns}
          data={dataTodo}
          pagination
          paginationServer
          progressComponent={<DataTableLoading />}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          sortServer
          onSort={handleSort}
          paginationDefaultPage={filter.page}
        />
      </GridContainer>
    </ContentPanel>
  )
}