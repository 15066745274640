import api from '../../api'

export async function getAllows() {
  return api.get('rtpi/allows');
}

export async function getRevisions(branch, protheusCode) {
  return api.get(`rtpi/${branch}/${protheusCode}/revisions`);
}

export async function getRtpiFull(branch, protheusCode, revision) {
  return api.get(`rtpi/full/${branch}/${protheusCode}/${revision}`);
}

export async function getRtpis(query, numOfRegisters = 10, page = 1, order, branch) {
  const params = {
    order: order ? order : 'protheusCode',
    only_registered: true,
  }
  if (query) {
    params.query = query.trim(); 
  }
  if (branch) {
    params.branch = branch; 
  }
  
  return api.get('rtpi', {
    params,
    headers: {
      registerperpage: numOfRegisters,
      page
    }
  });
}

export async function getRtpi(branch, protheusCode, revision = null) {
  if (!revision) {
    return api.get(`rtpi/${branch}/${protheusCode}`);
  } else {
    return api.get(`rtpi/${branch}/${protheusCode}/${revision}`);
  }
}

export async function getRtpiReport(branch, protheusCode, revision = null) {
  let url = `rtpi/${branch}/${protheusCode}/report`;
  
  if (revision) {
    url = `rtpi/${branch}/${protheusCode}/${revision}/report`;
  }

  return api.get(url, {responseType: 'blob'});
}

export async function getWarrantyLevelReport(branch, protheusCode, revision = null) {
  let url = `rtpi/${branch}/${protheusCode}/warrantylevel/report`;
  
  if (revision) {
    url = `rtpi/${branch}/${protheusCode}/${revision}/warrantylevel/report`;
  }

  return api.get(url, {responseType: 'blob'});
}

export async function flagPrintRequest(uuid) {
  return api.get(`rtpi/flagprint/${uuid}`);
}

export async function putRtpi(branch, protheusCode, revision, data) {
  return api.put(`rtpi/${branch}/${protheusCode}/${revision}`, data);
}

export async function findBranch(query, numOfRegisters = 6) {
  return api.get('rtpi/branches', {
    params: {
      query,
      order: 'description'
    },
    headers: {
      registerperpage: numOfRegisters,
      page: 1
    }
  });
}