//external
import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
//internal
import Route from '../../routes/Route';
import Additive from './list';
import AdditiveEdit from './edit';

export const FilterAdditives = createContext({});

export default function Routes() {
  const [filter, setFilter] = useState({
    search: "",
    page: 1,
  });

  let location = useLocation();

  useEffect(() => {
    const split = location?.pathname.split('/');
    if (split[2] !== "additives") {
      setFilter({
        search: "",
        page: 1,
      });
    }
  }, [location, setFilter]);

  return (
    <FilterAdditives.Provider value={{ filter, setFilter }}>
      <Route path="/premixlabel/additives" exact component={Additive} isPrivate/>
      <Route path="/premixlabel/additives/edit/:id" exact component={AdditiveEdit} isPrivate/>
    </FilterAdditives.Provider>
  )
}