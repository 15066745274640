import React from 'react';
import { Container, makeStyles } from '@material-ui/core';
import Route from '../../routes/Route';
import Allowed from '../../components/Allowed';
import Home from '../../pages/home';
import MyProfile from '../../pages/myProfile';
import DefaultsPage from '../../pages/defaults';

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

export default function Content() {
  const classes = useStyles();

  return (    
    <main className={classes.content} id="content">
      <div className={classes.appBarSpacer} />
      <Container maxWidth={false} className={classes.container}>
        {/* Allowed Modules */}
        <MyProfile />
        <Allowed />

        <DefaultsPage />
        <Route path="/" exact component={Home} isPrivate />
      </Container>
    </main>    
  );
}