import styled from 'styled-components';

export const Title = styled.div`
  font-weight: bold;
`

export const Value = styled.div`
  padding-left: 3px;
  color: #666;
  min-height: 16px;
`