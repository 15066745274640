//external
import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
//internal
import Route from '../../routes/Route';
import TodoDelete from './delete';
import TodoEdit from './edit';
import Todo from './list';

export const FilterScheduleTodo = createContext({});

export default function Routes() {
  const [filter, setFilter] = useState({
    search: "",
    page: 1,
  });

  let location = useLocation();

  useEffect(() => {
    const split = location?.pathname.split('/');
    if (split[2] !== "schedule_todo") {
      setFilter({
        search: "",
        page: 1,
      });
    }
  }, [location, setFilter]);

  return (
    <FilterScheduleTodo.Provider value={{ filter, setFilter }}>
      <Route path="/schedule/schedule_todo" exact component={Todo} isPrivate/>
      <Route path="/schedule/schedule_todo/add" exact component={TodoEdit} isPrivate/>
      <Route path="/schedule/schedule_todo/edit/:id" exact component={TodoEdit} isPrivate/>
      <Route path="/schedule/schedule_todo/delete/:id" exact component={TodoDelete} isPrivate/>
    </FilterScheduleTodo.Provider>
  )
}