import React, { useState, useRef, useEffect } from 'react'
import { changePassSchema } from './schemas'
import logo from '../../assets/images/logo-cinza.png'
import { Container, Content } from './styled'
import { Form } from '@unform/web'
import { useSelector, useDispatch } from 'react-redux';
import { InputAdornment, IconButton, Button } from '@material-ui/core'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined'
import InputText from 'components/v2/inputText';
import GridContainer from 'components/v2/grid';

import Loading from '../../components/Loading'

import { loginSuccess } from '../../store/modules/auth/actions'

import * as cognito from '../../services/cognito';
import history from '../../services/history';
import { validateForm } from '../../components/Form/validate'
import Message from '../../components/Message'
import { setMessage } from '../../store/modules/message/action'
import { translate } from '../../services/translateCognitoErrors'

export default function ChangePassword({ location }) {
  const {user} = useSelector(state => state.auth);
  const passFormRef = useRef(null);
  const { email } = location.state ? location.state : '';
  const message = useSelector(state => state.message.data);

  const dispatch = useDispatch();
  
  const [errors, setErrors] = useState([]);
  const [viewPass, setViewPass] = useState(false);
  const [viewPassConfirm, setViewPassConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [changeState, setChangeState] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  })

  useEffect(() => {
    setChangeState({...changeState, email});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  useEffect(() => {
    dispatch(setMessage({text: "Criar senha"}));
  }, [dispatch]);

  async function handleSubmit () {

    const errorsList = await validateForm(changeState, changePassSchema);
    if (errorsList.length > 0) {
      setErrors(errorsList);
      return;
    }

    try {
      const { password } = changeState;

      setLoading(true);
      await cognito.changePassword(user, password);
      const result = await cognito.currentUser();
      dispatch(loginSuccess(result));
      setLoading(false);
      
      history.push('/');
    } catch (err) {
      setLoading(false);
      dispatch(setMessage({error: true, text: translate(err)}));
    }
  };

  return (
    <>
      {loading && <Loading />}
      <Container>
        <Content>
          <img src={logo} alt="Premix" />
          <Message message={message} />
          <br />
          {/* Change password without confirmation code */}
          <GridContainer>
            <InputText
              xs={12}
              sm={12}
              required
              name="email"
              label="Seu e-mail"      
              placeholder="email@exemplo.com.br"        
              type="email"
              value={changeState.email}
              onChange={(e) => setChangeState({...changeState, email: e.target.value})}
              errors={errors}
              hasWarning={false}
            />

            <InputText
              xs={12}
              sm={12}
              required
              name="password"
              label="Nova senha"
              placeholder="xxxxxx"              
              type={viewPass ? "text" : "password"}
              value={changeState.password}    
              onChange={(e) => setChangeState({...changeState, password: e.target.value})}  
              errors={errors}
              hasWarning={false}
              InputProps={{
                endAdornment: 
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setViewPass(!viewPass)}
                          >
                            {viewPass ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon  />}
                        </IconButton>
                    </InputAdornment>
              }}
            />              
            
            <InputText
              xs={12}
              sm={12}
              required
              name="confirmPassword"
              label="Confirme a nova senha"    
              placeholder="xxxxxx"             
              type={viewPassConfirm ? "text" : "password"}
              value={changeState.confirmPassword}    
              onChange={(e) => setChangeState({...changeState, confirmPassword: e.target.value})} 
              errors={errors}
              hasWarning={false}
              InputProps={{
                endAdornment: 
                  <InputAdornment position="end">
                      <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setViewPassConfirm(!viewPassConfirm)}                          
                        >
                          {viewPassConfirm ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon  />}
                      </IconButton>
                  </InputAdornment>
              }}
            />   
          </GridContainer>
          <br />
          <Form ref={passFormRef}>
            <Button variant="contained" color="primary" onClick={handleSubmit} >
              {loading ? 'Carregando...' : 'Criar senha'}
            </Button>            
          </Form>
        </Content>
      </Container>
    </>
  )
}