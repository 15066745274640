//external
import React, { useContext, useEffect, useState } from 'react';
import { Form } from '@unform/web';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from '@material-ui/icons/Refresh';
//internal
import { getFamilys, getAllows } from 'services/productFamily/service';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import ContentPanel from 'components/ContentPanel';
import DataTableLoading from 'components/DataTableLoading';
import { setMessage } from 'store/modules/message/action';
import { useDebounce } from 'utils/uiUtils';
import GridContainer from 'components/v2/grid';
import InputText from 'components/v2/inputText';
import TableAction from 'components/v2/tableAction';
import { sanitizeErrorUI } from 'utils/errorUtil';
import DataTableReact from 'components/v2/dataTableReact';
import { FilterFamilys } from '..';
//style
import { IconContainer } from './styled';

export default function Component() {
  const message = useSelector(state => state.message.data);
  
  const [familys, setFamilys] = useState();
  const [allows, setAllows] = useState({});
  //Pagination
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sort, setSort] = useState();
  const [reload, setReload] = useState(0);

  const { filter, setFilter } = useContext(FilterFamilys);

  const debouncedSearchFamilys = useDebounce(filter.search);
  
  const dispatch = useDispatch();

  const columns = [
    {
      maxWidth: "25%",
      name: 'Código',
      selector: 'protheusCode',
      sortable: true,
    },
    {
      maxWidth: "25%",
      name: 'Descrição',
      selector: 'description',
      sortable: true,
    },
    {
      maxWidth: "25%",
      name: 'Rótulo',
      selector: 'descriptionAlias',
      sortable: false,
    },
    {
      maxWidth: "25%",
      style: {justifyContent: "flex-end"},
      cell: row => 
        <div>
          <IconContainer>
            <TableAction 
            title={"Editar"} 
            disabled={!allows.put} 
            to={`/premixlabel/product_family/edit/${row.protheusCode}`}>
              <EditIcon/>
            </TableAction>
          </IconContainer>
        </div>
    }
  ];

  const actions = [
    <div key='refresh'>
      <TableAction 
      title={"Atualizar"} 
      onClick={refreshHandler}
      isLink={false}>
        <RefreshIcon/>
      </TableAction>
    </div>
  ];

  function refreshHandler() {
    setReload(reload + 1);
  }

  useEffect(() => {
    getAllows().then(data => {
      setAllows(data.data)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    });
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);
    dispatch(startRequest());    
    getFamilys(debouncedSearchFamilys, perPage, filter.page, sort).then(data => {
      setTotalRows(parseInt(data.headers['recordcount']));
      setFamilys(data.data);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
      setLoading(false);
    });
  }, [debouncedSearchFamilys, dispatch, filter.page, perPage, sort, reload]);

  const handlePageChange = (page) => {
    setFilter({...filter, page});
  };

  const handlePerRowsChange = (perPage) => {
    setPerPage(perPage);
  };

  const handleSort = (column, sortDirection) => {
    setSort(`${column.selector} ${sortDirection}`);
  };

  return (
    <>
      <ContentPanel message={message} title="Grupo de produto" actions={actions}>
        <Form>
          <GridContainer>
            <InputText
              xs={12}
              name="search"
              label="Pesquisar"
              type="text"
              placeholder="Pesquisar"
              value={filter.search}
              onChange={(e) => setFilter({page: 1, search: e.target.value})}
              hasWarning={false}
            />
          </GridContainer>
        </Form>
        <DataTableReact
          noHeader
          fixedHeader
          progressPending={loading}
          progressComponent={<DataTableLoading />}
          columns={columns}
          data={familys}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          sortServer
          onSort={handleSort}
          paginationDefaultPage={filter.page}
        />
      </ContentPanel>
    </>
  )
}