//external
import React, { useContext } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Grid } from '@material-ui/core';
import { Warning } from 'routes/Route';

export default function CheckboxComponent({ 
  onChange, 
  checked,
  name,
  label,
  sm,
  xs,
  disabled,
  required,
  tabIndex,
  inputProps,
  disableRipple,
  edge,
  hasWarning = true
}) {

  const { setIsWarning } = useContext(Warning);

  const onChangeCheckbox = (e) => {
    if (onChange && typeof onChange === 'function') {
      onChange(e);
    }
    if (hasWarning) {
      setIsWarning();
    }
  }

  return (
    <Grid item xs={xs} sm={sm}>
      <FormControlLabel
        control={
          <Checkbox 
            checked={checked} 
            onChange={(e) => onChangeCheckbox(e)}
            name={name}
            color="primary"
            disabled={disabled}
            required={required}
            tabIndex={tabIndex}
            inputProps={inputProps}
            disableRipple={disableRipple}
            edge={edge}
          />
        }
        label={label} 
      />
    </Grid>
  );
}