//external
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//internal
import ContentPanel from 'components/ContentPanel';
import { setMessage } from 'store/modules/message/action';
import { sanitizeErrorUI } from 'utils/errorUtil';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import GridContainer from 'components/v2/grid';
import InputText from 'components/v2/inputText';
import { getComponentGroup, postComponentGroup, putComponentGroup } from 'services/componentGroup/service';
import history from 'services/history';
import { componentGroupSchema } from '../schemas';
import { validateForm } from 'components/Form/validate';
import { Warning } from 'routes/Route';
//style
import { Button, ButtonContainer } from 'styles/components';

export default function ComponentGroupEdit({match}) {
  const ComponentGroupId = match.params.id;
  const message = useSelector(state => state.message.data);
  const [dataComponentGroup, setDataComponentGroup] = useState({});
  const [errors, setErrors] = useState([]);

  const { setNotWarning } = useContext(Warning);

  const dispatch = useDispatch();

  useEffect(() => {
    if (ComponentGroupId) {
      dispatch(startRequest());
      getComponentGroup(ComponentGroupId).then(data => {
        const formData = data.data
        setDataComponentGroup({
          description: formData.description,
          order: formData.order,
          id: formData.id
        });
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, ComponentGroupId]);

  async function handleSubmit () {
    setNotWarning();
    
    const errorsList = await validateForm(dataComponentGroup, componentGroupSchema);
    if (errorsList.length > 0) {
      setErrors(errorsList);
      return
    }

    if (ComponentGroupId) {
      dispatch(startRequest());
      putComponentGroup(ComponentGroupId, dataComponentGroup).then(data => {
        history.push('/premixLabel/component_group')
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    } else {
      dispatch(startRequest());
      postComponentGroup(dataComponentGroup).then(data => {
        history.push('/premixLabel/component_group')
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }  
  }

  return (
    <ContentPanel title="Grupo de Matéria Prima" message={message}>
      <GridContainer>
        <InputText
          xs={12}
          sm={6}
          required
          name="description"
          label="Descrição"
          type="text"
          value={dataComponentGroup.description}
          onChange={(e) => setDataComponentGroup({...dataComponentGroup, description: e.target.value})}
          errors={errors}
        />
        <InputText
          xs={12}
          sm={6}
          required
          name="order"
          label="Ordem"
          type="text"
          value={dataComponentGroup.order}
          onChange={(e) => setDataComponentGroup({...dataComponentGroup, order: e.target.value})}
          errors={errors}
        />
      </GridContainer>

      <ButtonContainer>
        <Button type="submit" onClick={handleSubmit}>Salvar</Button>
      </ButtonContainer> 
    </ContentPanel>
  )
}