export function loginRequest(email, password, messageCallback) {
  return {
    type: '@auth/LOGIN_REQUEST',
    payload: {email, password, messageCallback}
  }
}

export function loginSuccess(user) {
  return {
    type: '@auth/LOGIN_SUCCESS',
    payload: { user }
  }
}

export function loginFailure() {
  return {
    type: '@auth/LOGIN_FAILURE'
  }
}

export function logoutRequest(email, password) {
  return {
    type: '@auth/LOGOUT_REQUEST',
    payload: {email, password}
  }
}

export function logoutSuccess() {
  return {
    type: '@auth/LOGOUT_SUCCESS'
  }
}

export function logoutFailure() {
  return {
    type: '@auth/LOGOUT_FAILURE'
  }
}

export function updateUserNameAttribute({ name }) {
  return {
    type: '@auth/UPDATE_USER_NAME_ATTRIBUTE',
    payload: { name }
  }
}

export function updateUserPictureAttribute({ picture }) {
  return {
    type: '@auth/UPDATE_USER_PICTURE_ATTRIBUTE',
    payload: { picture }
  }
}

export function newPasswordRequired(user) {
  return {
    type: '@auth/NEW_PASSWORD_REQUIRED',
    payload: { user },
  }
}