import React, { useContext } from "react";
import { Grid } from "@material-ui/core";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Warning } from "routes/Route";

export default function DragAndDrop({array, renderChildCallback, reorderCallback, xs, sm, hasWarning = true}) {
  const { setIsWarning } = useContext(Warning);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      array,
      result.source.index,
      result.destination.index
    );
    
    reorderCallback(items);
    if (hasWarning) {
      setIsWarning();
    }
  };
  
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">  
        {(provided) => (
          <Grid item xs={xs} sm={sm}>
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >              
              {array.map((item, index) => (
                <Draggable key={index} draggableId={`id-${index}`} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      
                      {renderChildCallback(item, index)}
                      
                    </div>
                  )}  
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          </Grid>
        )}
      </Droppable>
    </DragDropContext>
  );
}