import React, { useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { logoutRequest } from '../../store/modules/auth/actions';
import { RightContainer, UserContainer } from 'components/Header/styles';
import { getAvatarURL } from 'services/user/service';
import { Divider } from './styled';
import { Menu, MenuItem } from '@material-ui/core';
import TableAction from 'components/v2/tableAction';
import SettingsIcon from '@material-ui/icons/Settings';
import { setMessage } from 'store/modules/message/action';
import { sanitizeErrorUI } from 'utils/errorUtil';
import { getHierarchy, getMe } from 'services/salesman/service';
import Modal from '../v2/modal'
import { useDebounce } from 'utils/uiUtils';
import SelectAutocomplete from 'components/v2/autocomplete';
import InputText from 'components/v2/inputText';
import GridContainer from 'components/v2/grid';
import { ButtonContainer, Button } from 'styles/components';
import { finishRequest, startRequest } from 'store/modules/loading/actions';
import * as userActions from 'store/modules/user/actions';
import { Warning } from 'routes/Route';

export default function UserData() {
  const [avatar, setAvatar] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [salesman, setSalesman] = useState({
    value: {
      me: false, 
      nivel: null,
      nome: "",
      path: ""
    }
  });
  const [me, setMe] = useState({id: "", name: ""});
  const [isOpenModal, setIsOpenModal] = useState(false);
  // select Gerente Regional
  const [optionsRegionalManager, setOptionsRegionalManager] = useState([]);
  const [itemSelectedRegionalManager, setItemSelectedRegionalManager] = useState(null);
  const [searchRegionalManager, setSearchRegionalManager] = useState("");
  const [isLoadingRegionalManager, setIsLoadingRegionalManager] = useState(false);
  //select Gestor de Vendas
  const [optionsSalesManager, setOptionsSalesManager] = useState([]);
  const [itemSelectedSalesManager, setItemSelectedSalesManager] = useState(null);
  const [searchSalesManager, setSearchSalesManager] = useState("");
  const [isLoadingSalesManager, setIsLoadingSalesManager] = useState(false);
  const [optionsSalesManagerFilter, setOptionsSalesManagerFilter] = useState([]);
  //select Vendedores
  const [optionsSalesman, setOptionsSalesman] = useState([]);
  const [itemSelectedSalesman, setItemSelectedSalesman] = useState(null);
  const [searchSalesman, setSearchSalesman] = useState("");
  const [isLoadingSalesman, setIsLoadingSalesman] = useState(false);
  const [optionsSalesmanFilter, setOptionsSalesmanFilter] = useState([]);

  const { setNotWarning } = useContext(Warning);

  const userAttributes = useSelector(state => state.auth.userAttributes);
  const salesmanName = useSelector(state => state.user.name);  
  const isMe = useSelector(state => state.user.me);

  const debouncedSearchRegionalManager = useDebounce(searchRegionalManager);
  const debouncedSearchSalesManager = useDebounce(searchSalesManager);
  const debouncedSearchSalesman = useDebounce(searchSalesman);

  const dispatch = useDispatch();

  useEffect(() => {
    if (salesman?.me) {
      dispatch(userActions.myType({ id: salesman?.codigo, name: salesman?.nome, nivel: salesman?.nivel, me: salesman?.me }))
      dispatch(userActions.getAllowedsRequest());
    }else if (me) {
      dispatch(userActions.myType({ id: me?.id, name: me?.name }))
      dispatch(userActions.getAllowedsRequest());
    }
  }, [dispatch, salesman, me])

  useEffect(() => {
    setOptionsSalesManagerFilter(optionsSalesManager)
  }, [optionsSalesManager]); 

  useEffect(() => {
    setOptionsSalesmanFilter(optionsSalesman)
  }, [optionsSalesman]); 

  useEffect(() => {
    if (itemSelectedRegionalManager?.value.codigo) {
      const filter = optionsSalesManager?.filter(item => item.value.path.indexOf(itemSelectedRegionalManager.value.codigo) > -1)
      setOptionsSalesManagerFilter(filter)
    }else {
      setOptionsSalesManagerFilter(optionsSalesManager)
    }
  }, [itemSelectedRegionalManager, optionsSalesManager]); 

  useEffect(() => {
    if (itemSelectedSalesManager?.value.codigo) {
      const filter = optionsSalesman?.filter(item => item.value.path.indexOf(itemSelectedSalesManager.value.codigo) > -1)
      setOptionsSalesmanFilter(filter)
    }else {
      setOptionsSalesmanFilter(optionsSalesman)
    }
  }, [itemSelectedSalesManager, optionsSalesman]); 

  useEffect(() => {
    getAvatarURL().then(data => {
      setAvatar(URL.createObjectURL(data));
    });
  }, [userAttributes]);

  useEffect(() => {
    if(salesman?.nivel !== 1 && itemSelectedRegionalManager === null) {
      setItemSelectedSalesManager(null);
      setItemSelectedSalesman(null);
    }
    if (itemSelectedSalesManager === null) {
      setItemSelectedSalesman(null)
    }
  }, [itemSelectedRegionalManager, itemSelectedSalesManager, salesman]);

  useEffect(() => {
    dispatch(startRequest());
    getMe().then(data => {
      setMe(data.data); 
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }, [dispatch])

  const loadSalesman = useCallback((searchValue) => {
    if (me?.id) {
      setIsLoadingRegionalManager(true);
      setIsLoadingSalesManager(true);
      setIsLoadingSalesman(true);
      getHierarchy(searchValue).then(result => {
        const me = result.data.find(element => element.me);
        setSalesman(me)
        const level1 = result.data.filter(item => item.nivel === 1 && item.me !== true)
        const level2 = result.data.filter(item => item.nivel === 2 && item.me !== true)
        const level3 = result.data.filter(item => item.nivel === 3 && item.me !== true)
        const salesmansLevelOne = level1.map(element => {
          return { value: element, label: `${element.nome}` }
        });
        const salesmansLevelTwo = level2.map(element => {
          return { value: element, label: `${element.nome}` }
        });
        const salesmansLevelTree = level3.map(element => {
          return { value: element, label: `${element.nome}` }
        });
        setOptionsRegionalManager(salesmansLevelOne)
        setOptionsSalesManager(salesmansLevelTwo);
        setOptionsSalesman(salesmansLevelTree);
        setIsLoadingRegionalManager(false)
        setIsLoadingSalesManager(false);
        setIsLoadingSalesman(false);
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        setIsLoadingRegionalManager(false);
        setIsLoadingSalesManager(false);
        setIsLoadingSalesman(false);
      });
    }
  }, [dispatch, me])

  useEffect(() => {
    if(loadSalesman) loadSalesman(debouncedSearchSalesman, debouncedSearchSalesManager, debouncedSearchRegionalManager)
  },[debouncedSearchRegionalManager, debouncedSearchSalesman, debouncedSearchSalesManager, loadSalesman]);

  const handleLogout = () => {
    dispatch(logoutRequest());  
  };

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleOpenModal = () => {
    setAnchorEl(null)
    setIsOpenModal(true)
  }

  function handleRegionalManager(data) {
    setItemSelectedRegionalManager(data)
    setItemSelectedSalesManager(null);
    setItemSelectedSalesman(null);
  }

  function handleSalesManager(data) {
    setItemSelectedSalesManager(data);
    setItemSelectedSalesman(null);
  }

  function handleSaveFilter() {
    if (itemSelectedSalesman !== null) {
      dispatch(userActions.myType({ id: itemSelectedSalesman.value?.codigo, name: itemSelectedSalesman.value?.nome, me: itemSelectedSalesman.value?.me}))
    }
    else if (itemSelectedSalesman === null && itemSelectedSalesManager !== null) {
      dispatch(userActions.myType({ id: itemSelectedSalesManager.value?.codigo, name: itemSelectedSalesManager.value?.nome, me: itemSelectedSalesManager.value?.me}))
      
    }else if (itemSelectedSalesman === null && itemSelectedSalesManager === null && itemSelectedRegionalManager !== null) {
      dispatch(userActions.myType({ id: itemSelectedRegionalManager.value?.codigo, name: itemSelectedRegionalManager.value?.nome, me: itemSelectedRegionalManager.value?.me }))
    }else if (itemSelectedSalesman === null && itemSelectedSalesManager === null && itemSelectedRegionalManager === null) {
      if (me?.name === "DIRETOR") {    
        dispatch(userActions.myType({ id: me?.id, name: me?.name }))
      }else if (salesman?.codigo) {
        dispatch(userActions.myType({ id: salesman?.codigo, name: salesman?.nome, me: salesman?.me }))
      }
    }

    setNotWarning();
    setIsOpenModal(false);
  }

  return (   
    <> 
      <RightContainer>
        <UserContainer>
          Olá, {userAttributes && userAttributes.name}
          {((!isMe && salesman?.nivel < 3) || me?.name === "DIRETOR") && (
            <p style={{color: '#BBB'}}>
              Visualizando como: {salesmanName || me.name}
            </p>
          )}
        </UserContainer>

        <Divider orientation="vertical" flexItem />

        <div style={{display: 'flex', alignItems: 'center'}}>
          <TableAction
            title="Configurações"
            onClick={handleClick}
            style={{color: 'white'}}
          >
            <SettingsIcon />
          </TableAction>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            {(salesman?.nivel < 3 || me?.name === "DIRETOR") && (
              <MenuItem
                onClick={handleOpenModal}
              >
                Filtros
              </MenuItem>
            )}
            <MenuItem
              onClick={handleLogout}
            >
              Sair
            </MenuItem>
          </Menu>
        </div>

        {avatar ? <img src={avatar} alt="avatar" /> : <img alt="" />}
      </RightContainer> 
    
      <Modal titleClose title="Filtros" isOpen={isOpenModal} onClose={() => setIsOpenModal(false)}>
        <GridContainer>
          <InputText
            xs={12}
            sm={12}
            name="subject"
            label={me?.name === "DIRETOR" ? "Diretor" : salesman?.nivel === 1 ? "Gerente regional" :  "Gestor de vendas" }
            type="text"
            value={salesman?.nome || me?.name}
            onChange={() => {}}
          />
          {me?.name === "DIRETOR" && (
            <SelectAutocomplete
              xs={12}
              sm={12}
              value={itemSelectedRegionalManager}
              label="Gerente regional"
              optionSelected={(option, value) => option.label === value.label}
              optionLabel={(option) => option.label}
              onChangeSelected={(event, value) => handleRegionalManager(value)}
              onChange={(event) => setSearchRegionalManager(event.target.value)}
              options={optionsRegionalManager}
              name="nutrientGroup"
              isLoading={isLoadingRegionalManager}
            />
          )}

          {(me?.name === "DIRETOR" || salesman?.nivel === 1) && (
            <SelectAutocomplete
              xs={12}
              sm={12}
              value={itemSelectedSalesManager}
              label="Gestor de vendas"
              optionSelected={(option, value) => option.label === value.label}
              optionLabel={(option) => option.label}
              onChangeSelected={(event, value) => handleSalesManager(value)}
              onChange={(event) => setSearchSalesManager(event.target.value)}
              options={optionsSalesManagerFilter}
              name="salesManager"
              isLoading={isLoadingSalesManager}
              disabled={salesman?.nivel !== 1 && itemSelectedRegionalManager === null}
            />
          )}
          
          {(me?.name === "DIRETOR" || salesman?.nivel === 0 || salesman?.nivel === 1 || salesman?.nivel === 2) && (
            <SelectAutocomplete
              xs={12}
              sm={12}
              value={itemSelectedSalesman}
              label="Representante"
              optionSelected={(option, value) => option.label === value.label}
              optionLabel={(option) => option.label}
              onChangeSelected={(event, value) => setItemSelectedSalesman(value)}
              onChange={(event) => setSearchSalesman(event.target.value)}
              options={optionsSalesmanFilter}
              name="salesman"
              isLoading={isLoadingSalesman}
              disabled={(salesman?.nivel === 1 || me?.name === "DIRETOR") && itemSelectedSalesManager === null}
            />
          )} 
        </GridContainer>

        <ButtonContainer>
          <Button type="submit" onClick={handleSaveFilter}>Salvar</Button>
        </ButtonContainer>
      </Modal>
    </>  
  );
}