import api from '../api'

export async function getAllows() {
  return api.get('components/allows');
}

export async function getComponents(description, numOfRegisters = 10, page = 1, order) {
  const params = {
    order: order ? order : 'description',
  }
  if (description) {
    params.query = description.trim(); 
  }

  return api.get('components', {
    params,
    headers: {
      registerperpage: numOfRegisters,
      page
    }
  });
}

export async function getComponent(componentId) {
  return api.get(`components/protheus/${componentId}`);
}

export async function putComponent(componentId, data) {
  return api.put(`components/protheus/${componentId}`, data);
}

export async function findComponent(description, numOfRegisters = 6) {
  return api.get('components', {
    params: {
      query: description,
      order: 'description'
    },
    headers: {
      registerperpage: numOfRegisters,
      page: 1
    }
  });
}

export async function findDonorSpecie(description, numOfRegisters = 6) {
  return api.get('components/getdonorspecies', {
    params: {
      description,
      order: 'description'
    },
    headers: {
      registerperpage: numOfRegisters,
      page: 1
    }
  });
}