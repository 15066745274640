export const sanitizeErrorUI = function(errObject) {
  const result = {
    error: true,
    text: errObject.response?.data?.message || errObject.message || 'Ocorreu um erro, entre em contato com o suporte.'
  }
  
  const list = errObject.response?.data?.list;
  if (list && Array.isArray(list)) {
    result.messageList = list;
  } else {
    result.messageList = [];
  }

  return result;
}