import styled from 'styled-components';

export const LoadingStyled = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.8);
  position: absolute;
  z-index: 999;

  img {
    width: 80px;
    margin: auto;
    padding: 10px;
    border-radius: 40px;
    background-color: #fff;
    box-shadow: rgba(255,255,255,0.2) 2px 2px 2px, inset rgba(255,255,255,0.2) 2px 2px 2px;
  }
`