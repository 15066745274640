import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import TableAction from '../tableAction';

export default function Modal({
    title, 
    labelSave, 
    labelCancel,
    labelDelete, 
    onClickSave, 
    onClickCancel,
    onClickDelete, 
    isOpen, 
    onClose, 
    children,
    titleClose,
    maxWidth = 'sm'
}) {

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="draggable-dialog-title"
        fullWidth={true}
        maxWidth={maxWidth}
      >
        <div >
          {titleClose ? (
            <Grid style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px'}} item sm={12}>
              <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {title}
              </DialogTitle>
              <TableAction 
                key='closeModal'
                title={"Fechar"} 
                onClick={() => onClose()}
                isLink={false}>
                <CloseIcon/>
              </TableAction>
            </Grid>
          ) : (
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
              {title}
            </DialogTitle>
          )}
          <DialogContent>
            {children}
          </DialogContent>
          <DialogActions>
            {onClickDelete !== null && (
              <Button onClick={onClickDelete} color="secondary">
                {labelDelete}
              </Button>  
            )}
            <Button onClick={onClickCancel} color="primary">
              {labelCancel}
            </Button>
            {onClickSave !== null && (
              <Button autoFocus onClick={onClickSave} color="primary">
                {labelSave}
              </Button>
            )}          
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}