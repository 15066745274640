//externall
import React from 'react';
//internal
import ProfilesModule from '../pages/profiles';
import AppVersionModule from '../pages/appversion';
import TermsModule from '../pages/terms';
import UsersModule from '../pages/user';
import NutrientModule from '../pages/nutrient';
import BranchModule from '../pages/branch';
import ComponentModule from '../pages/component';
import FixedFieldsModule from '../pages/rtpiFixedFields';
import ProductsModule from '../pages/rtpiProducts';
import ProductGroupModule from '../pages/productGroup';
import ProductFamilyModule from '../pages/productFamily';
import LineModule from '../pages/line';
import AdditiveModule from '../pages/additive';
import UserHistoryModule from '../pages/userHistory';
import IntentionsModule from '../pages/rtpiIntentions';
import RtpiModule from '../pages/rtpi';
import RtpiPrintModule from '../pages/rtpiPrint';
import RtpiLabelLogsModule from '../pages/rtpiLabelLogs';
import ReferenceValuesModule from '../pages/rtpiReferenceValues';
import EmailTemplateModule from '../pages/emailTemplate';
import NutrientGroupModule from '../pages/nutrientGroup';
import ProductRestrictionModule from '../pages/productRestriction';
import ComponentGroupModule from '../pages/componentGroup';
import ScheduleTypeModule from '../pages/scheduleType';
import ScheduleModule from '../pages/schedule';
import UserGroupModule from '../pages/userGroup';
import ChecklistModule from '../pages/checklist';
import DonorSpecieModule from 'pages/donorSpecie';
import ProspectModule from 'pages/prospect';
import ContactModule from 'pages/contact';
import CustomersModule from 'pages/customer';
import TodoModule from 'pages/todo';
import LogErrorModule from 'pages/logError';
import TodoRcModule from 'pages/todoRC';
import DocumentModule from 'pages/document';
import CommissionModule from 'pages/commission';
import PriceTableModule from 'pages/priceTable';
import ProductCategorySiteModule from 'pages/rtpiProductCategorySite';

const items = {
  // "/digitaltech": {
  //   module: <DigitalTechModule key={0} />,
  //   menu: {icon: 'business', to: '/digitaltech', label: 'Técnico Digital', section: 'premixApp'},
  // },
  // "/customerexperience": {
  //   module: <CustomerExperienceModule key={1} />,
  //   menu: {icon: 'business', to: '/customerexperience', label: 'Experiência do Cliente', section: 'premixApp'},
  // },
  "/customer": {
    module: <CustomersModule key={2} />,
    menu: {icon: 'account_circle', to: '/schedule/customer', label: 'Cliente', section: 'schedule'},
  },
  // "/order": {
  //   module: <OrdersModule key={3} />,
  //   menu: {icon: 'shopping_basket', to: '/order', label: 'Pedidos', section: 'premixApp'},
  // },
  // "/invoice": {
  //   module: <InvoiceModule key={4} />,
  //   menu: {icon: 'receipt', to: '/invoice', label: 'Notas Fiscais', section: 'premixApp'},
  // },
  // "/billet": {
  //   module: <BilletModule key={5} />,
  //   menu: {icon: 'view_week', to: '/billet', label: 'Boletos', section: 'premixApp'},
  // },
  "/commission": {
    module: <CommissionModule key={6} />,
    menu: {icon: 'attach_money', to: '/commission', label: 'Comissão', section: 'general'},
  },
  // "/calcweightgain": {
  //   module: <CalcWeightGainModule key={7} />,
  //   menu: {icon: 'attach_money', to: '/calcweightgain', label: 'Simulador', section: 'premixApp'},
  // },
  // "/calcconfinementviability": {
  //   module: <CalcConfinementViabilityModule key={8} />,
  //   menu: {icon: 'attach_money', to: '/calcconfinementviability', label: 'Simulador', section: 'premixApp'},
  // },
  // "/goal": {
  //   module: <GoalModule key={9} />,
  //   menu: {icon: 'trending_up', to: '/goal', label: 'Metas', section: 'premixApp'},
  // },
  // "/product": {
  //   module: <ProductModule key={10} />,
  //   menu: {icon: 'trending_up', to: '/product', label: 'Produtos', section: 'premixApp'},
  // },
  // "/shipment": {
  //   module: <ShipmentModule key={11} />,
  //   menu: {icon: 'trending_up', to: '/shipment', label: 'Carregamento', section: 'premixApp'},
  // },
  // "/indicators": {
  //   module: <IndicatorsModule key={12} />,
  //   menu: {icon: 'trending_up', to: '/indicators', label: 'Indicadores', section: 'premixApp'},
  // },
  "/profile": {
    module: <ProfilesModule key={13} />,
    menu: {icon: 'assignment_ind', to: '/userprofiles', label: 'Perfis', section: 'general'},
  },
  "/appversion": {
    module: <AppVersionModule key={14} />,
    menu: {icon: 'screen_lock_portrait', to: '/premixapp/appversion', label: 'Versões do App', section: 'premixApp'},
  },
  "/terms": {
    module: <TermsModule key={15} />,
    menu: {icon: 'subject', to: '/terms', label: 'Termos de uso', section: 'general'},
  },
  "/user": {
    module: <UsersModule key={16} />,
    menu: {icon: 'people', to: '/user', label: 'Usuários', section: 'general'},
  },
  "/branch": {
    module: <BranchModule key={17} />,
    menu: {icon: 'store', to: '/premixlabel/branch', label: 'Filial', section: 'premixLabel'},
  },
  "/nutrient": {
    module: <NutrientModule key={18} />,
    menu: {icon: 'bubble_chart', to: '/premixlabel/nutrient', label: 'Nutrientes', section: 'premixLabel'},
  },
  "/rtpi_fixed_fields": {
    module: <FixedFieldsModule key={19} />,
    menu: {icon: 'text_fields', to: '/premixlabel/rtpi/rtpi_fixed_fields', label: 'Campos Fixos', section: 'premixLabel', subSection: 'rtpi'},
  },
  "/components": {
    module: <ComponentModule key={20} />,
    menu: {icon: 'filter_alt', to: '/premixlabel/components', label: 'Matéria Prima', section: 'premixLabel'},
  },
  "/lines": {
    module: <LineModule key={21} />,
    menu: {icon: 'linear_scale', to: '/premixlabel/lines', label: 'Linhas', section: 'premixLabel'},
  },
  "/additives": {
    module: <AdditiveModule key={22} />,
    menu: {icon: 'upgrade', to: '/premixlabel/additives', label: 'Aditivos', section: 'premixLabel'},
  },
  "/rtpi_product_category_site": {
    module: <ProductCategorySiteModule key={23} />,
    menu: {icon: 'category', to: '/premixapp/rtpi_product_category_site', label: 'Categorias de Produtos', section: 'premixSite'},
  },
  "/rtpi_product": {
    module: <ProductsModule key={24} />,
    menu: {icon: 'table_chart', to: '/premixapp/rtpi_product', label: 'Produtos', section: 'premixApp'},
  },
  "/user_history" : {
    module: <UserHistoryModule key={25} />,
    menu: {icon: 'list', to: '/premixapp/user_history', label: 'Histórico', section:'premixApp'},
  },
  "/rtpi_intention" : {
    module: <IntentionsModule key={26} />,
    menu: {icon: 'layers', to: '/premixlabel/rtpi/rtpi_intention', label: 'Intenção', section:'premixLabel', subSection: 'rtpi'},
  },
  "/rtpi" : {
    module: <RtpiModule key={27} />,
    menu: {icon: 'picture_as_pdf', to: '/premixlabel/rtpi/rtpi', label: 'RTPI', section:'premixLabel', subSection: 'rtpi'},
  },
  "/rtpi_print" : {
    module: <RtpiPrintModule key={28} />,
    menu: {icon: 'print', to: '/premixlabel/rtpi/rtpi_print', label: 'RTPI - Impressão', section:'premixLabel', subSection: 'rtpi'},
  },
  "/rtpi_label_logs" : {
    module: <RtpiLabelLogsModule key={29} />,
    menu: {icon: 'label_important', to: '/premixlabel/rtpi/rtpi_label_logs', label: 'RTPI - Log Etiquetas', section:'premixLabel', subSection: 'rtpi'},
  },
  "/product_rv" : {
    module: <ReferenceValuesModule key={30} />,
    menu: {icon: 'storefront', to: '/premixlabel/rtpi/product_rv', label: 'Valor de Referência', section:'premixLabel', subSection: 'rtpi'},
  },
  "/email_template": {
    module: <EmailTemplateModule key={31} />,
    menu: {icon: 'email', to: '/email_template', label: 'Modelo de E-mail', section: 'general'},
  },
  "/nutrient_group": {
    module: <NutrientGroupModule key={32} />,
    menu: {icon: 'scatter_plot', to: '/premixlabel/nutrient_group', label: 'Grupo de Nutriente', section: 'premixLabel'},
  },
  "/product_restriction": {
    module: <ProductRestrictionModule key={33} />,
    menu: {icon: 'perm_device_information', to: '/premixlabel/product_restriction', label: 'Restrição de Produto', section: 'premixLabel'},
  },
  "/component_group": {
    module: <ComponentGroupModule key={34} />,
    menu: {icon: 'eco', to: '/premixlabel/component_group', label: 'Grupo de Matéria Prima', section: 'premixLabel'},
  },
  "/schedule_type": {
    module: <ScheduleTypeModule key={35} />,
    menu: {icon: 'event_note', to: '/schedule/schedule_type', label: 'Tipo de Agendamento', section: 'schedule'},
  },
  "/schedule": {
    module: <ScheduleModule key={36} />,
    menu: {icon: 'event', to: '/schedule/schedule', label: 'Agendamento', section: 'schedule'},
  },
  "/user_group": {
    module: <UserGroupModule key={37} />,
    menu: {icon: 'group_add', to: '/user_group', label: 'Grupo de Usuário', section: 'general'},
  },
  "/checklist": {
    module: <ChecklistModule key={38} />,
    menu: {icon: 'checklist', to: '/checklist', label: 'Cadastro de Checklist', section: 'general'},
  },
  "/donor_specie": {
    module: <DonorSpecieModule key={39} />,
    menu: {icon: 'swap_horiz', to: '/premixlabel/donor_specie', label: 'Espécies Doadoras', section: 'premixLabel'},
  },
  "/prospect": {
    module: <ProspectModule key={40} />,
    menu: {icon: 'person_add', to: '/schedule/prospect', label: 'Prospect', section: 'schedule'},
  },
  "/contact": {
    module: <ContactModule key={41} />,
    menu: {icon: 'contact_page', to: '/schedule/contact', label: 'Contato', section: 'schedule'},
  },
  "/schedule_todo": {
    module: <TodoModule key={42} />,
    menu: {icon: 'assignment', to: '/schedule/schedule_todo', label: 'Tarefas', section: 'schedule'},
  },
  "/log_error": {
    module: <LogErrorModule key={43} />,
    menu: {icon: 'error', to: '/log_error', label: 'Log de Erros', section: 'log_error'},
  },
  "/schedule_todo_rc": {
    module: <TodoRcModule key={44} />,
    menu: {icon: 'assignment_turned_in', to: '/schedule/schedule_todo_rc', label: 'Tarefas RC', section: 'schedule'},
  },
  "/document": {
    module: <DocumentModule key={45} />,
    menu: {icon: 'description', to: '/document', label: 'Documentos', section: 'general'},
  },
  "/product_price_table": {
    module: <PriceTableModule key={46} />,
    menu: {icon: 'request_quote_icon', to: '/premixapp/product_price_table', label: 'Tabela de Preços', section: 'premixApp'},
  },
  "/product_group": {
    module: <ProductGroupModule key={47} />,
    menu: {icon: 'group_work', to: '/premixlabel/product_group', label: 'Grupo de produtos', section: 'premixLabel'},
  },
  "/product_family": {
    module: <ProductFamilyModule key={48} />,
    menu: {icon: 'view_compact', to: '/premixlabel/product_family', label: 'Família de produtos', section: 'premixLabel'},
  },
};

export function getModulesList(allowed) {
  let list = [];
  if (Array.isArray(allowed)) {
    for (let item of allowed) {    
      if (items[item.path]) {
        list.push(items[item.path].module);
      }
    }
  }
  return list;
}

export function getMenuList(allowed) {
  let menuItens = [
    {icon: 'home', to: '/', label: 'Home', section: 'general'},
    {icon: 'person', to: '/myprofile', label: 'Meu Perfil', section: 'general'},
  ];

  let premixSite = {icon: 'web', open: false, label: 'Premix Site', items: []};
  let premixLabel = {icon: 'label', open: false, label: 'Premix Label', items: []};
  let premixApp = {icon: 'stay_current_portrait', open: false, label: 'Premix App', items: []};
  let premixSchedule = {icon: 'calendar_today', open: false, label: 'Agenda', items: []};
  let premixLabelRtpi = {icon: 'folder', to: '/premixlabel/rtpi', open: false, label: 'RTPI', section: 'premixLabel', items: []};
  let premixLogs = {icon: 'login', open: false, label: "Logs", items: []};

  if (Array.isArray(allowed)) { 
    for (let item of allowed) {
      if (items[item.path]) {
        switch (items[item.path].menu.section) {
          case 'general':
            menuItens.push(items[item.path].menu);
            break;
          case 'premixSite':
            premixSite.items.push(items[item.path].menu);
            break;
          case 'premixApp':
            premixApp.items.push(items[item.path].menu);
            break;
          case 'premixLabel':
            if(items[item.path].menu.subSection === 'rtpi') {
              premixLabelRtpi.items.push(items[item.path].menu)
            }else {
              premixLabel.items.push(items[item.path].menu);
            }
            break;
          case 'schedule':
            premixSchedule.items.push(items[item.path].menu);
            break; 
          case 'log_error': 
            premixLogs.items.push(items[item.path].menu);
            break;   
          default:
            menuItens.push(items[item.path].menu);
            break;
        }
      }
    }
  }

  if (premixLabelRtpi.items.length > 0) {
    premixLabel.items.push(premixLabelRtpi);
  }

  if (premixLabel.items.length > 0) {
    menuItens.push(premixLabel);
  }

  if (premixSite.items.length > 0) {
    menuItens.push(premixSite);
  }

  if (premixApp.items.length > 0) {
    menuItens.push(premixApp);
  }

  if (premixSchedule.items.length > 0) {
    menuItens.push(premixSchedule);
  }

  if (premixLogs.items.length > 0) {
    menuItens.push(premixLogs);
  }

  return menuItens;
}