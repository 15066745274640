import api from '../api'

export async function getAllows() {
  return api.get('component_group/allows');
}

export async function getComponentGroups(description, numOfRegisters = 10, page = 1, order) {
  const params = {
    order: order ? order : 'description',
  }
  if (description) {
    params.description = description.trim(); 
  }

  return api.get('component_group', {
    params,
    headers: {
      registerperpage: numOfRegisters,
      page
    }
  });
}

export async function getComponentGroup(id) {
  return api.get(`component_group/${id}`);
}

export async function postComponentGroup(componentGroup) {
  return api.post(`component_group/`, componentGroup);
}

export async function putComponentGroup(id, data) {
  return api.put(`component_group/${id}`, data);
}

export async function deleteComponentGroup(id) {
  return api.delete(`component_group/${id}`);
}

export async function findComponentGroup(description, numOfRegisters = 6) {
  return api.get('component_group', {
    params: {
      description,
      order: 'description'
    },
    headers: {
      registerperpage: numOfRegisters,
      page: 1
    }
  });
}