//external
import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
//internal
import Route from '../../routes/Route';
import ProductRestritionDelete from './delete';
import ProductRestrictionEdit from './edit';
import ProductRestriction from './list';

export const FilterProductRestriction = createContext({});

export default function Routes() {
  const [filter, setFilter] = useState({
    search: "",
    page: 1,
  });

  let location = useLocation();

  useEffect(() => {
    const split = location?.pathname.split('/');
    if (split[2] !== "product_restriction") {
      setFilter({
        search: "",
        page: 1,
      });
    }
  }, [location, setFilter]);

  return (
    <FilterProductRestriction.Provider value={{ filter, setFilter }}>
      <Route path="/premixlabel/product_restriction" exact component={ProductRestriction} isPrivate/>
      <Route path="/premixlabel/product_restriction/add" exact component={ProductRestrictionEdit} isPrivate/>
      <Route path="/premixlabel/product_restriction/edit/:id" exact component={ProductRestrictionEdit} isPrivate/>
      <Route path="/premixlabel/product_restriction/delete/:id" exact component={ProductRestritionDelete} isPrivate/>
    </FilterProductRestriction.Provider>
  )
}