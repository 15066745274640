import api from '../api'

export async function getAllows() {
  return api.get('donor_specie/allows');
}

export async function getDonorSpecies(description, numOfRegisters = 10, page = 1, order) {
  const params = {
    order: order ? order : 'description',
  }
  if (description) {
    params.description = description.trim(); 
  }

  return api.get('donor_specie', {
    params,
    headers: {
      registerperpage: numOfRegisters,
      page
    }
  });
}

export async function getDonorSpecie(id) {
  return api.get(`donor_specie/${id}`);
}

export async function postDonorSpecie(donorSpecie) {
  return api.post(`donor_specie/`, donorSpecie);
}

export async function putDonorSpecie(id, data) {
  return api.put(`donor_specie/${id}`, data);
}

export async function deleteDonorSpecie(id) {
  return api.delete(`donor_specie/${id}`);
}