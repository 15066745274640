import api from '../../api'

export async function getAllows() {
  return api.get('rtpi_product/allows');
}

export async function getProducts(query, exported, branch, numOfRegisters = 10, page = 1, order) {
  const params = {
    order: order ? order : 'description',
    only_registered: true,
  }
  if (query) {
    params.query = query.trim(); 
  }
  if (branch) {
    params.branch = branch; 
  }
  if (exported) {
    params.only_exported = exported;
  }
  
  return api.get('rtpi_product', {
    params,
    headers: {
      registerperpage: numOfRegisters,
      page
    }
  });
}

export async function getProduct(branchId, productId) {
  return api.get(`rtpi_product/protheus/${branchId}/${productId}`);
}

export async function hasRtpi(branchId, productId) {
  return api.get(`rtpi_product/protheus/hasRtpi/${branchId}/${productId}`);
}

export async function putProduct(branchId, productId, data) {
  return api.put(`rtpi_product/protheus/${branchId}/${productId}`, data);
}

export async function findBranch(query, numOfRegisters = 6) {
  return api.get('rtpi_product/branches', {
    params: {
      query,
      order: 'description'
    },
    headers: {
      registerperpage: numOfRegisters,
      page: 1
    }
  });
}

export async function findProduct(query, branch, numOfRegisters = 6) {
  if (!branch) {
    return {
      data: []
    };
  }

  return api.get('rtpi_product', {
    params: {
      query,
      order: 'description',
      only_registered: false,
      branch: branch,
    },
    headers: {
      registerperpage: numOfRegisters,
      page: 1
    }
  });
}

export async function getCatalogImage() {
  return api.get(`rtpi_product/const/catalog_images`);
}

