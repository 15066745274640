//external
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Button as MuiButton, Grid, Typography} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
//internal
import { getFileId } from 'services/files/service';
import { getDocument, delDocument } from 'services/document/service';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import history from 'services/history';
import ContentPanel from 'components/ContentPanel'
import { setMessage } from 'store/modules/message/action'
import { sanitizeErrorUI } from 'utils/errorUtil';
import GridContainer from 'components/v2/grid';
import { formatStatusDocument, formatDateTime } from 'utils/formatUtil';
//style
import { Button, ButtonContainer } from 'styles/components';

export default function DocumentCommissionEdit({match}) {
  const message = useSelector(state => state.message.data);
  const {id} = match.params;  
  
  const [url, setUrl] = useState(null);
  const [data, setData] = useState(null);
  //dialog
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  useEffect(() => {
    if (id) {
      dispatch(startRequest());
      getDocument(id).then(async (response) => {
        setData(response?.data);
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (data?.File) {
      const {id, fileUuid} = data?.File;
      if (id && fileUuid) {
        dispatch(startRequest());
        getFileId(id, fileUuid).then(async (response) => {           
          const blob = new Blob([response.data], {type: 'application/pdf'});        
          const url = URL.createObjectURL(blob);
          setUrl(url);        
        }).catch(err => {
          dispatch(setMessage(sanitizeErrorUI(err)));
        }).finally(() => {
          dispatch(finishRequest());
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, data?.File]);

  async function deleteDocument() {       
    dispatch(startRequest());
    delDocument(id).then((response) => {
      handleBack();
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
      handleClose();
    });
  }

  async function handleBack () {
    history.goBack();
  }
  
  return (
    <ContentPanel title='Documento' message={message}>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>          
          <DialogTitle style={{padding: '16px 0px'}} id="alert-dialog-title">
            Confirmação
          </DialogTitle>          
          <DialogContentText id="alert-dialog-description">
            {`Deseja realmente excluir o documento "${data?.name}"?`}
          </DialogContentText>          
        </DialogContent>        
        <DialogActions>
          <MuiButton onClick={handleClose} color="secondary">
            Cancelar
          </MuiButton>
          <MuiButton onClick={deleteDocument} color="primary" autoFocus>
            Excluir
          </MuiButton>
        </DialogActions>        
      </Dialog>
      <GridContainer>
        <Grid item xs={12} sm={3}>
          <Typography color="textPrimary" variant="h6">Nome:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{data?.name}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography color="textPrimary" variant="h6">Tipo:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{data?.DocumentType?.name}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography color="textPrimary" variant="h6">Data:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{formatDateTime(data?.createdAt)}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography color="textPrimary" variant="h6">Status:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{formatStatusDocument(data?.status)}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography color="textPrimary" variant="h6">Usuário:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{data?.username}</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography color="textPrimary" variant="h6">Vendedor:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{data?.salesmanId} - {data?.salesmanName}</Typography>
        </Grid>
      </GridContainer>
      <br />
      {url && (
        <iframe title="Documento" src={url} width="100%" height="600" />          
      )}
      <ButtonContainer>
        <Button left={true} cancel={true} onClick={handleBack}>
          Voltar
        </Button>        
        <Button onClick={handleOpen} disabled={data?.status !== 'PENDING'}>
          Excluir
        </Button>            
      </ButtonContainer>
    </ContentPanel>
  )
}
