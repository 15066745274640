//external
import React, { useContext } from 'react';
import { FormControlLabel, Grid, Radio } from '@material-ui/core';
import { Warning } from 'routes/Route';

export default function RadioButton({
  value, 
  checked,
  onChange,
  name,
  inputProps,
  disabled,
  labelPlacement,
  label,
  xs,
  sm,
  valueControlLabel,
  hasWarning = true
}) {

  const { setIsWarning } = useContext(Warning);

  const onChangeRadio = (...props) => {
    onChange(...props);
    if (hasWarning) {
      setIsWarning();
    }
  }

  return (
    <Grid item xs={xs} sm={sm}>
      <FormControlLabel
        value={valueControlLabel}
        control={
          <Radio
            checked={checked}
            onChange={(...props) => onChangeRadio(...props)}
            name={name}
            inputProps={inputProps}
            disabled={disabled}
            value={value}
          />
        }
        label={label}
        labelPlacement={labelPlacement}
      />
    </Grid>  
  )
}