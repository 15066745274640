import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { storeSchema } from './schemas'
import logo from '../../assets/images/logo-cinza.png'
import { Container, Content, ContainerButton, ShowPass, PassContainer, Input } from './styled'
import { Form } from '@unform/web'

import viewPassSVG from '../../assets/images/viewPass.svg'
import hidePassSVG from '../../assets/images/hidePass.svg'
import Loading from '../../components/Loading'

import { storeRequest } from '../../store/modules/user/actions';
import { validateForm } from '../../components/Form/validate';
import Message from '../../components/Message';

export default function SignUp() {
  const loading = useSelector(state => state.loading.loading);
  const message = useSelector(state => state.message.data);
  const formRef = useRef(null);

  const [viewPass, setViewPass] = useState(false);
  const [viewPassConfirm, setViewPassConfirm] = useState(false);
  
  const dispatch = useDispatch();

  function handleViewPassClick() {
    setViewPass(!viewPass);
  }
  function handleViewPassConfirmClick() {
    setViewPassConfirm(!viewPassConfirm);
  }

  async function handleSubmit (data , { resetForm }) {
    if (await validateForm(formRef, data, storeSchema)) {
      const { name, email, password } = data;

      dispatch(storeRequest(name, email, password));
    }
  };

  return (
    <>
      {loading && <Loading />}
      <Container>
        <Content>
          <img src={logo} alt="Premix" />
          <Message message={message}/>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Input name="name" type="text" placeholder="Seu nome" />
            <Input name="email" type="email" placeholder="Seu e-mail" />
            <PassContainer>
              <Input name="password" type={viewPass ? "text" : "password"} placeholder="Senha" />
              <ShowPass type="button" onClick={handleViewPassClick}>
                <img src={viewPass ? hidePassSVG : viewPassSVG} alt="Ver senha" />
              </ShowPass>
            </PassContainer>
            <PassContainer>
              <Input name="confirmPassword" type={viewPassConfirm ? "text" : "password"} placeholder="Confirme a senha" />
              <ShowPass type="button" onClick={handleViewPassConfirmClick}>
                <img src={viewPassConfirm ? hidePassSVG : viewPassSVG} alt="Ver senha" />
              </ShowPass>
            </PassContainer>
            <ContainerButton>
              <button type="submit">
                {loading ? 'Carregando...' : 'Salvar'}
              </button>
            </ContainerButton>
          </Form>
        </Content>
      </Container>
    </>
  )
}