//external
import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
//internal
import Route from '../../routes/Route';
import EmailTemplateDelete from './delete';
import EmailTemplateEdit from './edit';
import EmailTemplate from './list';

export const FilterEmailTemplate = createContext({});

export default function Routes() {
  const [filter, setFilter] = useState({
    page: 1,
  });

  let location = useLocation();

  useEffect(() => {
    const split = location?.pathname.split('/');
    if (split[1] !== "email_template") {
      setFilter({
        page: 1,
      });
    }
  }, [location, setFilter]);

  return (
    <FilterEmailTemplate.Provider value={{ filter, setFilter }}>
      <Route path="/email_template" exact component={EmailTemplate} isPrivate/>
      <Route path="/email_template/edit/:id" exact component={EmailTemplateEdit} isPrivate/>
      <Route path="/email_template/add" exact component={EmailTemplateEdit} isPrivate/>
      <Route path="/email_template/delete/:id" exact component={EmailTemplateDelete} isPrivate/>
    </FilterEmailTemplate.Provider>
  )
  }