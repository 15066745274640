export function clearMessage() {
  return {
    type: '@message/CLEAR_MESSAGE'
  }
}

export function setMessage(message) {
  return {
    type: '@message/SET_MESSAGE',
    message
  }
}