//external
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//internal
import history from 'services/history';
import ContentPanel from 'components/ContentPanel'
import InputText from 'components/v2/inputText';
import GridContainer from 'components/v2/grid';
import { getNutrient, putNutrient } from 'services/nutrient/service';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import { setMessage } from 'store/modules/message/action';
import { nutrientSchema } from '../schemas';
import { validateForm } from 'components/Form/validate';
import { findNutrientGroup } from 'services/nutrientGroup/service';
import { useDebounce } from 'utils/uiUtils';
import SelectAutocomplete from 'components/v2/autocomplete';
import { sanitizeErrorUI } from 'utils/errorUtil';
import { Warning } from 'routes/Route';
//style
import { Button, ButtonContainer } from 'styles/components'
import Toggle from 'components/v2/toggle';

export default function Nutrient({match, location}) {
  const message = useSelector(state => state.message.data);
  const nutrientId = match.params.id;
  const [dataNutrient, setDataNutrient] = useState({
    optimixCode:"", 
    description:"",
    unity:"",
    descriptionAlias:"",
    valueType: "",
    nutrientGroup: "",
    ignoreMin: false,
    ignoreMax: false,
    ignoreEqu: false,
  });
  const [errors, setErrors] = useState([]);
  const [options, setOptions] = useState([]);
  const [searchGroupNutrient, setSearchGroupNutrient] = useState("");
  const [itemSelected, setItemSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { setNotWarning } = useContext(Warning);

  const debouncedSearchGroup = useDebounce(searchGroupNutrient);

  const dispatch = useDispatch();

  const loadNutrientGroup = useCallback((searchValue) => {
    setIsLoading(true)
    findNutrientGroup(searchValue).then(result => {
      const nutrients = result.data.map(element => {
        return { value: element, label: `${element.description}` }
      });
      setOptions(nutrients);
      setIsLoading(false)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      setIsLoading(false);
    });
  }, [dispatch])

  useEffect(() => {
    if(loadNutrientGroup) loadNutrientGroup(debouncedSearchGroup)
  },[debouncedSearchGroup, loadNutrientGroup]);

  useEffect(() => {
    if (nutrientId) {
      dispatch(startRequest());
      getNutrient(nutrientId).then(data => {
        const formData = data.data;
        setDataNutrient({
          optimixCode: formData.optimixCode,
          description: formData.description,
          unity: formData.unity,
          descriptionAlias: formData.descriptionAlias,
          ignoreMin: formData.ignoreMin,
          ignoreMax: formData.ignoreMax,
          ignoreEqu: formData.ignoreEqu,
          nutrientGroup: formData.nutrientGroup,
        })
        if (formData.nutrientGroup) {
          setItemSelected({
            label: formData.nutrientGroup.description,
            value: {
              description: formData.nutrientGroup.description,
              id: formData.nutrientGroup.id,
              order: formData.nutrientGroup.order
            }
          });
        }
        
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, nutrientId])

  async function handleSubmit() {
    setNotWarning();

    const errorsList = await validateForm(dataNutrient, nutrientSchema);
    if (errorsList.length > 0) {
      setErrors(errorsList);
      return;
    } 

    const objCopy = JSON.parse(JSON.stringify(dataNutrient));
    if (itemSelected) {
      objCopy.nutrientGroup = {id: itemSelected?.value.id};
    }else {
      objCopy.nutrientGroup = ""
    }
    
    dispatch(startRequest());
    putNutrient(nutrientId, objCopy).then(data => {
      history.push('/premixlabel/nutrient')
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });        
  }



  return (
    <ContentPanel title='Nutriente' message={message}>
      <GridContainer>
        <InputText
          xs={12}
          sm={6}
          required
          name="optimixCode"
          label="Código"
          type="text"
          value={dataNutrient.optimixCode}
          errors={errors}
          onChange={(e) => setDataNutrient({...dataNutrient, optimixCode: e.target.value})}
          disabled={true}
        />

        <InputText
          xs={12}
          sm={6}
          required
          name="description"
          label="Descrição"
          type="text"
          value={dataNutrient.description}
          errors={errors} 
          onChange={(e) => setDataNutrient({...dataNutrient, description: e.target.value})}
          disabled={true}
        /> 

        <InputText
          xs={12}
          sm={6}
          required
          name="unity"
          label="Unidade"
          type="text"
          value={dataNutrient.unity}
          errors={errors}
          onChange={(e) => setDataNutrient({...dataNutrient, unity: e.target.value})}
          disabled={true}
        />

        <InputText
          xs={12}
          sm={6}
          name="descriptionAlias"
          label="Rótulo"
          placeholder="Rótulo"
          type="text"
          value={dataNutrient.descriptionAlias}
          onChange={(e) => setDataNutrient({...dataNutrient, descriptionAlias: e.target.value})}
          errors={errors}
        />

        <SelectAutocomplete
          xs={12}
          sm={12}
          value={itemSelected}
          label="Grupo de Nutriente"
          optionSelected={(option, value) => option.value.description === value.value.description}
          optionLabel={(option) => option.value.description}
          onChangeSelected={(event, value) => setItemSelected(value)}
          onChange={(event) => setSearchGroupNutrient(event.target.value)}
          options={options}
          name="nutrientGroup"
          isLoading={isLoading}
        />

        <Toggle
          xs={4}
          sm={4}
          name="Ignora minimo"
          checked={dataNutrient.ignoreMin}
          onChange={(e) => setDataNutrient({...dataNutrient, ignoreMin: e.target.checked})}
          label="Ignora minimo"
        />

        <Toggle
          xs={4}
          sm={4}
          name="Ignora máximo"
          checked={dataNutrient.ignoreMax}
          onChange={(e) => setDataNutrient({...dataNutrient, ignoreMax: e.target.checked})}
          label="Ignora máximo"
        />

        <Toggle
          xs={4}
          sm={4}
          name="Ignora igual"
          checked={dataNutrient.ignoreEqu}
          onChange={(e) => setDataNutrient({...dataNutrient, ignoreEqu: e.target.checked})}
          label="Ignora igual"
        />
      </GridContainer>
      <ButtonContainer>
        <Button type="submit" onClick={handleSubmit}>Salvar</Button>
      </ButtonContainer>
    </ContentPanel>
  )
}