//external
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//internal
import { getFamily, putFamily } from 'services/productFamily/service'
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import history from 'services/history';
import ContentPanel from 'components/ContentPanel'
import { validateForm } from 'components/Form/validate'
import { setMessage } from 'store/modules/message/action'
import { familySchema } from '../schemas'
import GridContainer from 'components/v2/grid';
import InputText from 'components/v2/inputText';
import { sanitizeErrorUI } from 'utils/errorUtil';
import { Warning } from 'routes/Route';
//style
import { Button, ButtonContainer } from 'styles/components'
import Toggle from 'components/v2/toggle';

export default function Family({match}) {
  const message = useSelector(state => state.message.data);
  const familyId = match.params.id;
  const [dataFamily, setDataFamily] = useState({
    protheusCode: "",
    description: "",
    descriptionAlias: "",
    expirationDate: "",
    ignoreExpirationDate: false,
  })
  const [errors, setErrors] = useState([]);

  const { setNotWarning } = useContext(Warning);

  const dispatch = useDispatch();

  useEffect(() => {
    if (familyId) {
      dispatch(startRequest());
      getFamily(familyId).then(data => {
        const formData = data.data;
        setDataFamily({
          protheusCode: formData.protheusCode,
          description: formData.description,
          descriptionAlias: formData.descriptionAlias,
          expirationDate: formData.expirationDate,
          ignoreExpirationDate: formData.ignoreExpirationDate,
        })
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, familyId])

  async function handleSubmit () {
    setNotWarning();
    const errorsList = await validateForm(dataFamily, familySchema);
    if (errorsList.length > 0) {
      setErrors(errorsList);
      return;
    } 

    dispatch(startRequest());
    putFamily(familyId, dataFamily).then(data => {
      history.push('/premixlabel/product_family')
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });    
  }

  return (
    <ContentPanel title='Grupo de produto' message={message}>
      <GridContainer>
        <InputText
          xs={12}
          sm={6}
          required
          name="protheusCode"
          label="Código"
          type="text"
          value={dataFamily.protheusCode}
          onChange={(e) => setDataFamily({...dataFamily, protheusCode: e.target.value})}
          errors={errors} 
          disabled={true}
        />

        <InputText
          xs={12}
          sm={6}
          required
          name="description"
          label="Descrição"
          type="text"
          value={dataFamily.description}
          onChange={(e) => setDataFamily({...dataFamily, description: e.target.value})}
          errors={errors} 
          disabled={true}
        /> 
  
        <InputText
          xs={12}
          sm={6}
          required
          name="expirationDate"
          label="Validade em Meses"
          type="number"
          placeholder="Validade em Meses"
          value={dataFamily.expirationDate}
          onChange={(e) => setDataFamily({...dataFamily, expirationDate: e.target.value})}
          errors={errors} 
        /> 

        <InputText
          xs={12}
          sm={6}
          name="descriptionAlias"
          label="Rótulo"
          type="text"
          placeholder="Rótulo"
          value={dataFamily.descriptionAlias}
          onChange={(e) => setDataFamily({...dataFamily, descriptionAlias: e.target.value})}
          errors={errors} 
        />
        
        <Toggle
          xs={6}
          sm={6}
          name="Ignora validade"
          checked={dataFamily.ignoreExpirationDate}
          onChange={(e) => setDataFamily({...dataFamily, ignoreExpirationDate: e.target.checked})}
          label="Ignora validade"
        />
      </GridContainer>

      <ButtonContainer>
        <Button type="submit" onClick={handleSubmit}>Salvar</Button>
      </ButtonContainer>
    </ContentPanel>
  )
}