//external
import React from 'react';
import DataTable from 'react-data-table-component';

export default function DataTableReact({...props}) {

  const customStyles = {
    table: {
      style: {
        height: 'auto'
      }
    },
  };

  return (
    <DataTable
      style={props.style}
      noHeader={props.noHeader}
      dense
      fixedHeader={props.fixedHeader}
      customStyles={customStyles}
      progressPending={props.progressPending}
      progressComponent={props.progressComponent}
      title={props.title}
      columns={props.columns}
      data={props.data}
      pagination={props.pagination}
      paginationServer={props.paginationServer}
      paginationTotalRows={props.paginationTotalRows}
      onChangeRowsPerPage={props.onChangeRowsPerPage}
      onChangePage={props.onChangePage}
      sortServer={props.sortServer}
      onSort={props.onSort}
      paginationDefaultPage={props.paginationDefaultPage}
      paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
      paginationPerPage={props.paginationPerPage}
      expandableRows={props.expandableRows}
      expandableRowsComponent={props.expandableRowsComponent}
      expandOnRowClicked={props.expandOnRowClicked}
	  	expandOnRowDoubleClicked={props.expandOnRowDoubleClicked}
  		expandableRowsHideExpander={props.expandableRowsHideExpander}
      {...props}
    />
  )
}