//external
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
//internal
import ContentPanel from 'components/ContentPanel';
import { setMessage } from 'store/modules/message/action';
import { sanitizeErrorUI } from 'utils/errorUtil';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import GridContainer from 'components/v2/grid';
import InputText from 'components/v2/inputText';
import DateAndTimePickers from 'components/v2/dateTimePicker';
import InputMultiline from 'components/v2/inputMultiline';
import { getFormatedScheduleStatus } from 'utils/formatSchedule';
import CheckboxComponent from 'components/v2/checkbox';
import { getScheduleTodoRc, getTodoRcCustomersId } from 'services/todoRc/service';
import history from 'services/history';
//style
import { ContainerPrimarySubContent, ContainerSecondarySubContent, ContentTodo, SeparatorTodo, SubContent } from 'pages/todo/edit/styled';
import { ButtonContainer, Button } from 'styles/components';

export default function TodoEdit({match}) {
  const todoRcId = match.params.id;
  const message = useSelector(state => state.message.data);
  const [dataTodo, setDataTodo] = useState({
    ScheduleType: {
      description: "",
      id: null,
    },
    allDay: false,
    initialDate: new Date(),
    finalDate: new Date(),
    id: null,
    notes: "",
    owner: "",
    place: "",
    prospect: {
      customerName: "",
      farmName: "",
      notes: "",
    },  
    prospectId: null,
    status: "",
    subject: "",
    user: ""
  }); 
  const [customerId, setCustomerId] = useState({
    customerId: null, 
    customerStore: null
  });
  const [customerData, setCustomerData] = useState({
    nome: "",
    fazenda: ""
  })

  const dispatch = useDispatch();

  useEffect(() => {
    if (todoRcId) {
      dispatch(startRequest());
      getScheduleTodoRc(todoRcId).then(data => {
        const formData = data.data;
        setDataTodo({
          ScheduleType: formData.ScheduleType,
          allDay: formData.allDay,
          initialDate: formData.initialDate,
          finalDate: formData.finalDate,
          id: formData.id,
          notes: formData.notes,
          owner: formData.owner,
          place: formData.place,
          prospect: formData.prospect,  
          prospectId: formData.prospectId,
          status: formData.status,
          subject: formData.subject,
          user: formData.user,
        }); 
        setCustomerId({
          customerId: formData.customerId,
          customerStore: formData.customerStore,
        })
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, todoRcId,]);

  useEffect(() => {
    dispatch(startRequest());
    getTodoRcCustomersId(customerId.customerId, customerId.customerStore).then(data => {
      setCustomerData({
        nome: data.data.nome,
        fazenda: data.data.fazenda
      })
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }, [dispatch, customerId]);

  async function handleBack () {
    history.goBack();
  }

  return (
    <ContentPanel title="Tarefas RC" message={message}>
      <ContentTodo>
        <SubContent>
          <GridContainer>
            <InputText
              xs={12}
              sm={12}
              name="scheduleType"
              label="Tipo de visita"
              type="text"
              value={dataTodo.ScheduleType.description}
              onChange={() => {}}
            />
            {dataTodo.prospect ? (
              <>
                <InputText
                  xs={12}
                  sm={12}
                  name="prospect"
                  label="Prospect"
                  type="text"
                  value={dataTodo.prospect.customerName}
                  onChange={() => {}}
                />
                <InputText
                  xs={12}
                  sm={12}
                  name="farmName"
                  label="Fazenda"
                  type="text"
                  value={dataTodo.prospect.customerName}
                  onChange={() => {}}
                />
                <InputText
                  xs={12}
                  sm={12}
                  name="notes"
                  label="Notas"
                  type="text"
                  value={dataTodo.prospect.customerName}
                  onChange={() => {}}
                />
              </>
            ) : (
              <>
                <InputText
                  xs={12}
                  sm={12}
                  required
                  name="customerId"
                  label="Cliente"
                  type="text"
                  value={customerData.nome}
                  onChange={() => {}}
                  />
                  <InputText
                  xs={12}
                  sm={12}
                  name="farmName"
                  label="Fazenda"
                  type="text"
                  value={customerData.fazenda}
                  onChange={() => {}}
                />
              </>
            )} 
          </GridContainer>     
        </SubContent>  

        <SeparatorTodo/>

        <SubContent>
          <ContainerPrimarySubContent>
            <Typography style={{color: '#3f8ed4', paddingBottom: '10px'}} color="textPrimary" variant="h6">Data/Hora</Typography>
            <GridContainer>
              {dataTodo.allDay ? (
                <CheckboxComponent
                  xs={12}
                  sm={6}
                  checked={dataTodo.allDay}
                  onChange={() => {}}
                  name="allDay"
                  label="Agendar o dia todo"
                />
              ) : (
                <>
                  <DateAndTimePickers
                    xs={12}
                    sm={6}
                    name="initialDate"
                    label="Data inicial"
                    value={dataTodo.initialDate}
                    onChange={() => {}}
                  />
                  <DateAndTimePickers
                    xs={12}
                    sm={6}
                    name="finalDate"
                    label="Data final"
                    value={dataTodo.finalDate}
                    onChange={() => {}}
                  />
                </>
              )}
            </GridContainer>  
          </ContainerPrimarySubContent>

          <ContainerSecondarySubContent>
            <GridContainer>
              <InputText
                xs={12}
                sm={12}
                required
                name="subject"
                label="Assunto"
                type="text"
                value={dataTodo.subject}
                onChange={() => {}}
              />
              <InputText
                xs={12}
                sm={6}
                name="place"
                label="Local"
                type="text"
                value={dataTodo.place}
                onChange={() => {}}
              />
              <InputText
                xs={12}
                sm={6}
                name="status"
                label="Status"
                type="text"
                value={getFormatedScheduleStatus(dataTodo.status)}
                onChange={() => {}}
              />
              <InputText
                xs={12}
                sm={12}
                name="user"
                label="Usuário"
                type="text"
                value={dataTodo.user}
                onChange={() => {}}
              />
              <InputMultiline
                xs={12}
                sm={12}          
                name="notes"
                label="Descrição"
                type="text"
                value={dataTodo.notes}
                onChange={() => {}}
              />
            </GridContainer>
          </ContainerSecondarySubContent>
        </SubContent>
      </ContentTodo> 
      <ButtonContainer>
        <Button type="submit" cancel={true} onClick={handleBack}>Voltar</Button>
      </ButtonContainer>  
    </ContentPanel>
  )
}