import React, { useState, useRef, useEffect } from 'react'
import { forgotMailSchema, forgotPassSchema } from './schemas'
import logo from '../../assets/images/logo-cinza.png'
import { Container, Content, TextButton } from './styled'
import { Form } from '@unform/web'
import { InputAdornment, IconButton, Button } from '@material-ui/core'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined'
import Loading from '../../components/Loading'

import * as cognito from '../../services/cognito';
import history from '../../services/history';
import { validateForm } from '../../components/Form/validate'
import Message from '../../components/Message'
import { useSelector, useDispatch } from 'react-redux'
import { setMessage } from '../../store/modules/message/action'
import { translate } from '../../services/translateCognitoErrors'
import InputText from '../../components/v2/inputText';
import GridContainer from 'components/v2/grid';

export default function ForgotPassword({ location }) {
  const resendFormRef = useRef(null);
  const passFormRef = useRef(null);
  const message = useSelector(state => state.message.data);
  
  const dispatch = useDispatch();

  const [errors, setErrors] = useState([]);
  const [viewPass, setViewPass] = useState(false);
  const [viewPassConfirm, setViewPassConfirm] = useState(false);
  const [confirmState, setConfirmState] = useState({
    confirm: false,
    email: '',
    code: '',
    password: '',
    confirmPassword: '',    
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(setMessage({text: "Informe seu e-mail, enviaremos um código para alteração da senha."}));
  }, [dispatch]);

  async function handleSubmit () {
    const {email, code, password, confirmPassword} = confirmState;
    const formData = {
      email,
      code,
      password,
      confirmPassword,
    }
    
    const errorsList = await validateForm(formData, forgotPassSchema);
    if (errorsList.length > 0) {
      setErrors(errorsList);
      return;
    }

    try {
      const { email, code, password } = formData;

      setLoading(true);
      await cognito.confirmPassword(email, code, password);
      setLoading(false);
      
      const message = {success: true, text: `Senha alterada com sucesso!`};
      
      history.push('/login', { message });
    } catch (err) {
      setLoading(false);
      dispatch(setMessage({error: true, text: translate(err)}));
    }
  };

  async function handleResendClick () {

    const errorsList = await validateForm({email: confirmState.email}, forgotMailSchema);
    if (errorsList.length > 0) {
      setErrors(errorsList);
      return;
    }    

    try {
      const { email } = confirmState;

      setLoading(true);
      const result = await cognito.forgotPassword(email);
      setLoading(false);

      setConfirmState({confirm: true, email});

      dispatch(setMessage({
        text: 'Enviamos um código de confirmação para o ' + result.CodeDeliveryDetails.AttributeName + ' ' + result.CodeDeliveryDetails.Destination + '! ' +
              'Digite o código de confirmação e crie uma nova senha de acesso!'
      }));
    } catch (err) {
      setLoading(false);
      dispatch(setMessage({error: true, text: translate(err)}));
    }
  }

  return (
    <>
      {loading && <Loading />}
      <Container>
        <Content>
          <img src={logo} alt="Premix" />
          <Message message={message} />
          <br />
          {/* Send coonfirmation code to user email */}
          {!confirmState.confirm && (
            <>
              <GridContainer>
                <InputText
                  xs={12}
                  sm={12}
                  required
                  name="email"
                  label="Seu e-mail"      
                  placeholder="email@exemplo.com.br"        
                  type="email"
                  value={confirmState.email}
                  onChange={(e) => setConfirmState({...confirmState, email: e.target.value})}
                  errors={errors}
                  hasWarning={false}
                />
              </GridContainer>
              <br />              
              <Form ref={resendFormRef}>                    
                <Button variant="contained" color="primary" onClick={handleResendClick} >
                  {loading ? 'Carregando...' : 'Enviar código'}
                </Button>
              </Form>
            </>
          )}

          {/* Change password with confirmation code */}
          {confirmState.confirm && (
            <>
              <GridContainer>
                <InputText
                  xs={12}
                  sm={12}
                  required
                  name="code"
                  label="Código de ativação"
                  placeholder="999999"              
                  type="text"
                  value={confirmState.code}    
                  onChange={(e) => setConfirmState({...confirmState, code: e.target.value})}                        
                  errors={errors}
                  hasWarning={false}
                />

                <InputText
                  xs={12}
                  sm={12}
                  required
                  name="password"
                  label="Nova senha"
                  placeholder="xxxxxx"              
                  type={viewPass ? "text" : "password"}
                  value={confirmState.password}    
                  onChange={(e) => setConfirmState({...confirmState, password: e.target.value})}  
                  errors={errors}
                  hasWarning={false}
                  InputProps={{
                    endAdornment: 
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setViewPass(!viewPass)}
                              >
                                {viewPass ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon  />}
                            </IconButton>
                        </InputAdornment>
                  }}
                />              
                
                <InputText
                  xs={12}
                  sm={12}
                  required
                  name="confirmPassword"
                  label="Confirme a nova senha"    
                  placeholder="xxxxxx"             
                  type={viewPassConfirm ? "text" : "password"}
                  value={confirmState.confirmPassword}    
                  onChange={(e) => setConfirmState({...confirmState, confirmPassword: e.target.value})} 
                  errors={errors}
                  hasWarning={false}
                  InputProps={{
                    endAdornment: 
                      <InputAdornment position="end">
                          <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setViewPassConfirm(!viewPassConfirm)}                          
                            >
                              {viewPassConfirm ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon  />}
                          </IconButton>
                      </InputAdornment>
                  }}
                />        
              </GridContainer>   
              <br />              
              <Form ref={passFormRef}>    
                <Button variant="contained" color="primary" onClick={handleSubmit} >
                  {loading ? 'Carregando...' : 'Cadastrar senha'}
                </Button>
                <TextButton onClick={() => handleResendClick({email: confirmState.email})}>Reenviar código de ativação</TextButton>
              </Form>
            </>
          )}
        </Content>
      </Container>
    </>
  )
}