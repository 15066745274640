import api from '../../api';

export async function getAllows() {
  return api.get('schedule_type/allows');
}
  
export async function getScheduleTypes(numOfRegisters = 10, page = 1, order) {
  const params = {
    order: order ? order : 'description',
  }
  return api.get('schedule_type', {
    params,
    headers: {
      registerperpage: numOfRegisters,
      page
    }
  });
}

export async function getScheduleType(id) {
  return api.get(`schedule_type/${id}`);
}

export async function getChecklists() {
  return api.get('schedule_type/checklists');
}

export async function postScheduleType(scheduleType) {
  return api.post(`schedule_type/`, scheduleType);
}

export async function putScheduleType(id, data) {
  return api.put(`schedule_type/${id}`, data);
}

export async function deleteScheduleType(id) {
  return api.delete(`schedule_type/${id}`);
}