import React from 'react';
import { makeStyles, Grid, IconButton } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import '../imageUpload/styled.scss';

const useStyles = makeStyles((theme) => ({
  MuiSvgIconClose: {
    position: 'absolute',
  },
}));

export default function Thumb({
  xs,
  sm,
  src,
  removeImage,
}) {

  const classes = useStyles();

  return (
    <Grid style={{paddingRight: '8px', paddingBottom: '8px'}} item xs={xs} sm={sm}>
      <IconButton 
        className={classes.MuiSvgIconClose} 
        aria-label="delete" 
        size="small" 
        color="secondary" 
        onClick={removeImage}
      >
        <DeleteForeverIcon fontSize="small" />
      </IconButton>
      <div className="img-upload">
        <img style={{width: '100%', height: '100%'}} src={src} alt="img" />
      </div>
    </Grid>
  )
}