//external
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableAction from 'components/v2/tableAction';
import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
//internal
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import ContentPanel from 'components/ContentPanel';
import { setMessage } from 'store/modules/message/action';
import { sanitizeErrorUI } from 'utils/errorUtil';
import DataTableReact from 'components/v2/dataTableReact';
import DataTableLoading from 'components/DataTableLoading';
import { useDebounce } from 'utils/uiUtils';
import GridContainer from 'components/v2/grid';
import InputText from 'components/v2/inputText';
import { getDonorSpecies, getAllows } from 'services/donorSpecie/service';
import { FilterDonorSpecie } from '..';
//style
import { IconContainer } from 'pages/nutrient/list/styled';

export default function DonorSpecie() {
  const message = useSelector(state => state.message.data);
  const [reload, setReload] = useState(0);
  const [dataDonorSpecie, setDataDonorSpecie] = useState();
  const [allows, setAllows] = useState({});
  //pagination
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sort, setSort] = useState();

  const dispatch = useDispatch();
  
  const { filter, setFilter } = useContext(FilterDonorSpecie);

  const debouncedSearchDonorSpecie = useDebounce(filter.search);

  const columns = [
    {
      name: 'Descrição',
      selector: 'description',
      sortable: true,
    },
    {
      style: {justifyContent: "flex-end"},
      cell: row => 
        <div>
          <IconContainer>
            <TableAction 
            title={"Editar"} 
            disabled={!allows.put} 
            to={`/premixlabel/donor_specie/edit/${row.id}`}>
              <EditIcon/>
            </TableAction>
            <TableAction 
            title={"Excluir"} 
            disabled={!allows.delete} 
            to={`/premixlabel/donor_specie/delete/${row.id}`}>
              <DeleteIcon/>
            </TableAction>
          </IconContainer>
        </div>
    }
  ]

  const actions = [
    <div style={{display: 'flex'}} key="actionsComponentGroup">
      <TableAction 
      key='addNutrientGroup'
      title={"Adicionar"} 
      disabled={!allows.post} 
      to='/premixLabel/donor_specie/add'>
        <AddIcon/>
      </TableAction>
      <TableAction 
      key='refresh'
      title={"Atualizar"} 
      onClick={refreshHandler}
      isLink={false}>
        <RefreshIcon/>
      </TableAction>
    </div>
  ];

  useEffect(() => {
    dispatch(startRequest());
    getDonorSpecies(debouncedSearchDonorSpecie, perPage, filter.page, sort).then(data => {
      setTotalRows(parseInt(data.headers['recordcount']));
      setDataDonorSpecie(data.data);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
    dispatch(startRequest());
    getAllows().then(data => {
      setAllows(data.data)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }, [dispatch, reload, debouncedSearchDonorSpecie, perPage, filter.page, sort]);

  function refreshHandler() {
    setReload(reload + 1);
  }

  const handlePerRowsChange = (perPage) => {
    setPerPage(perPage);
  };

  const handlePageChange = (page) => {
    setFilter({...filter, page});
  };

  const handleSort = (column, sortDirection) => {
    setSort(`${column.selector} ${sortDirection}`);
  };

  return (
    <ContentPanel title="Espécies Doadoras" message={message} actions={actions}>
      <GridContainer>
        <InputText
          xs={12}
          name="name"
          label="Pesquisar"
          type="text"
          placeholder="Pesquisar"
          value={filter.search}
          onChange={(e) => setFilter({page: 1, search: e.target.value})}
          hasWarning={false}
        />
      </GridContainer>
      <DataTableReact
        noHeader
        fixedHeader
        columns={columns}
        data={dataDonorSpecie}    
        pagination
        paginationServer
        progressComponent={<DataTableLoading />}
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        sortServer
        onSort={handleSort}
        paginationDefaultPage={filter.page}
      />
    </ContentPanel>
  )
}