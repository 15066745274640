import api from '../api'

export async function getAllows() {
  return api.get('lines/allows');
}

export async function getLines(description, numOfRegisters = 10, page = 1, order) {
  const params = {
    order: order ? order : 'description',
  }
  if (description) {
    params.query = description.trim(); 
  }

  return api.get('lines', {
    params,
    headers: {
      registerperpage: numOfRegisters,
      page
    }
  });
}

export async function getLine(lineId) {
  return api.get(`lines/protheus/${lineId}`);
}

export async function putLine(lineId, data) {
  return api.put(`lines/protheus/${lineId}`, data);
}

export async function getCatalogImage() {
  return api.get(`lines/const/catalog_images`);
}
