//external
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import { MenuItem } from '@material-ui/core';
//internal
import { findComponent } from 'services/component/service';
//import { findNutrient } from 'services/nutrient/service';
import { getAdditive, putAdditive } from 'services/additive/service'
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import history from 'services/history';
import ContentPanel from 'components/ContentPanel'
import { validateForm } from 'components/Form/validate'
import { setMessage } from 'store/modules/message/action'
import { additiveSchema, sealImageList } from '../schemas'
import GridContainer from 'components/v2/grid';
import InputText from 'components/v2/inputText';
import InputSelect from 'components/v2/inputSelect';
import TableAction from 'components/v2/tableAction';
import { IconContainer } from '../list/styled';
import { sanitizeErrorUI } from 'utils/errorUtil';
import SelectAutocomplete from 'components/v2/autocomplete';
import { useDebounce } from 'utils/uiUtils';
import DataTableReact from 'components/v2/dataTableReact';
import { Warning } from 'routes/Route';
//style
import { Button, ButtonContainer } from 'styles/components'
import Toggle from 'components/v2/toggle';

export default function Additive({match}) {
  const message = useSelector(state => state.message.data);
  const additiveId = match.params.id;
  const [dataAdditive, setDataAdditive] = useState({
    protheusCode: "",
    description: "",
    descriptionAlias: "",
    expirationDate: "",
    ignoreExpirationDate: false,
  })
  const [errors, setErrors] = useState([]);

  // Components
  const [selectedComponents, setSelectedComponents] = useState([]);
  const [optionsComponent, setOptionsComponent] = useState([]);
  const [searchComponent, setSearchComponent] = useState("");
  const [itemSelectedComponent, setItemSelectedComponent] = useState(null);
  const [isLoadingComponents, setIsLoadingComponents] = useState(false);
  const [catalogSeal, setCatalogSeal] = useState(null);

  // Nutrients
  /*const [selectedNutrients, setSelectedNutrients] = useState([]);
  const [optionsNutrient, setOptionsNutrient] = useState([]);
  const [searchNutrient, setSearchNutrient] = useState("");
  const [itemSelectedNutrient, setItemSelectedNutrient] = useState(null);
  const [isLoadingNutrients, setIsLoadingNutrients] = useState(false);*/

  const { setNotWarning } = useContext(Warning);

  const debouncedSearchComponent = useDebounce(searchComponent);
  
  const dispatch = useDispatch();

  const loadComponent = useCallback((searchValue) => {
    setIsLoadingComponents(true)
    findComponent(searchValue).then(result => {
      const components = result.data.map(element => {
        return { value: element, label: `${element.protheusCode} - ${element.description}` }
      });
      setOptionsComponent(components);
      setIsLoadingComponents(false)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      setIsLoadingComponents(false);
    });
  }, [dispatch])

  useEffect(() => {
    if(loadComponent) loadComponent(debouncedSearchComponent)
  },[debouncedSearchComponent, loadComponent]);

  useEffect(() => {
    if (additiveId) {
      dispatch(startRequest());
      getAdditive(additiveId).then(data => {
        const formData = data.data;
        setDataAdditive({
          protheusCode: formData.protheusCode,
          description: formData.description,
          descriptionAlias: formData.descriptionAlias,
          expirationDate: formData.expirationDate,
          ignoreExpirationDate: formData.ignoreExpirationDate,
        })
        if (formData.components) {
          setSelectedComponents(formData.components);
        }
        /*if(formData.nutrients) {
          setSelectedNutrients(formData.nutrients);
        }*/
        if (formData.catalogSeal) {
          setCatalogSeal(formData.catalogSeal);
        }
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, additiveId])

  async function handleSubmit () {
    setNotWarning();
    const errorsList = await validateForm(dataAdditive, additiveSchema);
    if (errorsList.length > 0) {
      setErrors(errorsList);
      return;
    } 

    if (selectedComponents.length > 0) {
      dataAdditive.components = selectedComponents;
    }

    /*if (selectedNutrients.length > 0) {
      dataAdditive.nutrients = selectedNutrients;
    }*/

    dataAdditive.catalogSeal = catalogSeal || null;

    dispatch(startRequest());
    putAdditive(additiveId, dataAdditive).then(data => {
      history.push('/premixlabel/additives')
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });    
  }

  function handleComponentInputChange(data, event) {
    if (data) {
      const componentIndex = selectedComponents.findIndex(item => item.protheusCode === data?.value?.protheusCode);
      setItemSelectedComponent(data);
      if (componentIndex >= 0)  return;
      
      setSelectedComponents([...selectedComponents, data.value]); 
    } else {
      setItemSelectedComponent(null);
      loadComponent("");
    }
  }

  function handleRemoveComponent(component) {    
    var componentIndex = selectedComponents.indexOf(component);
    selectedComponents.splice(componentIndex, 1);
    setSelectedComponents([...selectedComponents]);    
  }
  
  const columnsComponents = [
    {
      maxWidth: "25%",
      name: 'Código',
      selector: 'protheusCode',
      sortable: true,
    },
    {
      maxWidth: "25%",
      name: 'Descrição',
      selector: 'description',
      sortable: true,
    },
    {
      maxWidth: "25%",
      name: 'Rótulo',
      selector: 'descriptionAlias',
      sortable: false,
    },
    {
      maxWidth: "25%",
      style: {justifyContent: "flex-end"},
      cell: row => 
      <>
        <div>
          <IconContainer>
            <TableAction 
            title={"Excluir"} 
            onClick={() => handleRemoveComponent(row)}>
              <DeleteIcon/>
            </TableAction>
          </IconContainer>
        </div>
      </>,
    }
  ];

  function getSealImage(sealCode) {
    switch (sealCode.toUpperCase()) {
      case 'FP':
        return require('../../../assets/images/produtos/FP.jpg');
      case '20A':
        return require('../../../assets/images/produtos/20A.jpg');
      default:
        return null;
    }
  };

  function contentElement(index, option) {
    return (
      <MenuItem key={index} value={option}><img style={{width: 30, marginRight: 10}} src={getSealImage(option)} alt="option"/>{option}</MenuItem>
    )
  }

  return (
    <ContentPanel title='Aditivo' message={message}>
      <GridContainer>
        <InputText
          xs={12}
          sm={6}
          required
          name="protheusCode"
          label="Código"
          type="text"
          value={dataAdditive.protheusCode}
          onChange={(e) => setDataAdditive({...dataAdditive, protheusCode: e.target.value})}
          errors={errors} 
          disabled={true}
        />

        <InputText
          xs={12}
          sm={6}
          required
          name="description"
          label="Descrição"
          type="text"
          value={dataAdditive.description}
          onChange={(e) => setDataAdditive({...dataAdditive, description: e.target.value})}
          errors={errors} 
          disabled={true}
        /> 
  
        <InputText
          xs={12}
          sm={6}
          required
          name="expirationDate"
          label="Validade em Meses"
          type="number"
          placeholder="Validade em Meses"
          value={dataAdditive.expirationDate}
          onChange={(e) => setDataAdditive({...dataAdditive, expirationDate: e.target.value})}
          errors={errors} 
        /> 

        <InputText
          xs={12}
          sm={6}
          name="descriptionAlias"
          label="Rótulo"
          type="text"
          placeholder="Rótulo"
          value={dataAdditive.descriptionAlias}
          onChange={(e) => setDataAdditive({...dataAdditive, descriptionAlias: e.target.value})}
          errors={errors} 
        />

        <InputSelect
          xs={6}
          sm={6}
          name="catalogSeal"
          label="Selo (Caso seja necessário, selecione o selo para o aditivo)"
          value={catalogSeal}
          onChange={(e) => setCatalogSeal(e.target.value)}
          defaultValue={null}
          options={sealImageList}
          errors={errors}
          contentElement={contentElement}
        /> 
        
        <Toggle
          xs={6}
          sm={6}
          name="Ignora validade"
          checked={dataAdditive.ignoreExpirationDate}
          onChange={(e) => setDataAdditive({...dataAdditive, ignoreExpirationDate: e.target.checked})}
          label="Ignora validade"
        />
      </GridContainer>

      <ContentPanel title="Matéria Prima">
        <SelectAutocomplete
          xs={12}
          sm={12}
          value={itemSelectedComponent}
          label="Buscar Matéria Prima"
          optionSelected={(option, value) => option.label === value.label}
          optionLabel={(option) => option.label}
          onChangeSelected={(event, value) => handleComponentInputChange(value)}
          onChange={(event) => setSearchComponent(event.target.value)}
          options={optionsComponent}
          name="components"
          isLoading={isLoadingComponents}
        />

        <DataTableReact
          noHeader
          columns={columnsComponents}
          data={selectedComponents}
        />
      </ContentPanel>

      <ButtonContainer>
        <Button type="submit" onClick={handleSubmit}>Salvar</Button>
      </ButtonContainer>
    </ContentPanel>
  )
}