//external
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  paper: {
    width: 350,
    height: 250,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  formControl: {
    margin: 10
  }
}));

export default function SimpleTransferList({leftList, rightList, onChangeList, side, labelItem, valueItem}) {
  const classes = useStyles();
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [itemSelected, setItemSelected] = useState("");
  const [sidePosition, setSidePosition] = useState("");

  useEffect(() => {
    setLeft(leftList)
  }, [leftList]);

  useEffect(() => {
    setRight(rightList)
  }, [rightList]);

  useEffect(() => {
    setSidePosition(side);
  }, [side]);

  const handleSelectItem = (event) => {
    const {value} = event.target;
    const onlyNumbers = /[0-9]/g;
    const result = value.match(onlyNumbers);
    getSidePosition(result ? parseInt(value) : value);
    setItemSelected(result ? parseInt(value) : value);
  };

  const getSidePosition = (itemPosition) => {
    const side = left.findIndex(item => item[valueItem] === itemPosition) > -1;
    if(side) {
      setSidePosition("left");
    }else {
      setSidePosition("right");
    }
  }

  function listTransferList() {
    onChangeList(itemSelected, sidePosition);
    setTimeout(() => {
      setItemSelected("");
      setSidePosition("");  
    }, 200);
  }

  const customList = (items) => (
    <Paper className={classes.paper} elevation={3}>   
      <RadioGroup aria-label="gender" name="gender1" value={itemSelected} onChange={handleSelectItem}> 
        {items.map((value, index) => {
          return (
            <FormControlLabel
              key={index}
              className={classes.formControl}
              value={value[valueItem]} 
              control={<Radio />} 
              label={value[labelItem]} 
            />
          );
        })}
      </RadioGroup>
    </Paper>
  );

  return (
    <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
      <Grid item>{customList(left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={() => listTransferList()}
            disabled={sidePosition === "right" || sidePosition === ""}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={() => listTransferList()}
            disabled={sidePosition === "left" || sidePosition === ""}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          
        </Grid>
      </Grid>
      <Grid item>{customList(right)}</Grid>
    </Grid>
  );
}

SimpleTransferList.propTypes = {
  leftList: PropTypes.array,
  rightList: PropTypes.array,
  side: PropTypes.string,
  onChangeList: PropTypes.func,
}