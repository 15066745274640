//external
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//internal
import { deleteUserGroup, getUserGroup } from 'services/userGroup/service';
import history from 'services/history';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import ContentPanel from 'components/ContentPanel';
import { setMessage } from 'store/modules/message/action';
import { sanitizeErrorUI } from 'utils/errorUtil';
//style
import { Attr, AttrName } from './styled';
import { Button, ButtonContainer } from 'styles/components';

export default function UserGroupDelete({match}) {
  const userGroupId = match.params.id;
  const message = useSelector(state => state.message.data);
  const [userGroup, setUserGroup] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    if (userGroupId) {
      dispatch(startRequest());
      getUserGroup(userGroupId).then(data => {
        setUserGroup(data.data);
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, userGroupId]);

  async function handleDelete () {
    dispatch(startRequest());
    deleteUserGroup(userGroupId).then(data => {
      history.push('/user_group')
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }

  async function handleBack () {
    history.goBack();
  }

  return (
    <>
      <ContentPanel title="Deseja excluir este grupo de usuário?" message={message}>
        {userGroup && <>
          <AttrName>Descrição:</AttrName>
          <Attr>{userGroup.description}</Attr>
          <ButtonContainer>
            <br />
            <Button left={true} cancel={true} onClick={handleBack}>
              Voltar
            </Button>
            <Button onClick={handleDelete}>
              Excluir
            </Button>
          </ButtonContainer>
        </>}
      </ContentPanel>
    </>
  )
}