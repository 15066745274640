//external
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import HistoryIcon from '@material-ui/icons/History';
//internal
import { getAllUsers, getAllows } from 'services/user/service'
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import ContentPanel from 'components/ContentPanel';
import { setMessage } from 'store/modules/message/action';
import { useDebounce } from 'utils/uiUtils';
import GridContainer from 'components/v2/grid';
import InputText from 'components/v2/inputText';
import TableAction from 'components/v2/tableAction';
import { sanitizeErrorUI } from 'utils/errorUtil';
import DataTableReact from 'components/v2/dataTableReact';
//style
import { IconContainer } from './styled';

export default function Profile() {
  const message = useSelector(state => state.message.data);

  const [users, setUsers] = useState([]);
  const [allows, setAllows] = useState({post: false});
  const [searchTerm, setSearchTerm] = useState("");

  //Pagination
  const [loading, setLoading] = useState(false);

  const debouncedSearchTerm = useDebounce(searchTerm);

  const dispatch = useDispatch();

  const actions = [
    <div key="addProfile">
      <TableAction 
      title={"Adicionar"} 
      disabled={!allows.post} 
      to='/user/add'>
        <AddIcon/>
      </TableAction>
    </div>
  ];

  const columns = [
    {
      name: 'Usuário',
      selector: 'username',
      sortable: true,
      maxWidth: "18%",
    },
    {
      name: 'Nome',
      selector: 'name',
      sortable: true,
      maxWidth: "15%",
    },
    {
      name: 'Vendedor',
      selector: 'salesman',
      sortable: true,
      maxWidth: "15%",
    },
    {
      name: 'Técnico',
      selector: 'technical',
      sortable: true,
      maxWidth: "15%",
    },
    {
      name: 'Ativo',
      selector: 'enabled',
      sortable: true,
      cell: row => <input type='checkbox' checked={row.enabled} readOnly />,
      center: true,
      maxWidth: '5%',
    },
    {
      name: 'Status',
      selector: 'status',
      center: true,
      maxWidth: '15%',
    },
    {
      maxWidth: '14%',
      style: {justifyContent: "flex-end"},
      cell: row => 
      <div>
        <IconContainer>
          <TableAction 
          title={"Editar"} 
          disabled={!allows.put} 
          to={`/user/edit/${row.username}`}>
            <EditIcon/>
          </TableAction>
          <TableAction 
          title={"Historico"} 
          to={{pathname: '/premixapp/user_history', username: row.username }}>
            <HistoryIcon/>
          </TableAction>
        </IconContainer>
        </div>,
      sortable: true
    }
  ];

  useEffect(() => {
    getAllows().then(data => {
      setAllows(data.data)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    });
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);
    dispatch(startRequest());    
    getAllUsers(debouncedSearchTerm).then(data => {
      setUsers(data.data.map(value => {
        value.salesman = value?.salesman?.id ? `${value.salesman.id} - ${value.salesman.name}` : '';
        value.technical = value?.technical?.id ? `${value.technical.id} - ${value.technical.name}` : '';
        return value;
      }));
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
      setLoading(false);
    });
  }, [debouncedSearchTerm, dispatch]);

  const handleSearchChange = (value) => {
    setSearchTerm(value);
  }

  return (
    <>
      <ContentPanel message={message} title="Usuários" actions={actions}>
        <form>
          <GridContainer>
            <InputText
              xs={12}
              name="search"
              label="Pesquisar"
              type="text"
              placeholder="Pesquisar"
              value={searchTerm}
              onChange={(e) => handleSearchChange(e.target.value)}
              hasWarning={false}
            />
          </GridContainer>
        </form>

        <DataTableReact
          fixedHeader
          progressPending={loading}
          columns={columns}
          data={users}
          noHeader
        />
      </ContentPanel>
    </>
  )
}