import React, { useEffect, useState } from 'react'

import { Attr, AttrName } from './styled'

import { getVersion, deleteVersion } from '../../../services/appVersion/service'
import { useDispatch, useSelector } from 'react-redux';
import { startRequest, finishRequest } from '../../../store/modules/loading/actions';

import history from '../../../services/history';
import ContentPanel from '../../../components/ContentPanel';
import { ButtonContainer, Button } from '../../../styles/components';
import { setMessage } from '../../../store/modules/message/action';
import { formatDate } from '../../../utils/formatUtil';
import { sanitizeErrorUI } from 'utils/errorUtil';

export default function AppVersion({match}) {
  const appVersionId = match.params.id;
  const message = useSelector(state => state.message.data);
  const [appVersion, setAppVersion] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startRequest());
    getVersion(appVersionId).then(data => {
      setAppVersion(data.data);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }, [dispatch, appVersionId])

  async function handleDelete () {
    dispatch(startRequest());
    deleteVersion(appVersionId).then(data => {
      history.push('/premixapp/appversion')
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }

  async function handleBack () {
    history.goBack();
  }

  return (
    <>
      <ContentPanel title="Deseja excluir a versao?" message={message}>
        {appVersion && <>
          <AttrName>Aplicativo:</AttrName>
          <Attr>{appVersion.appname}</Attr>
          <AttrName>Versao:</AttrName>
          <Attr>{appVersion.version}</Attr>
          <AttrName>Data Limite Web:</AttrName>
          <Attr>{formatDate(appVersion.webLimitDate)}</Attr>
          <AttrName>Data Limite Android:</AttrName>
          <Attr>{formatDate(appVersion.androidLimitDate)}</Attr>
          <AttrName>Data Limite Ios:</AttrName>
          <Attr>{formatDate(appVersion.iosLimitDate)}</Attr>
          <AttrName>Notas:</AttrName>
          <Attr>{appVersion.notes}</Attr>

          <ButtonContainer>
            <br />
            <Button left={true} cancel={true} onClick={handleBack}>
              Voltar
            </Button>
            <Button onClick={handleDelete}>
              Excluir
            </Button>
          </ButtonContainer>
        </>}
      </ContentPanel>
    </>
  )
}