import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core'

import { StyledInput, ErrorMessage, Container } from './styled'

export default function Input({ name, label, type, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    });
  }, [fieldName, registerField]);

  //{/* <input ref={inputRef} defaultValue={defaultValue} {...rest}/> */}

  return (
    <Container type={type}>
      <label htmlFor={fieldName}>{label}</label>

      {<ErrorMessage className="error">{error}&nbsp;</ErrorMessage>}

      <StyledInput
        error={error}
        id={fieldName}
        name={name}
        ref={inputRef}
        defaultValue={defaultValue}
        autoComplete={'' + Math.random()}
        type={type}
        {...rest}
      />
    </Container>
  )
}