import api from '../../api';

export async function getAllows() {
  return api.get('rtpi_product_category_site/allows');
}
  
export async function getProductCategoriesSite(query, numOfRegisters = 10, page = 1, order) {
  const params = {
    order: order ? order : 'description',
  }
  if (query) {
    params.query = query.trim(); 
  }

  return api.get('rtpi_product_category_site', {
    params,
    headers: {
      registerperpage: numOfRegisters,
      page
    }
  });
}

export async function getBackground() {
  return api.get(`rtpi_product_category_site/const/background`);
}

export async function getIcon() {
  return api.get(`rtpi_product_category_site/const/icon`);
}

export async function getColor() {
  return api.get(`rtpi_product_category_site/const/color`);
}

export async function getProductCategorySite(id) {
  return api.get(`rtpi_product_category_site/${id}`);
}

export async function postProductCategorySite(categorySite) {
  return api.post(`rtpi_product_category_site/`, categorySite);
}

export async function putProductCategorySite(id, data) {
  return api.put(`rtpi_product_category_site/${id}`, data);
}

export async function deleteProductCategorySite(id) {
  return api.delete(`rtpi_product_category_site/${id}`);
}

export async function findProductCategorySite(query, numOfRegisters = 10) {
  return api.get('rtpi_product_category_site', {
    params: {
      query,
      order: 'description'
    },
    headers: {
      registerperpage: numOfRegisters,
      page: 1
    }
  });
}