//external
import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from "date-fns/locale/pt-BR";
import { getIsError, getErrorMessage } from 'utils/validationUtil';
import { Warning } from 'routes/Route';

export default function DatePickers({name, label, xs, sm, okLabel, cancelLabel, value, onChange, errors = [], disabled, disablePast, hasWarning = true}) {
  const [stateErrors, setStateErrors] = useState([]);

  const { setIsWarning } = useContext(Warning);

  const onChangeInput = (e) => {
    onChange(e);
    setStateErrors(stateErrors.filter(item => item.field !== name));
    if (hasWarning) {
      setIsWarning();
    }
  }

  useEffect(() => {
    if(Array.isArray(errors) && errors.length > 0) {   
      setStateErrors(errors)
    }
  }, [errors]);

  return (
    <Grid item xs={xs} sm={sm} >
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
        <KeyboardDatePicker 
          style={{width: '100%'}}
          disablePast={disablePast}
          name={name}
          label={label}
          value={value} 
          onChange={onChangeInput} 
          ampm={false}
          cancelLabel={cancelLabel}
          okLabel={okLabel}
          format="dd/MM/yyyy"
          inputVariant="outlined"
          margin="none"
          error={getIsError(name, stateErrors)}
          helperText={getErrorMessage(name, stateErrors)}
          disabled={disabled}
        />
      </MuiPickersUtilsProvider>
    </Grid>
  );
}