import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

export default function TableAction({disabled, disableRipple, onClick, children, title, xs, sm, to, isLink = true, style}) {

return (
  <Grid item xs={xs} sm={sm}>
    {isLink && !disabled ? (
      <Link to={to || "#"}>
        <Tooltip title={title}>
          <span>
            <IconButton style={style} onClick={onClick} disabled={disabled} disableRipple={disableRipple}>
              {children}
            </IconButton>
          </span>
        </Tooltip>
      </Link>
    ) : (
      <Tooltip title={title}>
        <span>
          <IconButton style={style} onClick={onClick} disabled={disabled} disableRipple={disableRipple}>
            {children}
          </IconButton>
        </span>
      </Tooltip>
    )}
  </Grid>
)
} 