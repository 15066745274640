import * as Yup from 'yup';

export const scheduleSchema = Yup.object().shape({
  subject: Yup.string().required('Assunto é obrigatório.'),
  ScheduleType: Yup.object().shape({
    id: Yup.number().integer().required('O código do tipo de visita é obrigatório.'),
  }).required('O tipo de visita é obrigatório.').typeError("O tipo de visita é obrigatório."),
  allDay: Yup.boolean().default(false),
  notes: Yup.string(),
  user: Yup.string(),
  place: Yup.string(),
});