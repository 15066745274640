//external
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactDOM from 'react-dom';
import CloseIcon from '@material-ui/icons/Close';
//internal
import TableAction from 'components/v2/tableAction';
import { ContainerModal } from 'pages/schedule/list/styled';

export function showAlert(message, title, confirmButtonText) {
  const props = {
    title: title || 'Atenção',
    message: message,
    questionMessage: false,
    showButtons: true,
    confirmButtonText,
    closeButtonDialog: false
  }

  return renderDialog(props);
}

export function showConfirm(message, title, confirmButtonText, cancelButtonText) {
  const props = {
    title: title || 'Confirmação',
    message: message,
    questionMessage: true,
    showButtons: true,
    confirmButtonText,
    cancelButtonText,
    closeButtonDialog: false
  }

  return renderDialog(props);
}

function renderDialog(props) {
  const wrapper = document.body.appendChild(document.createElement('div'));

  const promise = new Promise((resolve, reject) => {
    const removeDialog = function(resolved) {
      ReactDOM.unmountComponentAtNode(wrapper);
      if (document.body.contains(wrapper)) {
        document.body.removeChild(wrapper)
      }
      
      if (resolved) {
        resolve(true);
      } else {
        resolve(false);
      }
    }

    try {
      ReactDOM.render(
        <div>
          <Dialog
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              {props.closeButtonDialog && (
                <ContainerModal>
                  <TableAction 
                    key='closeAlert'
                    title={"Fechar"} 
                    onClick={() => removeDialog(true)}>
                    <CloseIcon/>
                  </TableAction>
                </ContainerModal>
              )}
              {props.title && <DialogTitle style={{padding: '16px 0px'}} id="alert-dialog-title">{props.title}</DialogTitle>}
              {React.isValidElement(props.message) ? (
                props.message
              ) : (
                <DialogContentText id="alert-dialog-description">
                  {props.message}
                </DialogContentText>
              )}
            </DialogContent>
            {props.questionMessage ? 
              props.showButtons && (
                <DialogActions>
                  <Button onClick={() => removeDialog(false)} color="secondary">
                    {props.cancelButtonText || 'Cancelar'}
                  </Button>
                  <Button onClick={() => removeDialog(true)} color="primary" autoFocus>
                    {props.confirmButtonText || 'Confirmar'}
                  </Button>
                </DialogActions>
              )
            :
              props.showButtons && (
                <DialogActions>
                  <Button onClick={() => removeDialog(true)} color="primary" autoFocus>
                    {props.confirmButtonText || 'Ok'}
                  </Button>
                </DialogActions>
              ) 
            } 
          </Dialog>
        </div>,
        wrapper
      );
    } catch (e) {
      console.error(e);
      throw e;
    }
  })

  return promise.then((result) => {
    return result;
  }, (result) => {
    return Promise.reject(result);
  });
}