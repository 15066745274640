import api from '../api'

export async function getAllows() {
  return api.get('profile/allows');
}

export async function getAllProfiles(numOfRegisters = 10, page = 1) {
  return api.get('profile', {
    headers: {
      registerperpage: numOfRegisters,
      page
    }
  });
}

export async function getProfile(id) {
  return api.get(`profile/${id}`);
}

export async function putProfile(id, profile) {
  return api.put(`profile/${id}`, profile);
}

export async function postProfile(profile) {
  return api.post(`profile/`, profile);
}

export async function deleteProfile(id) {
  return api.delete(`profile/${id}`);
}

export async function getModules() {
  return api.get(`profile/modules`);
}

export async function getDashboards() {
  return api.get(`profile/appdashboards`);
}

export async function findUserGroups(description, numOfRegisters = 6) {
  return api.get('profile/usergroups', {
    params: {
      description,
      order: 'description'
    },
    headers: {
      registerperpage: numOfRegisters,
      page: 1
    }
  });
}

export async function getSpecialPermissions() {
  return api.get(`profile/specialpermissions`);
}