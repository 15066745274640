//external
import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
//internal
import Route from '../../routes/Route';
import ContactDelete from './delete';
import ContactEdit from './edit';
import Contact from './list';

export const FilterContact = createContext({});

export default function Routes() {
  const [filter, setFilter] = useState({
    search: "",
    page: 1,
  });

  let location = useLocation();

  useEffect(() => {
    const split = location?.pathname.split('/');
    if (split[2] !== "contact") {
      setFilter({
        search: "",
        page: 1,
      });
    }
  }, [location, setFilter]);

  return (
    <FilterContact.Provider value={{ filter, setFilter }}>
      <Route path="/schedule/contact" exact component={Contact} isPrivate/>
      <Route path="/schedule/contact/edit/:id" exact component={ContactEdit} isPrivate/>
      <Route path="/schedule/contact/add" exact component={ContactEdit} isPrivate/>
      <Route path="/schedule/contact/delete/:id" exact component={ContactDelete} isPrivate/>
    </FilterContact.Provider>
  )
}