//external
import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, MenuItem, Typography } from '@material-ui/core';
import { Fragment } from 'react';
//internal
import { getIntention, putIntention, getStrings, action, getLabelTypes } from 'services/rtpi/intentions/service';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import history from 'services/history';
import ContentPanel from 'components/ContentPanel';
import { showConfirm } from 'components/AlertDialog';
import Errors from 'components/Errors';
import { validateForm } from 'components/Form/validate';
import { setMessage } from 'store/modules/message/action';
import { intentionSchema } from '../schemas';
import GridContainer from 'components/v2/grid';
import InputText from 'components/v2/inputText';
import InputMultiline from 'components/v2/inputMultiline';
import InputSelect from 'components/v2/inputSelect';
import SearchRtpi from '../searchRtpi';
import InputSelectChildren from 'components/v2/inputSelectChildren';
import { formatStatusRtpiIntention } from 'utils/formatUtil';
import Toggle from 'components/v2/toggle';
import { sanitizeErrorUI } from 'utils/errorUtil';
import DataTableReact from 'components/v2/dataTableReact';
import { Warning } from 'routes/Route';
//style
import { Button, ButtonContainer } from 'styles/components';

const CustomTitle = ({ row }) => (
	<div>
		{}
		<div 
      data-tag="allowRowEvents"
			style={{ overflow: 'hidden', whiteSpace: 'pre-wrap', textOverflow: 'ellipses' }}
    >
      {row.descriptionComponent}
    </div>
	</div>
);

const columnsComponents = [
  {
    maxWidth: "20%",
    name: 'Código',
    selector: 'idComponent',
    sortable: true,
  },
  {
    // maxWidth: "59%",
    maxWidth: "80%",
    name: 'Descrição',
    selector: "descriptionComponent" ,
    sortable: true,
    cell: row => <CustomTitle row={row} />,
  },
  // {
  //   maxWidth: "20%",
  //   name: 'Quantidade',
  //   selector: 'quantityComponent',
  //   sortable: false,
  // },
];

const columnsComponentsSubs = [
  {
    maxWidth: "20%",
    name: 'Código',
    selector: 'idComponent',
    sortable: true,
  },
  {
    maxWidth: "80%",
    name: 'Descrição',
    selector: 'descriptionComponent',
    sortable: true,
  },
];

const columnsNutrients = [
  {
    maxWidth: "20%",
    name: 'Código',
    selector: 'idNutrient',
    sortable: true,
  },
  {
    maxWidth: "40%",
    name: 'Descrição',
    selector: 'descriptionNutrient',
    sortable: true,
  },
  {
    maxWidth: "20%",
    name: 'Quantidade',
    selector: 'quantityNutrient',
    sortable: false,
  },
  {
    maxWidth: "20%",
    name: 'Unidade',
    selector: 'unity',
    sortable: false,
  },
];

export default function Intention({match, location}) {
  const message = useSelector(state => state.message.data);
  const {intentionId} = match.params;
  const urlIsApprove = location.pathname.indexOf('approve') > -1;
  const [isApprove, setIsApprove] = useState(urlIsApprove);
  const [errors, setErrors] = useState([]);
  const [errorResponse, setErrorResponse] = useState([]);
  const [form, setState] = useState({
    id: '',
    productDescription: '',
    revision: '',
    conservationCondition: '',
    productEnrichment: '',
    finishedProductDescription: '',
    productClassification: '',
    productPhysicalForm: '',
    productPackageType: '',
    productPackageForm: '',
    productRestrictionRestrictiveText: '',
    productIndication: '',
    productUseMode: '',
    RtpiIntentionWarrantyLevels: [],
    RtpiIntentionStructures: [],
    RtpiIntentionStructureSubstitutives: [],
    RtpiIntentionWarrantyLevelOlds: [],
    RtpiIntentionStructureOlds: [],
    RtpiIntentionStructureSubstitutiveOlds: [],
    RtpiIntentionDiluitions: [],
    allowPrintLabel: false,    
    // Usados apenas como exibição
    protheusBranch: '',
    protheusCode: '',
    oldRev: '',
    newRev: '',
    status: '',
    LabelType: '',
    reason: '',
    consumptionMin: 0,
    consumptionMax: 0
  });
  const [strings, setStrings] = useState({
    productClassification: [],
    productPhysicalForm: [],
    productPackageType: [],
    productPackageForm: [],
  });
  const [isLabelTypes, setIsLabelType] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [isCopy, setIsCopy] = useState(false);
  const [qtyFields, setQtyFields] = useState("");
  const [formLabels, setFormLabels] = useState({});

  const { setNotWarning } = useContext(Warning);
  
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (intentionId) {
      dispatch(startRequest());
      getLabelTypes().then(data => {
        setIsLabelType(data.data);
      })
      getStrings().then(data => {
        const formData = data.data;
        setStrings({...formData});
      }); 
      
      getIntention(intentionId).then(data => {
        const formData = data.data;
        formData.status = formatStatusRtpiIntention(formData.status);
        setState({...formData});
        setQtyFields(formData.LabelType?.diluitionQty);
        
        normalizeRtpiDiluitions(formData.RtpiIntentionDiluitions);     

        setLoaded(true);
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, intentionId]);

  function normalizeRtpiDiluitions(diluitions) {
    if (diluitions && Array.isArray(diluitions)) {
      const diluition = {}
      for (var idx in diluitions) {
        diluition[`percent${idx}`] = diluitions[idx].percent;
        diluition[`text${idx}`] = diluitions[idx].text;
      }

      setFormLabels(diluition);
    }
  } 

  const scrollToTop = () => {
    const elContent = document.getElementById("content");
    elContent.scrollTo(0,0);
  };

  async function handleSubmit() {
    setNotWarning();

    const objTemp = JSON.parse(JSON.stringify(form));

    setErrorResponse([]);
    scrollToTop();
    const errorsList = await validateForm(objTemp, intentionSchema);
    if (errorsList.length > 0) {
      setErrors(errorsList);
      return;
    } 

    if (form.status !== 'Pendente') {
      dispatch(setMessage({error: true, text: 'Somente intenções com status pendente podem ser alteradas.'}));
      return;
    }

    const diluition = [];

    if (form.LabelType.hasDiluition) {   
      [...Array(qtyFields)].forEach((element, index) => {
        diluition.push({percent: formLabels[`percent${index}`], text: formLabels[`text${index}`]});  
      });
    }

    const validateDiluition = diluition.findIndex(
      item => item.percent === "" || item.percent === undefined || item.text === "" || item.text === undefined
    )   

    if (validateDiluition > -1) {
      dispatch(setMessage({error: true, text: 'Porcentagem ou texto inválidos'}));
      return
    }

    const regex = /^(0|[1-9][0-9]?|100)$/;

    const validatePercent = diluition.findIndex(item => !regex.test(item.percent))

    if (validatePercent > -1) {
      dispatch(setMessage({error: true, text: 'Porcentagem somente números entre 0 e 100'}));
      return
    }
  
    const objCopy = JSON.parse(JSON.stringify(objTemp));
    delete objCopy.protheusBranch;
    delete objCopy.protheusCode;
    delete objCopy.oldRev;
    delete objCopy.newRev;
    delete objCopy.status;
    objCopy.allowPrintLabel = form.allowPrintLabel || false;
    objCopy.RtpiIntentionDiluitions = diluition;

    dispatch(startRequest());
    putIntention(intentionId, objCopy).then(data => {
      if (urlIsApprove) {
        setIsApprove(true);
      } else {
        history.push('/premixlabel/rtpi/rtpi_intention');
      }
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });    
  }

  function onChandeLabelType(value) {
    const labelObject = isLabelTypes.find(item => item.id === value)
    setState({...form, LabelType: labelObject});
    setQtyFields(labelObject.diluitionQty);
  }

  function handleCopy() {
    setIsCopy(true);
  }

  const handleClose = () => {
    if(!isCopy) {
      setIsOpen(false);
    }else{
      setIsCopy(false);
    }
  }

  function handleClickSaveCopy(param) {
    setIsOpen(false);
    setState({...form, ...param})

    setQtyFields(param.LabelType?.diluitionQty || 0);
    normalizeRtpiDiluitions(param.RtpiDiluitions);
    setIsCopy(false);
  }

  const handleApproveClick = async () => {
    if (!await showConfirm('Deseja realmente aprovar esta intenção?')) {
      return;
    }

    scrollToTop();

    dispatch(startRequest());
    action('APPROVE',intentionId).then(data => {
      history.push('/premixlabel/rtpi/rtpi_intention')
    }).catch(err => {
      scrollToTop();
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });    
  };
  
  const handleRejectClick = async (renew) => {
    const evt = renew ? {
      question: 'Deseja realmente reprocessar esta intenção?',
      action: 'RENEW'
    } : {
      question: 'Deseja realmente reprovar esta intenção?',
      action: 'REPROVE'
    }

    if (!await showConfirm(evt.question)) {
      return;
    }

    scrollToTop();

    dispatch(startRequest());
    action(evt.action, intentionId).then(data => {
      history.push('/premixlabel/rtpi/rtpi_intention')
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    }); 
  };

  const handleClickOpen = () => {
    setIsOpen(true);
  }
  
  const updateField = (e) => {
    setState({...form, [e.target.name]: e.target.value});
  };

  const updateFieldLabels = (e) => {
    setFormLabels({...formLabels, [e.target.name]: e.target.value});
  };

  const handleClickEdit = () => {
    dispatch(startRequest());
    scrollToTop();

    setTimeout(() => {
      setIsApprove(false)
    }, 100);
    
    setTimeout(() => {
      dispatch(finishRequest());
    }, 400);
  };

  return (
    <>
      <ContentPanel title={`Intenção - Rev. ${form.oldRev} -> ${form.newRev}`} message={message}>
        {errorResponse && Array.isArray(errorResponse) && errorResponse.length > 0 && (
          <Errors errorResponse={errorResponse} />  
        )}
        {errors && Array.isArray(errors) && errors.length > 0 && (
          <Errors errors={errors} />  
        )}

        <div>
          {!isApprove && (
            <Button onClick={handleClickOpen}>Copiar RTPI'S</Button>
          )}

          <SearchRtpi 
            isOpen={isOpen} 
            onHandleClose={handleClose}
            onClickSave={handleClickSaveCopy}
            onHandleCopy={handleCopy}
            isCopy={isCopy}
          /> 
          
          <GridContainer>
            {!isApprove && (
              <>
                <InputText
                  xs={12}
                  sm={4}
                  name="status"
                  label="Status"
                  type="text"
                  value={form.status}
                  errors={errors}
                  disabled={true}
                />
                <InputText
                  xs={12}
                  sm={4}
                  name="protheusBranch"
                  label="Filial"
                  type="text"
                  value={form.protheusBranch}
                  errors={errors}
                  disabled={true}
                />
                <InputText
                  xs={12}
                  sm={4}
                  name="protheusCode"
                  label="Código"
                  type="text"
                  value={form.protheusCode}
                  errors={errors}
                  disabled={true}
                />
                <InputText
                  xs={12}
                  sm={6}
                  required
                  name="reason"
                  label="Razão"
                  type="text"
                  value={form.reason}
                  errors={errors}
                  disabled={true}
                />
                <InputText
                  xs={12}
                  sm={6}
                  required
                  name="productDescription"
                  label="Descrição"
                  type="text"
                  value={form.productDescription}
                  errors={errors}
                  disabled={true}
                />
                
                <InputMultiline
                  xs={12}
                  sm={6}
                  name="conservationCondition"
                  label="Condição de conservação"
                  type="text"
                  value={form.conservationCondition || ''}
                  onChange={updateField}
                  errors={errors}
                />
                <InputMultiline
                  xs={12}
                  sm={6}
                  name="productEnrichment"
                  label="Enriquecimento do produto"
                  type="text"
                  value={form.productEnrichment || ''}
                  onChange={updateField}
                  errors={errors}
                />

                <InputMultiline
                  xs={12}
                  sm={6}
                  required
                  name="productIndication"
                  label="Indicação"
                  type="text"
                  value={form.productIndication}
                  onChange={updateField}
                  errors={errors}
                />
                <InputMultiline
                  xs={12}
                  sm={6}
                  required
                  name="productUseMode"
                  label="Modo de uso"
                  type="text"
                  value={form.productUseMode}
                  onChange={updateField}
                  errors={errors}
                />

                <InputMultiline
                  xs={12}
                  name="finishedProductDescription"
                  label="Descrição do produto acabado"
                  type="text"
                  value={form.finishedProductDescription || ''}
                  onChange={updateField}
                  errors={errors}
                />

                <InputText
                  xs={6}
                  sm={6}
                  required
                  name="consumptionMin"
                  label="Consumo Minimo (gr/Cab/Dia)"
                  type="number"
                  value={form.consumptionMin}
                  onChange={updateField}
                  errors={errors}
                />
                <InputText
                  xs={6}
                  sm={6}
                  required
                  name="consumptionMax"
                  label="Consumo Maximo (gr/Cab/Dia)"
                  type="number"
                  value={form.consumptionMax}
                  onChange={updateField}
                  errors={errors}
                />

                <InputSelect
                  xs={12}
                  sm={4}
                  required
                  name="productClassification"
                  label="Classificação"
                  value={form.productClassification}
                  onChange={updateField}
                  defaultValue={null}
                  options={strings.productClassification}
                  errors={errors}
                />
                <InputSelect
                  xs={12}
                  sm={4}
                  required
                  name="productPhysicalForm"
                  label="Forma física"
                  value={form.productPhysicalForm}
                  onChange={updateField}
                  defaultValue={null}
                  options={strings.productPhysicalForm}
                  errors={errors}
                />
                <InputSelect
                  xs={12}
                  sm={4}
                  required
                  name="productPackageType"
                  label="Tipo de embalagem"
                  value={form.productPackageType}
                  onChange={updateField}
                  defaultValue={null}
                  options={strings.productPackageType}
                  errors={errors}
                />

                <InputSelect
                  xs={12}
                  sm={6}
                  required
                  name="productPackageForm"
                  label="Forma da embalagem"
                  value={form.productPackageForm}
                  onChange={updateField}
                  defaultValue={null}
                  options={strings.productPackageForm}
                  errors={errors}
                />
                <InputText
                  xs={6}
                  sm={3}
                  required
                  name="revision"
                  label="Revisão"
                  type="text"
                  onChange={updateField}
                  value={form?.revision?.toString() || ''}
                  errors={errors}
                  disabled={form.disableRevisionChange}
                />
                <InputText
                  xs={6}
                  sm={3}
                  required
                  name="productExpirationDate"
                  label="Validade (meses)"
                  type="text"
                  onChange={updateField}
                  value={form?.productExpirationDate?.toString() || ''}
                  errors={errors}
                  disabled={true}
                />
                <InputText
                  xs={12}
                  sm={12}
                  required
                  name="productRestrictionRestrictiveText"
                  label="Restrição do produto"
                  type="text"
                  value={form?.productRestrictionRestrictiveText?.toString() || ''}
                  errors={errors}
                  disabled={true}
                />
                <InputSelectChildren
                  xs={12}
                  sm={12}
                  required
                  name="LabelType"
                  label="Tipo de etiqueta"
                  value={form.LabelType?.id}
                  onChange={(e) => onChandeLabelType(e.target.value)}
                  defaultValue={null}
                  errors={errors}
                >
                  {isLabelTypes.map(LabelType => (
                    <MenuItem key={LabelType.id} value={LabelType.id}>{LabelType.description}</MenuItem>
                  ))}
                </InputSelectChildren>

                {[...Array(qtyFields)].map((x,i) => 
                  <Fragment key={i}>
                    <InputText
                      xs={6}
                      sm={2}
                      name={`percent${i}`}
                      label="Porcentagem"
                      maxLength={100}
                      minLength={0}
                      type="number" 
                      value={formLabels[`percent${i}`]}
                      errors={errors}
                      onChange={updateFieldLabels}
                      
                    />
                    <InputText
                      xs={6}
                      sm={10}
                      name={`text${i}`}
                      label="Texto"
                      type="text"
                      value={formLabels[`text${i}`]}
                      errors={errors}
                      onChange={updateFieldLabels}
                    />
                  </Fragment>  
                )} 

                {loaded && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <ContentPanel title="Matéria Prima">
                        <DataTableReact
                          noHeader
                          columns={columnsComponents}
                          data={form.RtpiIntentionStructures ? form.RtpiIntentionStructures.filter(elem => elem.add) : []}
                        />
                      </ContentPanel>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <ContentPanel title="Substitutivos">
                        <DataTableReact
                          noHeader
                          columns={columnsComponentsSubs}
                          data={form.RtpiIntentionStructureSubstitutives ? form.RtpiIntentionStructureSubstitutives.filter(elem => elem.add) : []}
                        />
                      </ContentPanel>
                    </Grid>
                    <Grid item xs={12}>
                      <ContentPanel title="Níveis de Garantia">
                        <DataTableReact
                          noHeader
                          columns={columnsNutrients}
                          data={form.RtpiIntentionWarrantyLevels ? form.RtpiIntentionWarrantyLevels.filter(elem => elem.add) : []}
                        />
                      </ContentPanel>
                    </Grid>
                    {/* <Grid item xs={12}>
                      {NutrientsMemo}
                    </Grid>
                    
                    <Grid item xs={12}>
                      {ComponentsMemo}
                    </Grid>
                    
                    <Grid item xs={12}>
                      {SubstitutiveMemo}
                    </Grid> */}
                  </>
                )}
              </>
            )}

            {isApprove && (
              <>
                <Grid item xs={12} sm={4}>
                  <Typography color="textPrimary" variant="h6">Status:</Typography>
                  <Typography color="textPrimary" variant="subtitle2">{form.status}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography color="textPrimary" variant="h6">Filial:</Typography>
                  <Typography color="textPrimary" variant="subtitle2">{form.protheusBranch}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography color="textPrimary" variant="h6">Código:</Typography>
                  <Typography color="textPrimary" variant="subtitle2">{form.protheusCode}</Typography>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                  <Typography color="textPrimary" variant="h6">Razão:</Typography>
                  <Typography color="textPrimary" variant="subtitle2">{form.reason}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography color="textPrimary" variant="h6">Descrição:</Typography>
                  <Typography color="textPrimary" variant="subtitle2">{form.productDescription}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography color="textPrimary" variant="h6">Condição de conservação:</Typography>
                  <Typography color="textPrimary" variant="subtitle2">{form.conservationCondition}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography color="textPrimary" variant="h6">Enriquecimento do produto:</Typography>
                  <Typography color="textPrimary" variant="subtitle2">{form.productEnrichment}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography color="textPrimary" variant="h6">Indicação:</Typography>
                  <Typography color="textPrimary" variant="subtitle2">{form.productIndication}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography color="textPrimary" variant="h6">Modo de uso:</Typography>
                  <Typography color="textPrimary" variant="subtitle2">{form.productUseMode}</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography color="textPrimary" variant="h6">Descrição do produto acabado:</Typography>
                  <Typography color="textPrimary" variant="subtitle2">{form.finishedProductDescription}</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography color="textPrimary" variant="h6">Etiqueta:</Typography>
                  <Typography color="textPrimary" variant="subtitle2">{form.LabelType?.description}</Typography>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Typography color="textPrimary" variant="h6">Classificação:</Typography>
                  <Typography color="textPrimary" variant="subtitle2">{form.productClassification}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography color="textPrimary" variant="h6">Forma física:</Typography>
                  <Typography color="textPrimary" variant="subtitle2">{form.productPhysicalForm}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography color="textPrimary" variant="h6">Tipo de embalagem:</Typography>
                  <Typography color="textPrimary" variant="subtitle2">{form.productPackageType}</Typography>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <Typography color="textPrimary" variant="h6">Forma da embalagem:</Typography>
                  <Typography color="textPrimary" variant="subtitle2">{form.productPackageForm}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography color="textPrimary" variant="h6">Restrição:</Typography>
                  <Typography color="textPrimary" variant="subtitle2">{`${form.productRestrictionRestrictiveText || ''}`}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography color="textPrimary" variant="h6">Revisão:</Typography>
                  <Typography color="textPrimary" variant="subtitle2">{form.revision}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <ContentPanel title="Matéria Prima">
                    <DataTableReact
                      noHeader
                      columns={columnsComponents}
                      data={form.RtpiIntentionStructures ? form.RtpiIntentionStructures.filter(elem => elem.add) : []}
                    />
                  </ContentPanel>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ContentPanel title="Substitutivos">
                    <DataTableReact
                      noHeader
                      columns={columnsComponentsSubs}
                      data={form.RtpiIntentionStructureSubstitutives ? form.RtpiIntentionStructureSubstitutives.filter(elem => elem.add) : []}
                    />
                  </ContentPanel>
                </Grid>
                <Grid item xs={12}>
                  <ContentPanel title="Níveis de Garantia">
                    <DataTableReact
                      noHeader
                      columns={columnsNutrients}
                      data={form.RtpiIntentionWarrantyLevels ? form.RtpiIntentionWarrantyLevels.filter(elem => elem.add) : []}
                    />
                  </ContentPanel>
                </Grid>
              </>
            )}  

            
          </GridContainer>
          <br />
          <ButtonContainer>
            
              {!isApprove ? 
                <div style={{display: "flex", justifyContent: "space-between"}}>
                  <Toggle
                    xs={12}
                    sm={6}
                    name="allowPrintLabel"
                    checked={form.allowPrintLabel || false}
                    onChange={(e) => setState({...form, allowPrintLabel: e.target.checked})}
                    label="Permitir impressão de etiqueta"
                  />          
                  <Button onClick={handleSubmit}>Salvar</Button> 
                </div>  
              : (
                <>
                  <Button onClick={handleClickEdit} left={true}>Editar</Button>                  
                  <Button onClick={handleApproveClick}>Aprovar</Button>
                  <Button onClick={() => handleRejectClick(true)} cancel={true} style={{marginRight: '10px'}}>Reprocessar</Button>
                  <Button onClick={() => handleRejectClick(false)} cancel={true} style={{marginRight: '10px'}}>Reprovar</Button>
                </>
              )}
          </ButtonContainer>
        </div>
      </ContentPanel>
      
    </>
  )
}