import api from '../api';

export async function getAllows() {
  return api.get('product_restriction/allows');
}
  
export async function getProductRestrictions(description, numOfRegisters = 10, page = 1, order) {
  const params = {
    order: order ? order : 'description',
  }
  if (description) {
    params.description = description.trim(); 
  }

  return api.get('product_restriction', {
    params,
    headers: {
      registerperpage: numOfRegisters,
      page
    }
  });
}

export async function getProductRestriction(id) {
  return api.get(`product_restriction/${id}`);
}

export async function postProductRestriction(productRestriction) {
  return api.post(`product_restriction/`, productRestriction);
}

export async function putProductRestriction(id, data) {
  return api.put(`product_restriction/${id}`, data);
}

export async function deleteProductRestriction(id) {
  return api.delete(`product_restriction/${id}`);
}

export async function findProductRestriction(description, numOfRegisters = 10) {
  return api.get('product_restriction', {
    params: {
      description,
      order: 'description'
    },
    headers: {
      registerperpage: numOfRegisters,
      page: 1
    }
  });
}