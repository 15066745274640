import * as Yup from 'yup';

export const contactSchema = Yup.object().shape({
  name: Yup.string().required('Nome do contato é obrigatorio.'),
  email: Yup.string().email(),
  phone: Yup.string().required('Telefone é obrigatório'),
  active: Yup.bool().default(true),
  notes: Yup.string(),
  contactRoleId: Yup.number().integer().required('Cargo do contato é obrigatorio.'),
  prospects: Yup.array().of(Yup.object().shape({
    id: Yup.number().integer().required('O ID do Prospect é obrigatório')
  })),
  customers: Yup.array().of(Yup.object().shape({
    id: Yup.string().required('O ID do Cliente é obrigatório'),
    store: Yup.string().required('A loja do Cliente é obrigatório')
  })) 
});