import React, { useEffect } from 'react';
import { Collapse } from '@material-ui/core';

export default function MyCollapse(props) {
  const {item} = props;

  function needsToBeOpened() {
    return !item.open && isUrlInChildren(item, window.location.pathname);
  }

  function isUrlInChildren(parent, url) {
    if (!Array.isArray(parent.items)) {
      return false;
    }

    for ( let i = 0; i < parent.items.length; i++ ) {
      if ( parent.items[i].to === url || url.includes(parent.items[i].to) ) {
        return true;
      }
    }

    return false;
  }

  useEffect(() => {    
    item.open = needsToBeOpened();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  return (
    <Collapse in={item.open} timeout="auto" unmountOnExit>
      {props.children}
    </Collapse>          
  )
}