//external
import React, { useEffect, useState, useContext } from 'react'
import { Form } from '@unform/web';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Grid, Typography, Divider } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DescriptionIcon from '@material-ui/icons/Description';
import RefreshIcon from '@material-ui/icons/Refresh';
import InfoIcon from '@material-ui/icons/Info';
import PaymentIcon from '@material-ui/icons/Payment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
//internal
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import { findDocumentTypes } from 'services/document/service';
import { getYears } from 'services/util/service';
import { getCommissions, getSummary, getCommissionsPayed, getAllows } from 'services/commission/service'
import { groupBy, getMonthName, formatDate, formatCurrency, monthList } from 'utils/formatUtil';
import { setMessage } from 'store/modules/message/action';
import { sanitizeErrorUI } from 'utils/errorUtil';
import ContentPanel from 'components/ContentPanel';
import DataTableLoading from 'components/DataTableLoading';
import DataTableReact from 'components/v2/dataTableReact';
import GridContainer from 'components/v2/grid';
import TabComponent from 'components/v2/tabs';
import TabPanel from 'components/v2/tabPanel';
import InputSelect from 'components/v2/inputSelect';
import TableAction from 'components/v2/tableAction';
import SelectAutocomplete from 'components/v2/autocomplete';
import { FilterCommission } from '..';
//style
import { IconContainer } from 'pages/user/list/styled';
import { typeDocument } from '../schemas';

const CustomValue = ({ item }) => (
	<div>
		<div 
      data-tag="allowRowEvents"
			style={{ 
        overflow: 'hidden', 
        whiteSpace: 'pre-wrap', 
        textOverflow: 'ellipses',
      }}
    >
      <span style={{
        color: item.payed === 0 ? "#008000" : "#dc3545",
        fontWeight: 'bold',
      }}>
        {item.payed === 0 ? formatCurrency(item.value) : formatCurrency(item.payed * -1)}
      </span>
    </div>
	</div>
);

export default function Commissions({match}) {
  const message = useSelector(state => state.message.data);
  const salesman = useSelector(state => state.user.id);

  const [commissions, setCommissions] = useState([]);
  const [payed, setPayed] = useState([]);
  const [summary, setSummary] = useState([]);
  const [allows, setAllows] = useState({});
  const [types, setTypes] = useState([]);
  const [reload, setReload] = useState(0);
  const [yearList, setYearList] = useState([]);
  //Pagination
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const { filter, setFilter } = useContext(FilterCommission);
  
  const dispatch = useDispatch();

  function props(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  const tabsMenu = () => {
    return tabs.map((item, index) => (
      <Tab key={index} label={item.name} {...props(index)} />
    ))
  };

  const actions = [
    <div style={{display: 'flex'}} key='actionsCommission'>      
      {filter.selectedIndex === 4 && allows?.post && types.find((type) => type.id === typeDocument.NFS_COMISSAO_RC.typeId) && (
        <TableAction 
          title={"Enviar NF"} 
          to={"/commission/document"}
        >
          <DescriptionIcon/>
        </TableAction>
      )}
      <TableAction 
        title={"Atualizar"} 
        onClick={refreshHandler}
        isLink={false}
      >
        <RefreshIcon/>
      </TableAction>      
    </div>
  ];

  function refreshHandler() {
    setReload(reload + 1);
  }

  const tabs = [
    {id: 1, name: 'Vencidos'},
    {id: 2, name: 'Previsão'},
    {id: 3, name: 'Abertos'},
    {id: 4, name: 'A Receber'},
    {id: 5, name: 'Pagos'},
  ];

  const handleChange = (event, newValue) => {
    if (newValue) {
      setFilter({...filter, page: 1, selectedIndex: newValue});
    } else {
      setFilter({...filter, page: 1, selectedIndex: 0});
      refreshHandler();
    }
  };

  const getType = () => {
    return filter.selectedIndex === 0
        ? 'prevision_dued'
        : filter.selectedIndex === 1
        ? 'prevision'
        : filter.selectedIndex === 2
        ? 'opened'
        : filter.selectedIndex === 3
        ? 'to_receive'
        : 'payed';
  };

  const columns = [
    {
      name: 'Pedido',
      selector: row => `${row?.filial || ''}-${row?.numeroPedido}`,
      sortable: false
    },
    {
      name: 'Emissão',
      selector: row => formatDate(row.dataEmissao),
      sortable: false
    },
    {
      name: 'Pagamento',
      selector: row => formatDate(row.dataPagto),
      sortable: false
    },
    {
      name: 'Vencimento',
      selector: row => formatDate(row.dataVencimento),
      sortable: false
    },
    {
      name: 'Valor base',
      selector: row => formatCurrency(row.valorBase),
      sortable: false
    },    
    {
      name: 'Valor comissão',
      selector: row => formatCurrency(row.valorComissao),
      sortable: false
    },
    {
      name: 'Observação',
      selector: 'observacao',
      sortable: false,  
    },
    {
      style: {justifyContent: "flex-end"},
      cell: row => 
      <>
        <div>
          <IconContainer>
            <TableAction 
              title={"Visualizar"} 
              to={`/commission/view/${row.filial}/${row.numero}/${row.prefixo}/${row.parcela}`}              
            >
              <VisibilityIcon/>
            </TableAction>
          </IconContainer>
        </div>
      </>,
    }
  ];

  const columnsPayed = [
    {
      name: 'Mês',
      selector: row => row.line.description,
      sortable: false
    },
    {
      style: {justifyContent: "flex-end"},
      cell: row => 
      <>
        <div>
          <IconContainer>
            <TableAction title={`Movimentação de ${row.line.description}`} disableRipple isLink={false}>
              <InfoIcon />
            </TableAction>
          </IconContainer>
        </div>
      </>,
    }
  ];

  useEffect(() => {
    dispatch(startRequest());
    getAllows().then(data => {
      setAllows(data.data)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
    dispatch(startRequest());
    getYears().then(data => {      
      setYearList(data.data);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
    dispatch(startRequest());
    findDocumentTypes().then(data => {
      setTypes(data.data)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);
    if (salesman && filter.selectedIndex !== 4) {      
      const type = getType();
      getSummary(salesman, filter?.month?.value, filter.year, type).then(data => {        
        setSummary(data.data);
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {        
        setLoading(false);
      });
    } else {
      setSummary([]);
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesman, filter.month, filter.year, filter.selectedIndex, reload, dispatch]);

  useEffect(() => {
    setLoading(true);
    if (salesman) {      
      const type = getType();
      if (filter.selectedIndex === 4) {
        getCommissionsPayed(salesman, filter?.month?.value, filter.year, perPage, filter.page, type).then(data => {
          const formatedList = formatPayed(data.data)          
          setTotalRows(formatedList.length);
          setPayed(formatedList);
        }).catch(err => {
          dispatch(setMessage(sanitizeErrorUI(err)));
        }).finally(() => {
          setLoading(false);
        });
      } else {
        getCommissions(salesman, filter?.month?.value, filter.year, perPage, filter.page, type).then(data => {
          setTotalRows(parseInt(data.headers['recordcount']));
          setCommissions(data.data);          
        }).catch(err => {
          dispatch(setMessage(sanitizeErrorUI(err)));
        }).finally(() => {
          setLoading(false);
        });
      }
    } else {
      setCommissions([]);
      setPayed([]);
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesman, filter.year, filter.month, perPage, filter.page, filter.selectedIndex, reload, dispatch]);

  const handlePageChange = (page) => {
    setFilter({...filter, page});
  };

  const handlePerRowsChange = (perPage) => {
    setPerPage(perPage);
  };

  function handleSelectedMonth(month) {
    if (month) {
      setFilter({...filter, page: 1, month: month});
    }else {
      setFilter({...filter, page: 1, month: new Date().getMonth() + 1});
      refreshHandler();
    }
  }

  function handleSelectedYear(year) {
    if (year) {
      setFilter({...filter, page: 1, year: year});
    }else {
      setFilter({...filter, page: 1, year: new Date().getFullYear()});
      refreshHandler();
    }
  }

  const formatPayed = (payed) => {
    let arrResult = [];

    if (Array.isArray(payed)) {
      const groupPayed = groupBy(payed, (item) => {
        return [item.month];
      });

      groupPayed.forEach((items, index) => {
        const data = items[0].month.split('/');
        let obj = {
          id: index + 1,
          line: {description: `${getMonthName(data[0])}/${data[1]}`},
          items: items,
        };
        arrResult.push(obj);
      });
    }

    return arrResult;
  };

  const ExpandedComponent = ({data}) => {
    return (
      <Grid container spacing={0}>
        {data?.items?.map((item, index) => (
          <Grid item xs={12} key={index}>
            <Grid container alignContent="center" alignItems="center">
              <Grid item xs={2} />
              <Grid item xs={2}>
                <TableAction title={item.payed === 0 ? 'Comissão' : 'Pagamento'} disableRipple isLink={false}>
                  {item.payed === 0 ? <AttachMoneyIcon /> : <PaymentIcon />}
                </TableAction>
              </Grid>
              <Grid item xs={3}>
                <Typography color="textPrimary" variant="subtitle2">
                  {item.payed === 0 ? 'Comissão' : 'Pagamento'}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography color="textPrimary" variant="subtitle2">
                  <CustomValue item={item} />
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <IconContainer>
                  <TableAction 
                    title={"Visualizar"} 
                    to={{
                      pathname: `/commission/operation/${item.idInvoice || item.idOrder}/${item.month}`, // item.month === '03/2022'
                      state: {...item},
                    }}
                  >
                    <VisibilityIcon/>
                  </TableAction>
                </IconContainer>
              </Grid>
            </Grid>
            {index + 1 < data.items.length && <Divider light fullWidth />}
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <ContentPanel message={message} title="Comissão" actions={actions}>
      <Form>
          <GridContainer>
            <SelectAutocomplete
              xs={12}
              sm={6}
              value={filter.month}
              label="Mês"
              optionSelected={(option, value) => option.label === value.label}
              optionLabel={(option) => option.label}
              onChangeSelected={(event, value) => handleSelectedMonth(value)}              
              options={monthList}
              name="month"              
              hasWarning={false}
            />
            <InputSelect
              xs={12}
              sm={6}              
              name="year"
              label="Ano"
              value={filter.year}
              onChange={(e) => handleSelectedYear(e.target.value)}
              options={yearList}
              hasWarning={false}
            />
          </GridContainer>
        </Form>        
      <br />
      <GridContainer>      
        <Grid item xs={12}>                  
          <Typography color="textPrimary" variant="subtitle1" align="center">
            {filter.selectedIndex === 0 
              ? 'Boletos vencidos - todos'
              : filter.selectedIndex === 1
              ? 'Boletos em aberto - os valores podem sofrer alterações'
              : filter.selectedIndex === 2
              ? 'Boletos pagos - os valores podem sofrer alterações'
              : filter.selectedIndex === 3
              ? 'Pagamento programado'
              : filter.selectedIndex === 4
              ? 'Pagamento realizado'
              : ''
            }
          </Typography>
        </Grid>

        <TabComponent 
          tabs={tabsMenu()} 
          onChangeTabs={handleChange} 
          value={filter.selectedIndex}
          variant="fullWidth"
        > 
          <>
            <TabPanel value={filter.selectedIndex} index={0}>              
              <DataTableReact 
                fixedHeader    
                title={`Total de comissão: ${formatCurrency(summary[0]?.valorComissao || 0)}`}                           
                progressPending={loading}
                progressComponent={<DataTableLoading />}                  
                columns={columns}
                data={commissions}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}                
              />
            </TabPanel>

            <TabPanel value={filter.selectedIndex} index={1}>
              <DataTableReact                
                fixedHeader
                title={`Total de comissão: ${formatCurrency(summary[0]?.valorComissao || 0)}`}               
                progressPending={loading}
                progressComponent={<DataTableLoading />}                  
                columns={columns}
                data={commissions}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}                
              />
            </TabPanel>

            <TabPanel value={filter.selectedIndex} index={2}>
              <DataTableReact
                fixedHeader
                title={`Total de comissão: ${formatCurrency(summary[0]?.valorComissao || 0)}`}               
                progressPending={loading}
                progressComponent={<DataTableLoading />}                  
                columns={columns}
                data={commissions}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}                
              />
            </TabPanel>

            <TabPanel value={filter.selectedIndex} index={3}>
              <DataTableReact
                fixedHeader
                title={`Total de comissão: ${formatCurrency(summary[0]?.valorComissao || 0)}`}               
                progressPending={loading}
                progressComponent={<DataTableLoading />}                  
                columns={columns}
                data={commissions}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}                
              />
            </TabPanel>

            <TabPanel value={filter.selectedIndex} index={4}>
              <DataTableReact              
                fixedHeader
                title={`Saldo atual: ${formatCurrency(payed[0]?.items[0]?.balance || 0)}`}
                progressPending={loading}
                progressComponent={<DataTableLoading />}                 
                columns={columnsPayed}
                data={payed}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                expandableRows
                expandableRowsComponent={<ExpandedComponent />}
                expandOnRowClicked
              />
            </TabPanel> 
          </>         
        </TabComponent>
      </GridContainer>
    </ContentPanel>
  )
}