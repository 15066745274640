//external
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//internal
import history from 'services/history';
import ContentPanel from 'components/ContentPanel';
import GridContainer from 'components/v2/grid';
import InputText from 'components/v2/inputText';
import { getVersion, putVersion, postVersion } from 'services/terms/service';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import { setMessage } from 'store/modules/message/action';
import { validateForm } from 'components/Form/validate';
import { termsSchema } from '../schemas';
import { sanitizeErrorUI } from 'utils/errorUtil';
import { Warning } from 'routes/Route';
//styled
import { Button, ButtonContainer } from 'styles/components'

export default function Terms({match}) {
  const message = useSelector(state => state.message.data);
  const termsId = match.params.id;
  const [dataTerms, setDataTerms] = useState({
    url: "",
    notes: "",
  })
  const [errors, setErrors] = useState([]);

  const { setNotWarning } = useContext(Warning);

  const dispatch = useDispatch();

  useEffect(() => {
    if (termsId) {
      dispatch(startRequest());
      getVersion(termsId).then(data => {
        setDataTerms({
          url: data.data.url,
          notes: data.data.notes
        })
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, termsId])

  async function handleSubmit () {
    setNotWarning();
    const errorsList = await validateForm(dataTerms, termsSchema);
    if (errorsList.length > 0) {
      setErrors(errorsList);
      return;
    } 

    const payload = {
      url: dataTerms.url,
      notes: dataTerms.notes,
    }
    if (termsId) {
      dispatch(startRequest());
      putVersion(termsId, payload).then(data => {
        history.push('/terms')
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    } else {
      dispatch(startRequest());
      postVersion(payload).then(data => {
        history.push('/terms')
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }
  
  return (
    <>
      <ContentPanel title='Termo' message={message}>
        <div>
          <GridContainer>
            <InputText
              xs={12}
              required
              name="url"
              label="URL"
              type="text"
              value={dataTerms.url}
              onChange={(e) => setDataTerms({...dataTerms, url: e.target.value})}
              errors={errors}
              disabled={true}
            />

            <InputText
              xs={12}
              name="notes"
              label="Notas"
              type="text"
              placeholder="Notas"
              value={dataTerms.notes}
              onChange={(e) => setDataTerms({...dataTerms, notes: e.target.value})}
            />
          </GridContainer>

          <ButtonContainer>
            <Button type="submit" onClick={handleSubmit}>Salvar</Button>
          </ButtonContainer>
        </div>
      </ContentPanel>
    </>
  )
}