import api from '../../api';

export async function getAllows() {
  return api.get('log_error/allows');
}
  
export async function getLogErrors(user, startDate, endDate, type, numOfRegisters = 10, page = 1, order) {
  const params = {
    order: order ? order : 'user',
  };

  if (user) {
    params.user = user; 
  }

  if (startDate) {
    params.startDate = startDate;
  }

  if (endDate) {
    params.endDate = endDate;
  }

  if (type) {
    params.type = type;
  }
  
  return api.get('log_error/error', {
    params,
    headers: {
      registerperpage: numOfRegisters,
      page
    }
  });
}

export async function getError(id) {
  return api.get(`log_error/error/${id}`);
}

export async function getKeyPass() {
  return api.get('log_error/keypass');
}

export async function getLogErrorsUsers(username) {
  const params = {};

  if (username) {
    params.query = username; 
  }

  return api.get('log_error/users', {
    params,
    headers: {
      registerperpage: 6,
      page: 1
    }
  });
}

export async function getTypes() {
  return api.get(`log_error/types`);
}

export async function getOrigins() {
  return api.get(`log_error/origins`);
}
