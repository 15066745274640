//external
import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button as MuiButton, Typography, Grid, makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DescriptionIcon from '@material-ui/icons/Description';
//internal
import { postFiles, getFileId } from 'services/files/service';
import { getPriceTable, postPriceTable, putPriceTable } from 'services/priceTable/service';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import { Warning } from 'routes/Route';
import history from 'services/history';
import ContentPanel from 'components/ContentPanel'
import { setMessage } from 'store/modules/message/action'
import { sanitizeErrorUI } from 'utils/errorUtil';
import InputText from 'components/v2/inputText';
import Toggle from 'components/v2/toggle';
import { validateForm } from 'components/Form/validate';
import { priceTableSchema } from '../schemas';
import GridContainer from 'components/v2/grid';
import Message from 'components/Message';
//style
import { Button, ButtonContainer } from 'styles/components';

const useStyles = makeStyles((theme) => ({
  MuiSvgIcon: {
    height: '95px',
    cursor: 'pointer'
  }
}));

export default function PriceTableEdit({match}) {
  const classes = useStyles();
  const message = useSelector(state => state.message.data);
  const priceTableId = match.params.id;
  const [data, setData] = useState({
    description: '',
    active: true,
    fileId: '',
    fileUuid: '',
    File: null,
  });
  const [url, setUrl] = useState(null);
  const [messageError, setMessageError] = useState({
    error: false,
    text: '',
  });
  const [errors, setErrors] = useState([]);
  //document
  const [selectedFile, setSelectedFile] = useState(null);
  //dialog
  const [open, setOpen] = useState(false);

  const { setNotWarning } = useContext(Warning);

  const dispatch = useDispatch();

  useEffect(() => {
    if (data?.File) {
      const {id, fileUuid} = data?.File;
      if (id && fileUuid) {
        dispatch(startRequest());
        getFileId(id, fileUuid).then(async (response) => {           
          const blob = new Blob([response.data], {type: 'application/pdf'});        
          const url = URL.createObjectURL(blob);
          setUrl(url);        
        }).catch(err => {
          dispatch(setMessage(sanitizeErrorUI(err)));
        }).finally(() => {
          dispatch(finishRequest());
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, data?.File]);

  useEffect(() => {
    if (priceTableId) {
      dispatch(startRequest());
      getPriceTable(priceTableId).then(data => {
        const formData = data.data
        setData({          
          description: formData?.description || '',
          active: formData?.active || false,
          File: formData?.File || null, 
        },
      );
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, priceTableId])

  async function handleSubmit () {
    setNotWarning();

    const formData = {
      description: data?.description || '',
      active: data?.active,
      fileId: data?.File?.id,
      fileUuid: data?.File?.fileUuid,
    }
    
    const errorsList = await validateForm(formData, priceTableSchema);

    if (errorsList.length > 0) {
      setErrors(errorsList);
      return;
    }

    if (priceTableId) {
      dispatch(startRequest());
      putPriceTable(priceTableId, formData).then(data => {
        history.push('/premixapp/product_price_table')
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    } else {
      dispatch(startRequest());
      postPriceTable(formData).then(data => {
        history.push('/premixapp/product_price_table')
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setSelectedFile(null);
    setOpen(false);
  }

  const changeHandler = async (event) => {
    setSelectedFile(event.target.files[0]);
    setMessageError({error: false, text: ''});
	};

  async function postFile() {
    if (!selectedFile) {        
      setMessageError({error: true, text: 'Arquivo é obrigatório.'});        
      return;
    }

    return new Promise((resolve, reject) => {
      dispatch(startRequest());      
      postFiles(selectedFile).then(result => {
        setData({...data, File: result.data });
        resolve(result.data);
        handleClose();
      }).catch(err => {
        dispatch(setMessageError(sanitizeErrorUI(err)));
        reject(err);
      }).finally(() => {
        dispatch(finishRequest());
      });
    });
  }

  async function handleBack () {
    history.goBack();
  }
  
  return (
    <ContentPanel title='Versão' message={message}>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>          
          <DialogTitle style={{padding: '16px 0px'}} id="alert-dialog-title">
            Selecionar arquivo
          </DialogTitle>          
          <DialogContentText id="alert-dialog-description">
            Selecione um arquivo para enviar.
            Ao enviar o documento selecionado, o mesmo será analisado pelo setor responsável.
          </DialogContentText>           
          <GridContainer>            
            <Grid item xs={12}>
              <Typography color="textPrimary" variant="subtitle1" align="center">
                <div style={{marginLeft: '40%'}} className="img-upload">
                  <label>          
                    <DescriptionIcon className={classes.MuiSvgIcon} fontSize="large" color="action" />          
                    <input 
                      name="file"                     
                      type="file"
                      accept="application/pdf" 
                      onChange={changeHandler}
                    />
                  </label>
                </div>
              </Typography>              
            </Grid>
            <Grid item xs={12}>
              <Typography color="textPrimary" variant="subtitle1" align="center">
                {selectedFile ? `Nome do arquivo: ${selectedFile.name}` : "Nenhum arquivo selecionado"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" align="center">
                <Message message={messageError} />
              </Typography>
            </Grid>
          </GridContainer>
        </DialogContent>        
        <DialogActions>
          <MuiButton onClick={handleClose} color="secondary">
            Cancelar
          </MuiButton>
          <MuiButton onClick={() => postFile()} color="primary" autoFocus>
            Enviar
          </MuiButton>
        </DialogActions>        
      </Dialog>

      <GridContainer>
        <InputText
          xs={12}
          sm={12}
          required
          name="description"
          label="Descrição"
          type="text"
          value={data.description}
          onChange={(e) => setData({...data, description: e.target.value})}
          errors={errors}
        />
        <Toggle
          xs={12}
          sm={6}
          name="Ativo"
          checked={data.active}
          onChange={(e) => setData({...data, active: e.target.checked})}
          label="Ativo"
        />        
      </GridContainer>
      <br />
      {url && (
        <iframe title="Documento" src={url} width="100%" height="600" />          
      )}
      <ButtonContainer>
        <br />
        <Button left={true} cancel={true} onClick={handleBack}>
          Voltar
        </Button>
        <Button onClick={handleSubmit}>
          Salvar
        </Button>
        <Button onClick={handleOpen} style={{marginRight: '10px'}}>
          Selecionar Arquivo
        </Button>
      </ButtonContainer>
    </ContentPanel>
  )
}
