//external
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
//internal
import { findComponent } from 'services/component/service';
import { findNutrient } from 'services/nutrient/service';
import { getLine, putLine, getCatalogImage } from 'services/line/service'
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import history from 'services/history';
import ContentPanel from 'components/ContentPanel'
import { validateForm } from 'components/Form/validate'
import { setMessage } from 'store/modules/message/action'
import { lineSchema } from '../schemas'
import InputText from 'components/v2/inputText';
import GridContainer from 'components/v2/grid';
import TableAction from 'components/v2/tableAction';
import { sanitizeErrorUI } from 'utils/errorUtil';
import SelectAutocomplete from 'components/v2/autocomplete';
import { useDebounce } from 'utils/uiUtils';
import { IconContainer } from '../list/styled';
import DataTableReact from 'components/v2/dataTableReact';
import { Warning } from 'routes/Route';
//style
import { Button as ButtonRef, ButtonContainer } from 'styles/components'
import { MenuItem } from '@material-ui/core';
import InputSelect from 'components/v2/inputSelect';

export default function Line({match}) {
  const message = useSelector(state => state.message.data);
  const lineId = match.params.id;
  const [dataLines, setDataLines] = useState({
    protheusCode: "",
    description: "",
    descriptionAlias: "",
    expirationDate: "",
    components: ""
  })
  const [errors, setErrors] = useState([]);

  const { setNotWarning } = useContext(Warning);

  // Components
  const [selectedComponents, setSelectedComponents] = useState([]);
  const [optionsComponent, setOptionsComponent] = useState([]);
  const [searchComponent, setSearchComponent] = useState("");
  const [itemSelectedComponent, setItemSelectedComponent] = useState(null);
  const [isLoadingComponent, setIsLoadingComponent] = useState(false);
  const [catalogImage, setCatalogImage] = useState(null);

  // Nutrients
  const [selectedNutrients, setSelectedNutrients] = useState([]);
  const [optionsNutrient, setOptionsNutrient] = useState([]);
  const [searchNutrient, setSearchNutrient] = useState("");
  const [itemSelectedNutrient, setItemSelectedNutrient] = useState(null);
  const [isLoadingNutrient, setIsLoadingNutrient] = useState(false);

  // Edit Nutrients
  const [openEdit, setOpenEdit] = useState(false);
  const [min, setMin] = useState(null);
  const [max, setMax] = useState(null);
  // Menu do item
  const [menuItem, setMenuItem] = useState(null);
  const [indexItem, setIndexItem] = useState(null);

  const [productImageList, setProductImageList] = useState([]);

  const debouncedSearchComponent = useDebounce(searchComponent);
  const debouncedSearchNutrient = useDebounce(searchNutrient);
  
  const handleCloseEdit = () => {
    setMin(null);
    setMax(null);
    setOpenEdit(false);
  };

  const handleSaveEdit = () => {
    menuItem.min = !min ? null : min;    
    menuItem.max = !max ? null : max;
    selectedNutrients[indexItem] = menuItem;
    setSelectedNutrients([...selectedNutrients]);
    handleCloseEdit();
  }
  
  const dispatch = useDispatch();

  const loadNutrient = useCallback((searchValue) => {
    setIsLoadingNutrient(true)
    findNutrient(searchValue).then(result => {
      const nutrients = result.data.map(element => {
        return { value: element, label: `${element.optimixCode} - ${element.description}` }
      });
      setOptionsNutrient(nutrients);
      setIsLoadingNutrient(false)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      setIsLoadingNutrient(false);
    });
  }, [dispatch])

  const loadComponent = useCallback((searchValue) => {
    setIsLoadingComponent(true)
    findComponent(searchValue).then(result => {
      const components = result.data.map(element => {
        return { value: element, label: `${element.protheusCode} - ${element.description}` }
      });
      setOptionsComponent(components);
      setIsLoadingComponent(false)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      setIsLoadingComponent(false);
    });
  }, [dispatch])

  useEffect(() => {
    if(loadComponent) loadComponent(debouncedSearchComponent)
  },[debouncedSearchComponent, loadComponent]);

  useEffect(() => {
    if(loadNutrient) loadNutrient(debouncedSearchNutrient)
  },[debouncedSearchNutrient, loadNutrient]);

  useEffect(() => {
    getCatalogImage().then(data => {
      setProductImageList(data.data);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });

    if (lineId) {
      dispatch(startRequest());
      getLine(lineId).then(data => {
        const formData = data.data;
        setDataLines({
          protheusCode: formData.protheusCode,
          description: formData.description,
          descriptionAlias: formData.descriptionAlias,
          expirationDate: formData.expirationDate,
          components: formData.components
        })
        setCatalogImage((formData.catalogImage || '').toLowerCase());        
        
        if (formData.components) {
          setSelectedComponents(formData.components);
        }
        if(formData.nutrients) {
          setSelectedNutrients(formData.nutrients);
        }
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, lineId])

  async function handleSubmit () {
    setNotWarning();
    const errorsList = await validateForm(dataLines, lineSchema);
    if (errorsList.length > 0) {
      setErrors(errorsList);
      return;
    } 

    if (selectedComponents.length > 0) {
      dataLines.components = selectedComponents;
    }

    if (selectedNutrients.length > 0) {
      dataLines.nutrients = selectedNutrients;
    }
    dataLines.catalogImage = catalogImage || null;

    dispatch(startRequest());
    putLine(lineId, dataLines).then(data => {
      history.push('/premixlabel/lines')
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });    
  }

  function handleComponentInputChange(data, event) {
    if (data) {
      const componentIndex = selectedComponents.findIndex(item => item.protheusCode === data?.value?.protheusCode);
      setItemSelectedComponent(data);
      if (componentIndex >= 0)  return;
      
      setSelectedComponents([...selectedComponents, data.value]); 
    } else {
      setItemSelectedComponent(null);
      loadComponent("");
    }
  }

  function handleRemoveComponent(component) {    
    var componentIndex = selectedComponents.indexOf(component);
    selectedComponents.splice(componentIndex, 1);
    setSelectedComponents([...selectedComponents]);    
  }
  
  const columnsComponents = [
    {
      maxWidth: "25%",
      name: 'Código',
      selector: 'protheusCode',
      sortable: true,
    },
    {
      maxWidth: "25%",
      name: 'Descrição',
      selector: 'description',
      sortable: true,
    },
    {
      maxWidth: "25%",
      name: 'Rótulo',
      selector: 'descriptionAlias',
      sortable: false,
    },
    {
      maxWidth: "25%",
      style: {justifyContent: "flex-end"},
      cell: row =>       
        <IconContainer>
          <TableAction 
            title={"Excluir"} 
            onClick={() => handleRemoveComponent(row)}
          >
            <DeleteIcon />
          </TableAction>
        </IconContainer>      
    }
  ];

  function handleNutrientInputChange(data, event) {
    if (data) {
      const nutrientIndex = selectedNutrients.findIndex(item => item.optimixCode === data?.value?.optimixCode);
      if (nutrientIndex >= 0) {
        setItemSelectedNutrient(data);
        return;
      }

      setItemSelectedNutrient(data);
      setSelectedNutrients([...selectedNutrients, data.value]); 
    } else {
      setItemSelectedNutrient(null);
      loadNutrient("");
    }
  }

  function contentElement(index, option) {
    return (
      <MenuItem key={index} value={option.key}><img style={{width: 40, marginRight: 10}} src={option.path} alt="option"/>{option.name}</MenuItem>
    )
  }

  const renderEditForm = () => {
    return (
      <Dialog open={openEdit} onClose={handleCloseEdit} aria-labelledby="form-dialog-title" scroll="body">
        <DialogTitle id="form-dialog-title">Editar</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            Alteração de descrição e quantidade
          </DialogContentText> */}
          {menuItem && (
            <GridContainer>
              <InputText
                xs={12}
                name="min"
                label="Mínimo"
                type="number"
                value={min}
                onChange={(e) => setMin(e.target.value)}
                autoFocus
              />              
              <InputText
                xs={12}
                name="max"
                label="Máximo"
                type="number"
                value={max}
                onChange={(e) => setMax(e.target.value)}
              />              
            </GridContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEdit} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleSaveEdit} color="primary">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  function handleRemoveNutrient(nutrient) {    
    var nutrientIndex = selectedNutrients.indexOf(nutrient);
    selectedNutrients.splice(nutrientIndex, 1);
    setSelectedNutrients([...selectedNutrients]);    
  }

  function handleEditNutrient(nutrient) {    
    setMenuItem(nutrient);
    
    var nutrientIndex = selectedNutrients.indexOf(nutrient);
    setIndexItem(nutrientIndex);

    nutrient.min ? setMin(nutrient.min.toString()) : setMin(null);
    nutrient.max ? setMax(nutrient.max.toString()) : setMax(null);
    
    setOpenEdit(true); 
  }
  
  const columnsNutrients = [
    {
      maxWidth: "15%",
      name: 'Código',
      selector: 'optimixCode',
      sortable: true,
    },
    {
      maxWidth: "25%",
      name: 'Descrição',
      selector: 'description',
      sortable: true,
    },
    {
      maxWidth: "25%",
      name: 'Rótulo',
      selector: 'descriptionAlias',
      sortable: false,
    },
    {
      maxWidth: "10%",
      name: 'Mínimo',
      selector: 'min',
      sortable: false,
    },
    {
      maxWidth: "10%",
      name: 'Máximo',
      selector: 'max',
      sortable: false,
    },
    {
      maxWidth: "15%",
      style: {justifyContent: "flex-end"},
      cell: row =>       
        <IconContainer>
          <TableAction 
            title={"Editar"} 
            onClick={() => handleEditNutrient(row)}
          >
            <EditIcon />
          </TableAction>
          <TableAction 
            title={"Excluir"} 
            onClick={() => handleRemoveNutrient(row)}
          >
            <DeleteIcon/>
          </TableAction>
        </IconContainer>        
    }
  ];

  return (
    <>
      {renderEditForm()}
      <ContentPanel title='Linha' message={message}>
        <GridContainer>
          <InputText
            xs={12}
            sm={6}
            required
            name="protheusCode"
            label="Código"
            type="number"
            value={dataLines.protheusCode}
            onChange={(e) => setDataLines({...dataLines, protheusCode: e.target.value})}
            errors={errors} 
            disabled={true}
          />

          <InputText
            xs={12}
            sm={6}
            required
            name="description"
            label="Descrição"
            type="text"
            value={dataLines.description}
            onChange={(e) => setDataLines({...dataLines, description: e.target.value})}
            errors={errors} 
            disabled={true}
          /> 
        
          <InputText
            xs={12}
            sm={6}
            name="descriptionAlias"
            label="Rótulo"
            type="text"
            placeholder="Rótulo"
            value={dataLines.descriptionAlias}
            onChange={(e) => setDataLines({...dataLines, descriptionAlias: e.target.value})}
            errors={errors} 
          /> 

          <InputText
            xs={12}
            sm={6}
            name="expirationDate"
            label="Validade em Meses"
            type="number"
            placeholder="Validade em Meses"
            value={dataLines?.expirationDate?.toString() || ''}
            onChange={(e) => setDataLines({...dataLines, expirationDate: e.target.value})}
            errors={errors} 
          /> 

          <InputSelect
            xs={12}
            sm={12}
            name="catalogImage"
            label="Imagem"
            value={catalogImage}
            onChange={(e) => setCatalogImage(e.target.value)}
            defaultValue={null}
            options={productImageList}
            errors={errors}
            contentElement={contentElement}
          />
        </GridContainer>

        <ContentPanel message={message} title="Matéria Prima">      
          <SelectAutocomplete
            xs={12}
            sm={12}
            value={itemSelectedComponent}
            label="Buscar Matéria Prima"
            optionSelected={(option, value) => option.label === value.label}
            optionLabel={(option) => option.label}
            onChangeSelected={(event, value) => handleComponentInputChange(value)}
            onChange={(event) => setSearchComponent(event.target.value)}
            options={optionsComponent}
            name="components"
            isLoading={isLoadingComponent}
          />
          
          <DataTableReact
            noHeader
            columns={columnsComponents}
            data={selectedComponents}
          />
        </ContentPanel>

        <ContentPanel message={message} title="Nutrientes">  
          <SelectAutocomplete
            xs={12}
            sm={12}
            value={itemSelectedNutrient}
            label="Buscar Nutrientes"
            optionSelected={(option, value) => option.label === value.label}
            optionLabel={(option) => option.label}
            onChangeSelected={(event, value) => handleNutrientInputChange(value)}
            onChange={(event) => setSearchNutrient(event.target.value)}
            options={optionsNutrient}
            name="nutrients"
            isLoading={isLoadingNutrient}
          />
      
          <DataTableReact
            noHeader
            columns={columnsNutrients}
            data={selectedNutrients}
          />
        </ContentPanel>

        <ButtonContainer>
          <ButtonRef type="submit" onClick={handleSubmit}>Salvar</ButtonRef>
        </ButtonContainer>
      </ContentPanel>
    </>
  )
}