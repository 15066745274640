import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';

import Drawer from '../Drawer';
import Content from '../Content';
import AppBar from '../AppBar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
}));

export default function Layout() {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [size, setSize] = useState('large');

  const handleDrawerOpen = () => {
    setOpen(true);
    setSize('large');
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const verifySize =  () => {
    const mediaQuery = window.matchMedia('(max-width: 800px)');

    if (mediaQuery.matches) {
      setSize('small');
    } else {
      setSize('large');
    }
    
    mediaQuery.addEventListener("change", (mq) => {
      if (mq.matches) {
        /* the viewport is 800 pixels wide or less */
        setSize('small');
      } else {
        /* the viewport is more than than 800 pixels wide */
        setSize('large');
      }
    });
  };

  useEffect(() => { 
    verifySize();
  }, []);

  const verifyOpen = () => {
    if (size === 'small') {
      return false;
    
    } else {
      return open;
    }
  };

  return (
    <div className={classes.root}>
      <AppBar open={verifyOpen()} onClick={handleDrawerOpen} />
      <Drawer open={verifyOpen()} onClick={handleDrawerClose} />

      <Content />
    </div>
  );
}