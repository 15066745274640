import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  height: ${props => props.type === 'hidden' ? '0px' : props => props.type === 'textarea' ? '155px' : '55px'};
`

export const StyledInput = styled.input`
  background: #fff;
  border: 0px;
  border-radius: 22px;
  height: 30px;
  padding: 0 15px;
  font-size: 16px;
  color: #666;
  flex: 1;
  border: 1px solid ${props => (props.error ? '#F77' : '#888')};
  box-shadow: 0px 0px 2px ${props => (props.error ? '#F77' : '#888')};

  &:focus {
    border-color: ${props => (props.error ? '#eb4034' : '#87cbff')};
    box-shadow: 0px 0px 2px ${props => (props.error ? '#eb4034' : '#87cbff')};
  }

  transition: border-color 0.1s linear;
`
export const StyledTextArea = styled.textarea`
  background: #fff;
  border: 0px;
  border-radius: 11px;
  height: 90px;
  padding: 0 15px;
  font-size: 16px;
  color: #666;
  flex: 1;
  border: 1px solid ${props => (props.error ? '#F77' : '#888')};
  box-shadow: 0px 0px 2px ${props => (props.error ? '#F77' : '#888')};

  &:focus {
    border-color: ${props => (props.error ? '#eb4034' : '#87cbff')};
    box-shadow: 0px 0px 2px ${props => (props.error ? '#eb4034' : '#87cbff')};
  }

  transition: border-color 0.1s linear;
`
export const ErrorMessage = styled.span`
  color: #eb4034;
  padding-left: 20px;
  font-size: 10px;
`