import styled from 'styled-components'
import AvatarComp from 'react-avatar-edit'

export const AvatarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const Avatar = styled(AvatarComp)`
  margin: 10px;
`

export const AvatarPreview = styled.img`
  width: 120px;
  height: 120px;
  margin: 10px;
`