import * as Yup from 'yup';

export const appVersionSchema = Yup.object().shape({
  appname: Yup.string().required('Aplicativo é obrigatório.'),
  version: Yup.string().required('Versão é obrigatória'),
  webLimitDate: Yup.date().transform(parseDateString).required('Data Limite Web é obrigatória').typeError('O campo não é uma data válida.'),
  androidLimitDate: Yup.date().transform(parseDateString).required('Data Limite Android é obrigatória').typeError('O campo não é uma data válida.'),
  iosLimitDate: Yup.date().transform(parseDateString).required('Data Limite IOS é obrigatória').typeError('O campo não é uma data válida.'),
  notes: Yup.string()
});

function parseDateString(value, originalValue) {
  const dateString = originalValue.split("/");
  const date = new Date(dateString[2], dateString[1]-1, dateString[0]);

  if (isNaN(date.getTime())) {
    return '';
  } else {
    return date;
  }
}