// external 
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RefreshIcon from '@material-ui/icons/Refresh';
//internal
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import ContentPanel from 'components/ContentPanel';
import DataTableLoading from 'components/DataTableLoading';
import { setMessage } from 'store/modules/message/action';
import { useDebounce } from 'utils/uiUtils';
import { getUserHistory } from 'services/userHistory/service';
import { formatDateTime } from 'utils/formatUtil';
import GridContainer from 'components/v2/grid';
import InputText from 'components/v2/inputText';
import { sanitizeErrorUI } from 'utils/errorUtil';
import TableAction from 'components/v2/tableAction';
import DataTableReact from 'components/v2/dataTableReact';
import { FilterUserHistory } from '..';

export default function UserHistoryList({location}) {
  const message = useSelector(state => state.message.data);
  let { username } = location.state ? location.state : '';  
  
  const [userHistory, setUserHistory] = useState([]);
  const [searchTerm, setSearchTerm] = useState(username);
  //Pagination
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sort, setSort] = useState();
  const [reload, setReload] = useState(0);

  const { filter, setFilter } = useContext(FilterUserHistory);

  const debouncedSearchTerm = useDebounce(searchTerm);
  
  const dispatch = useDispatch();

  const columns = [
    {
      maxWidth: "30%",
      name: 'Usuário',
      selector: 'userName',
      sortable: true,
    },
    {
      maxWidth: "15%",
      name: 'Tipo',
      selector: 'type',
      sortable: true,
    },
    {
      maxWidth: "10%",
      name: 'Status',
      selector: 'status',
      sortable: true,
    },
    {
      maxWidth: "20%",
      name: 'Mensagem',
      selector: 'message',
      sortable: true,
    },
    {
      maxWidth: "15%",
      name: 'Data',
      selector: 'createdAt',
      sortable: true,
    },
    {
      maxWidth: "10%",
      name: 'Versão',
      selector: 'appVersion',
      sortable: true,
    },
  ];

  const actions = [
    <div key='refresh'>
      <TableAction 
      title={"Atualizar"} 
      onClick={refreshHandler}>
        <RefreshIcon/>
      </TableAction>
    </div>
  ];

  function refreshHandler() {
    setReload(reload + 1);
  }

  useEffect(() => {
    handleSearchChange(filter);
  }, [filter]);

  useEffect(() => {
    setLoading(true);
    dispatch(startRequest());    
    getUserHistory(debouncedSearchTerm, perPage, filter.page, sort).then(data => {
      setTotalRows(parseInt(data.headers['recordcount']));
      setUserHistory(data.data.map(value => {
        value.createdAt = formatDateTime(new Date(value.createdAt))

        return value;
      }));
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
      setLoading(false);
    });
  }, [debouncedSearchTerm, dispatch, filter.page, perPage, sort, reload]);

  const handlePageChange = (page) => {
    setFilter({...filter, page});
  };

  const handlePerRowsChange = (perPage) => {
    setPerPage(perPage);
  };

  const handleSort = (column, sortDirection) => {
    setSort(`${column.selector} ${sortDirection}`);
  };

  const handleSearchChange = (value) => {
    setSearchTerm(value.search);
  }
  
  return (
    <>
      <ContentPanel message={message} title="Histórico do Usuário" actions={actions}>
        <GridContainer>
          <InputText
            xs={12}
            name="search"
            label="Pesquisar"
            type="text"
            placeholder="Pesquisar"
            value={searchTerm}
            onChange={(e) => setFilter({page: 1, search: e.target.value})}
            hasWarning={false}
          />
        </GridContainer>
        <DataTableReact
          noHeader
          fixedHeader
          progressPending={loading}
          progressComponent={<DataTableLoading />}
          title="Histórico do Usuário"
          columns={columns}
          data={userHistory}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          sortServer
          onSort={handleSort}
          paginationDefaultPage={filter.page}
        />
      </ContentPanel>
    </>
  )
}