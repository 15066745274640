//external
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@material-ui/core';
import { getErrorMessage, getIsError } from 'utils/validationUtil';
import { makeStyles } from '@material-ui/core/styles';
import { Warning } from 'routes/Route';

const useStyles = makeStyles((theme) => ({
  label: {
    zIndex: 0,
  },
}));

export default function InputText({
    name, 
    label, 
    type, 
    placeholder, 
    required, 
    ref, 
    value, 
    onChange, 
    xs,
    sm, 
    errors = [],
    disabled,
    readOnly = false,
    maxLength,
    minLength,
    onFocus,
    validationArray,
    validationIndex,
    hasWarning = true,
    InputProps = {},
  }) {

    const { setIsWarning } = useContext(Warning);

    const classes = useStyles();

    const [stateErrors, setStateErrors] = useState([]);

    const onChangeInput = (e) => {
      if (onChange && typeof onChange === 'function') {
        onChange(e);
      }
      setStateErrors(stateErrors.filter(item => item.field !== name));
      if (hasWarning) {
        setIsWarning();
      }
    }

    useEffect(() => {
      if(Array.isArray(errors) && errors.length > 0) {   
        setStateErrors(errors)
      }
    }, [errors]);

  return (
    <Grid item xs={xs} sm={sm}>
      <TextField 
        className={classes.label}
        required={required} 
        label={label} 
        name={name} 
        type={type}
        placeholder={placeholder}
        ref={ref}
        value={value || ''}
        onFocus={onFocus}
        onChange={(e) => onChangeInput(e)}
        error={getIsError(name, stateErrors, validationArray, validationIndex)}
        helperText={getErrorMessage(name, stateErrors, validationArray, validationIndex)}
        InputLabelProps={{shrink: true}}
        InputProps={{
          readOnly: readOnly,
          inputProps : {
            max: maxLength,
            min: minLength,
          },
          autoComplete: 'chrome-off',
          ...InputProps,
        }}
        variant="outlined"
        margin="none"
        disabled={disabled}
        fullWidth
      />
    </Grid> 
    
  )
} 

InputText.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  ref: PropTypes.any,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onChange: PropTypes.func,
  errors: PropTypes.array,
}