//external
import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
//internal
import Route from '../../routes/Route';
import Checklist from './list';
import ChecklistEdit from './edit';
import ChecklistDelete from './delete';

export const FilterChecklist = createContext({});

export default function Routes() {
  const [filter, setFilter] = useState({
    page: 1,
  });

  let location = useLocation();

  useEffect(() => {
    const split = location?.pathname.split('/');
    if (split[2] !== "checklist") {
      setFilter({
        page: 1,
      });
    }
  }, [location, setFilter]);

  return (
    <FilterChecklist.Provider value={{ filter, setFilter }}>
      <Route path="/checklist" exact component={Checklist} isPrivate/>
      <Route path="/checklist/edit/:id" exact component={ChecklistEdit} isPrivate/>
      <Route path="/checklist/add" exact component={ChecklistEdit} isPrivate/>
      <Route path="/checklist/delete/:id" exact component={ChecklistDelete} isPrivate/>
    </FilterChecklist.Provider>
  )
}  