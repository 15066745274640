//external
import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
//internal
import Route from '../../routes/Route';
import Documents from './list';
import DocumentEdit from './edit';

export const FilterDocument = createContext({});

export default function Routes() {
  const [filter, setFilter] = useState({
    search: "",
    status: "",
    type: "",
    page: 1,
    initialDate: null,
    finalDate: null,
  });

  let location = useLocation();

  useEffect(() => {
    const split = location?.pathname.split('/');
    if (split[1] !== "document") {
      setFilter({
        search: "",
        status: "",
        type: "",
        page: 1,
        initialDate: null,
        finalDate: null,
      });
    }
  }, [location, setFilter]);

  return (
    <FilterDocument.Provider value={{ filter, setFilter }}>
      <Route path="/document" exact component={Documents} isPrivate/>      
      <Route path="/document/edit/:id" exact component={DocumentEdit} isPrivate/>
    </FilterDocument.Provider>
  )
}