//external
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Highlight from 'react-highlight';
//internal
import ContentPanel from 'components/ContentPanel';
import { setMessage } from 'store/modules/message/action';
import { sanitizeErrorUI } from 'utils/errorUtil';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import GridContainer from 'components/v2/grid';
import InputText from 'components/v2/inputText';
import { getError } from 'services/logs/logError/service';
import DateAndTimePickers from 'components/v2/dateTimePicker';
import history from 'services/history';
//style
import { Button, ButtonContainer } from 'styles/components';
import { ContainerLogError, SeparatorSync } from './styled';
import { getTypeLabel } from '../list';

export default function LogErrorDetails({match}) {
  const syncId = match.params.id;
  const message = useSelector(state => state.message.data);
  const [dataSync, setDataSync] = useState({
    user: "",
    deviceToken: "",
    date: new Date(),
    url: "",
    appVersion: "",
    type: "",
  });
  const [payload, setPayload] = useState('{}');
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (syncId) {
      dispatch(startRequest());
      getError(syncId).then(data => {
        const formData = data.data;
        setErrorMessage(formatJSON(formData.errorMessage));
        setDataSync({
          user: formData.user,
          deviceToken: formData.deviceToken,
          date: formData.date,
          url: formData.url,
          appVersion: formData.appVersion,
          type: getTypeLabel(formData.type),
        });
        if (formData.payload) {
          setPayload(formatJSON(formData.payload));
        }   
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, syncId]);

  async function handleBack () {
    history.goBack();
  }

  function formatJSON(item) {
    try {
      const jsonErrorMessage = JSON.stringify(JSON.parse(item), null, 2);
      return jsonErrorMessage;
    }catch(e) {
      return item;
    } 
  }

  return (
    <ContentPanel title="Log de Erros" message={message}>
      <GridContainer>
        <InputText
          xs={12}
          sm={5}
          name="user"
          label="Usuário"
          type="text"
          value={dataSync.user}
          hasWarning={false}
        />
        <InputText
          xs={12}
          sm={7}
          name="url"
          label="URL"
          type="text"
          value={dataSync.url}
          hasWarning={false}
        />
        <InputText
          xs={12}
          sm={5}
          name="deviceToken"
          label="Dispositivo"
          type="text"
          value={dataSync.deviceToken}
          hasWarning={false}
        />
        <InputText
          xs={12}
          sm={2}
          name="appVersion"
          label="Versão"
          type="text"
          value={dataSync.appVersion}
          hasWarning={false}
        />
        <InputText
          xs={12}
          sm={2}
          name="type"
          label="Tipo"
          type="text"
          value={dataSync.type}
          hasWarning={false}
        />
        <DateAndTimePickers
          xs={12}
          sm={3}
          name="date"
          label="Data"
          value={dataSync.date}
          cancelLabel="Cancelar"
          okLabel="Salvar"
          disabled={true}
        />

        <SeparatorSync>Mensagem de erro</SeparatorSync>
        <ContainerLogError>
          <Highlight language='string'>
            {errorMessage}
          </Highlight>
        </ContainerLogError>

        <SeparatorSync>Payload</SeparatorSync>
        <ContainerLogError>
          <Highlight language='json'>
            {payload}
          </Highlight>
        </ContainerLogError>  
      </GridContainer>  

      <ButtonContainer>
        <Button type="submit" cancel={true} onClick={handleBack}>Voltar</Button>
      </ButtonContainer>
    </ContentPanel>
  )
}
