//external
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    width: "100%",
  },
  paper: {
    width: 380,
    height: 230,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  paperr: { 
    width: 200,
    height: 230,
    overflow: 'auto',
  } ,
  itemRight: {
    display: 'flex'
  },
  mainOption: {
    width: '40%'
  },
  labelList: {
    fontSize: 14,
  }, 
}));

function not(a, b) {
  return a.filter((value) => b.findIndex(valueb => valueb.id === value.id) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.findIndex(valueb => valueb.id === value.id) !== -1);
}

export default function TransferList({
  leftList,
  rightList,
  getRightList
}) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, normaLizeAuthModules(right));

  useEffect(() => {
    setLeft(leftList)
  }, [leftList]);

  useEffect(() => {
    if (Array.isArray(rightList) && rightList.length) {
      setRight(rightList);
      getRightList(rightList);
    }
  }, [rightList, getRightList]);

  useEffect(() => {
    if (Array.isArray(right) && right.length) {
      getRightList(right);
    }
  }, [right, getRightList]);

  function normaLizeModules(modules) {
    return modules.map((itemModule) => ({
      AuthModule: {
        id: itemModule.id,
        description: itemModule.description
      },
      allow_delete: false,
      allow_post: false,
      allow_put: false
    }))
  }

  function normaLizeAuthModules(authModules) {
    return authModules.map(item => ({
      id: item.AuthModule.id,
      description: item.AuthModule.description
    }))
  }


    const handleToggle = (moduleObj) => () => {
      let currentIndex;
      const newChecked = [...checked];
  
      if(moduleObj.AuthModule) {
        currentIndex = checked.findIndex((item) => item.id === moduleObj.AuthModule.id);
       
        if (currentIndex === -1) {
          newChecked.push(moduleObj.AuthModule);
        } else {
          newChecked.splice(currentIndex, 1);

        }
      } else {
        currentIndex = checked.findIndex((item) => item.id === moduleObj.id);
  
        if (currentIndex === -1) {
          newChecked.push(moduleObj);
        } else {
          newChecked.splice(currentIndex, 1);
        }
      }
  
      setChecked(newChecked);
    };

  const handleAllRight = () => {
    setRight(right.concat(normaLizeModules(left)));
    setLeft([]);
  };

  function handleCheckedRight(){
    const newLeft = left.filter(
      item => {
        return leftChecked.findIndex(sItem => sItem.id === item.id) < 0
      }
    )
    setLeft(newLeft)

    const concat = right.concat(normaLizeModules(leftChecked))
    setRight(concat)
    setChecked(not(checked, left));
  };

  const handleCheckedLeft = () => {
    const newRight = right.filter(
      item => {
        return rightChecked.findIndex(sItem => sItem.id === item.AuthModule.id) < 0
      }
    )
    setRight(newRight)
    const concat = left.concat(rightChecked);
    setLeft(concat);
    setChecked(not(checked, normaLizeAuthModules(right)));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(normaLizeAuthModules(right)));
    setRight([]);
  };

  const onSelectPermission = (option, moduleId) => {
    const newList = [...right];
    const index = right.findIndex(item => item.AuthModule.id === moduleId);
    newList[index][option] = !newList[index][option] 
    setRight(newList);
  }

  const customListLeft = (items) => (
    <Paper className={classes.paper} elevation={3}>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value.id}-label`;

          return (
            <ListItem key={value.id} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={leftChecked.findIndex((module) => module.id === value.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.description} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );


  const customListRight = (items) => (
    <Paper className={classes.paper} elevation={3}>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value.AuthModule.id}-label`;

          return (
            <div key={value.AuthModule.id} className={classes.itemRight}>
              <ListItem className={classes.mainOption}  role="listitem" button onClick={handleToggle(value)}>
                <ListItemIcon>
                  <Checkbox
                    checked={rightChecked.findIndex((module) => module.id === value.AuthModule.id) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value.AuthModule.description} />
              </ListItem>
              <Checkboxes
                onSelect={(option) => onSelectPermission(option, value.AuthModule.id)}
                isChecked={value}
              />
            </div>
            
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
      <Grid item>{customListLeft(left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customListRight(right)}</Grid>
    </Grid>
  );
}

function Checkboxes({ onSelect, isChecked }) {
  const classes = useStyles();
  return (
    <FormControl component="fieldset">
      <FormGroup aria-label="position" row>
        <FormControlLabel
          classes={{label: classes.labelList}}
          value="top"          
          control={<Checkbox color="primary" checked={isChecked["allow_post"]} />}
          label="Incluir"
          labelPlacement="top"
          onClick={() => onSelect("allow_post")}
        />
        <FormControlLabel
          classes={{label: classes.labelList}}
          value="top"          
          control={<Checkbox color="primary" checked={isChecked["allow_put"]}/>}
          label="Editar"
          labelPlacement="top"
          onClick={() => onSelect("allow_put")}

        />
        <FormControlLabel
          classes={{label: classes.labelList}}
          value="top"
          control={<Checkbox color="primary" checked={isChecked["allow_delete"]}/>}
          label="Excluir"
          labelPlacement="top"
          onClick={() => onSelect("allow_delete")}
        />
      </FormGroup>
    </FormControl>
  );
}
