//external
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//internal
import { getComponent, putComponent, findDonorSpecie } from 'services/component/service';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import history from 'services/history';
import ContentPanel from 'components/ContentPanel';
import { validateForm } from 'components/Form/validate';
import { setMessage } from 'store/modules/message/action';
import { componentSchema } from '../schemas';
import GridContainer from 'components/v2/grid';
import InputText from 'components/v2/inputText';
import Toggle from 'components/v2/toggle';
import { findComponentGroup } from 'services/componentGroup/service';
import { findComponent } from 'services/component/service';
import { findProductRestriction } from 'services/productRestriction/service';
import { sanitizeErrorUI } from 'utils/errorUtil';
import { useDebounce } from 'utils/uiUtils';
import SelectAutocomplete from 'components/v2/autocomplete';
import { Warning } from 'routes/Route';
//style
import { Button, ButtonContainer } from 'styles/components';
import { Typography } from '@material-ui/core';
import { IconContainer } from '../list/styled';
import DeleteIcon from '@material-ui/icons/Delete';
import TableAction from 'components/v2/tableAction';
import DataTableReact from 'components/v2/dataTableReact';
import { Grid } from '@material-ui/core';

export default function Component({match}) {
  const message = useSelector(state => state.message.data);
  const componentId = match.params.id;
  const [dataComponents, setDataComponents] = useState({
    protheusCode:"", 
    description:"",
    descriptionAlias:"",
    componentGroup: "",
  });
  const [errors, setErrors] = useState([]);
  const [options, setOptions] = useState([]);
  const [searchGroupComponent, setSearchGroupComponent] = useState("");
  const [itemSelected, setItemSelected] = useState(null);
  const [donorSpecies, setDonorSpecies] = useState([]);
  const [donorSpecieSelected, setDonorSpecieSelected] = useState(null);
  const [searchDonorSpecie, setSearchDonorSpecie] = useState("");
  const [donorSpecies2, setDonorSpecies2] = useState([]);
  const [donorSpecie2Selected, setDonorSpecie2Selected] = useState(null);
  const [searchDonorSpecie2, setSearchDonorSpecie2] = useState("");
  const [isLoadingComponent, setIsLoadingComponent] = useState(false);
  const [isLoadingDonorSpecie, setIsLoadingDonorSpecie] = useState(false);
  const [isLoadingDonorSpecie2, setIsLoadingDonorSpecie2] = useState(false);

  const { setNotWarning } = useContext(Warning);

  // Components
  const [selectedSubstitutives, setSelectedSubstitutives] = useState([]);
  const [optionsComponent, setOptionsComponent] = useState([]);
  const [searchComponent, setSearchComponent] = useState("");
  const [itemSelectedComponent, setItemSelectedComponent] = useState(null);

  //select restriction 
  const [optionsRestriction, setOptionsRestriction] = useState([]);
  const [searchRestriction, setSearchRestriction] = useState("");
  const [itemSelectedRestriction, setItemSelectedRestriction] = useState(null);
  const [isLoadingRestriction, setIsLoadingRestriction] = useState(false);

  const debouncedSearchGroup = useDebounce(searchGroupComponent);
  const debouncedSearchDonorSpecie = useDebounce(searchDonorSpecie);
  const debouncedSearchDonorSpecie2 = useDebounce(searchDonorSpecie2);
  const debouncedSearchComponent = useDebounce(searchComponent);
  const debouncedSearchRestriction = useDebounce(searchRestriction);

  const dispatch = useDispatch();

  const columnsSubstitutives = [
    {
      maxWidth: "25%",
      name: 'Código',
      selector: 'protheusCode',
      sortable: true,
    },
    {
      maxWidth: "25%",
      name: 'Descrição',
      selector: 'description',
      sortable: true,
    },
    {
      maxWidth: "25%",
      name: 'Rótulo',
      selector: 'descriptionAlias',
      sortable: false,
    },
    {
      maxWidth: "25%",
      style: {justifyContent: "flex-end"},
      cell: row =>       
        <IconContainer>
          <TableAction 
            title={"Excluir"} 
            onClick={() => handleRemoveSubstitutive(row)}
          >
            <DeleteIcon />
          </TableAction>
        </IconContainer>      
    }
  ];
  
  const loadComponent = useCallback((searchValue) => {
    setIsLoadingComponent(true)
    findComponent(searchValue).then(result => {
      const components = result.data.map(element => {
        return { value: element, label: `${element.protheusCode} - ${element.description} - ${element.descriptionAlias}` }
      });
      setOptionsComponent(components);
      setIsLoadingComponent(false)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      setIsLoadingComponent(false);
    });
  }, [dispatch])

  function handleRemoveSubstitutive(component) {    
    var componentIndex = selectedSubstitutives.indexOf(component);
    selectedSubstitutives.splice(componentIndex, 1);
    setSelectedSubstitutives([...selectedSubstitutives]);    
  }

  function handleComponentInputChange(data, event) {
    if (data) {
      const componentIndex = selectedSubstitutives.findIndex(item => item.protheusCode === data?.value?.protheusCode);
      setItemSelectedComponent(data);
      if (componentIndex >= 0)  return;
      
      setSelectedSubstitutives([...selectedSubstitutives, data.value]); 
    } else {
      setItemSelectedComponent(null);
      loadComponent("");
    }
  }

  useEffect(() => {
    if(loadComponent) loadComponent(debouncedSearchComponent)
  },[debouncedSearchComponent, loadComponent]);

  const loadProductRestriction = useCallback((searchValue) => {
    setIsLoadingRestriction(true)
    findProductRestriction(searchValue).then(result => {
      const productRestrictions = result.data.map(element => {
        return { value: element, label: `${element.description}` }
      });
      setOptionsRestriction(productRestrictions);
      setIsLoadingRestriction(false)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      setIsLoadingRestriction(false);
    });
  }, [dispatch])

  useEffect(() => {
    if(loadProductRestriction) loadProductRestriction(debouncedSearchRestriction)
  },[debouncedSearchRestriction, loadProductRestriction]);

  const loadComponentGroup = useCallback((searchValue) => {
    setIsLoadingComponent(true)
    findComponentGroup(searchValue).then(result => {
      const components = result.data.map(element => {
        return { value: element, label: `${element.description}` }
      });
      setOptions(components);
      setIsLoadingComponent(false)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      setIsLoadingComponent(false);
    });
  }, [dispatch])

  useEffect(() => {
    if(loadComponentGroup) loadComponentGroup(debouncedSearchGroup)
  },[debouncedSearchGroup, loadComponentGroup]);

  const loadDonorSpecie = useCallback((searchValue) => {
    setIsLoadingDonorSpecie(true);
    findDonorSpecie(searchValue).then(result => {
      const donorSpecies = result.data.map(element => {
        return { value: element, label: `${element.description}` }
      });
      setDonorSpecies(donorSpecies);
      setIsLoadingDonorSpecie(false);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      setIsLoadingDonorSpecie(false);
    });
  }, [dispatch])

  const loadDonorSpecie2 = useCallback((searchValue) => {
    setIsLoadingDonorSpecie2(true);
    findDonorSpecie(searchValue).then(result => {
      const donorSpecies2 = result.data.map(element => {
        return { value: element, label: `${element.description}` }
      });
      setDonorSpecies2(donorSpecies2);
      setIsLoadingDonorSpecie2(false);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      setIsLoadingDonorSpecie2(false);
    });
  }, [dispatch])

  useEffect(() => {
    if(loadDonorSpecie) loadDonorSpecie(debouncedSearchDonorSpecie)
  },[debouncedSearchDonorSpecie, loadDonorSpecie]);

  useEffect(() => {
    if(loadDonorSpecie2) loadDonorSpecie2(debouncedSearchDonorSpecie2)
  },[debouncedSearchDonorSpecie2, loadDonorSpecie2]);

  useEffect(() => {
    if (componentId) {
      dispatch(startRequest());
      getComponent(componentId).then(data => {
        const formData = data.data;
        setDataComponents({
          protheusCode: formData.protheusCode,
          description: formData.description,
          descriptionAlias: formData.descriptionAlias,
          validateQty: formData.validateQty || false,
          notExplodeComponent: formData.notExplodeComponent || false,
          registerNumber: formData.registerNumber,
          componentGroup: formData.componentGroup,
          donorSpecie: formData.donorSpecie
        })

        if (formData.productRestriction) {
          setItemSelectedRestriction({
            label: `${(formData.productRestriction.description)} - ${(formData.productRestriction.restrictiveText)}`,
            value: {
              description: formData.productRestriction.description,
              id: formData.productRestriction.id,
              restrictiveText: formData.productRestriction.restrictiveText
            }
          });
        }

        if (formData.substitutives) {
          setSelectedSubstitutives(formData.substitutives);
        }

        if (formData.componentGroup) {
          setItemSelected({
            label: formData.componentGroup.description,
            value: {
              description: formData.componentGroup.description,
              id: formData.componentGroup.id,
              order: formData.componentGroup.order
            }
          });
        }
        if (formData.donorSpecie) {
          setDonorSpecieSelected({
            label: formData.donorSpecie.description,
            value: {
              description: formData.donorSpecie.description,
              id: formData.donorSpecie.id
            }
          });
        }
        if (formData.donorSpecie2) {
          setDonorSpecie2Selected({
            label: formData.donorSpecie2.description,
            value: {
              description: formData.donorSpecie2.description,
              id: formData.donorSpecie2.id
            }
          });
        }
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, componentId])

  async function handleSubmit () {
    setNotWarning();

    const errorsList = await validateForm(dataComponents, componentSchema);
    if (errorsList.length > 0) {
      setErrors(errorsList);
      return;
    }

    if (selectedSubstitutives.length > 0) {
      dataComponents.substitutives = selectedSubstitutives;
    }

    const objCopy = JSON.parse(JSON.stringify(dataComponents));
    if (itemSelected !== "") {
      objCopy.componentGroup = {id: itemSelected?.value.id};
    }
    if (donorSpecieSelected) {
      objCopy.donorSpecie = {id: donorSpecieSelected?.value.id};
    } else {
      delete(objCopy.donorSpecie);
    }
    if (donorSpecie2Selected) {
      objCopy.donorSpecie2 = {id: donorSpecie2Selected?.value.id};
    } else {
      delete(objCopy.donorSpecie2);
    }
    if (!objCopy.componentGroup?.id) {
      objCopy.componentGroup = null;
    }
    objCopy.productRestriction = itemSelectedRestriction?.value?.id ? {id: itemSelectedRestriction?.value?.id} : null;

    dispatch(startRequest());
    putComponent(componentId, objCopy).then(data => {
      history.push('/premixlabel/components')
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });    
  }

  return (
    <ContentPanel title='Componente' message={message}>
      <GridContainer>
        <InputText
          xs={12}
          sm={6}
          required
          name="protheusCode"
          label="Código"
          type="text"
          value={dataComponents.protheusCode}
          onChange={(e) => setDataComponents({...dataComponents, protheusCode: e.target.value})}
          errors={errors}
          disabled={true}
        />

        <InputText
          xs={12}
          sm={6}
          required
          name="description"
          label="Descrição"
          type="text"
          value={dataComponents.description}
          onChange={(e) => setDataComponents({...dataComponents, description: e.target.value})}
          errors={errors}
          disabled={true}
        /> 

        <InputText
          xs={12}
          sm={6}
          name="descriptionAlias"
          label="Rótulo"
          type="text"
          placeholder="Rótulo"
          value={dataComponents.descriptionAlias}
          onChange={(e) => setDataComponents({...dataComponents, descriptionAlias: e.target.value})}
          errors={errors}
        />

        <SelectAutocomplete
          xs={12}
          sm={6}
          value={itemSelected}
          label="Grupo de matéria prima"
          optionSelected={(option, value) => option.value.description === value.value.description}
          optionLabel={(option) => option.value.description}
          onChangeSelected={(event, value) => setItemSelected(value)}
          onChange={(event) => setSearchGroupComponent(event.target.value)}
          options={options}
          name="componentGroup"
          isLoading={isLoadingComponent}
        />

        <SelectAutocomplete
          xs={12}
          sm={6}
          value={donorSpecieSelected}
          label="Especie doadora"
          optionSelected={(option, value) => option.value.description === value.value.description}
          optionLabel={(option) => option.value.description}
          onChangeSelected={(event, value) => setDonorSpecieSelected(value)}
          onChange={(event) => setSearchDonorSpecie(event.target.value)}
          options={donorSpecies}
          name="donorSpecie"
          isLoading={isLoadingDonorSpecie}
        />

        <Toggle
          xs={12}
          sm={6}
          name="Valida quantidade"
          checked={dataComponents.validateQty}
          onChange={(e) => setDataComponents({...dataComponents, validateQty: e.target.checked})}
          label="Valida quantidade"
        />

        <SelectAutocomplete
          xs={12}
          sm={6}
          value={donorSpecie2Selected}
          label="Especie doadora2"
          optionSelected={(option, value) => option.value.description === value.value.description}
          optionLabel={(option) => option.value.description}
          onChangeSelected={(event, value) => setDonorSpecie2Selected(value)}
          onChange={(event) => setSearchDonorSpecie2(event.target.value)}
          options={donorSpecies2}
          name="donorSpecie2"
          isLoading={isLoadingDonorSpecie2}
        />

        <Toggle
          xs={12}
          sm={6}
          name="Nao explode componente na RTPI"
          checked={dataComponents.notExplodeComponent}
          onChange={(e) => setDataComponents({...dataComponents, notExplodeComponent: e.target.checked})}
          label="Nao explode componente na RTPI"
        />

        <Grid item
          xs={12}
          sm={12}>
          <Typography>
            PS: Pode-se usar a expressão {`{1}`} ou {`{2}`} para indicar onde será exibido o numero de referencia da primeira ou segunda especie doadora, respectivamente, caso não seja utilizado o numero será incluido no final da descrição do produto
          </Typography>
        </Grid>

        <SelectAutocomplete
          xs={12}
          sm={12}
          value={itemSelectedRestriction}
          label="Restrição"
          required
          optionSelected={(option, value) => option.label === value.label}
          optionLabel={(option) => option.label}
          onChangeSelected={(event, value) => setItemSelectedRestriction(value)}
          onChange={(event) => setSearchRestriction(event.target.value)}
          options={optionsRestriction}
          name="ProductRestriction"
          errors={errors}
          isLoading={isLoadingRestriction}
        />

        <InputText
          xs={12}
          sm={12}
          name="registerNumber"
          label="Numero do registro no ministério"
          type="text"
          placeholder="Numero do registro no ministério"
          value={dataComponents.registerNumber}
          onChange={(e) => setDataComponents({...dataComponents, registerNumber: e.target.value})}
          errors={errors}
        />
      </GridContainer>

      <ContentPanel message={message} title="Substitutivos"
          xs={12}
          sm={12}>      
        <SelectAutocomplete
          xs={12}
          sm={12}
          value={itemSelectedComponent}
          label="Buscar Matéria Prima"
          optionSelected={(option, value) => option.label === value.label}
          optionLabel={(option) => option.label}
          onChangeSelected={(event, value) => handleComponentInputChange(value)}
          onChange={(event) => setSearchComponent(event.target.value)}
          options={optionsComponent}
          name="components"
          isLoading={isLoadingComponent}
        />
        
        <DataTableReact
          noHeader
          columns={columnsSubstitutives}
          data={selectedSubstitutives}
        />
      </ContentPanel>

      <ButtonContainer>
        <Button type="submit" onClick={handleSubmit}>Salvar</Button>
      </ButtonContainer>
    </ContentPanel>
  )
}