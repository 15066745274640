import styled from 'styled-components'
import * as cor from '../../styles/colors'

import { Link } from 'react-router-dom'

export const Container = styled.ul`
  height: 100%;
  width: 249px;
  min-width: 239px;
  top: 0;
  left: 0;
  background-color: ${cor.fundoMenu};
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
`

export const Search = styled.div`
  padding: 15px 20px;
  border-bottom: solid ${cor.corBorda} 1px;

  input {
    border: solid ${cor.corBorda} 1px;;
    padding: 5px 9px;
    
    width: 100%;
  }
  
`

export const IconSearchMenu = styled.div`
  margin: 0 -8px;
  
`

export const InputIcon = styled.div`
  background-color: white;
  height: 55px;
  margin: 0 12px;
  //position: fixed !important;
  //top: 80px !important;
  //left: 80px !important;
`

export const Lnk = styled(Link)`
  display: flex;
  padding: 10px;
  color: ${props => (props.active ? cor.corTextoMenuAtivo : cor.corTextoMenu)};
  background-color: ${props => (props.active ? cor.fundoMenuAtivo : cor.fundoMenu)};
  font-size: 16px;
  text-decoration: none;
  align-items: center;

  transition: 0.2s;

  span {
    margin: 0px 5px;
  }

  :hover {
    background-color: ${cor.fundoMenuHover};
  }
`