//external
import React, { useContext, useEffect, useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
//internal
import { getErrorMessage, getIsError } from 'utils/validationUtil';
import { Warning } from 'routes/Route';

export default function InputMultiline({name, label, placeholder, type, value, onChange, xs, sm, disabled, required, hasWarning = true, errors = []}) {

  const [stateErrors, setStateErrors] = useState([]);

  const { setIsWarning } = useContext(Warning);

  const onChangeInput = (e) => {
    onChange(e);
    setStateErrors(stateErrors.filter(item => item.field !== name));
    if (hasWarning) {
      setIsWarning();
    }
  }

  useEffect(() => {
    if(Array.isArray(errors) && errors.length > 0) {   
      setStateErrors(errors)
    }
  }, [errors]);

  return (
    <Grid item xs={xs} sm={sm}>
      <TextField
        name={name}
        label={label}
        placeholder={placeholder}
        type={type}
        multiline
        rows={5}
        required={required}
        value={value || ''}
        onChange={(e) => onChangeInput(e)}
        disabled={disabled}
        error={getIsError(name, stateErrors)}
        helperText={getErrorMessage(name, stateErrors)}
        variant="outlined"
        InputLabelProps={{shrink: true}}
        fullWidth
      />
    </Grid>
  )  
}

InputMultiline.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string || PropTypes.number,
  onChange: PropTypes.func,
  errors: PropTypes.array,
  disabled: PropTypes.bool, 
}