import api from '../api'

export async function getAllows() {
  return api.get('document/allows');
}

export async function findDocumentTypes(name, numOfRegisters = 6, ignorePermissions = false) {
  let params = {};
  
  params.ignorePermissions = ignorePermissions;  

  return api.get('document/types', {
    params: {
      ...params,
      name,
      order: 'name'
    },
    headers: {
      registerperpage: numOfRegisters,
      page: 1
    }
  });
}

export async function getDocuments(query, status, typeId, startDate, endDate, numOfRegisters = 10, page = 1, order, app = false) {
  const params = {
    app: app,
    order: order ? order : 'name',
  }

  if (query) {
    params.query = query; 
  }

  if (status) {
    params.status = status; 
  }

  if (typeId) {
    params.typeId = typeId; 
  }

  if (startDate) {
    params.startDate = startDate; 
  }

  if (endDate) {
    params.endDate = endDate; 
  }

  return api.get('document', {
    params,
    headers: {
      registerperpage: numOfRegisters,
      page
    }
  });
}

export async function getDocument(id) {
  return api.get(`document/${id}`);
}

export async function putStatusDocument(id, data) {
  return api.put(`document/status/${id}`, data);
}

export const delDocument = (id) => {
  return api.delete(`document/${id}`);
};

export async function printDocuments(listIds) {
  const params = {
    documents: listIds,
  };

  return api.get('document/print', {
    responseType: 'blob',
    params,
  });
}
