import React, { useEffect, useState } from 'react'

import { Attr, AttrName } from './styled'

import { getProfile, deleteProfile } from '../../../services/profiles/service'
import { useDispatch, useSelector } from 'react-redux';
import { startRequest, finishRequest } from '../../../store/modules/loading/actions';

import history from '../../../services/history';
import ContentPanel from '../../../components/ContentPanel';
import { ButtonContainer, Button } from '../../../styles/components';
import { setMessage } from '../../../store/modules/message/action';
import { sanitizeErrorUI } from 'utils/errorUtil';

export default function Profile({match}) {
  const profileId = match.params.id;
  const message = useSelector(state => state.message.data);
  const [profile, setProfile] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startRequest());
    getProfile(profileId).then(data => {
      setProfile(data.data);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }, [dispatch, profileId])

  async function handleDelete () {
    dispatch(startRequest());
    deleteProfile(profileId).then(data => {
      history.push('/userprofiles')
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }

  async function handleBack () {
    history.goBack();
  }

  return (
    <>
      <ContentPanel title="Deseja excluir o perfil?" message={message}>
        {profile && <>
          <AttrName>Nome:</AttrName>
          <Attr>{profile.name}</Attr>
          <AttrName>Status:</AttrName>
          <Attr>{profile.active ? 'Ativo' : 'Inativo'}</Attr>

          <ButtonContainer>
            <br />
            <Button left={true} cancel={true} onClick={handleBack}>
              Voltar
            </Button>
            <Button onClick={handleDelete}>
              Excluir
            </Button>
          </ButtonContainer>
        </>}
      </ContentPanel>
    </>
  )
}