import * as cognito from '../../../services/cognito'

import { all, takeLatest, put, call, select } from 'redux-saga/effects'
import history from '../../../services/history';

import { storeFailure, storeSuccess, confirmSuccess, getAllowedsFailure, getAllowedsSuccess } from './actions';
import { startRequest, finishRequest } from '../loading/actions';
import { setMessage } from '../message/action';
import { translate } from '../../../services/translateCognitoErrors';
import { getAllowedModules } from '../../../services/user/service';

export function* store({ payload }) {
  try {
    yield put(startRequest());
    const { name, email, password } = payload;

    const user = yield call (cognito.storeUser, { name, email, password });
    
    yield put(storeSuccess(user));

    yield put(setMessage({success: true, text: 'Usuário cadastrado com sucesso!'}));

    history.push('/login');
  } catch (err) {
    yield put(setMessage({error: true, text: translate(err)}));
    yield put(storeFailure());
  } finally {
    yield put(finishRequest());
  }
}

export function* confirm({ payload }) {
  try {
    yield put(startRequest());
    const { email, confirmationCode } = payload;

    yield call (cognito.confirm, { email, confirmationCode });
    
    yield put(confirmSuccess(email));
    yield put(setMessage({success: true, text: 'Usuário confirmado com sucesso!'}));

    history.push('/login');
  } catch (err) {
    yield put(setMessage({error: true, text: translate(err)}));
    yield put(storeFailure());
  } finally {
    yield put(finishRequest());
  }
}

export function* getAlloweds() {
  try {
    const result = yield call (getAllowedModules);
    const { data } = result;
    const nivel = yield select(state => state.user.nivel);
    const userId = yield select(state => state.user.id)

    const indexTodo = data.findIndex(item => item.path === '/schedule_todo');

    if ((indexTodo > -1 && nivel < 3) || (indexTodo > -1 && userId === "XXXXXX")) {
      const itemMenu = {
        description: "Tarefas RC",
        path: "/schedule_todo_rc",
      }

      const newAllowed = [...data, itemMenu];
      yield put(getAllowedsSuccess(newAllowed));

      return
    } 
    yield put(getAllowedsSuccess(result.data));
  } catch (err) {
    yield put(setMessage({error: true, text: translate(err)}));
    yield put(getAllowedsFailure());
  }
}

export default all([
  takeLatest('@user/STORE_REQUEST', store),
  takeLatest('@user/CONFIRM_REQUEST', confirm),
  takeLatest('@user/ALLOWED_MODULES_REQUEST', getAlloweds),
]);