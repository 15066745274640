import api from '../../api';

export async function getAllows() {
  return api.get('schedule/allows');
}
  
export async function getSchedules(username, startDate, endDate) {
  const params = {};

  if (username) {
    params.username = username; 
  }
  params.startDate = startDate;
  params.endDate = endDate;
  
  return api.get('schedule', {
    params,
    headers: {
      registerperpage: 50,
      page: 1
    }
  });
}

export async function getSchedule(id) {
  return api.get(`schedule/${id}`);
}

export async function getScheduleUsers(username) {
  const params = {};

  if (username) {
    params.query = username; 
  }

  return api.get('schedule/users', {
    params,
    headers: {
      registerperpage: 6,
      page: 1
    }
  });
}

export async function getScheduleTypes() {
  return api.get('schedule/scheduleTypes');
}

export async function getScheduleTypesId(id) {
  return api.get(`schedule/scheduleTypes/${id}`);
}

export async function getCustomers(query, numOfRegisters = 6) {
  return api.get('schedule/customers', {
    params: {
      query,
      order: 'nome'
    },
    headers: {
      registerperpage: numOfRegisters,
      page: 1
    }
  });
} 

export async function getCustomersId(id, store) {
  return api.get(`schedule/customers/${id}/${store}`);
}

export async function getProspect(customerName, numOfRegisters = 6) {
  return api.get('schedule/prospect', {
    params: {
      customerName,
      order: 'customerName'
    },
    headers: {
      registerperpage: numOfRegisters,
      page: 1
    }
  });
}

export async function getProspectId(id) {
  return api.get(`schedule/prospect/${id}`);
}

export async function getChecklists(id) {
  return api.get(`schedule/full/${id}`);
}

export async function postSchedule(schedule, data) {
  return api.post(`schedule/`, schedule, data);
}

export async function putSchedule(id, data) {
  return api.put(`schedule/${id}`, data);
}

export async function putCheckin(id, data) {
  return api.put(`schedule/checkin/${id}`, data);
}

export async function putCheckout(id, data) {
  return api.put(`schedule/checkout/${id}`, data);
}

export async function putConfirm(id, data) {
  return api.put(`schedule/confirm/${id}`, data);
}

export async function putCancel(id, data) {
  return api.put(`schedule/cancel/${id}`, data);
}

export async function putMoveEvent(id, data) {
  return api.put(`schedule/time/${id}`, data);
}

export async function deleteSchedule(id) {
  return api.delete(`schedule/${id}`);
}

export async function getScheduleReport(id) {
  return api.get(`schedule/report/${id}`, {responseType: 'blob', timeout: 180000});
}

export async function revertStatusSchedule(id) {
  return api.put(`schedule/revert-status/${id}`);
}