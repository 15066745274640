//external
import ContentPanel from 'components/ContentPanel';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//internal
import { getNutrientGroup, putGroupTemplate, postGroupTemplate } from 'services/nutrientGroup/service';
import { setMessage } from 'store/modules/message/action';
import { sanitizeErrorUI } from 'utils/errorUtil';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import GridContainer from 'components/v2/grid';
import InputText from 'components/v2/inputText';
import { validateForm } from 'components/Form/validate';
import { nutrientGroupSchema } from '../schemas';
import history from 'services/history';
import { Warning } from 'routes/Route';
//style
import { Button, ButtonContainer } from 'styles/components';

export default function NutrientGroupEdit({match}) {
  const nutrientGroupId = match.params.id;
  const message = useSelector(state => state.message.data);
  const [dataNutrientGroup, setDataNutrientGroup] = useState({});
  const [errors, setErrors] = useState([]);

  const { setNotWarning } = useContext(Warning);

  const dispatch = useDispatch();

  useEffect(() => {
    if (nutrientGroupId) {
      dispatch(startRequest());
      getNutrientGroup(nutrientGroupId).then(data => {
        const formData = data.data
        setDataNutrientGroup({
          id: formData.id,
          description: formData.description,
          order: formData.order,
        });
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, nutrientGroupId]);

  async function handleSubmit () {
    setNotWarning();
    
    const errorsList = await validateForm(dataNutrientGroup, nutrientGroupSchema);
    if (errorsList.length > 0) {
      setErrors(errorsList);
      return
    }

    if (nutrientGroupId) {
      dispatch(startRequest());
      putGroupTemplate(nutrientGroupId, dataNutrientGroup).then(data => {
        history.push('/premixLabel/nutrient_group')
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    } else {
      dispatch(startRequest());
      postGroupTemplate(dataNutrientGroup).then(data => {
        history.push('/premixLabel/nutrient_group')
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
      
  }
  
  return (
    <ContentPanel title='Grupo de Nutriente' message={message}>
      <GridContainer>
        <InputText
          xs={12}
          sm={6}
          required
          name="description"
          label="Descrição"
          type="text"
          value={dataNutrientGroup.description}
          onChange={(e) => setDataNutrientGroup({...dataNutrientGroup, description: e.target.value})}
          errors={errors}
        />
        <InputText
          xs={12}
          sm={6}
          required
          name="order"
          label="Ordem"
          type="text"
          value={dataNutrientGroup.order}
          onChange={(e) => setDataNutrientGroup({...dataNutrientGroup, order: e.target.value})}
          errors={errors}
        />
      </GridContainer>

      <ButtonContainer>
        <Button type="submit" onClick={handleSubmit}>Salvar</Button>
      </ButtonContainer>   
  </ContentPanel>  
  )
}