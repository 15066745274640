import api from '../api'

export async function getAllows() {
  return api.get('commission/allows');
}

export const getCommissions = async (
  id_salesman,
  month,
  year,
  numOfRegisters = 10,
  page = 1,
  type = 'prevision',
) => {
  let formattedMonth = '0' + month;
  formattedMonth = formattedMonth.substring(formattedMonth.length - 2);

  const params = {
    id_salesman,
  };

  params.month = formattedMonth;
  params.year = year;
  params.type = type;

  return api.get(`commission/${id_salesman}`, {
    params,
    headers: {
      registerperpage: numOfRegisters,
      page,
    },
  });
};

export const getSummary = async (id_salesman, month, year, type = 'prevision') => {
  let formattedMonth = '0' + month;
  formattedMonth = formattedMonth.substring(formattedMonth.length - 2);

  const params = {
    id_salesman,
  };

  params.month = formattedMonth;
  params.year = year;
  params.type = type;

  return api.get(`commission/summary/${id_salesman}`, {params});
};

export const getCommissionsPayed = async (
  id_salesman,
  month,
  year,
  numOfRegisters = 10,
  page = 1,
  type = 'payed',
) => {
  let formattedMonth = '0' + month;
  formattedMonth = formattedMonth.substr(formattedMonth.length - 2);

  const params = {
    id_salesman,
  };

  params.month = formattedMonth;
  params.year = year;
  params.type = type;

  return api.get(`commission/payed/${id_salesman}`, {
      params,
      headers: {
        registerperpage: numOfRegisters,
        page,
      },
    });
};

export const getCommissionsPayedDetails = async (
  id_salesman,
  id_invoice,
  month,
  year,
  numOfRegisters = 10,
  page = 1,
  type = 'payed',
) => {
  const params = {
    id_invoice,
  };

  params.month = month;
  params.year = year;
  params.type = type;

  return api.get(`commission/payed/detail/${id_salesman}`, {
    params,
    headers: {
      registerperpage: numOfRegisters,
      page,
    },
  });
};

export async function postDocumentFile(file, data) {
  const formData = new FormData()
  formData.append('file', file);
  formData.append('document', JSON.stringify(data));
  return api.post(`commission/upload/`, formData, {
    headers: {
      'content-type': 'multipart/form-data'
    }
  })  
}
