import api from '../api';

export async function getAllows() {
  return api.get('user_group/allows');
}
  
export async function getUserGroups(numOfRegisters = 10, page = 1, order) {
  const params = {
    order: order ? order : 'description',
  }

  return api.get('user_group', {
    params,
    headers: {
      registerperpage: numOfRegisters,
      page
    }
  });
}

export async function getUserGroup(id) {
  return api.get(`user_group/${id}`);
}

export async function postUserGroup(useGroup) {
  return api.post(`user_group/`, useGroup);
}

export async function putUserGroup(id, data) {
  return api.put(`user_group/${id}`, data);
}

export async function deleteUserGroup(id) {
  return api.delete(`user_group/${id}`);
}

export async function findUserGroup(description, numOfRegisters = 6) {
  return api.get('user_group', {
    params: {
      description,
      order: 'description'
    },
    headers: {
      registerperpage: numOfRegisters,
      page: 1
    }
  });
}