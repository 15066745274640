//external
import React, { useCallback, useMemo, useState } from 'react';
import TableAction from 'components/v2/tableAction';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import CloseIcon from '@material-ui/icons/Close'; 
//internal
import DataTableReact from 'components/v2/dataTableReact';
import ChecklistAnswer from 'components/ChecklistAnswer';
import Modal from 'components/v2/modal';
//styled
import { IconContainer } from 'pages/nutrient/list/styled';
import { ContainerModal } from 'pages/schedule/list/styled';

export default function Checklists({onAnswer, answerList, data, dataAnswer}) {
  const [openModalAnswers, setOpenModalAnswers] = useState(false);
  const [idAnswer, setIdAnswer] = useState(null);
  const [dataAnswerId, setDataAnswerId] = useState({});

  const columns = [
    {
      maxWidth: "30%",
      name: 'Checklist',
      selector: 'checklist.description',
      sortable: true,
    },
    {
      maxWidth: "20%",
      name: 'Checklist obrigatório',
      selector: row => row.required ? 'Sim' : 'Não',
      sortable: true,
    },
    {
      maxWidth: "20%",
      name: 'Status',
      selector: row => answerList.findIndex(i => i.checklistId === row.checklist.id) > -1 ? "Respondido" : "Responder",
      sortable: true,
    },
    {
      maxWidth: "20%",
      style: {justifyContent: "flex-end"},
      cell: row => 
        <div>
          <IconContainer>
            <TableAction 
            title={"Responder"} 
            onClick={() => handleAnswerChecklist(row.checklist.id)}>
              <QuestionAnswerIcon/>
            </TableAction>
          </IconContainer>
        </div>
    }
  ]

  const handleAnswerChecklist = useCallback((id) => {
    const checklist = dataAnswer.find(i => i.id === id)
    setDataAnswerId(checklist)
    setOpenModalAnswers(true);
    setIdAnswer(id);
  }, [dataAnswer]);

  const handleCloseModalAnswers = () => {
    setOpenModalAnswers(false);
  };

  const handeSaveAnswer = useCallback((form) => {
    onAnswer(form)
    setOpenModalAnswers(false);
  }, [onAnswer]);

  const ChecklistAnswerMemo = useMemo(() => {
    return (
      <ChecklistAnswer
        checklistId={idAnswer}
        dataAnswer={dataAnswerId}
        answerList={answerList}
        onSaveAnswer={(form) => handeSaveAnswer(form)}
      />
    );
  }, [answerList, dataAnswerId, idAnswer, handeSaveAnswer]);

  return (
    <div style={{minHeight: '400px'}}>      
      <DataTableReact
        style={{overflow: 'hidden'}}
        noHeader
        columns={columns}
        data={data}
      />

      <Modal isOpen={openModalAnswers} key={idAnswer || Math.random(0.5)}>
        <ContainerModal> 
          <TableAction 
          key='closeModal'
          title={"Fechar"}  
          onClick={handleCloseModalAnswers}>
            <CloseIcon/>
          </TableAction>
        </ContainerModal>  

        {ChecklistAnswerMemo}
      </Modal>
    </div>  
  )
}