//external
import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Grid, Checkbox, FormGroup, FormControlLabel, Tab } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
//internal
import history from 'services/history';
import ContentPanel from 'components/ContentPanel';
import GridContainer from 'components/v2/grid';
import InputText from 'components/v2/inputText';
import Toggle from 'components/v2/toggle';
import TransferList from 'components/v2/transferList';
import { getProfile, putProfile, postProfile, getModules, getSpecialPermissions, findUserGroups, getDashboards } from 'services/profiles/service';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import { setMessage } from 'store/modules/message/action';
import { sanitizeErrorUI } from 'utils/errorUtil';
import SelectAutocomplete from 'components/v2/autocomplete';
import { useDebounce } from 'utils/uiUtils';
import TableAction from 'components/v2/tableAction';
import TabComponent from 'components/v2/tabs';
import TabPanel from 'components/v2/tabPanel';
import DataTableReact from 'components/v2/dataTableReact';
import { IconContainer } from 'pages/user/list/styled';
import SimpleTransferList from 'components/v2/simpleTransferList';
import { Warning } from 'routes/Route';
//style
import { Button, ButtonContainer } from 'styles/components';
import { findDocumentTypes } from 'services/document/service';

export default function Profile({match}) {
  const message = useSelector(state => state.message.data);
  const profileId = match.params.id;

  const [profile, setProfile] = useState({modules: []});
  const [modules, setModules] = useState([]);
  const [nameProfile, setNamePofile] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [updateModules, setUpdateModules] = useState([]);
  const [specials, setSpecials] = useState([]);
  const [dashboardsLeft, setDashboardsLeft] = useState([]);
  const [dashboardsRight, setDashboardsRight] = useState([]);
  const [side, setSide] = useState("");
  //select Grupo de Usuários
  const [selectedUserGroup, setSelectedUserGroup] = useState([]);
  const [searchUserGroup, setSearchUserGroup] = useState("");
  const [optionsUserGroup, setOptionsUserGroup] = useState([]);
  const [itemSelectedUserGroup, setItemSelectedUserGroup] = useState(null);
  const [scheduleAllowAllGroups, setScheduleAllowAllGroups] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  //select Tipo de Documentos
  const [selectedDocumentType, setSelectedDocumentType] = useState([]);
  const [searchDocumentType, setSearchDocumentType] = useState("");
  const [optionsDocumentType, setOptionsDocumentType] = useState([]);
  const [itemSelectedDocumentType, setItemSelectedDocumentType] = useState(null);
  const [documentAllowAllTypes, setDocumentAllowAllTypes] = useState(false);
  const [isLoadingTypes, setIsLoadingTypes] = useState(false);
  
  const [value, setValue] = useState(0);

  const { setNotWarning } = useContext(Warning);

  let _groups = [];
  let _modules = [];

  const dispatch = useDispatch();

  const debouncedUserGroup = useDebounce(searchUserGroup);
  const debouncedDocumentType = useDebounce(searchDocumentType);

  const columnsUserGroup = [
    {
      maxWidth: "75%",
      name: 'Descrição',
      selector: 'description',
      sortable: true,
    },
    {
      maxWidth: "25%",
      style: {justifyContent: "flex-end"},
      cell: row => 
      <>
        <div>
          <IconContainer>
            <TableAction 
            title={"Excluir"} 
            onClick={() => handleRemoveUserGroup(row)}>
              <DeleteIcon/>
            </TableAction>
          </IconContainer>
        </div>
      </>,
    }
  ];

  const columnsDocumentType = [
    {
      maxWidth: "75%",
      name: 'Nome',
      selector: 'name',
      sortable: true,
    },
    {
      maxWidth: "25%",
      style: {justifyContent: "flex-end"},
      cell: row => 
      <>
        <div>
          <IconContainer>
            <TableAction 
            title={"Excluir"} 
            onClick={() => handleRemoveDocumentType(row)}>
              <DeleteIcon/>
            </TableAction>
          </IconContainer>
        </div>
      </>,
    }
  ];

  useEffect(() => {
    setNamePofile(profile.name);
    setIsActive(profile.active || false);
  },[profile]);

  useEffect(() => {
    getDashboards().then(dashboards => {
      setDashboardsLeft(dashboards.data);
      if (profileId) {
        dispatch(startRequest())
        getProfile(profileId).then(data => {
          setProfile({
            id: data.data.id,
            name: data.data.name,
            active: data.data.active || false,
            modules: data.data.AuthProfileModules,
          }); 
          const difference = dashboards.data.filter((value) => data.data.AppDashboards.findIndex(valueb => valueb.id === value.id) === -1);
          setDashboardsLeft(difference);
          setDashboardsRight(data.data.AppDashboards) 
          setSelectedUserGroup(data.data.ScheduleUserGroups)       
          setScheduleAllowAllGroups(data.data.scheduleAllowAllGroups) 
          setSelectedDocumentType(data.data.DocumentTypes)  
          setDocumentAllowAllTypes(data.data.documentAllowAllTypes)   
          const permissions = data.data.SpecialPermissions || [];
          setSpecials([...permissions]);
        }).catch(err => {
          dispatch(setMessage(sanitizeErrorUI(err)));
        }).finally(() => {
          dispatch(finishRequest());
        });
      } else {
        dispatch(startRequest());
        getSpecialPermissions().then(data => {
          setSpecials([...data.data]);
        }).catch(err => {
          dispatch(setMessage(sanitizeErrorUI(err)));
        }).finally(() => {
          dispatch(finishRequest());
        });
      }
    })

    dispatch(startRequest());
    getModules().then(data => {
      setModules(data.data);      
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }, [dispatch, profileId]);

  async function handleSubmit() {
    setNotWarning();

    let permissions = specials.map(item => {        
      return {id: item.id, allow: item.allow || false}
    });

    if (profileId) {
      dispatch(startRequest());
      const data = {
        id: profileId,
        name: nameProfile,
        active: isActive,
        modules: updateModules,
        SpecialPermissions: permissions,
        scheduleAllowAllGroups: scheduleAllowAllGroups || false,
        ScheduleUserGroups: selectedUserGroup,
        documentAllowAllTypes: documentAllowAllTypes || false,
        DocumentTypes: selectedDocumentType,
        AppDashboards: dashboardsRight,
      };
      putProfile(profileId, data).then(data => {
        history.push('/userprofiles');
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    } else {
      dispatch(startRequest());
      const data = {
        name: nameProfile,
        active: isActive,
        modules: updateModules,
        SpecialPermissions: permissions,
        scheduleAllowAllGroups: scheduleAllowAllGroups || false,
        ScheduleUserGroups: selectedUserGroup,
        documentAllowAllTypes: documentAllowAllTypes || false,
        DocumentTypes: selectedDocumentType,
        AppDashboards: dashboardsRight,
      };
      postProfile(data).then(data => {
        history.push('/userprofiles');
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }

  const loadUserGroup = useCallback((searchValue) => {
    setIsLoading(true)
    findUserGroups(searchValue).then(result => {
      const userGroup = result.data.map(element => {
        return { value: element, label: `${element.description}` }
      });
      setOptionsUserGroup(userGroup);
      setIsLoading(false)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      setIsLoading(false);
    });
  }, [dispatch])

  useEffect(() => {
    if(loadUserGroup) loadUserGroup(debouncedUserGroup)
  },[debouncedUserGroup, loadUserGroup]);

  function handleUserGroupInputChange(data, event) {
    if (data) {
      const userGroupIndex = selectedUserGroup.findIndex(item => item.id === data?.value?.id);
      setItemSelectedUserGroup(data);
      if (userGroupIndex >= 0) return;

    setSelectedUserGroup([...selectedUserGroup, data.value]); 
    } else {
      setItemSelectedUserGroup(null);
      loadUserGroup("");
    }
  }

  function handleRemoveUserGroup(userGroup) {    
    var userGroupIndex = selectedUserGroup.indexOf(userGroup);
    selectedUserGroup.splice(userGroupIndex, 1);
    setSelectedUserGroup([...selectedUserGroup]);    
  }

  const loadDocumentType = useCallback((searchValue) => {
    setIsLoadingTypes(true)
    findDocumentTypes(searchValue, 6, true).then(result => {
      const types = result.data.map(element => {
        return { value: element, label: `${element.name}` }
      });
      setOptionsDocumentType(types);
      setIsLoadingTypes(false)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      setIsLoadingTypes(false);
    });
  }, [dispatch])

  useEffect(() => {
    if(loadDocumentType) loadDocumentType(debouncedDocumentType)
  },[debouncedDocumentType, loadDocumentType]);

  function handleDocumentTypeInputChange(data, event) {
    if (data) {
      const documentTypeIndex = selectedDocumentType.findIndex(item => item.id === data?.value?.id);
      setItemSelectedDocumentType(data);
      if (documentTypeIndex >= 0) return;

    setSelectedDocumentType([...selectedDocumentType, data.value]); 
    } else {
      setItemSelectedDocumentType(null);
      loadDocumentType("");
    }
  }

  function handleRemoveDocumentType(documentType) {    
    var documentTypeIndex = selectedDocumentType.indexOf(documentType);
    selectedDocumentType.splice(documentTypeIndex, 1);
    setSelectedDocumentType([...selectedDocumentType]);    
  }
  
  function mergeModules(modules) {
    if (Array.isArray(updateModules) && updateModules.length) {
      return modules.filter(elem => {
        return updateModules.findIndex(mod => mod.AuthModule.id === elem.id) < 0;
      });
    } else {
      return modules.filter(elem => {
        if (!profile || !profile.modules) return true;
        return profile.modules.findIndex(mod => mod.AuthModule.id === elem.id) < 0;
      });
    }
  }

  function groupExist(groupName) {
    var result = _groups.find(element => element === groupName);
    return result === groupName;
  }

  const renderGroup = (groupName, index) => {
    if (groupExist(groupName)) {
      return null;
    }

    _groups.push(groupName);

    return (
      <Grid item xs={12} key={`group-${index}`}>
        <Typography variant="h6">{groupName}</Typography>
      </Grid>
    );
  };

  function moduleExist(moduleName) {
    var result = _modules.find(element => element === moduleName);
    return result === moduleName;
  }

  const renderModule = (moduleName, index) => {
    if (moduleExist(moduleName)) {
      return null;
    }

    _modules.push(moduleName);

    return (
      <Grid item xs={12} key={`module-${index}`}>
        <Typography variant="h6" style={{marginLeft: '14px'}}>{moduleName}</Typography>
      </Grid>
    );
  };

  const handleCheckChange = (event, index) => {
    specials[index].allow = !specials[index].allow;
    setSpecials([...specials]);
  };

  const renderCheckbox = (item, index) => {
    return (
      <Grid item xs={12} key={`check-${index}`}>
        <FormGroup row style={{marginLeft: '28px'}}>
          <FormControlLabel
            control={
              <Checkbox
                checked={item.allow || false}
                onChange={(event) => handleCheckChange(event, index)}
                name={`check=${item.id}`}
                color="primary"
              />
            }
            label={item.description}
          />
        </FormGroup>
      </Grid>
    )
  };

  function props(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  const tabsMenu = () => {
    return tabs.map((item, index) => (
      <Tab key={index} label={item.name} {...props(index)} />
    ))
  }

  const tabs = [
    {id: 1, name: 'Permissões Administrativas'},
    {id: 2, name: 'Permissões Especiais'},
    {id: 3, name: 'Visualização de Agenda'},
    {id: 4, name: 'Documentos'},
    {id: 5, name: 'Dashboards'},
  ]

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function handleChangeSides(index, sidePosition) {
    if(sidePosition === "right") {
      const item = dashboardsRight.find(i => i.id === index);
      setSide("left");
      setDashboardsLeft([...dashboardsLeft, item]);
      setDashboardsRight(dashboardsRight.filter(i => i.id !== index))
    }else {
      const item = dashboardsLeft.find(i => i.id === index)
      setSide("right");
      setDashboardsRight([...dashboardsRight ,item]);
      setDashboardsLeft(dashboardsLeft.filter(i => i.id !== index))
    }
  } 
  
  return (
    <>
      <ContentPanel title='Perfil' message={message}>
        <GridContainer>
          <InputText
            xs={12}
            name="name"
            label="Nome"
            type="text"
            value={nameProfile}
            onChange={(e) => setNamePofile(e.target.value)}
          />

          <Toggle
            xs={12}
            name="active"
            checked={isActive}
            onChange={(e) => setIsActive(e.target.checked) }
            label="Ativo"
          />

          <TabComponent 
            tabs={tabsMenu()} 
            onChangeTabs={handleChange} 
            value={value}
            variant="fullWidth"
          >
            <>
              <TabPanel value={value} index={0}>
                <TransferList
                  leftList={mergeModules(modules)}
                  rightList={updateModules.length > 0 ? updateModules : profile.modules}
                  getRightList={(listModules) => setUpdateModules(listModules)}
                />
              </TabPanel>

              <TabPanel value={value} index={1}>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    {Array.isArray(specials) && specials.map((item, index) => (
                      <Fragment key={index}>
                        {renderGroup(item.groupName, index)}
                        {renderModule(item.moduleName, index)}
                        {renderCheckbox(item, index)}
                      </Fragment>
                    ))}
                  </Grid>
                </Grid>
              </TabPanel>

              <TabPanel value={value} index={2}>
                <SelectAutocomplete
                  xs={12}
                  sm={12}
                  value={itemSelectedUserGroup}
                  label="Buscar Grupo de Usuários"
                  optionSelected={(option, value) => option.label === value.label}
                  optionLabel={(option) => option.label}
                  onChangeSelected={(event, value) => handleUserGroupInputChange(value)}
                  onChange={(event) => setSearchUserGroup(event.target.value)}
                  options={optionsUserGroup}
                  name="userGroups"
                  isLoading={isLoading}
                  hasWarning={false}
                />

                <DataTableReact
                  noHeader
                  columns={columnsUserGroup}
                  data={selectedUserGroup}
                />
                <br/>

                <Toggle
                  name="scheduleAllowAllGroups"
                  checked={scheduleAllowAllGroups || false}
                  onChange={(e) => setScheduleAllowAllGroups(e.target.checked) }
                  label="Permitir visualizar a agenda de todos os usuários"
                /> 
              </TabPanel>

              <TabPanel value={value} index={3}>
                <SelectAutocomplete
                  xs={12}
                  sm={12}
                  value={itemSelectedDocumentType}
                  label="Buscar Tipo de Documento"
                  optionSelected={(option, value) => option.label === value.label}
                  optionLabel={(option) => option.label}
                  onChangeSelected={(event, value) => handleDocumentTypeInputChange(value)}
                  onChange={(event) => setSearchDocumentType(event.target.value)}
                  options={optionsDocumentType}
                  name="documentTypes"
                  isLoading={isLoadingTypes}
                  hasWarning={false}
                />

                <DataTableReact
                  noHeader
                  columns={columnsDocumentType}
                  data={selectedDocumentType}
                />
                <br/>

                <Toggle
                  name="documentAllowAllTypes"
                  checked={documentAllowAllTypes || false}
                  onChange={(e) => setDocumentAllowAllTypes(e.target.checked) }
                  label="Permitir visualizar documentos de todos os tipos"
                /> 
              </TabPanel>

              <TabPanel value={value} index={4}>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    {dashboardsLeft && 
                      <SimpleTransferList
                        leftList={dashboardsLeft}
                        rightList={dashboardsRight}
                        onChangeList={(index, sidePosition) => handleChangeSides(index, sidePosition)}
                        side={side}
                        valueItem={"id"}
                        labelItem={"description"}
                      />
                    }
                  </Grid>
                </Grid>
              </TabPanel>
            </>
          </TabComponent>
        </GridContainer>

        <ButtonContainer>
          <Button type="submit" onClick={handleSubmit}>Salvar</Button>
        </ButtonContainer>
      </ContentPanel>
    </>
  )
}