//external
import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';
import { Warning } from 'routes/Route';

export default function Toggle({xs, sm, name, label, onChange, checked, hasWarning = true}) {
  const { setIsWarning } = useContext(Warning);

  const onChangeToggle = (...props) => {
    onChange(...props);
    if (hasWarning) {
      setIsWarning();
    }
  }

  return (
    <Grid item xs={xs} sm={sm}>
      <FormGroup row >
        <FormControlLabel 
          control={<Switch 
            checked={checked} 
            onChange={(...props) => onChangeToggle(...props)} 
            name={name} 
          />}
          label={label} 
        />
      </FormGroup>
    </Grid>
  )
} 

Toggle.defaultProps = {
  checked: false,
}

Toggle.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
}