export function translate(error) {
  switch (error.code) {
    case 'InvalidParameterException':
      return 'O Nome não pode ser vazio.';

    case 'NotAuthorizedException':
      return 'Usuário ou senha inválidos!';

    case 'UserNotConfirmedException':
      return 'É necessário confirmar sua conta!';

    case 'PasswordResetRequiredException':
      return 'É necessário redefinir sua senha!';

    case 'InvalidPasswordException':
      return 'A senha não cumpre os requisitos de complexidade! Letras maiúsculas, numeros e simbolos são necessários.';

    case 'UsernameExistsException':
      return 'Já existe um usuário com este e-mail!';

    case 'CodeMismatchException':
      return 'O código de ativação não é válido! Verifique o código digitado ou reenvie outro código.';

    case 'LimitExceededException':
      return 'Numero de tentativas excedidas, tente novamente mais tarde!';

    case 'ExpiredCodeException':
      return  'O código de ativação expirou! Use o link Reenviar código de ativação.';

    default:
      return error.message;
  }
}