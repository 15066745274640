import produce from 'immer';

const INITIAL_STATE = {
  data: {
    success: false,
    error: false,
    warn: false,
    text: '',
    messageList: []
  },
};

export default function message(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@message/CLEAR_MESSAGE':
      return produce(state, draft => {
        draft.data = {
          success: false, 
          error: false, 
          warn: false, 
          text: '',
          messageList: []
        };
      })
    case '@message/SET_MESSAGE':
      return produce(state, draft => {    
        draft.data = action.message;
      })
    default: 
      return state;
  }
}