import * as Yup from 'yup';

export const prospectModalSchema = Yup.object().shape({
  active: Yup.bool(),
  contactQualifierId: Yup.number().required('Qualificação é obrigatório'),
  contactRoleId: Yup.number().integer().required('Cargo do contato é obrigatorio.'),
  email: Yup.string().email(),
  name: Yup.string().required('Nome é obrigatório.'),
  notes: Yup.string(),
  phone: Yup.string().required('Telefone é obrigatório'),
});