import React from 'react'
import Route from '../../routes/Route'

import Page403 from './Page403'

export default function Routes() {
  return (
    <>
      <Route path="/403" exact component={Page403} />
    </>
  )
}