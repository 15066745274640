//external
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//internal
import { getVersion, putVersion, postVersion } from 'services/appVersion/service'
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import history from 'services/history';
import ContentPanel from 'components/ContentPanel'
import { validateForm } from 'components/Form/validate'
import { setMessage } from 'store/modules/message/action'
import { appVersionSchema } from '../schemas'
import InputDate from 'components/v2/inputDate'
import GridContainer from 'components/v2/grid';
import InputText from 'components/v2/inputText';
import { sanitizeErrorUI } from 'utils/errorUtil';
import { Warning } from 'routes/Route';
import { formatDate } from 'utils/formatUtil';
//style
import { Button, ButtonContainer } from 'styles/components'

export default function AppVersion({match}) {
  const message = useSelector(state => state.message.data);
  const appVersionId = match.params.id;
  const [dataVersion, setDataVersion] = useState({
    webLimitDate: new Date(),
    androidLimitDate: new Date(),
    iosLimitDate: new Date(),
    version: '',
    notes: '',
    appname: '',
  });
  const [errors, setErrors] = useState([]);

  const { setNotWarning } = useContext(Warning);

  const dispatch = useDispatch();

  useEffect(() => {
    if (appVersionId) {
      dispatch(startRequest());
      getVersion(appVersionId).then(data => {
        const formData = data.data
        setDataVersion({
          webLimitDate: new Date(formData.webLimitDate),
          androidLimitDate: new Date(formData.androidLimitDate),
          iosLimitDate: new Date(formData.iosLimitDate),
          version: formData.version,
          notes: formData.notes || '',
          appname: formData.appname,
        },
      );
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, appVersionId])

  async function handleSubmit () {
    setNotWarning();
    
    const dateFormat = {...dataVersion}
    dateFormat.webLimitDate = formatDate(dateFormat.webLimitDate);
    dateFormat.androidLimitDate = formatDate(dateFormat.androidLimitDate);
    dateFormat.iosLimitDate = formatDate(dateFormat.iosLimitDate);
    const errorsList = await validateForm(dateFormat, appVersionSchema);

    if (errorsList.length > 0) {
      setErrors(errorsList);
      return;
    }
    if (appVersionId) {
      dispatch(startRequest());
      putVersion(appVersionId, dataVersion).then(data => {
        history.push('/premixapp/appversion')
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    } else {
      dispatch(startRequest());
      postVersion(dataVersion).then(data => {
        history.push('/premixapp/appversion')
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }
  
  return (
    <ContentPanel title='Versão' message={message}>
      <GridContainer>
        <InputText
          xs={12}
          sm={6}
          required
          name="appname"
          label="Aplicativo"
          type="text"
          value={dataVersion.appname}
          onChange={(e) => setDataVersion({...dataVersion, appname: e.target.value})}
          errors={errors}
        />
        <InputText
          xs={12}
          sm={6}
          required
          name="version"
          label="Versão"
          type="text"
          value={dataVersion.version}
          onChange={(e) => setDataVersion({...dataVersion, version: e.target.value})}
          errors={errors}
        />
      
        <InputDate
          xs={12}
          sm={4}
          required
          name="webLimitDate"
          value={dataVersion.webLimitDate}
          label="Data Limite Web"
          onChange={(date) => setDataVersion({...dataVersion, webLimitDate: date})}
          errors={errors}
        />

        <InputDate
          xs={12}
          sm={4}
          required
          name="androidLimitDate"
          value={dataVersion.androidLimitDate}
          label="Data Limite Android"
          onChange={(date) => setDataVersion({...dataVersion, androidLimitDate: date})}
          errors={errors}
        />

        <InputDate
          xs={12}
          sm={4}
          required
          name="iosLimitDate"
          value={dataVersion.iosLimitDate}
          label="Data Limite IOS"
          onChange={(date) => setDataVersion({...dataVersion, iosLimitDate: date})}
          errors={errors}
        />
      
        <InputText
          xs={12}
          name="notes"
          label="Notas"
          type="text"
          placeholder="Notas"
          value={dataVersion.notes}
          onChange={(e) => setDataVersion({...dataVersion, notes: e.target.value})}
          errors={errors}
        />
      </GridContainer>
      
      <ButtonContainer>
        <Button type="submit" onClick={handleSubmit}>Salvar</Button>
      </ButtonContainer>
    </ContentPanel>
  )
}
