import api from '../../api'

export async function getAllows() {
  return api.get('product_rv/allows');
}

export async function getReferenceValues() {
  return api.get('product_rv');
}

export async function putReferenceValues(data) {
  return api.put('product_rv', data);
}
