import produce from 'immer';

const INITIAL_STATE = {
  requests: 0,
  loading: false
};

export default function loading(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@loading/START_REQUEST':
      return produce(state, draft => {
        draft.requests = draft.requests + 1;
        draft.loading = (draft.requests > 0);
      })
    case '@loading/FINISH_REQUEST':
      return produce(state, draft => {
        draft.requests = draft.requests - 1;
        draft.loading = (draft.requests > 0);
      })
    default: 
      return state;
  }
}