//external
import React, { useEffect, useState, Fragment, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  Grid, 
  MenuItem
} from '@material-ui/core';
//internal
import ContentPanel from 'components/ContentPanel';
import GridContainer from 'components/v2/grid';
import InputMultiline from 'components/v2/inputMultiline';
import InputSelect from 'components/v2/inputSelect';
import InputSelectChildren from 'components/v2/inputSelectChildren';
import InputText from 'components/v2/inputText';
import { getLabelTypes, getStrings } from 'services/rtpi/intentions/service';
import { getRtpiFull, putRtpi } from 'services/rtpi/rtpi/service';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import { setMessage } from 'store/modules/message/action';
import { formatDateTime } from 'utils/formatUtil';
import { showConfirm } from 'components/AlertDialog';
import history from 'services/history';
import { sanitizeErrorUI } from 'utils/errorUtil';
import { rtpiPutSchema } from '../schemas';
import { validateForm } from 'components/Form/validate';
import { Warning } from 'routes/Route';
//style
import { Button, ButtonContainer } from 'styles/components';
import DataTableReact from 'components/v2/dataTableReact';

const CustomTitle = ({ row }) => (
	<div>
		{}
		<div 
      data-tag="allowRowEvents"
			style={{ overflow: 'hidden', whiteSpace: 'pre-wrap', textOverflow: 'ellipses' }}
    >
      {row.descriptionComponent}
    </div>
	</div>
);

export default function EditRtpi({match}) {
  const message = useSelector(state => state.message.data);
  const revision = match.params.revision;
  const protheusCode = match.params.protheusCode;
  const branch = match.params.branch;
  const dispatch = useDispatch();

  const columnsComponents = [
    {
      maxWidth: "20%",
      name: 'Código',
      selector: 'idComponent',
      sortable: true,
    },
    {
      // maxWidth: "59%",
      maxWidth: "80%",
      name: 'Descrição',
      selector: "descriptionComponent" ,
      sortable: true,
      cell: row => <CustomTitle row={row} />,
    },
  ];
  
  const columnsComponentsSubs = [
    {
      maxWidth: "20%",
      name: 'Código',
      selector: 'idComponent',
      sortable: true,
    },
    {
      maxWidth: "80%",
      name: 'Descrição',
      selector: 'descriptionComponent',
      sortable: true,
    },
  ];
  
  const columnsNutrients = [
    {
      maxWidth: "20%",
      name: 'Código',
      selector: 'idNutrient',
      sortable: true,
    },
    {
      maxWidth: "40%",
      name: 'Descrição',
      selector: 'descriptionNutrient',
      sortable: true,
    },
    {
      maxWidth: "20%",
      name: 'Quantidade',
      selector: 'quantityNutrient',
      sortable: false,
    },
    {
      maxWidth: "20%",
      name: 'Unidade',
      selector: 'unity',
      sortable: false,
    },
  ];

  //tabela materia prima 
  const [rtpiStructures, setRtpiStructures] = useState([]);
  
  //tabela substitutive
  const [rtpiStructureSubstitutives, setRtpiStructureSubstitutives] = useState([]);

  //tabela nutriente
  const [rtpiWarrantyLevels, setRtpiWarrantyLevels] = useState([]);
  
  //dados / inputs 
  const [isLabelTypes, setIsLabelType] = useState([]);
  const [qtyFields, setQtyFields] = useState("");
  const [formLabels, setFormLabels] = useState({});
  const [errors, setErrors] = useState([]);
  const [dataRtpiEdit, setDataRtpiEdit] = useState({
    LabelType: '',
    protheusCode: '',
    productRestrictionRestrictiveText: "",
    RtpiBranch: {
      activity: "",
      address: "",
      cifNumber: "",
      city: "",
      cnpj: "",
      establishmentName: "",
      ie: "",
      neighborhood: "",
      phone: "",
      zip: "",
    }
  });
  const [strings, setStrings] = useState({
    productClassification: [],
    productPhysicalForm: [],
    productPackageType: [],
    productPackageForm: [],
  });

  const { setNotWarning } = useContext(Warning);

  useEffect(() => {
    if (branch && protheusCode && revision) {
      dispatch(startRequest());
      getLabelTypes().then(data => {
        setIsLabelType(data.data);
      })
      getStrings().then(data => {
        const formData = data.data;
        setStrings({...formData});
      }); 
      getRtpiFull(branch, protheusCode, revision).then(data => {
        const formData = data.data;
        formData.createdAt = formatDateTime(new Date(formData.createdAt));
        setDataRtpiEdit({...formData});
        setRtpiStructures(formData.RtpiStructures);
        setRtpiStructureSubstitutives(formData.RtpiStructureSubstitutives);
        setRtpiWarrantyLevels(formData.RtpiWarrantyLevels);
        setQtyFields(formData.LabelType.diluitionQty);
        normalizeRtpiDiluitions(formData.RtpiDiluitions);
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, branch, protheusCode, revision]);

  const scrollToTop = () => {
    const elContent = document.getElementById("content");
    elContent.scrollTo(0,0);
  };

  const updateField = (e) => {
    setDataRtpiEdit({...dataRtpiEdit, [e.target.name]: e.target.value});
  };

  const updateFieldLabels = (e) => {
    setFormLabels({...formLabels, [e.target.name]: e.target.value});
  };

  function normalizeRtpiDiluitions(diluitions) {
    if (diluitions && Array.isArray(diluitions)) {
      const diluition = {}
      for (var idx in diluitions) {
        diluition[`percent${idx}`] = diluitions[idx].percent;
        diluition[`text${idx}`] = diluitions[idx].text;
      }

      setFormLabels(diluition);
    }
  } 

  function onChandeLabelType(value) {
    const labelObject = isLabelTypes.find(item => item.id === value)
    setDataRtpiEdit({...dataRtpiEdit, LabelType: labelObject});
    setQtyFields(labelObject.diluitionQty);
  }

  async function handleSubmit() {
    setNotWarning();
    
    if (!await showConfirm('A alteração da RTPI irá gerar uma nova versão desta mesma revisão, sendo assim necessário efetuar novamente a impressão e o arquivamento da mesma, deseja realmente fazer a alteração?')) {
      return;
    }

    const objTemp = JSON.parse(JSON.stringify(dataRtpiEdit));

    scrollToTop();
    const errorsList = await validateForm(objTemp, rtpiPutSchema);
    if (errorsList.length > 0) {
      setErrors(errorsList);
      return;
    }
    const diluition = [];

    if (dataRtpiEdit.LabelType.hasDiluition) {   
      [...Array(qtyFields)].forEach((element, index) => {
        diluition.push({percent: formLabels[`percent${index}`], text: formLabels[`text${index}`]});  
      });
    }

    const validateDiluition = diluition.findIndex(
      item => item.percent === "" || item.percent === undefined || item.text === "" || item.text === undefined
    )   

    if (validateDiluition > -1) {
      dispatch(setMessage({error: true, text: 'Porcentagem ou texto inválidos'}));
      return
    }

    const regex = /^(0|[1-9][0-9]?|100)$/;

    const validatePercent = diluition.findIndex(item => !regex.test(item.percent))

    if (validatePercent > -1) {
      dispatch(setMessage({error: true, text: 'Porcentagem somente números entre 0 e 100'}));
      return
    }

    const objCopy = JSON.parse(JSON.stringify(dataRtpiEdit));
    delete objCopy.ReferenceValues;
    delete objCopy.productConversion;
    delete objCopy.id;
    delete objCopy.allowPrintLabel;
    delete objCopy.createdAt;
    delete objCopy.RtpiBranch;
    objCopy.RtpiDiluitions = diluition;
    objCopy.RtpiStructureSubstitutives = rtpiStructureSubstitutives;
    objCopy.RtpiStructures = rtpiStructures;
    objCopy.RtpiWarrantyLevels = rtpiWarrantyLevels;
    objCopy.protheusBranch = branch;
    objCopy.protheusRevision = revision;

    dispatch(startRequest());
    putRtpi(branch, protheusCode, revision, objCopy).then(data => {
      history.push('/premixlabel/rtpi/rtpi');
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });    
  }

  async function handleBack () {
    history.goBack();
  }

  return(
    <ContentPanel title='Editar RTPI' message={message}>
      <GridContainer>
        <InputText
          xs={12}
          sm={6}
          name="revision"
          label="Revisão"
          type="text"
          value={revision}
          disabled={true}
        />
        <Grid item xs={12} sm={6}></Grid>

        <InputText
          xs={12}
          sm={3}
          name="branch"
          label="Filial"
          type="text"
          value={branch}
          disabled={true}
        />
        <InputText
          xs={12}
          sm={3}
          name="protheusCode"
          label="Código"
          type="text"
          value={dataRtpiEdit.protheusCode}
          disabled={true}
        />
        <InputText
          xs={12}
          sm={3}
          name="productDescription"
          label="Descrição"
          type="text"
          value={dataRtpiEdit.productDescription}
          disabled={true}
        />
        <InputText
          xs={12}
          sm={3}
          name="createdAt"
          label="Data"
          type="text"
          value={dataRtpiEdit.createdAt}
          disabled={true}
        />
        
        <InputText
          xs={12}
          sm={7}
          name="establishmentName"
          label="Nome do estabelecimento"
          type="text"
          value={dataRtpiEdit.RtpiBranch.establishmentName}
          disabled={true}
        />
        <InputText
          xs={12}
          sm={2}
          name="cifNumber"
          label="SIF"
          type="text"
          value={dataRtpiEdit.RtpiBranch.cifNumber}
          disabled={true}
        />
        <InputText
          xs={12}
          sm={3}
          name="cnpj"
          label="CNPJ"
          type="text"
          value={dataRtpiEdit.RtpiBranch.cnpj}
          disabled={true}
        />
        <InputText
          xs={12}
          sm={5}
          name="address"
          label="Endereço"
          type="text"
          value={dataRtpiEdit.RtpiBranch.address}
          disabled={true}
        />
        <InputText
          xs={12}
          sm={4}
          name="neighborhood"
          label="Bairro"
          type="text"
          value={dataRtpiEdit.RtpiBranch.neighborhood}
          disabled={true}
        />
        <InputText
          xs={12}
          sm={3}
          name="ie"
          label="IE"
          type="text"
          value={dataRtpiEdit.RtpiBranch.ie}
          disabled={true}
        />
        <InputText
          xs={12}
          sm={3}
          name="city"
          label="Minicípio/UF"
          type="text"
          value={dataRtpiEdit.RtpiBranch.city}
          disabled={true}
        />
        <InputText
          xs={12}
          sm={3}
          name="zip"
          label="CEP"
          type="text"
          value={dataRtpiEdit.RtpiBranch.zip}
          disabled={true}
        />
        <InputText
          xs={12}
          sm={3}
          name="phone"
          label="Telefone"
          type="text"
          value={dataRtpiEdit.RtpiBranch.phone}
          disabled={true}
        />
        <InputText
          xs={12}
          sm={3}
          name="activity"
          label="Atividade"
          type="text"
          value={dataRtpiEdit.RtpiBranch.activity}
          disabled={true}
        />
        <InputMultiline
          xs={12}
          sm={6}          
          name="conservationCondition"
          label="Condição de conservação"
          type="text"
          value={dataRtpiEdit.conservationCondition}
          onChange={updateField}
          errors={errors}
        />
        <InputMultiline
          xs={12}
          sm={6}
          name="productEnrichment"
          label="Enriquecimento do produto"
          type="text"
          value={dataRtpiEdit.productEnrichment}
          onChange={updateField}
          errors={errors}
        />
        <InputMultiline
          xs={12}
          sm={6}
          required
          name="productIndication"
          label="Indicação"
          type="text"
          value={dataRtpiEdit.productIndication}
          onChange={updateField}
          errors={errors}
        />
        <InputMultiline
          xs={12}
          sm={6}
          required
          name="productUseMode"
          label="Modo de uso"
          type="text"
          value={dataRtpiEdit.productUseMode}
          onChange={updateField}
          errors={errors}
        />
        <InputMultiline
          xs={12}
          sm={12}
          name="finishedProductDescription"
          label="Descrição do produto acabado"
          type="text"
          value={dataRtpiEdit.finishedProductDescription}
          onChange={updateField}
          errors={errors}
        />

        <InputText
          xs={6}
          sm={6}
          required
          name="consumptionMin"
          label="Consumo Minimo (gr/Cab/Dia)"
          type="number"
          value={dataRtpiEdit.consumptionMin}
          onChange={updateField}
          errors={errors}
        />
        <InputText
          xs={6}
          sm={6}
          required
          name="consumptionMax"
          label="Consumo Maximo (gr/Cab/Dia)"
          type="number"
          value={dataRtpiEdit.consumptionMax}
          onChange={updateField}
          errors={errors}
        />

        <InputSelect
          xs={12}
          sm={4}
          required
          name="productClassification"
          label="Classificação"
          value={dataRtpiEdit.productClassification}
          onChange={updateField}
          defaultValue={null}
          options={strings.productClassification}
          errors={errors}
        />
        <InputSelect
          xs={12}
          sm={4}
          required
          name="productPhysicalForm"
          label="Forma física"
          value={dataRtpiEdit.productPhysicalForm}
          onChange={updateField}
          defaultValue={null}
          options={strings.productPhysicalForm}
          errors={errors}
        />
        <InputSelect
          xs={12}
          sm={4}
          required
          name="productPackageType"
          label="Tipo de embalagem"
          value={dataRtpiEdit.productPackageType}
          onChange={updateField}
          defaultValue={null}
          options={strings.productPackageType}
          errors={errors}
        />

        <InputSelect
          xs={12}
          sm={6}
          required
          name="productPackageForm"
          label="Forma da embalagem"
          value={dataRtpiEdit.productPackageForm}
          onChange={updateField}
          defaultValue={null}
          options={strings.productPackageForm}
          errors={errors}
        />
        <InputText
          xs={6}
          sm={3}
          required
          name="revision"
          label="Revisão"
          type="text"
          onChange={updateField}
          value={dataRtpiEdit?.revision?.toString() || ''}
          errors={errors}
          disabled={true}
        />
        <InputText
          xs={6}
          sm={3}
          required
          name="productExpirationDate"
          label="Validade (meses)"
          type="text"
          onChange={updateField}
          value={dataRtpiEdit?.productExpirationDate?.toString() || ''}
          errors={errors}
        />
        <InputText
          xs={12}
          sm={12}
          required
          name="productRestrictionRestrictiveText"
          label="Restrição"
          type="text"
          value={dataRtpiEdit?.productRestrictionRestrictiveText || ''}
          errors={errors}
          disabled={true}
        />
        <InputSelectChildren
          xs={12}
          sm={12}
          required
          name="LabelType"
          label="Tipo de etiqueta"
          value={dataRtpiEdit.LabelType.id}
          onChange={(e) => onChandeLabelType(e.target.value)}
          defaultValue={null}
          errors={errors}
        >
          {isLabelTypes.map(LabelType => (
            <MenuItem key={LabelType.id} value={LabelType.id}>{LabelType.description}</MenuItem>
          ))}
        </InputSelectChildren>

        {[...Array(qtyFields)].map((x,i) => 
          <Fragment key={i}>
            <InputText
              xs={6}
              sm={2}
              name={`percent${i}`}
              label="Porcentagem"
              maxLength={100}
              minLength={0}
              type="number" 
              value={formLabels[`percent${i}`]}
              errors={errors}
              onChange={updateFieldLabels}
              
            />
            <InputText
              xs={6}
              sm={10}
              name={`text${i}`}
              label="Texto"
              type="text"
              value={formLabels[`text${i}`]}
              errors={errors}
              onChange={updateFieldLabels}
            />
          </Fragment>  
        )} 

        <Grid item xs={12} sm={6}>
          <ContentPanel title="Matéria Prima">
            <DataTableReact
              noHeader
              columns={columnsComponents}
              data={rtpiStructures}
            />
          </ContentPanel>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ContentPanel title="Substitutivos">
            <DataTableReact
              noHeader
              columns={columnsComponentsSubs}
              data={rtpiStructureSubstitutives}
            />
          </ContentPanel>
        </Grid>
        <Grid item xs={12}>
          <ContentPanel title="Níveis de Garantia">
            <DataTableReact
              noHeader
              columns={columnsNutrients}
              data={rtpiWarrantyLevels}
            />
          </ContentPanel>
        </Grid>
      </GridContainer>

      <ButtonContainer>
        <Button type="submit" onClick={handleSubmit}>Salvar</Button>
        <Button left={true} cancel={true} onClick={handleBack}>Cancelar</Button>
      </ButtonContainer>
    </ContentPanel>
  )
}