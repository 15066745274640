//external
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//internal
import ContentPanel from 'components/ContentPanel';
import { getEmailTemplate, putEmailTemplate, postEmailTemplate } from 'services/emailTemplate/service';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import { setMessage } from 'store/modules/message/action';
import GridContainer from 'components/v2/grid';
import InputText from 'components/v2/inputText';
import Editor from 'components/Editor';
import InputMultiline from 'components/v2/inputMultiline';
import history from 'services/history';
import { emailTemplateSchema } from '../schemas';
import { validateForm } from 'components/Form/validate';
import { sanitizeErrorUI } from 'utils/errorUtil';
import DataTableReact from 'components/v2/dataTableReact';
import { Warning } from 'routes/Route';
//style
import { Button, ButtonContainer } from 'styles/components';

export default function EmailTemplateEdit({match}) {
  const emailTemplateId = match.params.id;
  const loading = useSelector(state => state.loading.loading);
  const isEdit = emailTemplateId ? true : false;
  const message = useSelector(state => state.message.data);
  const [bodyHtml, setBodyHtml] = useState(null);
  const [dataEmailTemplate, setDataEmailTemplate] = useState({});
  // const [nameTag, setNameTag] = useState("");
  // const [tagDescription, setTagDescription] = useState("");
  const [emailTemplateTags, setEmailTemplateTags] = useState([]);
  const [errors, setErrors] = useState([]);
  // const [tagsError, setTagsError] = useState([]);

  const { setNotWarning } = useContext(Warning);

  const dispatch = useDispatch();

  const columns = [
    {
      name: 'Tag',
      selector: 'tagName',
      sortable: true,
    },
    {
      name: 'Descrição',
      selector: 'description',
      sortable: true,
    },
    // {
    //   style: {justifyContent: "flex-end"},
    //   cell: row => 
    //   <>
    //     <div>
    //       <IconContainer>
    //         <TableAction 
    //         title={"Deletar"} 
    //         onClick={() => deleteTag(row)}>
    //           <DeleteIcon/>
    //         </TableAction>
    //       </IconContainer>
    //     </div>
    //   </>,
    // }
  ]

  useEffect(() => {
    if (emailTemplateId) {
      dispatch(startRequest());
      getEmailTemplate(emailTemplateId).then(data => {
        const formData = data.data
        setDataEmailTemplate({
          templateName: formData.templateName,
          subject: formData.subject,
          bodyText: formData.bodyText,
        });
        setBodyHtml(formData.bodyHtml);
        setEmailTemplateTags(formData.EmailTemplateTags);
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, emailTemplateId]);

  async function handleSubmit () {
    setNotWarning();
    scrollToTop()
    if(bodyHtml === "<p><br></p>") {
      setBodyHtml("");
    }

    if (emailTemplateTags.length === 0) {
      dispatch(setMessage({error: true, text: "É necessário pelo menos 1 tag e descrição "}));
      return
    }

    const errorsList = await validateForm(dataEmailTemplate, emailTemplateSchema);
    if (errorsList.length > 0) {
      setErrors(errorsList);
      return
    }

    dataEmailTemplate.bodyHtml = bodyHtml || '';
    dataEmailTemplate.EmailTemplateTags = emailTemplateTags || [];

    if (emailTemplateId) {
      dispatch(startRequest());
      putEmailTemplate(emailTemplateId, dataEmailTemplate).then(data => {
        history.push('/email_template')
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    } else {
      dispatch(startRequest());
      postEmailTemplate({...dataEmailTemplate, EmailTemplateTags: emailTemplateTags}).then(data => {
        history.push('/email_template')
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
      
  }

  const scrollToTop = () => {
    const elContent = document.getElementById("content");
    elContent.scrollTo(0,0);
  };

  // function addAppVersionHandler() {
  //   setTagsError([]);
  //   if (nameTag !== "{{}}" && tagDescription !== "" ) {
  //     const firstkeys = nameTag.substring(0,2)
  //     const lastkeys = nameTag.substring(nameTag.length -2)
  //     if (firstkeys === "{{" && lastkeys === "}}") {
  //       setEmailTemplateTags([...emailTemplateTags, {
  //         tagName: nameTag,
  //         description: tagDescription
  //       }])
  //       setNameTag("{{}}");
  //       setTagDescription("");
  //     }else {
  //       setTagsError([...tagsError, {field: "tagName", message: "{{}} Obrigatório"}])
  //     }
  //   }else {

  //     if (tagDescription === "") {
  //       setTagsError([...tagsError, {field: "description", message: "Campo obrigatório"}])
  //     }

  //     if (nameTag === "{{}}"  || nameTag === "") {
  //       setTagsError((tagsError) => [...tagsError, {field: "tagName", message: "Campo obrigatório"}])
  //     }
  //     return
  //   }
  // }

  // function deleteTag(tag) {
  //   const tags = emailTemplateTags.filter(item => item.tagName !== tag.tagName)
  //   setEmailTemplateTags(tags)
  // }

  // function defaultTag() {
  //   if (nameTag === "") {
  //     setNameTag("{{}}");
  //   }
  // }

  return (
    <ContentPanel title='Modelo de E-mail' message={message}>
      <GridContainer>
        <InputText
          xs={12}
          sm={6}
          required
          name="templateName"
          label="Nome"
          type="text"
          value={dataEmailTemplate.templateName}
          onChange={(e) => setDataEmailTemplate({...dataEmailTemplate, templateName: e.target.value})}
          errors={errors}
          disabled={isEdit}
        />
        <InputText
          xs={12}
          sm={6}
          required
          name="subject"
          label="Assunto"
          type="text"
          value={dataEmailTemplate.subject}
          onChange={(e) => setDataEmailTemplate({...dataEmailTemplate, subject: e.target.value})}
          errors={errors}
        />
      </GridContainer>      
      
      {!loading && (
        <Editor 
          name="bodyHtml" 
          markup={bodyHtml} 
          label="Corpo do e-mail HTML" 
          onChange={(value) => setBodyHtml(value)}
          showLink
        />        
      )}
      
      <br/>

      <GridContainer>
        <InputMultiline
          xs={12}
          sm={12}
          name="bodyText"
          label="Corpo do e-mail texto"
          type="text"
          value={dataEmailTemplate.bodyText}
          onChange={(e) => setDataEmailTemplate({...dataEmailTemplate, bodyText: e.target.value})}
          errors={errors}
        />

        {/* <InputText
          xs={12}
          sm={4}
          required
          name="tagName"
          placeholder={"{{exemplo}}"}
          label="Nome da Tag"
          type="text"
          onFocus={defaultTag}
          value={nameTag}
          onChange={(e) => setNameTag(e.target.value)}
          errors={tagsError}
        />
        <InputText
          xs={12}
          sm={7}
          required
          name="description"
          label="Descrição"
          type="text"
          value={tagDescription}
          onChange={(e) => setTagDescription(e.target.value)}
          errors={tagsError}
        />
        <TableAction 
        xs={12}
        sm={1}
        title={"Adicionar"} 
        onClick={addAppVersionHandler}>
          <AddIcon/>
        </TableAction> */}
      </GridContainer>
      
      <DataTableReact
      columns={columns}
      data={emailTemplateTags}
      />
      <br/>

      <ButtonContainer>
        <Button type="submit" onClick={handleSubmit}>Salvar</Button>
      </ButtonContainer>
    </ContentPanel>
  )
}