import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { loginSchema } from './schemas'
import logo from '../../assets/images/logo-cinza.png'
import { Container, Content, ForgotText } from './styled'
import { Form } from '@unform/web'
// import { Link } from 'react-router-dom'
import { InputAdornment, IconButton, Button } from '@material-ui/core'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined'
import InputText from 'components/v2/inputText';
import GridContainer from 'components/v2/grid';

import { validateForm } from '../../components/Form/validate'

import Loading from '../../components/Loading'
import Message from '../../components/Message'

import { loginRequest } from '../../store/modules/auth/actions'

export default function Login({location}) {
  const message = useSelector(state => state.message.data);
  const loading = useSelector(state => state.loading.loading);

  const [errors, setErrors] = useState([]);
  const [viewPass, setViewPass] = useState(false);
  const [loginState, setLoginstate] = useState({
    email: '',
    password: '',
  });

  const formRef = useRef(null);

  const dispatch = useDispatch();

  async function handleSubmit () {
    const errorsList = await validateForm(loginState, loginSchema);
    if (errorsList.length > 0) {
      setErrors(errorsList);
      return;
    }

    const {email, password} = loginState;

    dispatch(loginRequest(email, password));    
  };

  return (
    <>
      {loading && <Loading />}
      <Container>
        <Content>
          <img src={logo} alt="Premix" />
          <Message message={message} />
          <br />
          <GridContainer>
            <InputText
              xs={12}
              sm={12}
              required
              name="email"
              label="Seu e-mail"      
              placeholder="email@exemplo.com.br"        
              type="email"
              value={loginState.email}
              onChange={(e) => setLoginstate({...loginState, email: e.target.value})}
              errors={errors}
              hasWarning={false}              
            />

            <InputText
              xs={12}
              sm={12}
              required
              name="password"
              label="Nova senha"
              placeholder="xxxxxx"              
              type={viewPass ? "text" : "password"}
              value={loginState.password}    
              onChange={(e) => setLoginstate({...loginState, password: e.target.value})}  
              errors={errors}
              hasWarning={false}
              InputProps={{
                endAdornment: 
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setViewPass(!viewPass)}
                          >
                            {viewPass ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon  />}
                        </IconButton>
                    </InputAdornment>
              }}
            /> 
          </GridContainer>
          <br />
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Button variant="contained" color="primary" onClick={handleSubmit} >
              {loading ? 'Carregando...' : 'Entrar'}
            </Button>            
            {/* <Link to="/signup">Inscreva-se</Link> */}
          </Form>
          <br />
          <ForgotText to="/forgotpassword">Esqueceu sua senha?</ForgotText>
        </Content>
      </Container>
    </>
  )
}