import api from '../../api'

export async function getAllows() {
  return api.get('rtpi_fixed_fields/allows');
}

export async function getFixedFields() {
  return api.get('rtpi_fixed_fields');
}

export async function putFixedFields(data) {
  return api.put('rtpi_fixed_fields', data);
}
