import api from '../api';

export async function getAllows() {
  return api.get('schedule_todo/allows');
}
  
export async function getScheduleTodosRc(id_salesman, query, numOfRegisters = 10, page = 1, order) {
  const params = {
    id_salesman,
    order: order ? order : 'initialDate desc',
  }
  if (query) {
    params.query = query.trim(); 
  }

  return api.get('schedule_todo_rc', {
    params,
    headers: {
      registerperpage: numOfRegisters,
      page
    }
  });
}

export async function getScheduleTodoRc(id) {
  return api.get(`schedule_todo_rc/${id}`);
}

export async function getSchedulesTodoRcChecklist(id) {
  return api.get(`schedule_todo_rc/full/${id}`);
}

export async function getScheduleTodoRcUsers(username) {
  const params = {};

  if (username) {
    params.query = username; 
  }

  return api.get('schedule_todo_rc/users', {
    params,
    headers: {
      registerperpage: 6,
      page: 1
    }
  });
}

export async function getScheduleTypesTodoRc() {
  return api.get('schedule_todo_rc/scheduleTypes');
}

export async function getScheduleTypesIdTodoRc(id) {
  return api.get(`schedule_todo_rc/scheduleTypes/${id}`);
}

export async function getTodoRcCustomers(nome, numOfRegisters = 6) {
  return api.get('schedule_todo_rc/customers', {
    params: {
      nome,
      order: 'nome'
    },
    headers: {
      registerperpage: numOfRegisters,
      page: 1
    }
  });
} 

export async function getTodoRcCustomersId(id, store) {
  return api.get(`schedule_todo_rc/customers/${id}/${store}`);
}

export async function getTodoRcProspect(customerName, numOfRegisters = 6) {
  return api.get('schedule_todo_rc/prospect', {
    params: {
      customerName,
      order: 'customerName'
    },
    headers: {
      registerperpage: numOfRegisters,
      page: 1
    }
  });
}

export async function getTodoRcProspectId(id) {
  return api.get(`schedule_todo_rc/prospect/${id}`);
}

export async function postScheduleTodoRc(schedule_todo_rc, data) {
  return api.post(`schedule_todo_rc/`, schedule_todo_rc, data);
}

export async function putScheduleTodoRc(id, data) {
  return api.put(`schedule_todo_rc/${id}`, data);
}

export async function putStart(id, data) {
  return api.put(`schedule_todo_rc/checkin/${id}`, data);
}

export async function putFinish(id, data) {
  return api.put(`schedule_todo_rc/checkout/${id}`, data);
}

export async function putConfirm(id, data) {
  return api.put(`schedule_todo_rc/confirm/${id}`, data);
}

export async function putCancel(id, data) {
  return api.put(`schedule_todo_rc/cancel/${id}`, data);
}

export async function putMoveEventTodoRc(id, data) {
  return api.put(`schedule_todo_rc/time/${id}`, data);
}

export async function deleteScheduleTodoRc(id) {
  return api.delete(`schedule_todo_rc/${id}`);
}

export async function getScheduleReport(id) {
  return api.get(`schedule_todo_rc/report/${id}`, {responseType: 'blob'});
}