import styled from 'styled-components';
import * as cor from '../../styles/colors'

export const ContentWapper = styled.div`
  border-radius: 5px;
  border: solid ${cor.corBorda} 1px;
  background-color: ${cor.corFundoPainel};
  padding: 10px;
  box-shadow: 0px 0px 6px ${cor.corBorda};
`

export const Title = styled.div`
  font-size: 22px;
  color: ${cor.corTituloPainel};
`

export const Line = styled.div`
  height: 1.5px;
  margin-top: 13px;
  margin-bottom: 28px;
  background-color: ${cor.corLinhaPainel};
`