import { format } from 'date-fns';

export const formatDate = (strDate) => {
  if (!strDate) {
    return strDate;
  }

  return format(new Date(strDate), 'dd/MM/yyyy', { timeZone: 'America/Sao_Paulo' });
}

export const formatDateTime = (strDate) => {
  if (!strDate) {
    return strDate;
  }
  
  return format(new Date(strDate), 'dd/MM/yyyy HH:mm', { timeZone: 'America/Sao_Paulo' });
}

export function formatStatusRtpiIntention(status) {
  switch (status) {
    case 'PENDING':
      return 'Pendente';
    case 'APPROVED':
      return 'Aprovada';
    case 'REPROVED':
      return 'Reprovada';
    default:
      return '';
  }
}

export function formatStatusDocument(status) {
  switch (status) {
    case 'PENDING':
      return 'Pendente';
    case 'APPROVED':
      return 'Aprovado';
    case 'REPROVED':
      return 'Reprovado';
    default:
      return '';
  }
}

export const groupBy = (array, f) => {
  var groups = {};

  array.forEach((o) => {
      var group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
  });

  return Object.keys(groups).map(function(group) {
      return groups[group];
  });
};

export function ellipsis(inputText, size) {
  var text = inputText;

  if (text && size && size > 3) {
    if (text.length > size) {
      text = text.substr(0, size - 3) + '...';
    }
  }

  return text;
}

export function formatNumber(inputNumber, decimalPlaces, hideZero, textZero) {
  let value = inputNumber || 0;
  if (!value.toLocaleString) {
    value = 0;
  }
  if ((value === 0) && hideZero) {
    return textZero || '';
  }

  return value.toLocaleString('pt-br', {maximumFractionDigits: decimalPlaces || 1, minimumFractionDigits: decimalPlaces || 1})
}

export function formatCurrency(inputNumber) {
  let value = inputNumber || 0;
  if (!value.toLocaleString) {
    value = 0;
  }

  return value.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})
}

export function getMonthName(month) {
  switch (month) {
    case 1:
    case '1':
    case '01':
      return 'Janeiro';
    case 2:
    case '2':
    case '02':
      return 'Fevereiro';
    case 3:
    case '3':
    case '03':
      return 'Março';
    case 4:
    case '4':
    case '04':
      return 'Abril';
    case 5:
    case '5':
    case '05':
      return 'Maio';
    case 6:
    case '6':
    case '06':
      return 'Junho';
    case 7:
    case '7':
    case '07':
      return 'Julho';
    case 8:
    case '8':
    case '08':
      return 'Agosto';
    case 9:
    case '9':
    case '09':
      return 'Setembro';
    case 10:
    case '10':
      return 'Outubro';
    case 11:
    case '11':
      return 'Novembro';
    case 12:
    case '12':
      return 'Dezembro';
    default:
      return '';
  }
};

export const monthList = [
  { value: 1 , label: 'Janeiro'  },
  { value: 2 , label: 'Fevereiro'},
  { value: 3 , label: 'Março'    },
  { value: 4 , label: 'Abril'    },
  { value: 5 , label: 'Maio'     },
  { value: 6 , label: 'Junho'    },
  { value: 7 , label: 'Julho'    },
  { value: 8 , label: 'Agosto'   },
  { value: 9 , label: 'Setembro' },
  { value: 10, label: 'Outubro'  },
  { value: 11, label: 'Novembro' },
  { value: 12, label: 'Dezembro' },
];

export const yearList = [2020, 2021, 2022];
