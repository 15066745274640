//external
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//internal
import { deleteProspect, getProspect } from 'services/prospect/service';
import history from 'services/history';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import ContentPanel from 'components/ContentPanel';
import { setMessage } from 'store/modules/message/action';
import { sanitizeErrorUI } from 'utils/errorUtil';
//style
import { Button, ButtonContainer } from 'styles/components';
import { Attr, AttrName } from 'pages/appversion/delete/styled';

export default function ProspectDelete({match}) {
  const prospectId = match.params.id;
  const message = useSelector(state => state.message.data);
  const [prospect, setProspect] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    if (prospectId) {
      dispatch(startRequest());
      getProspect(prospectId).then(data => {
        setProspect(data.data);
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, prospectId]);

  async function handleDelete () {
    dispatch(startRequest());
    deleteProspect(prospectId).then(data => {
      history.push('/schedule/prospect')
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }

  async function handleBack () {
    history.goBack();
  }

  return (
    <>
      <ContentPanel title="Deseja excluir este prospect?" message={message}>
        {prospect && <>
          <AttrName>Nome:</AttrName>
          <Attr>{prospect.customerName}</Attr>
          <AttrName>Fazenda:</AttrName>
          <Attr>{prospect.farmName}</Attr>
          <ButtonContainer>
            <br />
            <Button left={true} cancel={true} onClick={handleBack}>
              Voltar
            </Button>
            <Button onClick={handleDelete}>
              Excluir
            </Button>
          </ButtonContainer>
        </>}
      </ContentPanel>
    </>
  )
}