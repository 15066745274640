import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../../assets/images/logo-cinza.png'
import { Container, Content, ContainerButton } from './styled'
import { Form } from '@unform/web'
import Loading from '../../../components/Loading'
import Message from '../../../components/Message'
import { finishRequest, startRequest } from 'store/modules/loading/actions';
import { flagPrintRequest } from 'services/rtpi/rtpi/service';
import { setMessage } from 'store/modules/message/action';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';

export default function FlagPrint({match}) {
  const uuid = match.params.uuid;
  const message = useSelector(state => state.message.data);
  const loading = useSelector(state => state.loading.loading);
  const [check, setCheck] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (!error && success) {
      window.location.replace('/');
    } else if (check) {
      printRequest();
    } else {
      dispatch(setMessage({error: true, text: 'Confirme a impressão da RTPI.'}));
    }    
  };

  const printRequest = () => {
    if (uuid) {
      dispatch(startRequest());
      flagPrintRequest(uuid).then((result) => {
        dispatch(setMessage({success: true, text: 'RTPI marcada como impressa.'}));
        setError(false);
        setSuccess(true);
      }).catch((err) => {
        dispatch(setMessage({error: true, text: err.response.data.message || err.message}));
        setError(true);
        setSuccess(false);
      }).finally(() => {
        dispatch(finishRequest())
      })
    }
  }

  const handleCheckChange = (value) => {
    setCheck(!check);
  };
  
  return (
    <>
      {loading && <Loading />}
      <Container>
        <Content>
          <img src={logo} alt="Premix" />
          <Message message={message} />
          <Form onSubmit={handleSubmit}>

            <FormGroup row style={{justifyContent: 'center'}}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={check}
                    onChange={(event) => handleCheckChange(event)}
                    name="check"
                    color="primary"
                  />
                }
                label="Realizei a impressão da RTPI"
              />
            </FormGroup>
            
            <ContainerButton>
              {error && (
                <button type="submit">
                  Tentar Novamente
                </button>
              )} 
              {!error && !success && (
                <button type="submit">
                  Confirmar
                </button>                
              )} 
              {!error && success && (
                <button type="submit">
                  {loading ? 'Carregando...' : 'Ok'}
                </button>                
              )}
            </ContainerButton>
          </Form>
        </Content>
      </Container>
    </>
  )
}