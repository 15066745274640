import api from '../api';

export async function getAllows() {
  return api.get('prospect/allows');
}
  
export async function getProspects(query, numOfRegisters = 10, page = 1, order) {
  const params = {
    order: order ? order : 'customerName',
  }
  if (query) {
    params.query = query.trim(); 
  }

  return api.get('prospect', {
    params,
    headers: {
      registerperpage: numOfRegisters,
      page
    }
  });
}

export async function getProspect(id) {
  return api.get(`prospect/${id}`);
}

export async function getStatus() {
  return api.get('prospect/status');
}

export async function getLeadOrigin() {
  return api.get('prospect/leadorigin');
}

export async function getContact(query, numOfRegisters = 10) {
  return api.get('prospect/contact', {
    params: {
      query,
      order: 'name'
    },
    headers: {
      registerperpage: numOfRegisters,
      page: 1
    }
  });
}

export async function getContactQualifier() {
  return api.get('prospect/contactQualifier');
}

export async function getContactRole() {
  return api.get('prospect/contactRole');
}

export async function postProspect(prospect) {
  return api.post(`prospect/`, prospect);
}

export async function putProspect(id, data) {
  return api.put(`prospect/${id}`, data);
}

export async function deleteProspect(id) {
  return api.delete(`prospect/${id}`);
}