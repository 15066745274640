import React from 'react';
import { Container, Spinner } from './styled';

export default function() {
  return (
    <Container>
      <Spinner />
      <div>Carregando dados...</div>
    </Container>
  )
}