import * as Yup from 'yup';

export const familySchema = Yup.object().shape({
  protheusCode: Yup.string().required('Código é obrigatório.'),
  description: Yup.string().required('Descrição é obrigatória.'),
  expirationDate: Yup.string().required('Validade em meses é obrigatória.'),
  descriptionAlias: Yup.string()
});

export const sealImageList = [
  'FP',
  '20A'
];