//externall
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
// import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
// import DeleteIcon from '@material-ui/icons/Delete';
import { sanitizeErrorUI } from 'utils/errorUtil';
//internal
import { setMessage } from 'store/modules/message/action';
import { getAllows, getEmailTemplates } from 'services/emailTemplate/service';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import ContentPanel from 'components/ContentPanel';
import TableAction from 'components/v2/tableAction';
import DataTableReact from 'components/v2/dataTableReact';
import { FilterEmailTemplate } from '..';
//style
import { IconContainer } from '../styled';

export default function EmailTemplate() {
  const message = useSelector(state => state.message.data);
  const [allows, setAllows] = useState({});
  const [dataEmailTemplate, setDataEmailTemplate] = useState();
  const [reload, setReload] = useState(0);
  //pagination
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sort, setSort] = useState();

  const { filter, setFilter } = useContext(FilterEmailTemplate);

  const dispatch = useDispatch();

  const columns = [
    {
      name: 'Nome do Modelo',
      selector: 'templateName',
      sortable: true,
    },
    {
      name: 'Assunto',
      selector: 'subject',
      sortable: true,
    },
    {
      style: {justifyContent: "flex-end"},
      cell: row => 
        <div>
          <IconContainer>
            <TableAction 
            title={"Editar"} 
            disabled={!allows.put} 
            to={`/email_template/edit/${row.id}`}>
              <EditIcon/>
            </TableAction>
            {/* <TableAction 
            title={"Excluir"} 
            disabled={!allows.delete} 
            to={``/email_template/delete/${row.id}`}>
              <DeleteIcon/>
            </TableAction> */}
          </IconContainer>
        </div>
    }
  ];
  
  const actions = [
    /* <div style={{display: 'flex'}} key='addEmailTemplate'>
      <TableAction 
      title={"Adicionar"} 
      disabled={!allows.post} 
      to='/email_template/add'>
        <AddIcon/>
      </TableAction>
    </div> */

    <div key='refresh'>
      <TableAction 
      title={"Atualizar"} 
      onClick={refreshHandler}
      isLink={false}>
        <RefreshIcon/>
      </TableAction>
    </div>
  ];

  function refreshHandler() {
    setReload(reload + 1);
  }

  useEffect(() => {
    dispatch(startRequest());
    getEmailTemplates(perPage, filter.page, sort).then(data => {
      setTotalRows(parseInt(data.headers['recordcount']));
      setDataEmailTemplate(data.data);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
    dispatch(startRequest());
    getAllows().then(data => {
      setAllows(data.data)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }, [dispatch, reload, perPage, filter, sort]);

  const handlePerRowsChange = (perPage) => {
    setPerPage(perPage);
  };

  const handlePageChange = (page) => {
    setFilter({...filter, page});
  };

  const handleSort = (column, sortDirection) => {
    setSort(`${column.selector} ${sortDirection}`);
  };
  
  return (
    <ContentPanel title="Modelo de E-mail" message={message} actions={actions}>
      <DataTableReact
        noHeader
        columns={columns}
        data={dataEmailTemplate}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        sortServer
        onSort={handleSort}
        paginationDefaultPage={filter.page}
      />
    </ContentPanel>
  )
}