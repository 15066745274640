//external
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { MenuItem } from '@material-ui/core';
//internal
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import { getCustomer, putCustomer, getContactQualifier, getContact, getContactRole } from 'services/customer/service';
import ContentPanel from 'components/ContentPanel';
import { setMessage } from 'store/modules/message/action';
import { sanitizeErrorUI } from 'utils/errorUtil';
import GridContainer from 'components/v2/grid';
import InputText from 'components/v2/inputText';
import InputMultiline from 'components/v2/inputMultiline';
import TableAction from 'components/v2/tableAction';
import { useDebounce } from 'utils/uiUtils';
import SelectAutocomplete from 'components/v2/autocomplete';
import DataTableReact from 'components/v2/dataTableReact';
import history from 'services/history';
import Modal from 'components/v2/modal';
import Toggle from 'components/v2/toggle';
import InputSelectChildren from 'components/v2/inputSelectChildren';
import { customerSchema } from '../schemas';
import { validateForm } from 'components/Form/validate';
import { customerModalSchema } from '../schemasContactModal';
import { Warning } from 'routes/Route';
//style
import { Separator } from '../view/styled';
import { IconContainer } from 'pages/user/list/styled';
import { ButtonContainer, Button } from 'styles/components';

export default function CustomerEdit({match}) {
  const customerId = match.params.id;
  const customerStore = match.params.store;

  const message = useSelector(state => state.message.data);
  const [customer, setCustomer] = useState({
    CustomerContacts: "",
    classificacao: "",
    cnpj: "",
    cobranca: "",
    codigo: "",
    cpf: "",
    entrega: "",
    fazenda: "",
    filialEntrega: "",
    fisJur: "",
    gerente: "",
    gestor: "",
    iestadual: "",
    loja: "",
    nome: "",
    tipo: "",
    tipoComissao: "",
    vendedor: "",
  });
  const [errors, setErrors] = useState([]);
  //select Contacts
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [searchContacts, setSearchContacts] = useState("");
  const [optionsContacts, setOptionsContacts] = useState([]);
  const [itemSelectedContacts, setItemSelectedContacts] = useState(null);
  const [isLoadingContacts, setIsLoadingContacts] = useState(false);
  //modal contacts 
  const [isOpen, setIsOpen] = useState(false);
  const [formModal, setFormModal] = useState({
    name: "",
    contactRole: "",
    email: "",
    phone: "",
    contactQualifierId: "",
    active: false,
    notes: "",
    ContactQualifier: ""
  });
  const [isActive, setIsActive] = useState(false);
  const [modalContactQualifier, setModalContactQualifier] = useState([]);
  const [contactRoleOptions, setContactRoleOptions] = useState([]);

  const { setNotWarning } = useContext(Warning);

  const debouncedContacts = useDebounce(searchContacts);

  const dispatch = useDispatch();

  const loadContact = useCallback((searchValue) => {
    setIsLoadingContacts(true)
    getContact(searchValue).then(result => {
      const Contacts = result.data.map(element => {
        return { value: element, label: `${element.name}` }
      });
      setOptionsContacts(Contacts);
      setIsLoadingContacts(false)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      setIsLoadingContacts(false);
    });
  }, [dispatch]);

  useEffect(() => {
    if(loadContact) loadContact(debouncedContacts)
  },[debouncedContacts, loadContact]);
  
  useEffect(() => {
    dispatch(startRequest());
    getContactQualifier().then(data => {
      setModalContactQualifier(data.data);
    });
    getContactRole().then(data => {
      setContactRoleOptions(data.data);
    });
    getCustomer(customerId, customerStore).then(data => {
      const formData = data.data;
      setCustomer({
        classificacao: formData.classificacao,
        cnpj: formData.classificacao,
        cobranca: formData.cobranca,
        codigo: formData.codigo,
        cpf: formData.cpf,
        entrega: formData.entrega,
        fazenda: formData.fazenda,
        filialEntrega: formData.filialEntrega,
        fisJur: formData.fisJur,
        gerente: formData.gerente,
        gestor: formData.gestor,
        iestadual: formData.iestadual,
        loja: formData.loja,
        nome: formData.nome,
        tipo: formData.tipo,
        tipoComissao: formData.tipoComissao,
        vendedor: formData.vendedor,
      });
      setSelectedContacts(formData.CustomerContacts);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }, [dispatch, customerId, customerStore]);

  const columnsContact = [
    {
      maxWidth: "75%",
      name: 'Nome',
      selector: 'Contact.name',
      sortable: true,
    },
    {
      maxWidth: "75%",
      name: 'Função',
      selector: 'Contact.contactRole',
      sortable: true,
    },
    {
      maxWidth: "25%",
      style: {justifyContent: "flex-end"},
      cell: row => 
      <>
        <div>
          <IconContainer>
            <TableAction 
            title={"Excluir"} 
            onClick={() => handleRemoveProspectContact(row)}>
              <DeleteIcon/>
            </TableAction>
          </IconContainer>
        </div>
      </>,
    }
  ];

  const scrollToTop = () => {
    const elContent = document.getElementById("content");
    elContent.scrollTo(0,0);
  };

  function handleRemoveProspectContact(contact) {    
    var contactIndex = selectedContacts.indexOf(contact);
    selectedContacts.splice(contactIndex, 1);
    setSelectedContacts([...selectedContacts]);    
  }

  function handleCustomerContactsInputChange(data, event) {
    if (data) {
      const contactIndex = selectedContacts.findIndex(item => item.Contact.id === data?.value?.id);
      setItemSelectedContacts(data);
      if (contactIndex >= 0) return;

    setSelectedContacts([...selectedContacts, { Contact: {
      contactRole: data.value.contactRole,
      id: data.value.id,
      name: data.value.name
    }}]); 
    } else {
      setItemSelectedContacts(null);
      loadContact("");
    } 
  }

  function handleOpenModal() {
    setIsOpen(true);
  }

  function handleCloseModal() {
    setIsOpen(false);
  }

  function handleChangeModalQualifier(value) {
    const contactQualifier = modalContactQualifier.find(item => item.id === value)
    setFormModal({...formModal, ContactQualifier: contactQualifier});
  }

  function handleChangeContactRole(value) {
    const contactRoler = contactRoleOptions.find(item => item.id === value)
    setFormModal({...formModal, ContactRole: contactRoler});
  }

  async function handleSaveModal() {
    const objCopy = JSON.parse(JSON.stringify(formModal));
    delete objCopy.ContactQualifier;
    objCopy.contactRoleId = formModal.ContactRole?.id;
    objCopy.contactQualifierId = formModal.ContactQualifier.id;

    const errorsList = await validateForm(objCopy, customerModalSchema);
    if (errorsList.length > 0) {
      setErrors(errorsList);
      return
    }

    setSelectedContacts([...selectedContacts, {Contact: objCopy}]);
    setIsOpen(false);
  }

  async function handleSubmit () {
    setNotWarning();
    scrollToTop();
    const errorsList = await validateForm(customer, customerSchema);
    if (errorsList.length > 0) {
      setErrors(errorsList);
      return
    }

    const objCopy = JSON.parse(JSON.stringify(customer));
    delete objCopy.CustomerContacts;
    delete objCopy.classificacao;
    delete objCopy.cnpj;
    delete objCopy.cobranca;
    delete objCopy.cpf;
    delete objCopy.entrega;
    delete objCopy.fazenda;
    delete objCopy.filialEntrega;
    delete objCopy.fisJur;
    delete objCopy.gerente;
    delete objCopy.gestor;
    delete objCopy.iestadual;
    delete objCopy.nome;
    delete objCopy.tipo;
    delete objCopy.tipoComissao;
    delete objCopy.vendedor;
    delete objCopy.codigo;
    delete objCopy.loja;
    objCopy.id = customer.codigo;
    objCopy.store = customer.loja;
    const contactsList = selectedContacts.map(item => {
      if (item.Contact) {
        return item.Contact
      }
      return undefined
    })
    objCopy.contacts = contactsList;

    if (customerId && customerStore) {
      dispatch(startRequest());
      putCustomer(customerId, customerStore, objCopy).then(data => {
        history.push('/schedule/customer')
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    } 
  }

  return (
    <ContentPanel message={message} title="Cliente">
      {customer &&
        <>
          <Separator>Informações da conta</Separator>
          <GridContainer>
            <InputText
            xs={12}
            sm={6}
            name="nome"
            label="Razão Social"
            type="text"
            value={customer.nome}
            disabled={true}
            />

            <InputText
              xs={12}
              sm={6}
              name="fazenda"
              label="Fazenda"
              type="text"
              value={customer.fazenda}
              disabled={true}
            />

            <InputText
              xs={12}
              sm={4}
              name="codigo"
              label="Codigo cliente ERP"
              type="text"
              value={customer.codigo}
              disabled={true}
            />

            <InputText
              xs={12}
              sm={4}
              name="loja"
              label="Codigo fazenda ERP"
              type="text"
              value={customer.loja}
              disabled={true}
            />

            <InputText
              xs={12}
              sm={4}
              name="classificacao"
              label="Classificação"
              type="text"
              value={customer.classificacao}
              disabled={true}
            />
          </GridContainer>

          <Separator>Informações fiscais</Separator>
          <GridContainer>
            <InputText
            xs={12}
            sm={6}
            name="tipo"
            label="Tipo de cliente"
            type="text"
            value={customer.tipo}
            disabled={true}
            />

            <InputText
              xs={12}
              sm={6}
              name="fisJur"
              label="Fisica/Juridica"
              type="text"
              value={customer.fisJur}
              disabled={true}
            />

            <InputText
              xs={12}
              sm={4}
              name="cpf"
              label="CPF"
              type="text"
              value={customer.cpf}
              disabled={true}
            />

            <InputText
              xs={12}
              sm={4}
              name="cnpj"
              label="CNPJ"
              type="text"
              value={customer.cnpj}
              disabled={true}
            />

            <InputText
              xs={12}
              sm={4}
              name="iestadual"
              label="Inscrição estadual"
              type="text"
              value={customer.iestadual}
              disabled={true}
            />
          </GridContainer>
          
          {customer.cobranca && 
            <>
              <Separator>Informações de cobrança</Separator>
              <GridContainer>
                <InputText
                xs={12}
                sm={6}
                name="cobranca.contato"
                label="Responsável pela cobrança"
                type="text"
                value={customer.cobranca.contato}
                disabled={true}
                />

                <InputText
                  xs={12}
                  sm={6}
                  name="cobranca.telefone"
                  label="Teledone de cobrança"
                  type="text"
                  value={customer.cobranca.telefone}
                  disabled={true}
                />

                <InputText
                  xs={12}
                  sm={4}
                  name="cobranca.email"
                  label="E-mail de cobrança"
                  type="text"
                  value={customer.cobranca.email}
                  disabled={true}
                />

                <InputText
                  xs={12}
                  sm={4}
                  name="cobranca.endereco"
                  label="Endereço de cobrança"
                  type="text"
                  value={customer.cobranca.endereco}
                  disabled={true}
                />

                <InputText
                  xs={12}
                  sm={4}
                  name="cobranca.cidade"
                  label="Cidade da cobrança"
                  type="text"
                  value={customer.cobranca.cidade}
                  disabled={true}
                />

                <InputText
                  xs={12}
                  sm={4}
                  name="cobranca.cep"
                  label="CEP"
                  type="text"
                  value={customer.cobranca.cep}
                  disabled={true}
                />

                <InputText
                  xs={12}
                  sm={4}
                  name="cobranca.bairro"
                  label="Bairro"
                  type="text"
                  value={customer.cobranca.bairro}
                  disabled={true}
                />

                <InputText
                  xs={12}
                  sm={4}
                  name="cobranca.estado"
                  label="Estado"
                  type="text"
                  value={customer.cobranca.estado}
                  disabled={true}
                />
              </GridContainer>
            </>  
          }
          {customer.entrega &&
          <>
            <Separator>Informações de Entrega</Separator>
            <GridContainer>
                <InputText
                xs={12}
                sm={6}
                name="entrega.endereco"
                label="Endereço da entrga"
                type="text"
                value={customer.entrega.endereco}
                disabled={true}
                />

                <InputText
                  xs={12}
                  sm={6}
                  name="entrega.cidade"
                  label="Cidade da entrega"
                  type="text"
                  value={customer.entrega.cidade}
                  disabled={true}
                />

                <InputText
                  xs={12}
                  sm={4}
                  name="entrega.cep"
                  label="CEP"
                  type="text"
                  value={customer.entrega.cep}
                  disabled={true}
                />

                <InputText
                  xs={12}
                  sm={4}
                  name="entrega.bairro"
                  label="Bairro"
                  type="text"
                  value={customer.entrega.bairro}
                  disabled={true}
                />

                <InputText
                  xs={12}
                  sm={4}
                  name="entrega.estado"
                  label="Estado"
                  type="text"
                  value={customer.entrega.estado}
                  disabled={true}
                />

                <InputText
                  xs={12}
                  sm={12}
                  name="entrega.obs"
                  label="Observações"
                  type="text"
                  value={customer.entrega.obs}
                  disabled={true}
                />

                <InputMultiline
                  xs={12}
                  sm={12}
                  name="entrega.roteiro"
                  label="Roteiro"
                  type="text"
                  value={customer.entrega.roteiro}
                  disabled={true}
                />
              </GridContainer>
            </>
          }

          <Separator>Contatos</Separator>
          <GridContainer>
            <SelectAutocomplete
              xs={12}
              sm={11}
              value={itemSelectedContacts}
              label="Buscar Contato"
              optionSelected={(option, value) => option.label === value.label}
              optionLabel={(option) => option.label}
              onChangeSelected={(event, value) => handleCustomerContactsInputChange(value)}
              onChange={(event) => setSearchContacts(event.target.value)}
              options={optionsContacts}
              name="CustomerContacts"
              isLoading={isLoadingContacts}
            />

          <TableAction 
            title={"Adicionar"}  
            onClick={handleOpenModal}>
            <AddIcon/>
          </TableAction>

            <DataTableReact
              noHeader
              columns={columnsContact}
              data={selectedContacts}
            />

            <Modal
              isOpen={isOpen} 
              title="Cadastrar Contato"
              labelCancel="Cancelar"
              labelSave="Confirmar"
              onClickCancel={handleCloseModal}
              onClickSave={handleSaveModal}
            >
              <>
                <GridContainer>
                  <Toggle
                    xs={12}
                    name="active"
                    checked={isActive}
                    onChange={(e) => setIsActive(e.target.checked) }
                    label="Ativo"
                  />
                
                  <InputText
                    xs={12}
                    sm={6}
                    required
                    name="name"
                    label="Nome"
                    type="text"
                    value={formModal.name}
                    onChange={(e) => setFormModal({...formModal, name: e.target.value})}
                    errors={errors}
                  />
                  <InputSelectChildren
                    xs={12}
                    sm={6}
                    required
                    name="contactRoleId"
                    label="Cargo"
                    value={formModal.ContactRole?.id}
                    onChange={(e) => handleChangeContactRole(e.target.value)}
                    defaultValue={null}
                    errors={errors}
                  >
                    {contactRoleOptions.map(contactRole => (
                      <MenuItem key={contactRole.id} value={contactRole.id}>{contactRole.description}</MenuItem>
                    ))}
                  </InputSelectChildren>
                  <InputText
                    xs={12}
                    sm={6}
                    required
                    name="phone"
                    label="Telefone"
                    type="text"
                    value={formModal.phone}
                    onChange={(e) => setFormModal({...formModal, phone: e.target.value})}
                    errors={errors}
                  />
                  <InputText
                    xs={12}
                    sm={6}
                    name="email"
                    label="E-mail"
                    type="text"
                    value={formModal.email}
                    onChange={(e) => setFormModal({...formModal, email: e.target.value})}
                    errors={errors}
                  />
                  <InputText
                    xs={12}
                    sm={6}
                    name="notes"
                    label="Notas"
                    type="text"
                    value={formModal.notes}
                    onChange={(e) => setFormModal({...formModal, notes: e.target.value})}
                    errors={errors}
                    />

                  <InputSelectChildren
                    xs={12}
                    sm={6}
                    required
                    name="contactQualifierId"
                    label="Qualificador do contato"
                    value={formModal.ContactQualifier.id}
                    onChange={(e) => handleChangeModalQualifier(e.target.value)}
                    defaultValue={null}
                    errors={errors}
                  >
                    {modalContactQualifier.map(ContactQualifier => (
                      <MenuItem key={ContactQualifier.id} value={ContactQualifier.id}>{ContactQualifier.description}</MenuItem>
                    ))}
                  </InputSelectChildren>
                </GridContainer>
              </>
            </Modal>
          </GridContainer>  
        </> 
      } 

      <ButtonContainer>
        <Button type="submit" onClick={handleSubmit}>Salvar</Button>
      </ButtonContainer>
    </ContentPanel>
  )
}