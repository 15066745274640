//external
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItem, Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
//internal
import ContentPanel from 'components/ContentPanel';
import { setMessage } from 'store/modules/message/action';
import { sanitizeErrorUI } from 'utils/errorUtil';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import GridContainer from 'components/v2/grid';
import InputText from 'components/v2/inputText';
import { 
  getScheduleTodo, 
  getScheduleTypesTodo, 
  postScheduleTodo, 
  putScheduleTodo,
  getScheduleTypesIdTodo,
  getTodoCustomersId, 
  getTodoProspectId,
  getScheduleTodoUsers,
  getTodoCustomers,
  getTodoProspect, 
  getSchedulesTodoChecklist,
  revertStatusScheduleTodo,
  // putConfirm,
  putCancel,
  putStart,
  putFinish} from 'services/todo/service';
import history from 'services/history';
import { validateForm } from 'components/Form/validate';
import { scheduleTodoSchema } from '../schemas';
import DatePickers from 'components/v2/datePicker';
import DateAndTimePickers from 'components/v2/dateTimePicker';
import InputSelectChildren from 'components/v2/inputSelectChildren';
import SelectAutocomplete from 'components/v2/autocomplete';
import { useDebounce } from 'utils/uiUtils';
import DataTableReact from 'components/v2/dataTableReact';
import InputMultiline from 'components/v2/inputMultiline';
import Checklists from 'components/Checklists';
import Modal from 'components/v2/modal';
import TableAction from 'components/v2/tableAction';
import { showAlert } from 'components/AlertDialog';
import { showConfirm } from 'components/AlertDialog';
import { getFormatedScheduleStatus } from 'utils/formatSchedule';
import { Warning } from 'routes/Route';
import CheckboxComponent from 'components/v2/checkbox';
import RadioButton from 'components/v2/radioButton';
//style
import { Button, ButtonContainer } from 'styles/components';
import { ContentTodo, SeparatorTodo, SubContent, ContainerPrimarySubContent, ContainerSecondarySubContent } from './styled';
import { Separator } from 'pages/customer/view/styled';
import { ContainerModal } from 'pages/schedule/list/styled';
import { IconContainer } from '../../appversion/styled';
import ChecklistAnswer from 'components/ChecklistAnswer';

export default function TodoEdit({match}) {
  const todoId = match.params.id;
  const message = useSelector(state => state.message.data);
  const [dataTodo, setDataTodo] = useState({
    allDay: false,
    finalDate: new Date(),
    id: "",
    initialDate: new Date(),
    method: "",
    notes: "",
    owner: "",
    place: "",
    status: "",
    subject: "",
    ScheduleType: ""
  });
  const [isScheduleTypes, setIsScheduleType] = useState([]);
  const [errors, setErrors] = useState([]);
  const [selectedClientOrProspect, setSelectedClientOrProspect] = useState("");
  const [scheduleTypeData, setScheduleTypeData] = useState({
    checklists: [],
    description: "",
    hasCustomer: false,
    hasProspect: false,
    hasProspectOrCustomer: false,
    id: ""
  });
  const [checklistsData, setChecklistsData] = useState({
    checklist: {
      description: "",
      id: "",
      items: []
    },
    answer: {}
  });
  const [openModalStartOrFinish, setOpenModalStartOrFinish] = useState(false);
  const [modalStartOrFinish, setModalStartOrFinish] = useState("");
  const [isAnswer, setIsAnswer] = useState(false);
  const [viewDisabled, setViewDisabled] = useState(false);
  const [isErrorClientOrProspect, setIsErrorClientOrProspect] = useState([])
  //Finish
  const [dataFinish, setDataFinish] = useState([]);
  const [answerFinish, setAnswerFinish] = useState([]);
  //Start
  const [dataStart, setDataStart] = useState([]);
  const [answerStart, setAnswerStart] = useState([]);
  //select users
  const [userSelected, setUserSelected] = useState(null);
  const [searchUsers, setSearchUsers] = useState("");
  const [optionsUsers, setOptionsUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userName, setUserName] = useState("");
  // Customer
  const [searchCustomers, setSearchCustomers] = useState("");
  const [optionsCustomers, setOptionsCustomers] = useState([]);
  const [itemSelectedCustomers, setItemSelectedCustomers] = useState(null);
  const [isLoadingCustomers, setIsLoadingCustomers] = useState(false);
  const [scheduleCustomerId, setScheduleCustomerId] = useState({CustomerContacts: []});
  // Prospect
  const [searchProspects, setSearchProspects] = useState("");
  const [optionsProspects, setOptionsProspects] = useState([]);
  const [itemSelectedProspect, setItemSelectedProspect] = useState(null);
  const [isLoadingProspects, setIsLoadingProspects] = useState(false);
  const [scheduleProspectId, setScheduleProspectId] = useState({ProspectContacts: []});
  //Answers
  const [openChecklistAnswer, setOpenChecklistAnswer] = useState(false);
  const [checklistId, setChecklistId] = useState(null);
  const [checklistAnswer, setChecklistAnswer] = useState(null);
  const [reloadChecklists, setReloadChecklists] = useState(0);

  const { setNotWarning } = useContext(Warning);

  const debouncedUsers = useDebounce(searchUsers);
  const debouncedCustomers = useDebounce(searchCustomers);
  const debouncedProspects = useDebounce(searchProspects);

  const dispatch = useDispatch();

  // start Callback endpoints
  const loadCustomers = useCallback((searchValue) => {
    setIsLoadingCustomers(true)
    getTodoCustomers(searchValue).then(result => {
      const customers = result.data.map(element => {
        return { value: element, label: `${element.nome}` }
      });
      setOptionsCustomers(customers);
      setIsLoadingCustomers(false)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      setIsLoadingCustomers(false);
    });
  }, [dispatch]);

  useEffect(() => {
    loadCustomers(debouncedCustomers);
  }, [debouncedCustomers, loadCustomers]);

  const loadProspects = useCallback((searchValue) => {
    setIsLoadingProspects(true)
    getTodoProspect(searchValue).then(result => {
      const prospects = result.data.map(element => {
        return { value: element, label: `${element.customerName}` }
      });
      setOptionsProspects(prospects);
      setIsLoadingProspects(false)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      setIsLoadingProspects(false);
    });
  }, [dispatch]);

  useEffect(() => {
    loadProspects(debouncedProspects);
  }, [debouncedProspects, loadProspects]);

  const loadUsers = useCallback((searchValue) => {
    setIsLoading(true);
    getScheduleTodoUsers(searchValue).then(result => {
      const users = result.data.map(element => {
        return { value: element, label: `${element.name}` }
      });
      setOptionsUsers(users);
      setIsLoading(false);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      setIsLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    if(debouncedUsers) {
      loadUsers(debouncedUsers) 
    } else {
      loadUsers(userName);
    }
  }, [debouncedUsers, loadUsers, userName]);
  //finish Callback endpoints

  // start Callback endpoints ids
  const getScheduleTypeById = useCallback((id) => {
    if (id) {
      dispatch(startRequest());
      getScheduleTypesIdTodo(id).then(data => {
        setScheduleTypeData(data.data);
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }  
  }, [dispatch]);

  const getScheduleCustomerById = useCallback((id, store) => {
    if (id) {
      dispatch(startRequest());
      getTodoCustomersId(id, store).then(data => {
        if (data?.data) {
          const newOption = {
            value: data?.data,
            label: data?.data?.nome,
          }
          const customerObject = optionsCustomers.find(i => i.value?.id === newOption?.value?.id);
          if (!customerObject) {
            setOptionsCustomers([...optionsCustomers, newOption]);
          }
          setItemSelectedCustomers(newOption);
        }
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const getScheduleProspectById = useCallback((id) => {
    if (id) {
      dispatch(startRequest());
      getTodoProspectId(id).then(data => {
        if (data?.data) {
          const newOption = {
            value: data?.data,
            label: data?.data?.customerName,
          }
          const prospectObject = optionsProspects.find(i => i.value?.id === newOption?.value?.id);
          if (!prospectObject) {
            setOptionsProspects([...optionsProspects, newOption]);
          }
          setItemSelectedProspect(newOption);
        }
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (todoId) {
      getScheduleTypeById();
    }
  }, [todoId, getScheduleTypeById]);

  useEffect(() => {
    if (todoId) {
      getScheduleCustomerById();
    }
  }, [todoId, getScheduleCustomerById]);

  useEffect(() => {
    if (todoId) {
      getScheduleProspectById();
    }
  }, [todoId, getScheduleProspectById]);
  //finish endpoints ids

  const updateProspect = useCallback((prospectsLists) => {
    const prospectObject = optionsProspects.find(i => i.value?.id === dataTodo?.prospectId);
    if (prospectObject && prospectsLists.length > 0) {
      setItemSelectedProspect(prospectObject);
    }
  }, [optionsProspects, dataTodo]);

  const updateCustomer = useCallback((customersLists) => {
    const customerObject = optionsCustomers.find(i => i.value?.codigo === dataTodo?.customerId);
    if (customerObject && customersLists) {
      setItemSelectedCustomers(customerObject)
    }
  }, [optionsCustomers, dataTodo]);

  const updateUser = useCallback((usersLists) => {
    const userObject = optionsUsers.find(i => i.value?.username === userName);
    if (userObject && usersLists) {
      setUserSelected(userObject)
    }
  }, [optionsUsers, userName]);

  useEffect(() => {
    dispatch(startRequest());
    getScheduleTypesTodo().then(data => {
      setIsScheduleType(data.data);
    }).finally(() => {
      dispatch(finishRequest());
    });

    if (todoId) {
      dispatch(startRequest());
      getScheduleTodo(todoId).then(data => {
        const formData = data.data;
        setDataTodo({
          allDay: formData.allDay,
          customerStore: formData.customerStore,
          customerId: formData.customerId,
          prospectId: formData.prospectId,
          finalDate: formData.finalDate,
          id: formData.id,
          initialDate: formData.initialDate,
          method: formData.method,
          notes: formData.notes,
          owner: formData.owner,
          place: formData.place,
          status: formData.status,
          subject: formData.subject,
          ScheduleType: formData.ScheduleType
        });

        if (formData.status === 'FINISHED' || formData.status === 'CANCELED') {
          setViewDisabled(true);
        }

        setSelectedClientOrProspect(formData.prospectId ? "prospectId" : "customerId");

        if (formData.user) {
          setUserName(formData.user);          
        }

        getScheduleTypeById(formData.ScheduleType.id);
        getScheduleCustomerById(formData.customerId, formData.customerStore);
        getScheduleProspectById(formData.prospectId);
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, todoId, getScheduleTypeById, getScheduleCustomerById, getScheduleProspectById]);

  useEffect(() => {
    if (todoId) {
      if (scheduleTypeData?.hasProspect && scheduleTypeData?.hasCustomer) {
        updateProspect(optionsProspects)
        updateCustomer(optionsCustomers);
        return
      }else if (scheduleTypeData?.hasProspectOrCustomer) {
        if (dataTodo?.prospectId) {
          updateProspect(optionsProspects);
        }else {
          updateCustomer(optionsCustomers);
        }   
      }else if(scheduleTypeData.hasProspect) {
        updateProspect(optionsProspects);
      }else if (scheduleTypeData.hasCustomer) {
        updateCustomer(optionsCustomers);
      } 
    }      
  }, [todoId, dataTodo, scheduleTypeData, optionsProspects, optionsCustomers, updateCustomer, updateProspect]);

  useEffect(() => {
    if (todoId) {
      updateUser(optionsUsers);
    }
  }, [optionsUsers, todoId, updateUser]);

  useEffect(() => {
    if (todoId) {
      dispatch(startRequest());
      getSchedulesTodoChecklist(todoId).then(data => {
        const checklists = data.data.checklists;
        const checklistData = checklists.map(i => {
          return i.checklist
        });
        setChecklistsData(checklistData);        
        if (modalStartOrFinish === 'finish') {          
          const finishList = checklists.filter(i => i.atCheckout);       
          setDataFinish(finishList);
        }else if (modalStartOrFinish === 'start') {
          const startList = checklists.filter(i => i.atCheckin);
          setDataStart(startList);
        }
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      })
    }
  }, [dispatch, modalStartOrFinish, todoId, reloadChecklists])

  const columnsScheduleChecklist = [
    {
      name: 'Descrição',
      selector: 'checklist.description',
      sortable: true,
    },
    {
      style: {justifyContent: "flex-end"},
      cell: row => 
        <div>
          {Array.isArray(checklistsData) && (
            <IconContainer>
              <TableAction 
                title={"Visualizar"}             
                onClick={() => handleOpenChecklistAnswer(row.checklist.id)}
              >
                <VisibilityIcon/>
              </TableAction>
            </IconContainer>
          )}
        </div>
    }
  ];

  const columnsProspectContacts = [
    {
      name: 'Nome',
      selector: 'Contact.name',
      sortable: true,
    },
    {
      name: 'Função',
      selector: 'Contact.contactRole',
      sortable: true,
    },
  ];

  const columnsCustomerContacts = [
    {
      name: 'Nome',
      selector: 'Contact.name',
      sortable: true,
    },
    {
      name: 'Função',
      selector: 'Contact.contactRole',
      sortable: true,
    },
  ]

  const updateField = (e) => {
    setDataTodo({...dataTodo, [e.target.name]: e.target.value});
  };

  const updateFinalDateField = (date) => {
    setDataTodo({...dataTodo, finalDate: date});
  }

  function handleChandeScheduleType(value) {
    const scheduleObject = isScheduleTypes.find(item => item.id === value);
    setDataTodo({...dataTodo, ScheduleType: scheduleObject});

    getScheduleTypeById(value);
    setItemSelectedProspect(null);
    setItemSelectedCustomers(null);
    setSelectedClientOrProspect("");
    setScheduleProspectId({ProspectContacts: []});
    setScheduleCustomerId({CustomerContacts: []});
  }

  function handleChandeCustomerId(value) {
    if (value) {
      setItemSelectedCustomers(value);
      getScheduleCustomerById(value.value.codigo, value.value.loja);
      if (scheduleTypeData.hasProspectOrCustomer) {
        setScheduleProspectId({ProspectContacts: []});
        setItemSelectedProspect(null);
      }else if(scheduleTypeData.hasCustomer && scheduleTypeData.hasProspect) {
        setItemSelectedCustomers(value);
        getScheduleCustomerById(value.value.codigo, value.value.loja);
      }else if (scheduleTypeData.hasCustomer) {
        setScheduleProspectId({ProspectContacts: []});
        setItemSelectedProspect(null);
      }
    }else {
      setItemSelectedCustomers(null);
      loadCustomers("");
    }
  }

  function handleChandeProspectId(value) {
    if (value) {
      setItemSelectedProspect(value);
      getScheduleProspectById(value.value.id);
      if (scheduleTypeData.hasProspectOrCustomer) {
        setScheduleCustomerId({CustomerContacts: []});
        setItemSelectedCustomers(null);
      }else if (scheduleTypeData.hasCustomer && scheduleTypeData.hasProspect) {
        setItemSelectedProspect(value);
        getScheduleProspectById(value.value.id);
      }else if (scheduleTypeData.hasProspect) {
        setScheduleCustomerId({CustomerContacts: []});
        setItemSelectedCustomers(null);
      }
    }else {
      setItemSelectedProspect(null);
      loadProspects("");
    }
  }

  function handleChangeUser(value) {
    if (value) {
      setUserSelected(value);
    }else {
      setUserSelected(null);
      loadUsers("");
    }
  }

  function handleChangeRadio(event) {
    setSelectedClientOrProspect(event.target.value);
  }

  // async function handleConfirm() {
  //   if (!await showConfirm('Deseja realmente confirmar a tarefa?')) {
  //     return;
  //   }

  //   const idConfirm = {id: dataTodo.id}

  //   dispatch(startRequest());
  //   putConfirm(dataTodo.id ,idConfirm).then(data => {
  //     setIsAnswer(!isAnswer);
  //   }).catch(err => {
  //     dispatch(setMessage(sanitizeErrorUI(err)));
  //   }).finally(() => {
  //     dispatch(finishRequest());
  //   });
  // }

  async function handleCancel() {
    if (!await showConfirm('Deseja realmente cancelar a tarefa?')) {
      return;
    }

    const idCancel = {id: dataTodo.id}

    dispatch(startRequest());
    putCancel(dataTodo.id, idCancel).then(data => {
      const formData = data.data;
      setDataTodo({...dataTodo, status: formData.status});
      setIsAnswer(!isAnswer);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }

  // async function handleOpenStart() {
  //   if (!await showConfirm('Deseja realmente iniciar a tarefa?')) {
  //     return;
  //   }

  //   setModalStartOrFinish('start')
  //   setOpenModalStartOrFinish(true);
  // }

  async function handleOpenFinish() {
    if (!await showConfirm('Deseja realmente finalizar a tarefa?')) {
      return;
    }

    setModalStartOrFinish('finish');
    setOpenModalStartOrFinish(true);
  }

  function handleCloseModalStartOrFinish() {
    setOpenModalStartOrFinish(false);
  }

  function handleOpenChecklistAnswer(id) {
    setChecklistId(id);
    if (Array.isArray(checklistsData)) {
      const checklist = checklistsData.find(i => i.id === id)
      setChecklistAnswer(checklist);
      setOpenChecklistAnswer(true);
    }
  }

  function handleCloseChecklistAnswer() {
    setOpenChecklistAnswer(false);
    setChecklistId(null);
    setChecklistAnswer(null);
  }

  async function revertStatus() {
    if (!await showConfirm('Deseja realmente voltar o evento para o status anterior?')) {
      return;
    }
    dispatch(startRequest());
    revertStatusScheduleTodo(dataTodo.id).then(data => {
      const formData = data.data;
      setDataTodo({...dataTodo, status: formData.status});
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  };

  const handeSaveAnswerFinish = useCallback((form) => {
    const index = answerFinish.findIndex((item) => item.checklistId === form.checklistId);
    if (index > -1) {
      const newAnswers = [...answerFinish];
      newAnswers[index] = form;
      setAnswerFinish(newAnswers);
    } else {
      setAnswerFinish([...answerFinish, form]);
    }
    setModalStartOrFinish('finish');
  }, [answerFinish]);

  const handeSaveAnswerStart = useCallback((form) => {
    const index = answerStart.findIndex((item) => item.checklistId === form.checklistId);
    if (index > -1) {
      const newAnswers = [...answerStart];
      newAnswers[index] = form;
      setAnswerStart(newAnswers);
    } else {
      setAnswerStart([...answerStart, form])
    }
    setModalStartOrFinish('start');
  }, [answerStart]);

  const handleCheckbox = (e) => {
    setDataTodo({...dataTodo, [e.target.name]: e.target.checked});
  };

  function handleStartOrFinish(type) {
    let checklist;
    let method;
    let endPoint;
    let answer;
    if (type === 'start') {
      checklist = dataStart.filter(i => i.required && i.atCheckin === true);
      method = 'Iniciar'
      endPoint = putStart;
      answer = answerStart;
    } else {
      checklist = dataFinish.filter(i => i.required && i.atCheckout === true);
      method = 'Finalizar'
      endPoint = putFinish;
      answer = answerFinish;
    }

    let isRequired = [];
    checklist.forEach((item) => {
      const wasAnswered = answer.findIndex(i => i.checklistId === item.checklist.id);

      if (wasAnswered === -1) {
        isRequired.push(item);
      }
    });

    if(isRequired.length > 0) {
      showAlert(`Você precisa responder todos os Checklists obrigatórios para ${method}`);
      return
    }

    const answers = {
      id: dataTodo.id,
      checklists: answer
    }

    dispatch(startRequest());
    endPoint(dataTodo.id, answers).then(data => {
      const formData = data.data;
      setDataTodo({...dataTodo, status: formData.status})
      setIsAnswer(!isAnswer);
      setOpenModalStartOrFinish(false);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
      setReloadChecklists(reloadChecklists + 1);
    });
  }

  async function handleSubmit () {
    setNotWarning();
    const errorsList = await validateForm(dataTodo, scheduleTodoSchema);
    if (errorsList.length > 0) {
      setErrors(errorsList);
      return
    } 
    
    const objCopy = JSON.parse(JSON.stringify(dataTodo));
    if (scheduleTypeData.hasProspectOrCustomer) {
        delete objCopy.customerId;
        delete objCopy.customerStore;
      if (itemSelectedProspect !== null) {
        objCopy.prospectId = itemSelectedProspect?.value.id;
      }else {
        delete objCopy.prospectId;
        objCopy.customerId = itemSelectedCustomers?.value?.codigo;
        objCopy.customerStore = itemSelectedCustomers?.value?.loja;
      }
      if (itemSelectedProspect === null && itemSelectedCustomers === null) {
        setIsErrorClientOrProspect([{field: 'prospectId', message: 'Cliente ou Prospect é obrigatório'}]);
      }
    }else if (scheduleTypeData.hasCustomer && scheduleTypeData.hasProspect) {
      if (itemSelectedProspect === null || itemSelectedCustomers === null) {
        setIsErrorClientOrProspect([
          {field: 'prospectId', message: 'Prospect é obrigatório'}, 
          {field: 'customerId', message: 'Cliente é obrigatório'} 
        ]);
      }
      objCopy.prospectId = itemSelectedProspect?.value?.id;
      objCopy.customerId = itemSelectedCustomers?.value?.codigo;
      objCopy.customerStore = itemSelectedCustomers?.value?.loja;
    }else if (scheduleTypeData.hasProspect) {
      if (itemSelectedProspect === null) {
        setIsErrorClientOrProspect([{field: 'prospectId', message: 'Prospect é obrigatório'}]);
      }
      delete objCopy.customerId;
      delete objCopy.customerStore;
      objCopy.prospectId = itemSelectedProspect?.value.id;
    }else if (scheduleTypeData.hasCustomer) {
      if (itemSelectedCustomers === null) {
        setIsErrorClientOrProspect([{field: 'customerId', message: 'Cliente é obrigatório'}]);
      }
      delete objCopy.prospectId;
      objCopy.customerId = itemSelectedCustomers?.value?.codigo;
      objCopy.customerStore = itemSelectedCustomers?.value?.loja;
    }
    
    if (userSelected !== null) {
      objCopy.user = userSelected.value?.username;
    }  

    if (todoId) {
      dispatch(startRequest());
      putScheduleTodo(todoId, objCopy).then(data => {
        history.push('/schedule/schedule_todo')
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    } else {
      dispatch(startRequest());
      postScheduleTodo(objCopy).then(data => {
        history.push('/schedule/schedule_todo')
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }  
  }

  return (
    <ContentPanel title="Tarefas" message={message}>
      <ContentTodo>
        <SubContent>
          <GridContainer>
            <InputSelectChildren
              xs={12}
              sm={12}
              required
              name="ScheduleType"
              label="Tipo de Visita"
              value={dataTodo.ScheduleType.id}
              onChange={(e) => handleChandeScheduleType(e.target.value)}
              defaultValue={null}
              errors={errors}
              disabled={viewDisabled}
            >
              {isScheduleTypes.map(ScheduleType => (
                <MenuItem key={ScheduleType.id} value={ScheduleType.id}>{ScheduleType.description}</MenuItem>
              ))}
            </InputSelectChildren>

            {scheduleTypeData.hasProspectOrCustomer ? (
              <>
                {selectedClientOrProspect === 'customerId' && optionsCustomers.length > 0  ? (
                  <SelectAutocomplete
                    xs={12}
                    sm={7}
                    value={itemSelectedCustomers}
                    label="Buscar Cliente"
                    optionSelected={(option, value) => option.value.codigo === value.value.codigo}
                    optionLabel={(option) => option.label}
                    onChangeSelected={(event, value) => handleChandeCustomerId(value)}
                    onChange={(event) => setSearchCustomers(event.target.value)}
                    options={optionsCustomers}
                    name="customerId"
                    isLoading={isLoadingCustomers}
                    disabled={selectedClientOrProspect === "" || viewDisabled}
                    errors={isErrorClientOrProspect}
                  />
                ) : optionsProspects.length > 0 && (
                  <SelectAutocomplete
                    xs={12}
                    sm={7}
                    value={itemSelectedProspect}
                    label={selectedClientOrProspect === "" ? "Buscar Cliente/Prospect" : "Buscar Prospect"}
                    optionSelected={(option, value) => option.value.id === value.value.id}
                    optionLabel={(option) => option.label}
                    onChangeSelected={(event, value) => handleChandeProspectId(value)}
                    onChange={(event) => setSearchProspects(event.target.value)}
                    options={optionsProspects}
                    name="prospectId"
                    isLoading={isLoadingProspects}
                    disabled={selectedClientOrProspect === "" || viewDisabled}
                    errors={isErrorClientOrProspect}
                  />
                )}
                <RadioButton 
                  xs={12}
                  sm={2}
                  valueControlLabel="top"
                  checked={selectedClientOrProspect === 'customerId'}
                  onChange={handleChangeRadio}
                  value="customerId"
                  name="clientOrProspect"
                  inputProps={{ 'aria-label': 'A' }}
                  disabled={viewDisabled}
                  label="Cliente"
                  labelPlacement="bottom"
                />
                <RadioButton 
                  xs={12}
                  sm={2}
                  valueControlLabel="top"
                  checked={selectedClientOrProspect === 'prospectId'}
                  onChange={handleChangeRadio}
                  value="prospectId"
                  name="clientOrProspect"
                  inputProps={{ 'aria-label': 'B' }}
                  disabled={viewDisabled}
                  label="Prospect"
                  labelPlacement="bottom"
                />
              </>
            ) : scheduleTypeData.hasProspect && scheduleTypeData.hasCustomer ? (
              <>
                <SelectAutocomplete
                  xs={12}
                  sm={12}
                  value={itemSelectedProspect}
                  label={"Buscar Prospect"}
                  optionSelected={(option, value) => option.value.id === value.value.id}
                  optionLabel={(option) => option.label}
                  onChangeSelected={(event, value) => handleChandeProspectId(value)}
                  onChange={(event) => setSearchProspects(event.target.value)}
                  options={optionsProspects}
                  name="prospectId"
                  isLoading={isLoadingProspects}
                  disabled={viewDisabled}
                  errors={isErrorClientOrProspect}
                />
                <SelectAutocomplete
                  xs={12}
                  sm={12}
                  value={itemSelectedCustomers}
                  label="Buscar Cliente"
                  optionSelected={(option, value) => option.value.codigo === value.value.codigo}
                  optionLabel={(option) => option.label}
                  onChangeSelected={(event, value) => handleChandeCustomerId(value)}
                  onChange={(event) => setSearchCustomers(event.target.value)}
                  options={optionsCustomers}
                  name="customerId"
                  isLoading={isLoadingCustomers}
                  disabled={viewDisabled}
                  errors={isErrorClientOrProspect}
                />
              </>    
            ) : scheduleTypeData.hasProspect ? (
              <SelectAutocomplete
                xs={12}
                sm={12}
                value={itemSelectedProspect}
                label={"Buscar Prospect"}
                optionSelected={(option, value) => option.value.id === value.value.id}
                optionLabel={(option) => option.label}
                onChangeSelected={(event, value) => handleChandeProspectId(value)}
                onChange={(event) => setSearchProspects(event.target.value)}
                options={optionsProspects}
                name="prospectId"
                isLoading={isLoadingProspects}
                disabled={viewDisabled}
                errors={isErrorClientOrProspect}
              />
            ) : scheduleTypeData.hasCustomer ? (
              <SelectAutocomplete
                xs={12}
                sm={12}
                value={itemSelectedCustomers}
                label="Buscar Cliente"
                optionSelected={(option, value) => option.value.codigo === value.value.codigo}
                optionLabel={(option) => option.label}
                onChangeSelected={(event, value) => handleChandeCustomerId(value)}
                onChange={(event) => setSearchCustomers(event.target.value)}
                options={optionsCustomers}
                name="customerId"
                isLoading={isLoadingCustomers}
                disabled={viewDisabled}
                errors={isErrorClientOrProspect}
              />
            ) : null} 

            {scheduleProspectId.ProspectContacts?.length > 0 && (
              <div style={{width: '100%', border: 'solid 1px #c4c4c4', borderRadius: '5px', padding: '2px', margin: '8px'}}>
                <Separator>Contatos de prospects</Separator>
                <DataTableReact
                  noHeader
                  fixedHeader
                  columns={columnsProspectContacts}
                  data={scheduleProspectId.ProspectContacts}    
                />
              </div>
            )}   
            {scheduleCustomerId.CustomerContacts?.length > 0 && (
              <div style={{width: '100%', border: 'solid 1px #c4c4c4', borderRadius: '5px', padding: '2px', margin: '8px'}}>
                <Separator>Contatos de clientes</Separator>
                <DataTableReact
                  noHeader
                  fixedHeader
                  columns={columnsCustomerContacts}
                  data={scheduleCustomerId.CustomerContacts}    
                />
              </div>
            )}   
                
            {scheduleTypeData.checklists?.length > 0 && (
              <div style={{width: '100%', border: 'solid 1px #c4c4c4', borderRadius: '5px', padding: '2px', margin: '8px'}}>
                <Separator>Checklist</Separator>
                <DataTableReact
                  noHeader
                  fixedHeader
                  columns={columnsScheduleChecklist}
                  data={scheduleTypeData.checklists}    
                />
              </div>    
            )}
          </GridContainer>     
        </SubContent>  

        <SeparatorTodo/>

        <SubContent>
          {todoId && (
            <>
              {(dataTodo.status !== "FINISHED" && dataTodo.status !== "CANCELED") && (
                <div style={{width: '100%', display: 'flex', justifyContent: 'space-around', marginBottom: '16px'}}>
                  {/* <Button type="submit" onClick={handleConfirm} disabled={dataTodo.status === 'CONFIRMED' || dataTodo.status === 'STARTED'}>Confirmar</Button> */}
                  <Button type="submit" cancel={true} onClick={handleCancel}>Cancelar</Button>
                  {/* <Button type="submit" checkin={true} onClick={handleOpenStart} disabled={dataTodo.status === 'STARTED'}>Iniciar</Button> */}
                  <Button type="submit" checkout={true} onClick={handleOpenFinish} disabled={dataTodo.status !== 'SCHEDULED' && dataTodo.status !== 'CONFIRMED' && dataTodo.status !== 'STARTED'}>Finalizar</Button>
                </div>  
              )}
            </>  
          )}

          <ContainerPrimarySubContent>
            <Typography style={{color: '#3f8ed4', paddingBottom: '10px'}} color="textPrimary" variant="h6">Data/Hora</Typography>
            <GridContainer>
              <CheckboxComponent
                xs={12}
                sm={6}
                checked={dataTodo.allDay}
                onChange={handleCheckbox}
                name="allDay"
                disabled={viewDisabled}
                label="Agendar o dia todo"
              />
              <Grid item xs={12} sm={6}></Grid>
              {dataTodo.allDay ? (
                <>
                  <DatePickers
                    xs={12}
                    sm={12}
                    name="finalDate"
                    label="Data"
                    value={dataTodo.finalDate}
                    onChange={updateFinalDateField}
                    cancelLabel="Cancelar"
                    okLabel="Salvar"
                    disabled={viewDisabled}
                  />
                </>
              ) : (
                <>
                  <DateAndTimePickers
                    xs={12}
                    sm={12}
                    name="finalDate"
                    label="Data"
                    value={dataTodo.finalDate}
                    onChange={updateFinalDateField}
                    cancelLabel="Cancelar"
                    okLabel="Salvar"
                    disabled={viewDisabled}
                  />
                </>
              )}
            </GridContainer>  
          </ContainerPrimarySubContent>

          <ContainerSecondarySubContent>
            <GridContainer>
              <InputText
                xs={12}
                sm={12}
                required
                name="subject"
                label="Assunto"
                type="text"
                value={dataTodo.subject}
                onChange={updateField}
                errors={errors}
                disabled={viewDisabled}
              />
              <InputText
                xs={12}
                sm={6}
                name="place"
                label="Local"
                type="text"
                value={dataTodo.place}
                onChange={updateField}
                errors={errors}
                disabled={viewDisabled}
              />
              <InputText
                xs={12}
                sm={6}
                name="status"
                label="Status"
                type="text"
                value={getFormatedScheduleStatus(dataTodo.status)}
                onChange={updateField}
                errors={errors}
                disabled={true}
              />
              <SelectAutocomplete
                xs={12}
                sm={12}
                value={userSelected}
                label="Buscar Responsavel"
                optionSelected={(option, value) => option.label === value.label}
                optionLabel={(option) => option.label}
                onChangeSelected={(event, value) => handleChangeUser(value)}
                onChange={(event) => setSearchUsers(event.target.value)}
                options={optionsUsers}
                name="user"
                isLoading={isLoading}
                disabled={viewDisabled}
              />
              <InputMultiline
                xs={12}
                sm={12}          
                name="notes"
                label="Descrição"
                type="text"
                value={dataTodo.notes}
                onChange={updateField}
                errors={errors}
                disabled={viewDisabled}
              />
            </GridContainer>
          </ContainerSecondarySubContent>
        </SubContent>
      </ContentTodo>  

      <Modal
        isOpen={openModalStartOrFinish} 
        key={todoId || Math.random(0.5)}
      >        
        <ContainerModal> 
          <TableAction 
          key='closeModal'
          title={"Fechar"}  
          onClick={handleCloseModalStartOrFinish}>
            <CloseIcon/>
          </TableAction>

          {modalStartOrFinish === 'finish' && (
            <div style={{width: '100%'}}>
              <Separator>Finalizar</Separator>
              <Checklists 
                answerList={answerFinish} 
                data={dataFinish}
                onAnswer={(form) => handeSaveAnswerFinish(form)}
                dataAnswer={checklistsData}
              />
              <ButtonContainer>   
                <Button type="submit" onClick={() => handleStartOrFinish("finish")}>Finalizar</Button>
              </ButtonContainer>
            </div>  
          )}

          {modalStartOrFinish === 'start' && (
            <div style={{width: '100%'}}>
              <Separator>Iniciar</Separator>
              <Checklists 
                answerList={answerStart} 
                data={dataStart}
                onAnswer={(form) => handeSaveAnswerStart(form)}
                dataAnswer={checklistsData}
              />
              <ButtonContainer>
                <Button type="submit" onClick={() => handleStartOrFinish("start")}>Iniciar</Button>
              </ButtonContainer>
            </div>  
          )}
        </ContainerModal>
      </Modal>

      <Modal isOpen={openChecklistAnswer} key={checklistId || Math.random(0.5)}>
        <ContainerModal> 
          <TableAction 
            key='closeModal'
            title={"Fechar"}  
            onClick={handleCloseChecklistAnswer}
          >
            <CloseIcon/>
          </TableAction>
        </ContainerModal>  

        <ChecklistAnswer checklistId={checklistId} dataAnswer={checklistAnswer} />
      </Modal>

      <ButtonContainer>
        <Button left={true} onClick={revertStatus} disabled={!dataTodo?.id || dataTodo?.status === 'SCHEDULED'}>Status Anterior</Button>          
        <Button type="submit" onClick={handleSubmit} disabled={dataTodo?.status === 'CANCELED' || dataTodo?.status === 'FINISHED'}>Salvar</Button>
      </ButtonContainer>  
    </ContentPanel>
  )
}