//export
import React, { useContext, useEffect, useState } from 'react';
import { Select, FormControl, InputLabel, Grid, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
//import { PropTypes } from 'prop-types';
import { getErrorMessage, getIsError } from 'utils/validationUtil';
import { Warning } from 'routes/Route';

const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: 120,
      width: "100%"
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

  export default function InputSelectChildren({
      xs, 
      sm, 
      onChange, 
      value, 
      name, 
      children, 
      label, 
      errors, 
      disabled, 
      required,
      validationArray,
      validationIndex,
      onFocus,
      hasWarning = true
    }) {
    const classes = useStyles();
  
    const [stateErrors, setStateErrors] = useState([]);

    const { setIsWarning } = useContext(Warning);
  
    const onChangeInput = (e) => {
      onChange(e);
      setStateErrors(stateErrors.filter(item => item.field !== name));
      if (hasWarning) {
        setIsWarning();
      }
    }
  
    useEffect(() => {
      if(Array.isArray(errors) && errors.length > 0) {   
        setStateErrors(errors)
      }
    }, [errors]);

    return (
      <Grid item xs={xs} sm={sm}>
        <FormControl required={required} variant="outlined" className={classes.formControl} error={getIsError(name, stateErrors, validationArray, validationIndex)}>
          <InputLabel id={name}>{label}</InputLabel>
          <Select
            defaultValue=""
            labelId={name}
            name={name}
            label={label}
            value={value || ''}
            disabled={disabled}
            onChange={(e) => onChangeInput(e)}
            fullWidth    
            onFocus={onFocus}      
          >
            {children}
          </Select>
          <FormHelperText>{getErrorMessage(name, stateErrors, validationArray, validationIndex)}</FormHelperText>
        </FormControl>
      </Grid>
    );
  }