import styled from 'styled-components';

export const Separator = styled.div`
  padding-top: 15px;
  font-weight: bold;
  font-size: 16px;
  border-bottom: solid #a2a2a2 1px;
  margin-bottom: 24px;
  color: #58585d;
`

export const SeparatorBottom = styled.div`
  padding-top: 15px;
  font-weight: bold;
  font-size: 16px;
  border-bottom: solid #a2a2a2 1px;
  color: #58585d;
`

export const ContainerModal = styled.div`
display: flex;
align-items: flex-end;
flex-direction: column;
`

export const ButtonsAlert = styled.div`
display: flex;
align-items: flex-end;
flex-direction: row-reverse;
`