import React from 'react'
import Route from './Route'
import { useSelector } from 'react-redux'

// import LayoutContainer from '../components/Layout'
import LayoutContainer from '../components/Layout1'
import Loading from '../components/Loading'
import { Router, Switch } from 'react-router-dom'
import history from '../services/history'
import Login from 'pages/login/login'
import SignUp from 'pages/login/signup'
import ForgotPassword from 'pages/login/forgotPassword'
import ChangePassword from 'pages/login/changePassword'
import ConfirmAccount from 'pages/login/confirmAccount'
import FlagPrint from 'pages/rtpi/flagPrint/flagPrint'

export default function Routes() {
  const loading = useSelector(state => state.loading.loading);

  return (
    <Router history={history}>
      {loading && <Loading />}
      <Switch>
        {/* Login routes */}
        <Route path="/login" exact component={Login} />
        <Route path="/signup" exact component={SignUp} />
        <Route path="/forgotpassword" exact component={ForgotPassword} />
        <Route path="/confirmaccount" exact component={ConfirmAccount} />
        <Route path="/change" exact component={ChangePassword} />

        {/* External routes */}
        <Route path="/flagprint/:uuid" exact component={FlagPrint} isPublic />
  
        {/* Private routes */}
        <Route component={LayoutContainer} isPrivate />
      </Switch>
    </Router>
  )
}