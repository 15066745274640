//external
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MenuItem } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
//internal
import { setMessage } from 'store/modules/message/action';
import { sanitizeErrorUI } from 'utils/errorUtil';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import InputSelectChildren from 'components/v2/inputSelectChildren';
import {  
  getCustomers, 
  getCustomersId, 
  getProspect, 
  getProspectId, 
  getSchedule, 
  getScheduleTypes, 
  getScheduleTypesId, 
  getScheduleUsers,
  postSchedule, 
  putCheckout, 
  putCheckin,
  putCancel,
  // putConfirm,
  putSchedule, 
  getChecklists,
  revertStatusSchedule} from 'services/schedule/schedule/service';
import GridContainer from 'components/v2/grid';
import InputText from 'components/v2/inputText';
import DateAndTimePickers from 'components/v2/dateTimePicker';
import InputMultiline from 'components/v2/inputMultiline';
import history from 'services/history';
import { validateForm } from 'components/Form/validate';
import { scheduleSchema } from '../schemas';
import DatePickers from 'components/v2/datePicker';
import Stepper from 'components/v2/stepper';
import DataTableReact from 'components/v2/dataTableReact';
import { useDebounce } from 'utils/uiUtils';
import SelectAutocomplete from 'components/v2/autocomplete';
import Modal from 'components/v2/modal';
import TableAction from 'components/v2/tableAction';
import { showAlert } from 'components/AlertDialog';
import Checklists from 'components/Checklists';
import { Warning } from 'routes/Route';
import CheckboxComponent from 'components/v2/checkbox';
import RadioButton from 'components/v2/radioButton';
//style
import { Button, ButtonContainer } from 'styles/components';
import { Separator } from 'pages/customer/view/styled';
import { ContainerModal } from '../list/styled';
import { showConfirm } from 'components/AlertDialog';
import { getFormatedScheduleStatus } from 'utils/formatSchedule';
import { IconContainer } from '../../appversion/styled';
import ChecklistAnswer from 'components/ChecklistAnswer';

export default function SchedulingEdit({scheduleId, dates, setIsOpenModal, refreshCallback}) {
  const [dataSchedule, setDataSchedule] = useState({
    id: "",
    subject: "",
    place: "",
    initialDate: dates?.start || new Date(),
    finalDate: dates?.end || new Date(),
    allDay: false,
    notes: "",
    method: "",
    ScheduleType: "",
    owner: "",
    status: ""
  });
  const [errors, setErrors] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedClientOrProspect, setSelectedClientOrProspect] = useState("");
  const [isScheduleTypes, setIsScheduleType] = useState([]);
  const [scheduleTypeData, setScheduleTypeData] = useState({
    checklists: [],
    description: "",
    hasCustomer: false,
    hasProspect: false,
    hasProspectOrCustomer: false,
    id: ""
  });
  const [viewDisabled, setViewDisabled] = useState(false);
  const [isAnswer, setIsAnswer] = useState(false);
  const [openModalCheckinOrCheckout, setOpenModalCheckinOrCheckout] = useState(false);
  const [modalCheckinOrCheckout, setModalCheckinOrCheckout] = useState("");
  const [dataAnswer, setDataAnswer] = useState({
    checklist: {
      description: "",
      id: "",
      items: []
    },
    answer: {}
  })
  //Checkout
  const [dataCheckout, setDataCheckout] = useState([]);
  const [answerCheckout, setAnswerCheckout] = useState([]);
  //Check-in
  const [dataCheckin, setDataCheckin] = useState([]);
  const [answerCheckin, setAnswerCheckin] = useState([]);
  //Answers
  const [openChecklistAnswer, setOpenChecklistAnswer] = useState(false);
  const [checklistId, setChecklistId] = useState(null);
  const [checklistAnswer, setChecklistAnswer] = useState(null);
  const [reloadChecklists, setReloadChecklists] = useState(0);
  //select users
  const [userSelected, setUserSelected] = useState(null);
  const [searchUsers, setSearchUsers] = useState("");
  const [optionsUsers, setOptionsUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userName, setUserName] = useState("");
  // Customer
  const [searchCustomers, setSearchCustomers] = useState("");
  const [optionsCustomers, setOptionsCustomers] = useState([]);
  const [itemSelectedCustomers, setItemSelectedCustomers] = useState(null);
  const [isLoadingCustomers, setIsLoadingCustomers] = useState(false);
  const [scheduleCustomerData, setScheduleCustomerData] = useState({CustomerContacts: []});
  // Prospect
  const [searchProspects, setSearchProspects] = useState("");
  const [optionsProspects, setOptionsProspects] = useState([]);
  const [itemSelectedProspect, setItemSelectedProspect] = useState(null);
  const [isLoadingProspects, setIsLoadingProspects] = useState(false);
  const [scheduleProspectData, setScheduleProspectData] = useState({ProspectContacts: []});

  const { setNotWarning } = useContext(Warning);

  const debouncedUsers = useDebounce(searchUsers);
  const debouncedCustomers = useDebounce(searchCustomers);
  const debouncedProspects = useDebounce(searchProspects);

  const dispatch = useDispatch();

  // start Callback endpoints
  const loadCustomers = useCallback((searchValue) => {
    setIsLoadingCustomers(true)
    getCustomers(searchValue).then(result => {
      const customers = result.data.map(element => {
        return { value: element, label: `${element.nome}` }
      });
      setOptionsCustomers(customers);
      setIsLoadingCustomers(false)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      setIsLoadingCustomers(false);
    });
  }, [dispatch]);

  useEffect(() => {
    loadCustomers(debouncedCustomers);
  }, [debouncedCustomers, loadCustomers]);

  const loadProspects = useCallback((searchValue) => {
    setIsLoadingProspects(true)
    getProspect(searchValue).then(result => {
      const prospects = result.data.map(element => {
        return { value: element, label: `${element.customerName}` }
      });
      setOptionsProspects(prospects);
      setIsLoadingProspects(false)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      setIsLoadingProspects(false);
    });
  }, [dispatch]);

  useEffect(() => {
    loadProspects(debouncedProspects);
  }, [debouncedProspects, loadProspects]);

  const loadUsers = useCallback((searchValue) => {
    setIsLoading(true);
    getScheduleUsers(searchValue).then(result => {
      const users = result.data.map(element => {
        return { value: element, label: `${element.name}` }
      });
      setOptionsUsers(users);
      setIsLoading(false);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      setIsLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    if (debouncedUsers) {
      loadUsers(debouncedUsers);
    } else {
      loadUsers(userName);
    }
  }, [debouncedUsers, loadUsers, userName]);
  //finish Callback endpoints

  // start Callback endpoints ids
  const getScheduleTypeId = useCallback((id) => {
    if (id) {
      dispatch(startRequest());
      getScheduleTypesId(id).then(data => {
        setScheduleTypeData(data.data);
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }  
  }, [dispatch]);

  const getScheduleCustomerId = useCallback((id, store) => {
    if (id) {
      dispatch(startRequest());
      getCustomersId(id, store).then(data => {
        if (data?.data) {
          const newOption = {
            value: data?.data,
            label: data?.data?.nome,
          }
          const customerObject = optionsCustomers.find(i => i.value?.id === newOption?.value?.id);
          if (!customerObject) {
            setOptionsCustomers([...optionsCustomers, newOption]);
          }
          setItemSelectedCustomers(newOption);
        }
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const getScheduleProspectId = useCallback((id) => {
    if (id) {
      dispatch(startRequest());
      getProspectId(id).then(data => {
        if (data?.data) {
          const newOption = {
            value: data?.data,
            label: data?.data?.customerName,
          }
          const prospectObject = optionsProspects.find(i => i.value?.id === newOption?.value?.id);
          if (!prospectObject) {
            setOptionsProspects([...optionsProspects, newOption]);
          }
          setItemSelectedProspect(newOption);
        }
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (scheduleId) {
      getScheduleTypeId();
    }
  }, [scheduleId, getScheduleTypeId]);

  useEffect(() => {
    if (scheduleId) {
      getScheduleCustomerId();
    }
  }, [scheduleId, getScheduleCustomerId]);

  useEffect(() => {
    if (scheduleId) {
      getScheduleProspectId();
    }
  }, [scheduleId, getScheduleProspectId]);
  //finish endpoints ids

  const updateProspect = useCallback((prospectsLists) => {
    const prospectObject = optionsProspects.find(i => i.value?.id === dataSchedule?.prospectId);
    if (prospectObject && prospectsLists.length > 0) {
      setItemSelectedProspect(prospectObject);
    }
  }, [optionsProspects, dataSchedule]);

  const updateCustomer = useCallback((customersLists) => {
    const customerObject = optionsCustomers.find(i => i.value?.codigo === dataSchedule?.customerId);
    if (customerObject && customersLists) {
      setItemSelectedCustomers(customerObject)
    }
  }, [optionsCustomers, dataSchedule]);

  const updateUser = useCallback((usersLists) => {
    const userObject = optionsUsers.find(i => i.value?.username === userName);
    if (userObject && usersLists) {
      setUserSelected(userObject)
    }
  }, [optionsUsers, userName]);

  useEffect(() => {
    dispatch(startRequest());
    getScheduleTypes().then(data => {
      setIsScheduleType(data.data);
    }).finally(() => {
      dispatch(finishRequest());
    });    
    
    if (scheduleId) {
      dispatch(startRequest());
      getSchedule(scheduleId).then(data => {
        const formData = data.data;
        setDataSchedule({
          id: formData.id,
          subject: formData.subject,
          customerStore: formData.customerStore,
          customerId: formData.customerId,
          prospectId: formData.prospectId,
          place: formData.place || '',
          initialDate: formData.initialDate,
          finalDate: formData.finalDate,
          allDay: formData.allDay,
          notes: formData.notes || '',
          ScheduleType: formData.ScheduleType,
          method: formData.method,
          owner: formData.owner,
          status: formData.status,
        });

        if (formData.status === 'FINISHED' || formData.status === 'CANCELED') {
          setViewDisabled(true);
        }
 
        setSelectedClientOrProspect(formData.prospectId ? "prospectId" : "customerId")
        
        if (formData.user) {
          setUserName(formData.user);          
        }

        getScheduleTypeId(formData.ScheduleType.id);
        getScheduleCustomerId(formData.customerId, formData.customerStore);
        getScheduleProspectId(formData.prospectId);
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, scheduleId, getScheduleTypeId, getScheduleCustomerId, getScheduleProspectId]);

  useEffect(() => {
    if (scheduleId) {
      if (scheduleTypeData?.hasProspect && scheduleTypeData?.hasCustomer) {
        updateProspect(optionsProspects)
        updateCustomer(optionsCustomers);
        return
      } else if (scheduleTypeData?.hasProspectOrCustomer) {
        if (dataSchedule?.prospectId) {
          updateProspect(optionsProspects);
        } else {
          updateCustomer(optionsCustomers);
        }   
      } else if (scheduleTypeData?.hasProspect) {
        updateProspect(optionsProspects);
      } else if (scheduleTypeData?.hasCustomer) {
        updateCustomer(optionsCustomers);
      } 
    }      
  }, [scheduleId, dataSchedule, scheduleTypeData, optionsProspects, optionsCustomers, updateCustomer, updateProspect]);

  useEffect(() => {
    if (scheduleId) {
      updateUser(optionsUsers);
    }
  }, [optionsUsers, scheduleId, updateUser]);

  useEffect(() => {
    if (scheduleId) {
      dispatch(startRequest());
      getChecklists(scheduleId).then(data => {
        const formData = data.data;
        const checklist = formData.checklists;
        const checklistData = checklist.map(i => {
          return i.checklist
        }); 
        setDataAnswer(checklistData);
        const checklists = formData.checklists;
        if (modalCheckinOrCheckout === 'checkout') {
          const checkoutList = checklists.filter(i => i.atCheckout);
          setDataCheckout(checkoutList);
        } else if (modalCheckinOrCheckout === 'checkin') {
          const checkinList = checklists.filter(i => i.atCheckin);
          setDataCheckin(checkinList)
        }
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, modalCheckinOrCheckout, scheduleId, reloadChecklists]);

  const columnsScheduleChecklist = [
    {
      name: 'Descrição',
      selector: 'checklist.description',
      sortable: true,
    },
    {
      style: {justifyContent: "flex-end"},
      cell: row => 
        <div>
          {Array.isArray(dataAnswer) && (
            <IconContainer>
              <TableAction 
                title={"Visualizar"}             
                onClick={() => handleOpenChecklistAnswer(row.checklist.id)}
              >
                <VisibilityIcon/>
              </TableAction>
            </IconContainer>
          )}
        </div>
    }
  ];

  const columnsProspectContacts = [
    {
      name: 'Nome',
      selector: 'Contact.name',
      sortable: true,
    },
    {
      name: 'Função',
      selector: 'Contact.contactRole',
      sortable: true,
    },
  ];

  const columnsCustomerContacts = [
    {
      name: 'Nome',
      selector: 'Contact.name',
      sortable: true,
    },
    {
      name: 'Função',
      selector: 'Contact.contactRole',
      sortable: true,
    },
  ]

  const updateField = (e) => {
    setDataSchedule({...dataSchedule, [e.target.name]: e.target.value});
  };

  const updateInitalDateField = (date) => {
    setDataSchedule({...dataSchedule, initialDate: date});
  }

  const updateFinalDateField = (date) => {
    setDataSchedule({...dataSchedule, finalDate: date});
  }

  function handleChandeScheduleType(value) {
    const scheduleObject = isScheduleTypes.find(item => item.id === value);
    setDataSchedule({...dataSchedule, ScheduleType: scheduleObject});

    getScheduleTypeId(value);
    setItemSelectedProspect(null);
    setItemSelectedCustomers(null);
    setSelectedClientOrProspect("");
    setScheduleProspectData({ProspectContacts: []});
    setScheduleCustomerData({CustomerContacts: []});
  }

  function handleChandeCustomerId(value) {
    if (value) {
      setItemSelectedCustomers(value);
      getScheduleCustomerId(value.value.codigo, value.value.loja);
      if (scheduleTypeData.hasProspectOrCustomer) {
        setScheduleProspectData({ProspectContacts: []});
        setItemSelectedProspect(null);
      }else if(scheduleTypeData.hasCustomer && scheduleTypeData.hasProspect) {
        setItemSelectedCustomers(value);
        getScheduleCustomerId(value.value.codigo, value.value.loja);
      }else if (scheduleTypeData.hasCustomer) {
        setScheduleProspectData({ProspectContacts: []});
        setItemSelectedProspect(null);
      }
    }else {
      setItemSelectedCustomers(null);
      loadCustomers("");
    }
  }

  function handleChandeProspectId(value) {
    if (value) {
      setItemSelectedProspect(value);
      getScheduleProspectId(value.value.id);
      if (scheduleTypeData.hasProspectOrCustomer) {
        setScheduleCustomerData({CustomerContacts: []});
        setItemSelectedCustomers(null);
      }else if (scheduleTypeData.hasCustomer && scheduleTypeData.hasProspect) {
        setItemSelectedProspect(value);
        getScheduleProspectId(value.value.id);
      }else if (scheduleTypeData.hasProspect) {
        setScheduleCustomerData({CustomerContacts: []});
        setItemSelectedCustomers(null);
      }
    }else {
      setItemSelectedProspect(null);
      loadProspects("");
    }
  }

  function handleChangeUser(value) {
    if (value) {
      setUserSelected(value);
    }else {
      setUserSelected(null);
      loadUsers("");
    }
  }

  function handleChangeRadio(event) {
    setSelectedClientOrProspect(event.target.value);
  }

  async function handleOpenCheckin() {
    if (!await showConfirm('Deseja realmente fazer check-in?')) {
      return;
    }

    setModalCheckinOrCheckout("checkin");
    setOpenModalCheckinOrCheckout(true);
  }

  async function handleOpenCheckout() {
    if (!await showConfirm('Deseja realmente fazer check-out?')) {
      return;
    }

    setModalCheckinOrCheckout("checkout");
    setOpenModalCheckinOrCheckout(true);
  }

  function handleCloseModalCheckinOrCheckout() {
    setOpenModalCheckinOrCheckout(false);
  }

  function handleOpenChecklistAnswer(id) {
    setChecklistId(id);
    if (Array.isArray(dataAnswer)) {
      const checklist = dataAnswer?.find(i => i.id === id)
      setChecklistAnswer(checklist);
      setOpenChecklistAnswer(true);
    }
  }

  function handleCloseChecklistAnswer() {
    setOpenChecklistAnswer(false);
    setChecklistId(null);
    setChecklistAnswer(null);
  }

  async function handleSubmit () {
    setNotWarning();

    const errorsList = await validateForm(dataSchedule, scheduleSchema);
    if (errorsList.length > 0) {
      setErrors(errorsList);
      return
    }

    const objCopy = JSON.parse(JSON.stringify(dataSchedule));
    if (scheduleTypeData?.hasProspectOrCustomer) {
      if (itemSelectedProspect !== null) {
        delete objCopy.customerId;
        delete objCopy.customerStore;
        objCopy.prospectId = itemSelectedProspect?.value?.id;
      }else {
        delete objCopy.prospectId;
        objCopy.customerId = itemSelectedCustomers?.value?.codigo;
        objCopy.customerStore = itemSelectedCustomers?.value?.loja;
      }
    }else if (scheduleTypeData?.hasCustomer && scheduleTypeData?.hasProspect) {
      objCopy.prospectId = itemSelectedProspect?.value.id;
      objCopy.customerId = itemSelectedCustomers?.value?.codigo;
      objCopy.customerStore = itemSelectedCustomers?.value?.loja;
    }else if (scheduleTypeData?.hasProspect) {
      delete objCopy.customerId;
      delete objCopy.customerStore;
      objCopy.prospectId = itemSelectedProspect?.value?.id;
    }else if (scheduleTypeData?.hasCustomer) {
      delete objCopy.prospectId;
      objCopy.customerId = itemSelectedCustomers?.value?.codigo;
      objCopy.customerStore = itemSelectedCustomers?.value?.loja;
    }
    
    if (userSelected !== null) {
      objCopy.user = userSelected?.value?.username;
    }  

    if (scheduleId) {
      dispatch(startRequest());
      putSchedule(scheduleId, objCopy).then(data => {
        history.push('/schedule/schedule');
        setIsOpenModal(false);
        if (refreshCallback) {
          refreshCallback();
        }
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    } else {
      dispatch(startRequest());
      postSchedule(objCopy).then(data => {
        history.push('/schedule/schedule');
        setIsOpenModal(false);
        if (refreshCallback) {
          refreshCallback();
        }
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }

  async function revertStatus() {
    if (!await showConfirm('Deseja realmente voltar o evento para o status anterior?')) {
      return;
    }
    dispatch(startRequest());
    revertStatusSchedule(dataSchedule.id).then(data => {
      const formData = data.data;
      setDataSchedule({...dataSchedule, status: formData.status});
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  };

  const handeSaveAnswerCheckout = useCallback((form) => {
    const index = answerCheckout.findIndex((item) => item.checklistId === form.checklistId);
    if (index > -1) {
      const newAnswers = [...answerCheckout];
      newAnswers[index] = form;
      setAnswerCheckout(newAnswers);
    } else {
      setAnswerCheckout([...answerCheckout, form]);
    }
    setModalCheckinOrCheckout('checkout');
  }, [answerCheckout]);

  const handeSaveAnswerCheckin = useCallback((form) => {
    const index = answerCheckin.findIndex((item) => item.checklistId === form.checklistId);
    if (index > -1) {
      const newAnswers = [...answerCheckin];
      newAnswers[index] = form;
      setAnswerCheckin(newAnswers);
    } else {
      setAnswerCheckin([...answerCheckin, form])
    }
    setModalCheckinOrCheckout('checkin');
  }, [answerCheckin]);

  function handleCheckinOrCheckout(type) {
    setNotWarning();
    let checklist;
    let method;
    let endPoint;
    let answer;
    if (type === 'checkin') {
      checklist = scheduleTypeData.checklists.filter(i => i.required && i.atCheckin === true);
      method = 'Check-in'
      endPoint = putCheckin;
      answer = answerCheckin;
    } else {
      checklist = scheduleTypeData.checklists.filter(i => i.required && i.atCheckout === true);
      method = 'Checkout'
      endPoint = putCheckout;
      answer = answerCheckout;
    }

    let isRequired = [];
    checklist.forEach((item) => {
      const wasAnswered = answer.findIndex(i => i.checklistId === item.checklist.id);

      if (wasAnswered === -1) {
        isRequired.push(item);
      }
    });

    if(isRequired.length > 0) {
      showAlert(`Você precisa responder todos os Checklists obrigatórios para realizar o ${method}`);
      return
    }

    const answers = {
      id: dataSchedule.id,
      checklists: answer
    }

    dispatch(startRequest());
    endPoint(dataSchedule.id, answers).then(data => {
      const formData = data.data;
      setDataSchedule({...dataSchedule, status: formData.status})
      setIsAnswer(!isAnswer);
      setOpenModalCheckinOrCheckout(false);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
      setReloadChecklists(reloadChecklists + 1);
    });
  }

  // async function handleConfirm() {
  //   if (!await showConfirm('Deseja realmente confirmar a tarefa?')) {
  //     return;
  //   }

  //   const idConfirm = {id: dataSchedule.id}

  //   dispatch(startRequest());
  //   putConfirm(dataSchedule.id, idConfirm).then(data => {
  //     history.push('/schedule/schedule');
  //     setIsAnswer(!isAnswer);
  //   }).catch(err => {
  //     dispatch(setMessage(sanitizeErrorUI(err)));
  //   }).finally(() => {
  //     dispatch(finishRequest());
  //   });
  // }

  async function handleCancel() {
    if (!await showConfirm('Deseja realmente cancelar a tarefa?')) {
      return;
    } 

    const idCancel = {id: dataSchedule.id}

    dispatch(startRequest());
    putCancel(dataSchedule.id, idCancel).then(data => {
      const formData = data.data;
      setDataSchedule({...dataSchedule, status: formData.status});
      setIsAnswer(!isAnswer);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }

  function pageOneModal() {
    return (
      <div>
        {dataSchedule.status === "FINISHED" || dataSchedule.status === "CANCELED" ? (
          <Separator>Dados do evento</Separator>
        ) : (
          <Separator>Preencha os dados necessáios</Separator>
        )}
        <GridContainer>
          {isScheduleTypes.length > 0 && (
            <InputSelectChildren
              xs={12}
              sm={12}
              required
              name="ScheduleType"
              label="Tipo de Visita"
              value={dataSchedule.ScheduleType.id}
              onChange={(e) => handleChandeScheduleType(e.target.value)}
              defaultValue={null}
              errors={errors}
              disabled={viewDisabled}
            >
              {isScheduleTypes.map(ScheduleType => (
                <MenuItem key={ScheduleType.id} value={ScheduleType.id}>{ScheduleType.description}</MenuItem>
              ))}
            </InputSelectChildren>
          )}
        
          {scheduleTypeData.hasProspectOrCustomer ? (
            <>
              {selectedClientOrProspect === 'customerId' && optionsCustomers.length > 0  ? (
                <SelectAutocomplete
                  xs={12}
                  sm={7}
                  value={itemSelectedCustomers}
                  label="Buscar Cliente"
                  optionSelected={(option, value) => option.value.codigo === value.value.codigo}
                  optionLabel={(option) => option.label}
                  onChangeSelected={(event, value) => handleChandeCustomerId(value)}
                  onChange={(event) => setSearchCustomers(event.target.value)}
                  options={optionsCustomers}
                  name="customerId"
                  isLoading={isLoadingCustomers}
                  disabled={selectedClientOrProspect === "" || viewDisabled}
                />
              ) : optionsProspects.length > 0 && (
                <SelectAutocomplete
                  xs={12}
                  sm={7}
                  value={itemSelectedProspect}
                  label={selectedClientOrProspect === "" ? "Buscar Cliente/Prospect" : "Buscar Prospect"}
                  optionSelected={(option, value) => option.value.id === value.value.id}
                  optionLabel={(option) => option.label}
                  onChangeSelected={(event, value) => handleChandeProspectId(value)}
                  onChange={(event) => setSearchProspects(event.target.value)}
                  options={optionsProspects}
                  name="prospectId"
                  isLoading={isLoadingProspects}
                  disabled={selectedClientOrProspect === "" || viewDisabled}
                />
              )}
                <RadioButton 
                  xs={12}
                  sm={2}
                  valueControlLabel="top"
                  checked={selectedClientOrProspect === 'customerId'}
                  onChange={handleChangeRadio}
                  name="clientOrProspect"
                  value="customerId"
                  inputProps={{ 'aria-label': 'A' }}
                  disabled={viewDisabled}
                  label="Cliente"
                  labelPlacement="bottom"
                />
                <RadioButton 
                  xs={12}
                  sm={2}
                  valueControlLabel="top"
                  checked={selectedClientOrProspect === 'prospectId'}
                  onChange={handleChangeRadio}
                  value="prospectId"
                  name="clientOrProspect"
                  inputProps={{ 'aria-label': 'B' }}
                  disabled={viewDisabled}
                  label="Prospect"
                  labelPlacement="bottom"
                />
            </>
          ) : scheduleTypeData.hasProspect && scheduleTypeData.hasCustomer ? (
            <>
              <SelectAutocomplete
                xs={12}
                sm={12}
                value={itemSelectedProspect}
                label={"Buscar Prospect"}
                optionSelected={(option, value) => option.value.id === value.value.id}
                optionLabel={(option) => option.label}
                onChangeSelected={(event, value) => handleChandeProspectId(value)}
                onChange={(event) => setSearchProspects(event.target.value)}
                options={optionsProspects}
                name="prospectId"
                isLoading={isLoadingProspects}
                disabled={viewDisabled}
              />
              <SelectAutocomplete
                xs={12}
                sm={12}
                value={itemSelectedCustomers}
                label="Buscar Cliente"
                optionSelected={(option, value) => option.value.codigo === value.value.codigo}
                optionLabel={(option) => option.label}
                onChangeSelected={(event, value) => handleChandeCustomerId(value)}
                onChange={(event) => setSearchCustomers(event.target.value)}
                options={optionsCustomers}
                name="customerId"
                isLoading={isLoadingCustomers}
                disabled={viewDisabled}
              />
            </>    
          ) : scheduleTypeData.hasProspect ? (
            <SelectAutocomplete
              xs={12}
              sm={12}
              value={itemSelectedProspect}
              label={"Buscar Prospect"}
              optionSelected={(option, value) => option.value.id === value.value.id}
              optionLabel={(option) => option.label}
              onChangeSelected={(event, value) => handleChandeProspectId(value)}
              onChange={(event) => setSearchProspects(event.target.value)}
              options={optionsProspects}
              name="prospectId"
              isLoading={isLoadingProspects}
              disabled={viewDisabled}
            />
          ) : scheduleTypeData.hasCustomer ? (
            <SelectAutocomplete
              xs={12}
              sm={12}
              value={itemSelectedCustomers}
              label="Buscar Cliente"
              optionSelected={(option, value) => option.value.codigo === value.value.codigo}
              optionLabel={(option) => option.label}
              onChangeSelected={(event, value) => handleChandeCustomerId(value)}
              onChange={(event) => setSearchCustomers(event.target.value)}
              options={optionsCustomers}
              name="customerId"
              isLoading={isLoadingCustomers}
              disabled={viewDisabled}
            />
          ) : null}

          {scheduleProspectData.ProspectContacts?.length > 0 && (
            <div style={{width: '100%', border: 'solid 1px #c4c4c4', borderRadius: '5px', padding: '2px', margin: '8px'}}>
              <Separator>Contatos de prospects</Separator>
              <DataTableReact
                noHeader
                fixedHeader
                columns={columnsProspectContacts}
                data={scheduleProspectData.ProspectContacts}    
              />
            </div>
          )}   

          {scheduleCustomerData.CustomerContacts?.length > 0 && (
            <div style={{width: '100%', border: 'solid 1px #c4c4c4', borderRadius: '5px', padding: '2px', margin: '8px'}}>
              <Separator>Contatos de clientes</Separator>
              <DataTableReact
                noHeader
                fixedHeader
                columns={columnsCustomerContacts}
                data={scheduleCustomerData.CustomerContacts}    
              />
            </div>
          )}   
            

          {scheduleTypeData.checklists?.length > 0 && (            
            <div style={{width: '100%', border: 'solid 1px #c4c4c4', borderRadius: '5px', padding: '2px', margin: '8px'}}>
              <Separator>Checklist</Separator>
              <DataTableReact
                noHeader
                fixedHeader
                columns={columnsScheduleChecklist}
                data={scheduleTypeData.checklists}    
              />
            </div>     
          )}
        </GridContainer>
      </div>
    );
  }

  function pageTwoModal() {
    return (
      <>
        {dataSchedule.status === "FINISHED" || dataSchedule.status === "CANCELED" ? (
          <Separator>Dados do evento</Separator>
        ) : (
          <Separator>Preencha os dados necessáios</Separator>
        )}
        <GridContainer>
          {scheduleId && (
            <>
              {(dataSchedule.status !== "FINISHED" && dataSchedule.status !== "CANCELED") && (
                <div style={{width: '100%', display: 'flex', justifyContent: 'space-around', marginBottom: '16px'}}>
                  {/* <Button type="submit" onClick={handleConfirm} disabled={dataSchedule.status === 'CONFIRMED' || dataSchedule.status === 'STARTED'}>Confirmar</Button> */}
                  <Button type="submit" cancel={true} onClick={handleCancel}>Cancelar</Button>
                  <Button type="submit" checkin={true} onClick={handleOpenCheckin} disabled={dataSchedule.status === 'STARTED'}>Check-in</Button>
                  <Button type="submit" checkout={true} onClick={handleOpenCheckout} disabled={dataSchedule.status !== 'STARTED'}>Checkout</Button>
                </div>  
              )}
            </>  
          )}
          
          <InputText
            xs={12}
            sm={12}
            required
            name="subject"
            label="Assunto"
            type="text"
            value={dataSchedule.subject}
            onChange={updateField}
            errors={errors}
            disabled={viewDisabled}
          />
          <SelectAutocomplete
            xs={12}
            sm={12}
            value={userSelected}
            label="Buscar Responsavel"
            optionSelected={(option, value) => option.label === value.label}
            optionLabel={(option) => option.label}
            onChangeSelected={(event, value) => handleChangeUser(value)}
            onChange={(event) => setSearchUsers(event.target.value)}
            options={optionsUsers}
            name="user"
            isLoading={isLoading}
            disabled={(!!scheduleId && !!userSelected) || viewDisabled}
          />
          <InputText
            xs={12}
            sm={12}
            name="place"
            label="Local"
            type="text"
            value={dataSchedule.place}
            onChange={updateField}
            errors={errors}
            disabled={viewDisabled}
          />
          <CheckboxComponent 
            xs={12}
            sm={6}
            checked={dataSchedule.allDay}
            onChange={(e) => setDataSchedule({...dataSchedule, allDay: e.target.checked})}
            name="allDay"
            disabled={viewDisabled}
            label="Agendar o dia todo"
          />
          <InputText
            xs={12}
            sm={6}
            name="status"
            label="Status"
            type="text"
            value={getFormatedScheduleStatus(dataSchedule.status)}
            onChange={updateField}
            errors={errors}
            disabled={true}
          />

          {dataSchedule.allDay ? (
            <>
              <DatePickers
                xs={12}
                sm={6}
                name="initialDate"
                label="Início"
                value={dataSchedule.initialDate}
                onChange={updateInitalDateField}
                cancelLabel="Cancelar"
                okLabel="Salvar"
                disabled={viewDisabled}
                disablePast={viewDisabled === false}
              />
              <DatePickers
                xs={12}
                sm={6}
                name="finalDate"
                label="Fim"
                value={dataSchedule.finalDate}
                onChange={updateFinalDateField}
                cancelLabel="Cancelar"
                okLabel="Salvar"
                disabled={viewDisabled}
                disablePast={viewDisabled === false}
              />
            </>
          ) : (
            <>
              <DateAndTimePickers
                xs={12}
                sm={6}
                name="initialDate"
                label="Início"
                value={dataSchedule.initialDate}
                onChange={updateInitalDateField}
                cancelLabel="Cancelar"
                okLabel="Salvar"
                disabled={viewDisabled}
                disablePast={viewDisabled === false}
              />
              <DateAndTimePickers
                xs={12}
                sm={6}
                name="finalDate"
                label="Fim"
                value={dataSchedule.finalDate}
                onChange={updateFinalDateField}
                cancelLabel="Cancelar"
                okLabel="Salvar"
                disabled={viewDisabled}
                disablePast={viewDisabled === false}
              />
            </>
          )}
          <InputMultiline
            xs={12}
            sm={12}          
            name="notes"
            label="Descrição"
            type="text"
            value={dataSchedule.notes}
            onChange={updateField}
            errors={errors}
            disabled={viewDisabled}
          />
        </GridContainer>        
        <ButtonContainer>
          <Button left={true} onClick={revertStatus} disabled={!dataSchedule?.id || dataSchedule?.status === 'SCHEDULED'}>Status Anterior</Button>          
          <Button type="submit" onClick={handleSubmit} disabled={dataSchedule?.status === 'CANCELED' || dataSchedule?.status === 'FINISHED'}>Salvar</Button>
        </ButtonContainer>        
      </>
    )
  }

  function getStepContent() {
    switch (currentStep) {
      case 0:
        return pageOneModal();
      case 1:
        return pageTwoModal();
      default:
        return undefined;
    }
  }

  return (
    <>
      <Stepper 
        setCurrentStep={(step) => setCurrentStep(step)} 
        maxSteps={2} 
        activeStep={currentStep}
        buttonPrimary="Próximo"
        buttonSecondary="Voltar"
      >
        {getStepContent()}
      </Stepper> 

      <Modal
        isOpen={openModalCheckinOrCheckout} 
        key={scheduleId || Math.random(0.5)}
      > 
        <ContainerModal> 
          <TableAction 
          key='closeModal'
          title={"Fechar"}  
          onClick={handleCloseModalCheckinOrCheckout}>
            <CloseIcon/>
          </TableAction>

          {modalCheckinOrCheckout === 'checkout' && (
            <div style={{width: '100%'}}>
              <Separator>Checkout</Separator>
              <Checklists 
                answerList={answerCheckout} 
                data={dataCheckout}
                onAnswer={(form) => handeSaveAnswerCheckout(form)}
                dataAnswer={dataAnswer}
              />
              <ButtonContainer>   
                <Button type="submit" onClick={() => handleCheckinOrCheckout("checkout")}>Checkout</Button>
              </ButtonContainer>
            </div>  
          )}

          {modalCheckinOrCheckout === 'checkin' && (
            <div style={{width: '100%'}}>
              <Separator>Check-in</Separator>
              <Checklists 
                answerList={answerCheckin} 
                data={dataCheckin}
                onAnswer={(form) => handeSaveAnswerCheckin(form)}
                dataAnswer={dataAnswer}
              />
              <ButtonContainer>
                <Button type="submit" onClick={() => handleCheckinOrCheckout("checkin")}>Check-in</Button>
              </ButtonContainer>
            </div>  
          )}
        </ContainerModal>
      </Modal>

      <Modal isOpen={openChecklistAnswer} key={checklistId || Math.random(0.5)}>
          <ContainerModal> 
            <TableAction 
              key='closeModal'
              title={"Fechar"}  
              onClick={handleCloseChecklistAnswer}
            >
              <CloseIcon/>
            </TableAction>
          </ContainerModal>  

          <ChecklistAnswer checklistId={checklistId} dataAnswer={checklistAnswer}  />
        </Modal>
    </>
  )
}