import React from 'react';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Grid, TextField } from '@material-ui/core';

const filter = createFilterOptions();

export default function CreateOption({xs, sm, label, value, options, onChange}) {

  const getOption = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option;
  }

  return (
    <Grid item xs={xs} sm={sm}>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => onChange(event, newValue)}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          // Suggest the creation of a new value
          if (params.inputValue !== '') {
            filtered.push(params.inputValue);
          }
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="select-creatable"
        options={options}
        getOptionLabel={(option) => getOption(option)}
        renderOption={(option) => option}
        freeSolo
        renderInput={(params) => (
          <TextField {...params} label={label} variant="outlined" />
        )}
      />
    </Grid>
  );
}