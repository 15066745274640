//external
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { MenuItem } from '@material-ui/core';
//internal
import ContentPanel from 'components/ContentPanel';
import { setMessage } from 'store/modules/message/action';
import { sanitizeErrorUI } from 'utils/errorUtil';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import GridContainer from 'components/v2/grid';
import InputText from 'components/v2/inputText';
import { getProspect, getContact, getLeadOrigin, getStatus, putProspect, postProspect, getContactQualifier, getContactRole } from 'services/prospect/service';
import { useDebounce } from 'utils/uiUtils';
import SelectAutocomplete from 'components/v2/autocomplete';
import { IconContainer } from 'pages/user/list/styled';
import TableAction from 'components/v2/tableAction';
import DataTableReact from 'components/v2/dataTableReact';
import history from 'services/history';
import { validateForm } from 'components/Form/validate';
import Modal from 'components/v2/modal';
import Toggle from 'components/v2/toggle';
import InputSelectChildren from 'components/v2/inputSelectChildren';
import { prospectSchema } from '../schema';
import { prospectModalSchema } from '../schemaProspesctModal';
import { Warning } from 'routes/Route';
//style
import { Button, ButtonContainer } from 'styles/components';

export default function ProspectEdit({match}) {
  const prospectId = match.params.id;
  const message = useSelector(state => state.message.data);
  const [dataProspect, setDataProspect] = useState({
    ProspectLeadOrigin: "",
    ProspectStatus: "",
    contacts: []
  });
  const [isProspectLeadOrigin, setIsProspectLeadOrigin] = useState([]);
  const [isProspectStatus, setIsProspectStatus] = useState([]);
  const [errors, setErrors] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [formModal, setFormModal] = useState({
    name: "",
    contactRole: "",
    email: "",
    phone: "",
    contactQualifierId: "",
    active: false,
    notes: "",
    ContactQualifier: ""
  });
  const [isActive, setIsActive] = useState(false);
  const [modalContactQualifier, setModalConactQualifier] = useState([]);
  const [contactRoleOptions, setContactRoleOptions] = useState([]);
  //select Contacts
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [searchContacts, setSearchContacts] = useState("");
  const [optionsContacts, setOptionsContacts] = useState([]);
  const [itemSelectedContacts, setItemSelectedContacts] = useState(null);
  const [isLoadingContacts, setIsLoadingContacts] = useState(false);

  const { setNotWarning } = useContext(Warning);

  const debouncedContacts = useDebounce(searchContacts);

  const dispatch = useDispatch();

  const loadContact = useCallback((searchValue) => {
    setIsLoadingContacts(true)
    getContact(searchValue).then(result => {
      const Contacts = result.data.map(element => {
        return { value: element, label: `${element.name}` }
      });
      setOptionsContacts(Contacts);
      setIsLoadingContacts(false)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      setIsLoadingContacts(false);
    });
  }, [dispatch]);

  useEffect(() => {
    if(loadContact) loadContact(debouncedContacts)
  },[debouncedContacts, loadContact]);

  useEffect(() => {
    dispatch(startRequest());
    getLeadOrigin().then(data => {
      setIsProspectLeadOrigin(data.data);
    });
    getStatus().then(data => {
      setIsProspectStatus(data.data)
    });
    getContactQualifier().then(data => {
      setModalConactQualifier(data.data);
    });
    getContactRole().then(data => {
      setContactRoleOptions(data.data);
    });
    dispatch(finishRequest());

    if (prospectId) {
      dispatch(startRequest());
      getProspect(prospectId).then(data => {
        const formData = data.data;
        setDataProspect({
          id: formData.id,
          customerName: formData.customerName,
          farmName: formData.farmName, 
          nutritionSupplier: formData.nutritionSupplier || "",
          notes: formData.notes || "",
          ProspectLeadOrigin: formData.ProspectLeadOrigin,
          ProspectStatus: formData.ProspectStatus
        });
        setSelectedContacts(formData.ProspectContacts);
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, prospectId]);

  const columnsContact = [
    {
      maxWidth: "75%",
      name: 'Nome',
      selector: 'Contact.name',
      sortable: true,
    },
    {
      maxWidth: "75%",
      name: 'Fazenda',
      selector: 'Contact.contactRole',
      sortable: true,
    },
    {
      maxWidth: "25%",
      style: {justifyContent: "flex-end"},
      cell: row => 
      <>
        <div>
          <IconContainer>
            <TableAction 
            title={"Excluir"} 
            onClick={() => handleRemoveProspectContact(row)}>
              <DeleteIcon/>
            </TableAction>
          </IconContainer>
        </div>
      </>,
    }
  ];

  const scrollToTop = () => {
    const elContent = document.getElementById("content");
    elContent.scrollTo(0,0);
  };

  function handleRemoveProspectContact(prospectContact) {    
    var prospectContactIndex = selectedContacts.indexOf(prospectContact);
    selectedContacts.splice(prospectContactIndex, 1);
    setSelectedContacts([...selectedContacts]);    
  }

  function handleCustomerContactsInputChange(data, event) {
    if (data) {
      const prospectContactIndex = selectedContacts.findIndex(item => item.Contact.id === data?.value?.id);
      setItemSelectedContacts(data);
      if (prospectContactIndex >= 0) return;

    setSelectedContacts([...selectedContacts, { Contact: {
      contactRole: data.value.contactRole,
      id: data.value.id,
      name: data.value.name
    }}]); 
    } else {
      setItemSelectedContacts(null);
      loadContact("");
    } 
  }

  function onChandeProspectLeadOrigin(value) {
    const leadOriginObject = isProspectLeadOrigin.find(item => item.id === value)
    setDataProspect({...dataProspect, ProspectLeadOrigin: leadOriginObject});
  }

  function onChandeProspectStatus(value) {
    const statusObject = isProspectStatus.find(item => item.id === value)
    setDataProspect({...dataProspect, ProspectStatus: statusObject});
  }

  function handleOpenModal() {
    setIsOpen(true);
  }

  function handleCloseModal() {
    setIsOpen(false);
  }

  async function handleSaveModal() {
    const objCopy = JSON.parse(JSON.stringify(formModal));
    delete objCopy.ContactQualifier;
    objCopy.contactRoleId = formModal.ContactRole?.id;
    objCopy.contactQualifierId = formModal.ContactQualifier.id;

    const errorsList = await validateForm(objCopy, prospectModalSchema);
    if (errorsList.length > 0) {
      setErrors(errorsList);
      return
    }

    setSelectedContacts([...selectedContacts, {Contact: objCopy}]);
    setIsOpen(false);
  }

  function handleChangeModalQualifier(value) {
    const contactQualifier = modalContactQualifier.find(item => item.id === value)
    setFormModal({...formModal, ContactQualifier: contactQualifier});
  }

  function handleChangeContactRole(value) {
    const contactRoler = contactRoleOptions.find(item => item.id === value)
    setFormModal({...formModal, ContactRole: contactRoler});
  }

  async function handleSubmit () {
    setNotWarning();
    scrollToTop();
    const errorsList = await validateForm(dataProspect, prospectSchema);
    if (errorsList.length > 0) {
      setErrors(errorsList);
      return
    }

    const objCopy = JSON.parse(JSON.stringify(dataProspect));
    delete objCopy.ProspectLeadOrigin;
    delete objCopy.ProspectStatus;
    const contactsList = selectedContacts.map(item => {
      if (item.Contact) {
        return item.Contact
      }
      return undefined
    })
    objCopy.contacts = contactsList;
    objCopy.leadOriginId = dataProspect.ProspectLeadOrigin.id;
    objCopy.statusId = dataProspect.ProspectStatus.id;
    if (prospectId) {
      dispatch(startRequest());
      putProspect(prospectId, objCopy).then(data => {
        history.push('/schedule/prospect')
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    } else {
      dispatch(startRequest());
      postProspect(objCopy).then(data => {
        history.push('/schedule/prospect')
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    } 
      
  }

  return (
    <ContentPanel title="Prospect" message={message}>
      <GridContainer>
        <InputText
          xs={12}
          sm={6}
          required
          name="customerName"
          label="Nome"
          type="text"
          value={dataProspect.customerName}
          onChange={(e) => setDataProspect({...dataProspect, customerName: e.target.value})}
          errors={errors}
        />
        <InputText
          xs={12}
          sm={6}
          name="farmName"
          label="Fazenda"
          type="text"
          value={dataProspect.farmName}
          onChange={(e) => setDataProspect({...dataProspect, farmName: e.target.value})}
          errors={errors}
        />
        <InputText
          xs={12}
          sm={12}
          name="nutritionSupplier"
          label="Fornecedor"
          type="text"
          value={dataProspect.nutritionSupplier}
          onChange={(e) => setDataProspect({...dataProspect, nutritionSupplier: e.target.value})}
          errors={errors}
        />

        <InputSelectChildren
          xs={12}
          sm={6}
          required
          name="leadOriginId"
          label="Origem do cliente"
          value={dataProspect.ProspectLeadOrigin.id}
          onChange={(e) => onChandeProspectLeadOrigin(e.target.value)}
          defaultValue={null}
          errors={errors}
        >
          {isProspectLeadOrigin.map(ProspectLeadOrigin => (
            <MenuItem key={ProspectLeadOrigin.id} value={ProspectLeadOrigin.id}>{ProspectLeadOrigin.description}</MenuItem>
          ))}
        </InputSelectChildren>

        <InputSelectChildren
          xs={12}
          sm={6}
          required
          name="statusId"
          label="Status"
          value={dataProspect.ProspectStatus.id}
          onChange={(e) => onChandeProspectStatus(e.target.value)}
          defaultValue={null}
          errors={errors}
        >
          {isProspectStatus.map(ProspectStatus => (
            <MenuItem key={ProspectStatus.id} value={ProspectStatus.id}>{ProspectStatus.description}</MenuItem>
          ))}
        </InputSelectChildren>

        <InputText
          xs={12}
          sm={12}
          name="notes"
          label="Notas"
          type="text"
          value={dataProspect.notes}
          onChange={(e) => setDataProspect({...dataProspect, notes: e.target.value})}
          errors={errors}
        />

        <SelectAutocomplete
          xs={12}
          sm={11}
          value={itemSelectedContacts}
          label="Buscar Contato"
          optionSelected={(option, value) => option.label === value.label}
          optionLabel={(option) => option.label}
          onChangeSelected={(event, value) => handleCustomerContactsInputChange(value)}
          onChange={(event) => setSearchContacts(event.target.value)}
          options={optionsContacts}
          name="CustomerContacts"
          isLoading={isLoadingContacts}
        />

        <TableAction 
          title={"Adicionar"}  
          onClick={handleOpenModal}>
          <AddIcon/>
        </TableAction>

        <Modal
          isOpen={isOpen} 
          title="Cadastrar Contato"
          labelCancel="Cancelar"
          labelSave="Confirmar"
          onClickCancel={handleCloseModal}
          onClickSave={handleSaveModal}
        >
          <>
            <GridContainer>
              <Toggle
                xs={12}
                name="active"
                checked={isActive}
                onChange={(e) => setIsActive(e.target.checked) }
                label="Ativo"
              />
            
              <InputText
                xs={12}
                sm={6}
                required
                name="name"
                label="Nome"
                type="text"
                value={formModal.name}
                onChange={(e) => setFormModal({...formModal, name: e.target.value})}
                errors={errors}
              />
              <InputSelectChildren
                xs={12}
                sm={6}
                required
                name="contactRoleId"
                label="Cargo"
                value={formModal.ContactRole?.id}
                onChange={(e) => handleChangeContactRole(e.target.value)}
                defaultValue={null}
                errors={errors}
              >
                {contactRoleOptions.map(contactRole => (
                  <MenuItem key={contactRole.id} value={contactRole.id}>{contactRole.description}</MenuItem>
                ))}
              </InputSelectChildren>
              <InputText
                xs={12}
                sm={6}
                required
                name="phone"
                label="Telefone"
                type="text"
                value={formModal.phone}
                onChange={(e) => setFormModal({...formModal, phone: e.target.value})}
                errors={errors}
              />
              <InputText
                xs={12}
                sm={6}
                name="email"
                label="E-mail"
                type="text"
                value={formModal.email}
                onChange={(e) => setFormModal({...formModal, email: e.target.value})}
                errors={errors}
              />
              <InputText
                xs={12}
                sm={6}
                name="notes"
                label="Notas"
                type="text"
                value={formModal.notes}
                onChange={(e) => setFormModal({...formModal, notes: e.target.value})}
                errors={errors}
                />

              <InputSelectChildren
                xs={12}
                sm={6}
                required
                name="contactQualifierId"
                label="Qualificador do contato"
                value={formModal.ContactQualifier.id}
                onChange={(e) => handleChangeModalQualifier(e.target.value)}
                defaultValue={null}
                errors={errors}
              >
                {modalContactQualifier.map(ContactQualifier => (
                  <MenuItem key={ContactQualifier.id} value={ContactQualifier.id}>{ContactQualifier.description}</MenuItem>
                ))}
              </InputSelectChildren>
            </GridContainer>
          </>
        </Modal>

        <DataTableReact
          noHeader
          columns={columnsContact}
          data={selectedContacts}
        />
      </GridContainer>

      <ButtonContainer>
        <Button type="submit" onClick={handleSubmit}>Salvar</Button>
      </ButtonContainer>
    </ContentPanel>
  );
}