import React from 'react'
import Route from '../../routes/Route'

import Profile from './profile'

export default function Routes() {
  return (
    <>
      <Route path="/myprofile" exact component={Profile} isPrivate/>
    </>
  )
}