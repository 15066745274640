//external
import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
//internal
import Route from '../../routes/Route';
import Component from './list';
import ComponentEdit from './edit';

export const FilterComponents = createContext({});

export default function Routes() {
  const [filter, setFilter] = useState({
    search: "",
    page: 1,
  });

  let location = useLocation();

  useEffect(() => {
    const split = location?.pathname.split('/');
    if (split[2] !== "components") {
      setFilter({
        search: "",
        page: 1,
      });
    }
  }, [location, setFilter]);

  return (
    <FilterComponents.Provider value={{ filter, setFilter }}>
      <Route path="/premixlabel/components" exact component={Component} isPrivate/>
      <Route path="/premixlabel/components/edit/:id" exact component={ComponentEdit} isPrivate/>
    </FilterComponents.Provider>
  )
}