//external
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, Divider } from '@material-ui/core';
//internal
import { getBillet, getBilletPdf } from 'services/billet/service';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import history from 'services/history';
import ContentPanel from 'components/ContentPanel';
import { setMessage } from 'store/modules/message/action';
import GridContainer from 'components/v2/grid';
import { formatDate, formatCurrency } from 'utils/formatUtil';
import { showReport } from 'utils/reportUtil';
import { sanitizeErrorUI } from 'utils/errorUtil';
//style
import { Button, ButtonContainer } from 'styles/components';

export default function BilletView({match}) {
  const message = useSelector(state => state.message.data);
  const {branch, number, prefix, parcel} = match.params;  
  const [billet, setBillet] = useState(null);
  const [isCopied, setIsCopied] = useState(false)

  const dispatch = useDispatch();

  useEffect(() => {
    if (branch && number && prefix) {
      dispatch(startRequest());
      getBillet(branch, number, prefix, parcel).then(data => {
        const formData = data.data;
        formData.dataEmissao = formatDate(formData.dataEmissao);
        formData.dataVencimento = formatDate(formData.dataVencimento);
        formData.valor = formatCurrency(formData?.valor || 0);
        formData.acrescimo = formatCurrency(formData?.acrescimo || 0);
        formData.desconto = formatCurrency(formData?.desconto || 0);
        formData.saldo = formatCurrency(formData?.saldo || 0);
        setBillet({...formData});        
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, branch, number, prefix, parcel]);

  async function handleBack () {
    history.goBack();
  }

  async function handlePrint () {
    if (branch && number && prefix) {
      dispatch(startRequest());
      getBilletPdf(branch, number, prefix, parcel).then(res => {
        showReport(res.data);
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }

  const copyTextToClipboard = async (text) => {
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(text)
    }

    return document.execCommand('copy', true, text)
  }

  const handleCopy = () => {
    copyTextToClipboard(billet?.codigoBarras || '')
      .then(() => {
        setIsCopied(true)
        setTimeout(() => {
          setIsCopied(false)
        }, 1500)
      });
  }

  return (
    <ContentPanel title='Boleto' message={message}>
      <GridContainer>
        <Grid item xs={12}>
          <Typography color="textPrimary" variant="h6">Informações do boleto</Typography>
          <Divider light fullWidth />
        </Grid>

        <Grid item xs={12} sm={3}>
          <Typography color="textPrimary" variant="subtitle1">Série:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{billet?.serie || ''}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography color="textPrimary" variant="subtitle1">Número:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{billet?.numero || ''}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography color="textPrimary" variant="subtitle1">Parcela:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{billet?.parcela || ''}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography color="textPrimary" variant="subtitle1">Filial:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{billet?.filial || ''}</Typography>
        </Grid>

        <Grid item xs={12} sm={3}>
          <Typography color="textPrimary" variant="subtitle1">Pedido:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{billet?.pedido || ''}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography color="textPrimary" variant="subtitle1">Emissão:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{billet?.dataEmissao || ''}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography color="textPrimary" variant="subtitle1">Vencimento:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{billet?.dataVencimento || ''}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
    
        </Grid>

        <Grid item xs={12} sm={3}>
          <Typography color="textPrimary" variant="subtitle1">Valor:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{billet?.valor}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography color="textPrimary" variant="subtitle1">Acréscimo:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{billet?.acrescimo}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography color="textPrimary" variant="subtitle1">Desconto:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{billet?.desconto}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography color="textPrimary" variant="subtitle1">Saldo:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{billet?.saldo}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography color="textPrimary" variant="h6">Informações da conta</Typography>
          <Divider light fullWidth />
        </Grid>

        <Grid item xs={12} sm={3}>
          <Typography color="textPrimary" variant="subtitle1">Código:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{billet?.cliente?.codigo || ''} / {billet?.cliente?.loja || ''}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography color="textPrimary" variant="subtitle1">Nome:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{billet?.cliente?.nome || ''}</Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography color="textPrimary" variant="subtitle1">Fazenda:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{billet?.cliente?.fazenda || ''}</Typography>
        </Grid>        
        <Grid item xs={12} sm={3}>
          <Typography color="textPrimary" variant="subtitle1">Cidade:</Typography>
          <Typography color="textPrimary" variant="subtitle2">{billet?.cliente?.cidade || ''} / {billet?.cliente?.estado || ''}</Typography>
        </Grid>        

        {billet?.codigoBarras && billet?.pdf && (
          <>
            <Grid item xs={12}>          
              <Divider light fullWidth />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography color="textPrimary" variant="subtitle1">Código de barras:</Typography>
              <Typography color="textPrimary" variant="subtitle2">{billet?.codigoBarras || ''}</Typography>
            </Grid>
          </>
        )}        

      </GridContainer>
      <ButtonContainer>
        <br />
        <Button left={true} cancel={true} onClick={handleBack}>
          Voltar        
        </Button>
        {billet?.codigoBarras && billet?.pdf && (
          <>
            <Button onClick={handlePrint}>
              Imprimir Boleto
            </Button>
            <Button onClick={handleCopy} style={{marginRight: '10px'}}>
              {isCopied ? 'Copiado' : 'Copiar código'}
            </Button>
          </>
        )}
      </ButtonContainer>
    </ContentPanel>
  )
}