//external
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//internal 
import ContentPanel from 'components/ContentPanel';
import { setMessage } from 'store/modules/message/action';
import { sanitizeErrorUI } from 'utils/errorUtil';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import GridContainer from 'components/v2/grid';
import InputText from 'components/v2/inputText';
import history from 'services/history';
import { getUserGroup, postUserGroup, putUserGroup } from 'services/userGroup/service';
import { userGroupSchema } from '../schemas';
import { validateForm } from 'components/Form/validate';
import { Warning } from 'routes/Route';
//style
import { Button, ButtonContainer } from 'styles/components';

export default function UserGroupEdit({match}) {
  const userGroupId = match.params.id;
  const message = useSelector(state => state.message.data);
  const [dataUserGroup, setDataUserGroup] = useState({});
  const [errors, setErrors] = useState([]);

  const { setNotWarning } = useContext(Warning);

  const dispatch = useDispatch();

  useEffect(() => {
    if (userGroupId) {
      dispatch(startRequest());
      getUserGroup(userGroupId).then(data => {
        const formData = data.data;
        setDataUserGroup({
          description: formData.description,
          id: formData.id
        });
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, userGroupId]);

  async function handleSubmit () {
    setNotWarning();

    const errorsList = await validateForm(dataUserGroup, userGroupSchema);
    if (errorsList.length > 0) {
      setErrors(errorsList);
      return
    }

    if (userGroupId) {
      dispatch(startRequest());
      putUserGroup(userGroupId, dataUserGroup).then(data => {
        history.push('/user_group')
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    } else {
      dispatch(startRequest());
      postUserGroup(dataUserGroup).then(data => {
        history.push('/user_group')
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }  
  }

  return (
    <ContentPanel title="Grupo de Usuário" message={message}>
      <GridContainer>
        <InputText
          xs={12}
          sm={12}
          required
          name="description"
          label="Descrição"
          type="text"
          value={dataUserGroup.description}
          onChange={(e) => setDataUserGroup({...dataUserGroup, description: e.target.value})}
          errors={errors}
        />
      </GridContainer> 

      <ButtonContainer>
        <Button type="submit" onClick={handleSubmit}>Salvar</Button>
      </ButtonContainer>  
    </ContentPanel>
  )
}