//external
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableAction from 'components/v2/tableAction';
import VisibilityIcon from '@material-ui/icons/Visibility';
import RefreshIcon from '@material-ui/icons/Refresh';
import LockOpenIcon from '@material-ui/icons/LockOpen';
//internal
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import { getKeyPass, getTypes } from 'services/logs/logError/service';
import ContentPanel from 'components/ContentPanel';
import { setMessage } from 'store/modules/message/action';
import { sanitizeErrorUI } from 'utils/errorUtil';
import { formatDateTime } from 'utils/formatUtil';
import { getLogErrors, getAllows, getLogErrorsUsers } from 'services/logs/logError/service';
import DataTableReact from 'components/v2/dataTableReact';
import DataTableLoading from 'components/DataTableLoading';
import { useDebounce } from 'utils/uiUtils';
import GridContainer from 'components/v2/grid';
import SelectAutocomplete from 'components/v2/autocomplete';
import DateAndTimePickers from 'components/v2/dateTimePicker';
import { FilterLogError } from '..';
import { showAlert } from 'components/AlertDialog';
//style
import { IconContainer } from 'pages/nutrient/list/styled';

export function getTypeLabel(type) {
  switch (type) {
    case 'SYNC':
      return 'Sincronização';
    case 'GENERAL':
      return 'Geral';
    default:
      return '';
  }
}

export default function LogError() {
  const message = useSelector(state => state.message.data);
  const [reload, setReload] = useState(0);
  const [dataSync, setDataSync] = useState();
  const [allows, setAllows] = useState({});
  //pagination
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sort, setSort] = useState("");
  //select users
  const [searchUsers, setSearchUsers] = useState("");
  const [options, setOptions] = useState([]);
  const [types, setTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { filter, setFilter } = useContext(FilterLogError);

  const dispatch = useDispatch();

  const debouncedUsers = useDebounce(searchUsers);
  
  const loadUsers = useCallback((searchValue) => {
    setIsLoading(true)
    getLogErrorsUsers(searchValue).then(result => {
      const users = result.data.map(element => {
        return { value: element, label: `${element.name}` }
      });
      setOptions(users);
      setIsLoading(false)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      setIsLoading(false);
    });
  }, [dispatch]);

  const loadTypes = useCallback(() => {
    setIsLoading(true)
    getTypes().then(result => {
      const opts = result.data.map(element => {
        return { value: element, label: getTypeLabel(element) }
      });
      setTypes(opts);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      setIsLoading(false);
    })
  }, [dispatch]);

  useEffect(() => {
    if(loadUsers) loadUsers(debouncedUsers)
  },[debouncedUsers, loadUsers]);

  useEffect(() => {
    if(loadTypes) loadTypes();
  },[loadTypes]);

  useEffect(() => {
    dispatch(startRequest());
    getLogErrors(
      filter.user?.value?.username,
      filter.initialDate,
      filter.finalDate,
      filter.type?.value,
      perPage,
      filter.page,
      sort
    ).then(data => {
      setTotalRows(parseInt(data.headers['recordcount']));      
      setDataSync(data.data.map(value => {
        value.date = formatDateTime(value.date);

        return value;
      }));
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
    dispatch(startRequest());
    getAllows().then(data => {
      setAllows(data.data)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }, [dispatch, reload, filter.page, filter.user, filter.initialDate, filter.finalDate, filter.type, perPage, sort]);

  const columns = [
    {
      maxWidth: "25%",
      name: 'Usuário',
      selector: 'user',
      sortable: false,
    },
    {
      maxWidth: "15%",
      name: 'Data/Hora',
      selector: 'date',
      sortable: false,
    },
    {
      maxWidth: "15%",
      name: 'Tipo',
      selector: row => getTypeLabel(row.type),
      sortable: false,
    },
    {
      maxWidth: "10%",
      name: 'Versão',
      selector: 'appVersion',
      sortable: false,
    },
    {
      maxWidth: "25%",
      name: 'Url',
      selector: 'url',
      sortable: false,
    },
    {
      maxWidth: "10%",
      style: {justifyContent: "flex-end"},
      cell: row => 
        <div>
          <IconContainer>
            <TableAction 
            title={"Detalhes"} 
            disabled={!allows.put} 
            to={`/log_error/details/${row.id}`}>
              <VisibilityIcon/>
            </TableAction>
          </IconContainer>
        </div>
    }
  ];

  const showKeyPass = () => {
    dispatch(startRequest());
    getKeyPass().then((data) => {
      showAlert(data.data.keypass, 'Chave de Liberação', 'Ok')
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }

  const actions = [
    <div style={{display: 'flex'}} key="actionsLogError">
      <TableAction 
      title={"Chave de Liberação"} 
      disabled={!allows.get} 
      onClick={showKeyPass}>
        <LockOpenIcon/>
      </TableAction>
      <TableAction 
      key='refresh'
      title={"Atualizar"} 
      onClick={refreshHandler}
      isLink={false}>
        <RefreshIcon/>
      </TableAction>
    </div>
  ];

  const handlePerRowsChange = (perPage) => {
    setPerPage(perPage);
  };

  const handlePageChange = (page) => {
    setFilter({...filter, page});
  };

  const handleSort = (column, sortDirection) => {
    setSort(`${column.selector} ${sortDirection}`);
  };

  const updateInitalDateField = (date) => {
    setFilter({...filter, initialDate: date});
  }

  const updateFinalDateField = (date) => {
    setFilter({...filter, finalDate: date});
  }

  function refreshHandler() {
    setReload(reload + 1);
  }

  function handleSelectedUser(user) {
    if (user) {
      setFilter({...filter, page: 1, user: user});
    } else {
      setFilter({...filter, page: 1, user: null});
      loadUsers("");
      refreshHandler();
    }
  }

  function handleSelectedType(type) {
    if (type) {
      setFilter({...filter, page: 1, type: type});
    }else {
      setFilter({...filter, page: 1, type: null});
      refreshHandler();
    }
  }

  return (
    <ContentPanel title="Log de Erros" message={message} actions={actions}>
      <GridContainer>
        <SelectAutocomplete
          xs={8}
          sm={8}
          value={filter.user}
          label="Filtrar por usuário"
          optionSelected={(option, value) => option.label === value.label}
          optionLabel={(option) => option.label}
          onChangeSelected={(event, value) => handleSelectedUser(value)}
          onChange={(event) => setSearchUsers(event.target.value)}
          options={options}
          name="users"
          isLoading={isLoading}
          hasWarning={false}
        />
        <SelectAutocomplete
          xs={4}
          sm={4}
          value={filter.type}
          label="Buscar por tipo"
          optionSelected={(option, value) => option.label === value.label}
          optionLabel={(option) => option.label || ''}
          onChangeSelected={(event, value) => handleSelectedType(value)}
          options={types}
          name="type"
          hasWarning={false}
        />
        <DateAndTimePickers
          xs={12}
          sm={6}
          name="initialDate"
          label="Filtrar por data inicial"
          value={filter.initialDate}
          onChange={updateInitalDateField}
          cancelLabel="Cancelar"
          okLabel="Salvar"
          hasWarning={false}
        />
        <DateAndTimePickers
          xs={12}
          sm={6}
          name="finalDate"
          label="Filtrar por data Final"
          value={filter.finalDate}
          onChange={updateFinalDateField}
          cancelLabel="Cancelar"
          okLabel="Salvar"
          hasWarning={false}
        />
      </GridContainer>
      <DataTableReact
        noHeader
        fixedHeader
        columns={columns}
        data={dataSync}  
        pagination
        paginationServer
        progressComponent={<DataTableLoading />}
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        sortServer
        onSort={handleSort}  
      />
    </ContentPanel>
  )
}