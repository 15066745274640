//external
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//internal
import { getAllows, getFixedFields, putFixedFields } from 'services/rtpi/fixedFields/service';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import ContentPanel from 'components/ContentPanel';
import { validateForm } from 'components/Form/validate';
import { setMessage } from 'store/modules/message/action';
import { fixedFieldsSchema } from '../schemas';
import GridContainer from 'components/v2/grid';
import InputMultiline from 'components/v2/inputMultiline';
import { sanitizeErrorUI } from 'utils/errorUtil';
import { Warning } from 'routes/Route';
//style
import { Button, ButtonContainer } from 'styles/components';

export default function Nutrient({match}) {
  const message = useSelector(state => state.message.data);
  
  const [allows, setAllows] = useState({});
  const [dataFixedFields, setDataFixedFields] = useState({
    conservationCondition: "",
    productEnrichment: "",
    finishedProductDescription: ""
  })
  const [errors, setErrors] = useState([]);

  const { setNotWarning } = useContext(Warning);

  const dispatch = useDispatch();

  useEffect(() => {
    getAllows().then(data => {
      setAllows(data.data)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    });
  }, [dispatch]);

  useEffect(() => {    
    dispatch(startRequest());
    getFixedFields().then(data => {
      const formData = data.data;
      setDataFixedFields({
        conservationCondition: formData.conservationCondition || '',
        productEnrichment: formData.productEnrichment || '',
        finishedProductDescription: formData.finishedProductDescription || '',
      })
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });    
  }, [dispatch])

  async function handleSubmit () {
    setNotWarning();
    const errorsList = await validateForm(dataFixedFields, fixedFieldsSchema);
    if (errorsList.length > 0) {
      setErrors(errorsList);
      return;
    } 

    dispatch(startRequest());
    putFixedFields(dataFixedFields).then(data => {
      // history.push('/premixlabel/rtpi/rtpi_fixed_fields')
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }
  
  return (
    <ContentPanel title='RTPI - Campos Fixos' message={message}>
      <GridContainer>
        <InputMultiline
          xs={12}
          sm={6}
          name="conservationCondition"
          label="Condição de conservação"
          type="text"
          value={dataFixedFields.conservationCondition}
          onChange={(e) => setDataFixedFields({...dataFixedFields, conservationCondition: e.target.value})}
          errors={errors}
          disabled={!allows.put}
        />

        <InputMultiline
          xs={12}
          sm={6}
          name="productEnrichment"
          label="Enriquecimento do produto"
          type="text"
          value={dataFixedFields.productEnrichment}
          onChange={(e) => setDataFixedFields({...dataFixedFields, productEnrichment: e.target.value})}
          errors={errors}
          disabled={!allows.put}
        />

        <InputMultiline
          xs={12}
          name="finishedProductDescription"
          label="Descrição do produto acabado"
          type="text"
          value={dataFixedFields.finishedProductDescription}
          onChange={(e) => setDataFixedFields({...dataFixedFields, finishedProductDescription: e.target.value})}
          errors={errors}
          disabled={!allows.put}
        />
      </GridContainer>

      <ButtonContainer>
        <Button type="submit" onClick={handleSubmit}>Salvar</Button>
      </ButtonContainer>
    </ContentPanel>
  )
}