import api from '../api'

export async function getBillets(id_salesman, query, numOfRegisters = 10, page = 1, invoice) {
  const params = {
    id_salesman
  }
  if (query) {
    params.query = query;
  }
  
  return api.get(`billet/${id_salesman}`, {
    params,
    headers: {
      registerperpage: numOfRegisters,
      page
    }
  });
}

export async function getBillet(branch, num, prefix, parcel = '') {
  const _prefix = prefix?.trim() === '' ? 'EMPTY' : prefix;

  return api.get(`billet/${branch}/${num}/${_prefix}/${parcel}`);
};

export async function getBilletPdf(branch, num, prefix, parcel = '') {
  const _prefix = prefix?.trim() === '' ? 'EMPTY' : prefix;

  let url = `billet/pdfv2?branch=${branch}&num=${num}&prefix=${_prefix}${parcel ? `&parcel=${parcel}` : ''}`;
  
  return api.get(url, {responseType: 'blob'});
}
