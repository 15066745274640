//external
import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
//internal
import Route from '../../routes/Route';
import Product from './list';
import ProductEdit from './edit';

export const FilterRtpiProduct = createContext({});

export default function Routes() {
  const [filter, setFilter] = useState({
    search: "",
    page: 1,
  });

  let location = useLocation();

  useEffect(() => {
    const split = location?.pathname.split('/');
    if (split[2] !== "rtpi_product") {
      setFilter({
        search: "",
        page: 1,
      });
    }
  }, [location, setFilter]);

  return (
    <FilterRtpiProduct.Provider value={{ filter, setFilter }}>
      <Route path="/premixapp/rtpi_product" exact component={Product} isPrivate/>
      <Route path="/premixapp/rtpi_product/add" exact component={ProductEdit} isPrivate/>
      <Route path="/premixapp/rtpi_product/edit/:protheusBranch/:protheusCode" exact component={ProductEdit} isPrivate/>
    </FilterRtpiProduct.Provider>
  )
}