//external
import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
//internal
import Route from '../../routes/Route';
import NutrientGroupEdit from './edit';
import NutrientGroup from './list';
import NutrientGroupDelete from './delete';

export const FilterNutrientGroup = createContext({});

export default function Routes() {
  const [filter, setFilter] = useState({
    search: "",
    page: 1,
  });

  let location = useLocation();

  useEffect(() => {
    const split = location?.pathname.split('/');
    if (split[2] !== "nutrient_group") {
      setFilter({
        search: "",
        page: 1,
      });
    }
  }, [location, setFilter]);

  return (
    <FilterNutrientGroup.Provider value={{ filter, setFilter }}>
      <Route path="/premixlabel/nutrient_group" exact component={NutrientGroup} isPrivate/>
      <Route path="/premixlabel/nutrient_group/edit/:id" exact component={NutrientGroupEdit} isPrivate/>
      <Route path="/premixlabel/nutrient_group/add" exact component={NutrientGroupEdit} isPrivate/>
      <Route path="/premixlabel/nutrient_group/delete/:id" exact component={NutrientGroupDelete} isPrivate/>
    </FilterNutrientGroup.Provider>
  )
  }