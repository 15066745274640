import React from 'react';
import Route from 'routes/Route';
import SchedulingEdit from './edit';
import Scheduling from './list';

export default function Routes() {
  return (
    <>
      <Route path="/schedule/schedule" exact component={Scheduling} isPrivate/>
      <Route path="/schedule/schedule/edit/:id" exact component={SchedulingEdit} isPrivate/>
      <Route path="/schedule/schedule/add/:start" exact component={SchedulingEdit} isPrivate/>
      <Route path="/schedule/schedule/add" exact component={SchedulingEdit} isPrivate/>
    </>
  )
}  