import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Divider, Drawer, IconButton, makeStyles } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import SideMenu from '../SideMenu';
import logo from '../../assets/images/logo-branca.png';

const drawerWidth = 250;

const useStyles = makeStyles((theme) => ({
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    backgroundColor: theme.palette.primary.main,
    ...theme.mixins.toolbar,
    height: '58px'
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    minWidth: '72px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  closeMenu: {
    color: theme.palette.primary.contrastText,
  },
}));

export default function DrawerLayout({onClick, open}) {
  const classes = useStyles();

  return (   
    <Drawer
    style={{zIndex: 0}} 
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
    >
      <div className={classes.toolbarIcon}>
        <Link to="/">
          <img src={logo} alt="Premix" width="150" />
        </Link>
        <IconButton onClick={onClick} className={classes.closeMenu}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <SideMenu open={open}/>
    </Drawer>
  );
}