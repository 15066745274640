import * as constants from './constants';

export function storeRequest(name, email, password, messageCallback) {
  return {
    type: '@user/STORE_REQUEST',
    payload: {name, email, password, messageCallback}
  }
}

export function storeSuccess(user) {
  return {
    type: '@user/STORE_SUCCESS',
    payload: { user }
  }
}

export function storeFailure() {
  return {
    type: '@user/STORE_FAILURE'
  }
}

export function confirmRequest(email, confirmationCode, messageCallback) {
  return {
    type: '@user/CONFIRM_REQUEST',
    payload: { email, confirmationCode, messageCallback }
  }
}

export function confirmSuccess() {
  return {
    type: '@user/CONFIRM_SUCCESS',
    payload: { }
  }
}

export function confirmFailure() {
  return {
    type: '@user/CONFIRM_FAILURE'
  }
}

export function getAllowedsRequest() {
  return {
    type: '@user/ALLOWED_MODULES_REQUEST',
  }
}

export function getAllowedsSuccess(data) {
  return {
    type: '@user/ALLOWED_MODULES_SUCCESS',
    data,
  }
}

export function getAllowedsFailure(error) {
  return {
    type: '@user/ALLOWED_MODULES_FAILURE',
    error,
  }
}

export const myType = (data) => ({
  type: constants.SET_FILTER_USER, 
  payload: {
    id: data.id,
    name: data.name,
    nivel: data.nivel,
    me: data.me
  }
});

export const userExist = (isUser) => ({
  type: constants.IS_USER_EXIST, 
  payload: {
    isUserExist: isUser
  }
});