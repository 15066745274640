import produce from 'immer';

const INITIAL_STATE = {
  user: null,
  userAttributes: null,
  signed: false,
};

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    // case '@auth/LOGIN_REQUEST':
    //   return produce(state, draft => {})
    case '@auth/LOGIN_SUCCESS':
      return produce(state, draft => {
        draft.userAttributes = action.payload.user.attributes;
        draft.signed = true;
      })
    // case '@auth/LOGIN_FAILURE':
    //   return produce(state, draft => {})
    // case '@auth/LOGOUT_REQUEST':
    //   return produce(state, draft => {})
    case '@auth/LOGOUT_SUCCESS':
      return produce(state, draft => {
        draft.user = null;
        draft.userAttributes = null;
        draft.signed = false;
      })
    // case '@auth/LOGOUT_FAILURE':
    //   return produce(state, draft => {})
    case '@auth/UPDATE_USER_NAME_ATTRIBUTE':
      return produce(state, draft => {
        draft.userAttributes.name = action.payload.name;
      })
    case '@auth/UPDATE_USER_PICTURE_ATTRIBUTE':
      return produce(state, draft => {
        draft.userAttributes.picture = action.payload.picture;
      })
    case '@auth/NEW_PASSWORD_REQUIRED':
      /** Este trecho não usa produce() pois essa função muda o tipo do objeto "user" que deve ser do tipo CognitoUser */
      return {
        signed: false,
        userAttributes: action.payload.user.challengeParam.userAttributes,
        user: action.payload.user,
      };    
    default: 
      return state;
  }
}