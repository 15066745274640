//external
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//internal
import history from 'services/history';
import ContentPanel from 'components/ContentPanel'
import GridContainer from 'components/v2/grid';
import InputText from 'components/v2/inputText';
import { getBranch, putBranch } from 'services/branch/service';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import { setMessage } from 'store/modules/message/action';
import { branchSchema } from '../schemas';
import { validateForm } from 'components/Form/validate';
import InputMultiline from 'components/v2/inputMultiline';
import { validateEmail } from 'utils/validationUtil';
import { sanitizeErrorUI } from 'utils/errorUtil';
import { Warning } from 'routes/Route';
//style
import { Button, ButtonContainer } from 'styles/components'

export default function Branch({match}) {
  const message = useSelector(state => state.message.data);
  const [dataBranch, setDataBranch] = useState({
    protheusCode: "",
    description: "",
    descriptionAlias: "",
    activity: "",
    address: "",
    cifNumber: "",
    city: "",
    cnpj: "",
    establishmentName: "",
    ie: "",
    neighborhood: "",
    phone: "",
    zip: "",
    responsibleEmail: "",
    techManagerName: "",
    techManagerRegister: "",
    letterUnity: ""
  });
  const [emailErrors, setEmailErrors] = useState([]);
  const [errors, setErrors] = useState([]);

  const { setNotWarning } = useContext(Warning);

  const branchId = match.params.id;
  const dispatch = useDispatch();

  useEffect(() => {
    if (branchId) {
      dispatch(startRequest());
      getBranch(branchId).then(data => {
        const formData = data.data;
        setDataBranch({
          protheusCode: formData.protheusCode || "",
          description: formData.description || "",
          descriptionAlias: formData.descriptionAlias || "",
          activity: formData.activity || "",
          address: formData.address || "",
          cifNumber: formData.cifNumber || "",
          city: formData.city || "",
          cnpj: formData.cnpj || "",
          establishmentName: formData.establishmentName || "",
          ie: formData.ie || "",
          neighborhood: formData.neighborhood || "",
          phone: formData.phone || "",
          zip: formData.zip || "",
          responsibleEmail: formData.responsibleEmail || "",
          techManagerName: formData.techManagerName || "",
          techManagerRegister: formData.techManagerRegister || "",
          letterUnity: formData.letterUnity || "",
        })
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, branchId])
  
  async function handleSubmit () {
    setNotWarning();
    
    const emails = dataBranch.responsibleEmail;
    if(emails !== "" && emails !== undefined && emails !== null) {
      const lastSemicolon = emails[emails.length -1];
      const removeSemicolon = lastSemicolon === ";" ? emails.substring(0, emails.length -1) : emails;
      const listEmail = removeSemicolon.trim().split(";")
      const list = listEmail.map(item => {
      return validateEmail(item.trim());
      });
  
      const index = list.findIndex(i => !i);
      if (index > -1) {
        setEmailErrors([{field: "responsibleEmail", message: "E-mail inválido ou faltando separação por ;"}])
        return
      }
    }
    const errorsList = await validateForm(dataBranch, branchSchema);
    if (errorsList.length > 0) {
      setErrors(errorsList);
      return;
    } 
    
    if (!dataBranch.descriptionAlias) {
      dataBranch.descriptionAlias = '';
    }

    dispatch(startRequest());
    putBranch(branchId, dataBranch).then(data => {
      history.push('/premixlabel/branch')
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });     
  }
  
  return (
    <ContentPanel title='Filial' message={message}>
      <GridContainer>
        <InputText
          xs={12}
          sm={4}
          name="protheusCode"
          label="Código"
          type="text"
          required
          value={dataBranch.protheusCode}
          errors={errors}
          onChange={(e) => setDataBranch({...dataBranch, protheusCode: e.target.value})}
          disabled={true}
        />

        <InputText
          xs={12}
          sm={4}
          name="description"
          label="Descrição"
          type="text"
          required
          value={dataBranch.description}
          errors={errors}
          onChange={(e) => setDataBranch({...dataBranch, description: e.target.value})}
          disabled={true}
        /> 

        <InputText
          xs={12}
          sm={4}
          required={true}
          name="descriptionAlias"
          label="Rótulo"
          placeholder="Rótulo"
          type="text"
          value={dataBranch.descriptionAlias}
          onChange={(e) => setDataBranch({...dataBranch, descriptionAlias: e.target.value})}
          errors={errors}
        /> 
      
        <InputText
          xs={12}
          sm={4}
          name="phone"
          label="Telefone"
          placeholder="Telefone"
          type="numbr"
          value={dataBranch.phone}
          onChange={(e) => setDataBranch({...dataBranch, phone: e.target.value})}
        /> 

        <InputText
          xs={12}
          sm={4}
          name="cnpj"
          label="CNPJ"
          placeholder="CNPJ"
          type="numbr"
          value={dataBranch.cnpj}
          onChange={(e) => setDataBranch({...dataBranch, cnpj: e.target.value})}
        /> 

        <InputText
          xs={12}
          sm={4}
          name="establishmentName"
          label="Nome do Estabelecimento"
          placeholder="Nome do Estabelecimento"
          type="text"
          value={dataBranch.establishmentName}
          onChange={(e) => setDataBranch({...dataBranch, establishmentName: e.target.value})}
        /> 
      
        <InputText
          xs={12}
          sm={3}
          name="activity"
          label="Atividade"
          placeholder="Atividade"
          type="text"
          value={dataBranch.activity}
          onChange={(e) => setDataBranch({...dataBranch, activity: e.target.value})}  
        /> 
        
        <InputText
          xs={12}
          sm={3}
          name="cifNumber"
          label="CIF Número"
          placeholder="CIF Número"
          type="text"
          value={dataBranch.cifNumber}
          onChange={(e) => setDataBranch({...dataBranch, cifNumber: e.target.value})}
        /> 

        <InputText
          xs={12}
          sm={3}
          name="ie"
          label="Inscrição Estadual"
          placeholder="Inscrição Estadual"
          type="text"
          value={dataBranch.ie}
          onChange={(e) => setDataBranch({...dataBranch, ie: e.target.value})}
        /> 

        <InputText
          xs={12}
          sm={3}
          name="letterUnity"
          label="Letra Un. Fab."
          placeholder="Letra Un. Fab."
          type="text"
          value={dataBranch.letterUnity}
          onChange={(e) => setDataBranch({...dataBranch, letterUnity: e.target.value})}
        /> 

        <InputText
          xs={12}
          sm={6}
          name="address"
          label="Endereço"
          placeholder="Endereço"
          type="text"
          value={dataBranch.address}
          onChange={(e) => setDataBranch({...dataBranch, address: e.target.value})}
        /> 

        <InputText
          xs={12}
          sm={6}
          name="neighborhood"
          label="Bairro"
          placeholder="Bairro"
          type="text"
          value={dataBranch.neighborhood}
          onChange={(e) => setDataBranch({...dataBranch, neighborhood: e.target.value})}        
        /> 
          
        <InputText
          xs={12}
          sm={6}
          name="zip"
          label="CEP"
          placeholder="CEP"
          type="numbr"
          value={dataBranch.zip}
          onChange={(e) => setDataBranch({...dataBranch, zip: e.target.value})}        
        /> 

        <InputText
          xs={12}
          sm={6}
          name="city"
          label="Cidade"
          placeholder="Cidade"
          type="text"
          value={dataBranch.city}
          onChange={(e) => setDataBranch({...dataBranch, city: e.target.value})}        
        /> 
      
        <InputMultiline
          xs={12}
          name="responsibleEmail"
          label="E-mails"
          placeholder="exemplo@email.com.br;exemplo@email.com"
          type="email"
          value={dataBranch.responsibleEmail}
          onChange={(e) => setDataBranch({...dataBranch, responsibleEmail: e.target.value})}
          errors={emailErrors}  
        />

        <InputText
          xs={12}
          sm={6}
          name="techManagerName"
          label="Responsável Técnico"
          placeholder="Nome do responsável"
          type="numbr"
          value={dataBranch.techManagerName}
          onChange={(e) => setDataBranch({...dataBranch, techManagerName: e.target.value})}        
        /> 

        <InputText
          xs={12}
          sm={6}
          name="techManagerRegister"
          label="Número de Registro"
          placeholder="Número de registro do responsável"
          type="text"
          value={dataBranch.techManagerRegister}
          onChange={(e) => setDataBranch({...dataBranch, techManagerRegister: e.target.value})}        
        /> 
      </GridContainer>

      <ButtonContainer>
        <Button type="submit" onClick={handleSubmit}>Salvar</Button>
      </ButtonContainer>        
    </ContentPanel>  
  )
}