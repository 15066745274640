import * as Yup from 'yup';

export const rtpiPutSchema = Yup.object().shape({
    /*protheusBranch: Yup.string().required('Código protheus é obrigatório.'),
    protheusCode: Yup.string().required('Código protheus é obrigatório.'),
    protheusRevision: Yup.number().integer().required('A revisão do produto é obrigatório.'),*/
    conservationCondition: Yup.string(),
    productEnrichment: Yup.string(),
    finishedProductDescription: Yup.string(),
    productExpirationDate: Yup.number().integer().required('A validade do produto é obrigatória'),
    productClassification: Yup.string().required('Classificação é obrigatória.').nullable(),
    productPhysicalForm: Yup.string().required('Forma física é obrigatória.').nullable(),
    productPackageType: Yup.string().required('Tipo de embalagem é obrigatório.').nullable(),
    productPackageForm: Yup.string().required('Forma da embalagem é obrigatória.').nullable(),
    consumptionMin: Yup.number().required('Consumo Minimo (gr/Cab/Dia) é obrigatório.').typeError('Consumo Minimo (gr/Cab/Dia) é obrigatório.'),
    consumptionMax: Yup.number().required('Consumo Maximo (gr/Cab/Dia) é obrigatório.').typeError('Consumo Minimo (gr/Cab/Dia) é obrigatório.'),
    /*ProductRestriction: Yup.object().shape({
      id: Yup.number().integer().required('Restrição do produto acabado é obrigatória.'),
    }).required('Restrição do produto acabado é obrigatória.').typeError('Restrição do produto acabado é obrigatória.'),*/
    productIndication: Yup.string().required('Indicação é obrigatória.').nullable(),
    productUseMode: Yup.string().required('Modo de uso do produto acabado é obrigatório.'),
    /*RtpiIntentionWarrantyLevels: Yup.array().optional(),
    RtpiIntentionStructures: Yup.array().optional(),
    RtpiIntentionStructureSubstitutives: Yup.array().optional(),*/
  });