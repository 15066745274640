export const translateSchedule = {
  allDay: 'Dia todo',
  previous: 'Anterior',
  next: 'Próximo',
  today: 'Hoje',
  month: 'Mês',
  week: 'Semana',
  day: 'Dia',
  agenda: 'Agenda',
  date: 'Data',
  time: 'Hora',
  event: 'Evento',
};

export const getFormatedScheduleStatus = (status) => {
  switch (status) {
    case 'SCHEDULED':
      return 'Agendado';
    case 'CONFIRMED': 
      return 'Confirmado';
    case 'STARTED':
      return 'Iniciado';
    case 'FINISHED':
      return 'Finalizado';
    case 'CANCELED':
      return 'Cancelado';
    default:
      return '';
  }
};
