//external
import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
//internal
import Route from 'routes/Route';
import ScheduleTypeDelete from './delete';
import ScheduleTypeEdit from './edit';
import ScheduleType from './list';

export const FilterScheduleType = createContext({});

export default function Routes() {
  const [filter, setFilter] = useState({
    page: 1,
  });

  let location = useLocation();

  useEffect(() => {
    const split = location?.pathname.split('/');
    if (split[2] !== "schedule_type") {
      setFilter({
        page: 1,
      });
    }
  }, [location, setFilter]);

  return (
    <FilterScheduleType.Provider value={{ filter, setFilter }}>
      <Route path="/schedule/schedule_type" exact component={ScheduleType} isPrivate/>
      <Route path="/schedule/schedule_type/add" exact component={ScheduleTypeEdit} isPrivate/>
      <Route path="/schedule/schedule_type/edit/:id" exact component={ScheduleTypeEdit} isPrivate/>
      <Route path="/schedule/schedule_type/delete/:id" exact component={ScheduleTypeDelete} isPrivate/>
    </FilterScheduleType.Provider>
  )
}  