import * as Yup from 'yup';

export const customerSchema = Yup.object().shape({
  contacts: Yup.array().of(Yup.object().shape({
    id: Yup.number().integer(),
    name: Yup.string().when('id', (id, schema) => {
      return id ? schema : schema.required('Nome é obrigatório.')
    }),
    contactRole: Yup.string().when('id', (id, schema) => {
      return id ? schema : schema.required('Cargo do contato é obrigatorio.')
    }),
    email: Yup.string().email(),
    phone: Yup.string().when('id', (id, schema) => {
      return id ? schema : schema.required('Telefone é obrigatório')
    }),
    contactQualifierId: Yup.number().integer().when('id', (id, schema) => {
      return id ? schema : schema.required('Qualificação é obrigatório')
    }),
    active: Yup.bool(),
    notes: Yup.string()
  }))
}); 