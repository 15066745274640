import React, { useContext, useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getErrorMessage, getIsError } from 'utils/validationUtil';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Warning } from 'routes/Route';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 120,
    width: "100% !important"
  },
}));

export default function SelectAutocomplete({
  xs,
  sm,
  value,
  onChange,
  label,
  optionLabel,
  optionSelected,
  options,
  onChangeSelected,
  errors=[],
  name,
  id,
  required,
  isLoading,
  disabled,
  hasWarning = true
}) {

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [stateErrors, setStateErrors] = useState([]);
  const { setIsWarning } = useContext(Warning);

    const onChangeInput = (e) => {
      onChange(e);
      setStateErrors(stateErrors.filter(item => item.field !== name));
      if (hasWarning) {
        setIsWarning();
      }
    }

    const onChangeAutocomplete = (...props) => {
      onChangeSelected(...props);
      if (hasWarning) {
        setIsWarning();
      }
    }

    useEffect(() => {
      if(Array.isArray(errors) && errors.length > 0) {   
        setStateErrors(errors)
      }
    }, [errors]);

  return (
    <Grid item xs={xs} sm={sm}>
      <Autocomplete
        className={classes.root}
        id={id}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        value={value}
        getOptionSelected={optionSelected}
        getOptionLabel={optionLabel}
        options={options}
        onChange={(...props) => onChangeAutocomplete(...props)}
        loading={isLoading}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            onChange={(e) => onChangeInput(e)}
            name={name}
            required={required}
            error={getIsError(name, stateErrors)}
            helperText={getErrorMessage(name, stateErrors)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Grid>
  )
}