//external
import React, { useCallback, useContext, useEffect,  useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, MenuItem, Tab } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
//internal
import { getProduct, putProduct, findBranch, findProduct, hasRtpi, getCatalogImage } from 'services/rtpi/products/service';
import { findProductCategorySite } from 'services/rtpi/productCategorySite/service';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import history from 'services/history';
import ContentPanel from 'components/ContentPanel';
import { validateForm } from 'components/Form/validate';
import { setMessage } from 'store/modules/message/action';
import { productSchema } from '../schemas';
import Editor from 'components/Editor';
import GridContainer from 'components/v2/grid';
import InputText from 'components/v2/inputText';
import Toggle from 'components/v2/toggle';
import { sanitizeErrorUI } from 'utils/errorUtil';
import InputSelect from 'components/v2/inputSelect';
import SelectAutocomplete from 'components/v2/autocomplete';
import { useDebounce } from 'utils/uiUtils';
import { Warning } from 'routes/Route';
import DataTableReact from 'components/v2/dataTableReact';
import TableAction from 'components/v2/tableAction';
import TabComponent from 'components/v2/tabs';
import TabPanel from 'components/v2/tabPanel';
//style
import { Button, ButtonContainer } from 'styles/components';
import { IconContainer } from 'pages/appversion/styled';

export default function Line({match}) {
  const message = useSelector(state => state.message.data);
  const loading = useSelector(state => state.loading.loading);
  const {protheusBranch, protheusCode} = match.params;

  const [edit, setEdit] = useState(false);
  const [catalogRecommendation, setCatalogRecommendation] = useState(null);
  const [catalogNotes, setCatalogNotes] = useState(null);
  const [catalogConsumption, setCatalogConsumption] = useState(null);
  const [catalogUsemode, setCatalogUsemode] = useState(null);
  const [catalogImage, setCatalogImage] = useState(null);
  
  const [branch, setBranch] = useState(null);
  const [product, setProduct] = useState(null);
  
  const [isExported, setIsExported] = useState(false);
  const [isSiteExported, setIsSiteExported] = useState(false);

  const [productImageList, setProductImageList] = useState([]);
  
  const [dataProduct, setDataProduct] = useState({
    protheusBranch: "",
    protheusCode: "",
    description: "",
    descriptionAlias: "",
    categoriesSite: [],
    siteSeoTitle: "",
	  siteSlug: "",
	  siteMetaDescription: ""
  });

  //Tabs
  const [tabValue, setTabValue] = useState(0);
  
  // Categories Site
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [itemSelectedCategories, setItemSelectedCategories] = useState(null);
  const [searchCategory, setSearchCategory] = useState("");
  const [optionsCategory, setOptionsCategory] = useState([]);
  const [isLoadingCategory, setIsLoadingCategory] = useState(false);

  const [errors, setErrors] = useState([]);
  const [optionsBranch, setOptionsBranch] = useState([]);
  const [searchBranch, setSearchBranch] = useState("");
  const [optionsProduct, setOptionsProduct] = useState([]);
  const [searchProduct, setSearchProduct] = useState("");
  const [isLoadingBranch, setIsLoadingBranch] = useState(false);
  const [isLoadingProduct, setIsLoadingProduct] = useState(false);
  const [rtpiText, setRtpiText] = useState('');

  const { setNotWarning } = useContext(Warning);

  const debouncedSearchProduct = useDebounce(searchProduct);
  const debouncedSearchBranch = useDebounce(searchBranch);
  const debouncedSearchCategory = useDebounce(searchCategory);
  
  const dispatch = useDispatch();

  const loadProductCategorySite = useCallback((searchValue) => {
    setIsLoadingCategory(true)
    findProductCategorySite(searchValue).then(result => {
      const categories = result.data.map(element => {
        return { value: element, label: (element.parent?.description ? `${element.parent.description}\\${element.description}` : element.description) }
      });
      setOptionsCategory(categories);
      setIsLoadingCategory(false)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      setIsLoadingCategory(false);
    });
  }, [dispatch])

  useEffect(() => {
    if(loadProductCategorySite) loadProductCategorySite(debouncedSearchCategory)
  },[debouncedSearchCategory, loadProductCategorySite]);

  const loadBranch = useCallback((searchValue) => {
    setIsLoadingBranch(true);
    findBranch(searchValue).then(result => {
      const branchs = result.data.map(element => {
        return { value: element, label: `${element.protheusCode} - ${element.description}` }
      });
      setOptionsBranch(branchs);
      setIsLoadingBranch(false);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      setIsLoadingBranch(false);
    });
  }, [dispatch]);

  useEffect(() => {
    if(loadBranch) loadBranch(debouncedSearchBranch)
  },[debouncedSearchBranch, loadBranch]);
  
  const loadProduct = useCallback((searchValue) => {
    if (!branch && searchValue) {
      dispatch(setMessage({error: false, text: 'Selecione uma filial antes de pesquisar o produto'}));
      return;
    } else {
      setIsLoadingProduct(true)
      findProduct(searchValue, branch?.value?.protheusCode).then(result => {
        const products = result.data.map(element => {
          return { value: element, label: `${element.protheusCode} - ${element.description}` }
        });
        setOptionsProduct(products);
        setIsLoadingProduct(false)
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        setIsLoadingProduct(false);
      });
    }
  }, [branch, dispatch]);

  useEffect(() => {
    if(loadProduct) loadProduct(debouncedSearchProduct)
  },[debouncedSearchProduct, loadProduct]);

  useEffect(() => {
    if (dataProduct.protheusBranch && dataProduct.protheusCode) {
      dispatch(startRequest());
      hasRtpi(dataProduct.protheusBranch, dataProduct.protheusCode).then(data => {
        if (data.data.hasRtpi) {
          setRtpiText(<div style={{color: 'green'}}>Produto com RTPI lançada no sistema</div>);
        } else {
          setRtpiText(<div style={{color: 'red'}}>Produto sem RTPI lançada no sistema</div>);
        }
      }).catch(err => {
        setRtpiText(<></>);
      }).finally(() => {
        dispatch(finishRequest());
      });
    } else {
      setRtpiText(<></>);
    }
  }, [dataProduct.protheusBranch, dataProduct.protheusCode, dispatch]);

  useEffect(() => {
    getCatalogImage().then(data => {
      setProductImageList(data.data);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });

    if (protheusBranch && protheusCode) {
      setEdit(true);
      dispatch(startRequest());
      getProduct(protheusBranch, protheusCode).then(data => {
        const formData = data.data;
        setDataProduct({
          protheusBranch: formData.protheusBranch,
          protheusCode: formData.protheusCode,
          description: formData.description,
          descriptionAlias: formData.descriptionAlias,
          siteSeoTitle: formData.siteSeoTitle,
          siteSlug: formData.siteSlug,
          siteMetaDescription: formData.siteMetaDescription
        });
        setIsExported(formData.catalogExport);
        setIsSiteExported(formData.siteExport);
        setCatalogRecommendation(formData.catalogRecommendation);
        setCatalogNotes(formData.catalogNotes);
        setCatalogConsumption(formData.catalogConsumption);
        setCatalogUsemode(formData.catalogUsemode);
        setCatalogImage(formData.catalogImage);        
        setCatalogImage((formData.catalogImage || '').toLowerCase());
        setSelectedCategories(formData.categorySites);
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, protheusBranch, protheusCode]);

  async function handleSubmit () {
    setNotWarning();
    window.scrollTo(0,-20)

    const errorsList = await validateForm(dataProduct, productSchema);
    if (errorsList.length > 0) {
      setErrors(errorsList);
      return;
    } 

    const objCopy = JSON.parse(JSON.stringify(dataProduct));
    objCopy.catalogRecommendation = catalogRecommendation || '';
    objCopy.catalogNotes = catalogNotes || '';
    objCopy.catalogConsumption = catalogConsumption || '';
    objCopy.catalogUsemode = catalogUsemode || '';
    objCopy.catalogExport = isExported;
    objCopy.siteExport = isSiteExported;
    objCopy.catalogImage = catalogImage || null;

    const categories = selectedCategories.map(item => {
      if (item.ProductCategorySite) {
        return item.ProductCategorySite
      }
      return undefined
    });
    objCopy.categoriesSite = categories;

    dispatch(startRequest());
    putProduct(objCopy.protheusBranch, objCopy.protheusCode, objCopy).then(data => {
      history.push('/premixapp/rtpi_product')
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });    
  }

  function handleBranchChange(data) {
    const obj = {
      protheusBranch: data?.value?.protheusCode,
      protheusCode: "",
      description: "",
      descriptionAlias: "",
    };
    setDataProduct({...dataProduct, ...obj});
    setProduct(null);

    if (data) {
      setBranch(data);
    } else {
      setBranch(null);
      loadBranch("");
    }   
  }

  function handleProductChange(data) {
    const obj = {
      protheusCode: data?.value?.protheusCode,
      description: data?.value?.description,
      descriptionAlias: data?.value?.descriptionAlias,
    };
    setDataProduct({...dataProduct, ...obj});
    if (data) {
      setProduct(data);
    } else {
      setProduct(null);
      loadProduct("");
    }   
  }
  
  function handleCategoryChange(data) {
    if (data) {
      const categorySiteIndex = selectedCategories.findIndex(item => item.ProductCategorySite.id === data?.value?.id);
      setItemSelectedCategories(data);
      if (categorySiteIndex >= 0) return;

      setSelectedCategories([...selectedCategories, { ProductCategorySite: {
        id: data.value.id,
        active: data.value.active,
        description: data.value.description
      }}]); 
    } else {
      setItemSelectedCategories(null);
      loadProductCategorySite("");
    }   
  } 

  const columnsCategory = [    
    {
      maxWidth: "50%",
      name: 'Descrição',
      selector: 'ProductCategorySite.description',
      sortable: true,
    },
    {
      maxWidth: "25%",
      name: 'Ativo',
      selector: 'ProductCategorySite.active',
      sortable: true,
      cell: row => <input type='checkbox' checked={row.ProductCategorySite.active} readOnly />,
    },
    {
      maxWidth: "25%",
      style: {justifyContent: "flex-end"},
      cell: row => 
      <>
        <div>
          <IconContainer>
            <TableAction 
              title={"Excluir"} 
              onClick={() => handleRemoveCategorySite(row)}
            >
              <DeleteIcon/>
            </TableAction>
          </IconContainer>
        </div>
      </>,
    }
  ];

  function handleRemoveCategorySite(categorySite) {    
    var categorySiteIndex = selectedCategories.indexOf(categorySite);
    selectedCategories.splice(categorySiteIndex, 1);
    setSelectedCategories([...selectedCategories]);    
  }

  function contentElement(index, option) {
    return (
      <MenuItem key={index} value={option.key}><img style={{width: 40, marginRight: 10}} src={option.path} alt="option"/>{option.name}</MenuItem>
    )
  }

  function props(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  const tabsMenu = () => {
    return tabs.map((item, index) => (
      <Tab key={index} label={item.name} {...props(index)} />
    ))
  }

  const tabs = [
    {id: 1, name: 'Catálogo'},
    {id: 2, name: 'Site'},
  ]

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (    
    <ContentPanel title='Produto' message={message}>
      <GridContainer>
        {!edit && (
          <>
            <SelectAutocomplete
              xs={12}
              sm={12}
              value={branch}
              label="Buscar filial"
              optionSelected={(option, value) => option.label === value.label}
              optionLabel={(option) => option.label}
              onChangeSelected={(event, value) => handleBranchChange(value)}
              onChange={(event) => setSearchBranch(event.target.value)}
              options={optionsBranch}
              name="branch"
              isLoading={isLoadingBranch}
            />  
            <SelectAutocomplete
              xs={12}
              sm={12}
              value={product}
              label="Produto"
              optionSelected={(option, value) => option.label === value.label}
              optionLabel={(option) => option.label}
              onChangeSelected={(event, value) => handleProductChange(value)}
              onChange={(event) => setSearchProduct(event.target.value)}
              options={optionsProduct}
              name="product"
              isLoading={isLoadingProduct}
            />
          </>
        )}

        <Grid item sm={12}>
          {rtpiText}
        </Grid>

        <InputText
          xs={12}
          sm={6}
          required
          name="protheusBranch"
          label="Filial"
          type="text"
          value={dataProduct.protheusBranch}
          onChange={(e) => setDataProduct({...dataProduct, protheusBranch: e.target.value})}
          errors={errors} 
          disabled={true}
        />

        <InputText
          xs={12}
          sm={6}
          required
          name="protheusCode"
          label="Código"
          type="text"
          value={dataProduct.protheusCode}
          onChange={(e) => setDataProduct({...dataProduct, protheusCode: e.target.value})}
          errors={errors}
          disabled={true}
        /> 
      
        <InputText
          xs={12}
          sm={12}
          name="description"
          label="Descrição"
          type="text"
          value={dataProduct.description}
          onChange={(e) => setDataProduct({...dataProduct, description: e.target.value})}
          errors={errors}
          disabled={true}
        />        
      
        <TabComponent
          tabs={tabsMenu()} 
          onChangeTabs={handleChange} 
          value={tabValue}
          variant="fullWidth"
        >
          <>
            <TabPanel value={tabValue} index={0}>
              <GridContainer>
                <InputText
                  xs={12}
                  sm={12}
                  name="descriptionAlias"
                  label="Rótulo"
                  placeholder="Rótulo"
                  type="text"
                  value={dataProduct.descriptionAlias}
                  onChange={(e) => setDataProduct({...dataProduct, descriptionAlias: e.target.value})} 
                  errors={errors}
                /> 

                <InputSelect
                  xs={12}
                  sm={12}
                  name="catalogImage"
                  label="Imagem (Utilize somente para exceções, se estiver em branco o sistema usa a imagem da linha do produto)"
                  value={catalogImage}
                  onChange={(e) => setCatalogImage(e.target.value)}
                  defaultValue={null}
                  options={productImageList}
                  errors={errors}
                  contentElement={contentElement}
                />
              </GridContainer>

              {!loading && (
                <>
                  <Editor name="catalogRecommendation" markup={catalogRecommendation} label="Recomendação" onChange={(value) => setCatalogRecommendation(value)} />
                  <Editor name="catalogNotes" markup={catalogNotes} label="Notas" onChange={(value) => setCatalogNotes(value)} />
                  <Editor name="catalogConsumption" markup={catalogConsumption} label="Consumo" onChange={(value) => setCatalogConsumption(value)} />
                  <Editor name="catalogUsemode" markup={catalogUsemode} label="Modo de Uso" onChange={(value) => setCatalogUsemode(value)} />
                </>
              )}
              
              <Toggle
                name="catalogExport"
                checked={isExported}
                onChange={(e) => setIsExported(e.target.checked) }
                label="Exportar Catálogo"
              />
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              <GridContainer>
                <InputText
                  xs={6}
                  sm={6}
                  name="siteSeoTitle"
                  label="Titulo SEO"
                  placeholder="Titulo SEO"
                  type="text"
                  value={dataProduct.siteSeoTitle}
                  onChange={(e) => setDataProduct({...dataProduct, siteSeoTitle: e.target.value})} 
                  errors={errors}
                /> 
                <InputText
                  xs={6}
                  sm={6}
                  name="siteSlug"
                  label="Slug (gerado automáticamente)"
                  placeholder="Slug"
                  type="text"
                  value={dataProduct.siteSlug}
                  disabled={true}
                /> 
                <InputText
                  xs={12}
                  sm={12}
                  name="siteMetaDescription"
                  label="Descrição Meta"
                  placeholder="Descrição Meta"
                  type="text"
                  value={dataProduct.siteMetaDescription}
                  onChange={(e) => setDataProduct({...dataProduct, siteMetaDescription: e.target.value})} 
                  errors={errors}
                /> 
              
                <Toggle
                  name="catalogSite"
                  checked={isSiteExported}
                  onChange={(e) => setIsSiteExported(e.target.checked) }
                  label="Exportar Site"
                />

                <SelectAutocomplete
                  xs={12}
                  sm={12}
                  value={itemSelectedCategories}
                  label="Buscar categoria de produtos do site"
                  optionSelected={(option, value) => option.label === value.label}
                  optionLabel={(option) => option.label}
                  onChangeSelected={(event, value) => handleCategoryChange(value)}
                  onChange={(event) => setSearchCategory(event.target.value)}
                  options={optionsCategory}
                  name="branch"
                  isLoading={isLoadingCategory}
                /> 

                <DataTableReact
                  noHeader
                  columns={columnsCategory}
                  data={selectedCategories}
                />
              </GridContainer>
            </TabPanel>
          </>  
        </TabComponent>
      </GridContainer> 

      <ButtonContainer>
        <Button type="submit" onClick={handleSubmit}>Salvar</Button>
      </ButtonContainer>        
    </ContentPanel>
  )
}