//external
import React, { useEffect, useState, useCallback, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import _ from 'lodash';
import { Tab } from '@material-ui/core';
//internal
import { findSalesman } from 'services/salesman/service'
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import history from 'services/history';
import { getAllProfiles } from 'services/profiles/service';
import { validateEmail } from 'utils/validationUtil';
import ContentPanel from 'components/ContentPanel';
import { setMessage } from 'store/modules/message/action';
import SimpleTransferList from 'components/v2/simpleTransferList';
import InputText from 'components/v2/inputText';
import TableAction from 'components/v2/tableAction';
import Toggle from 'components/v2/toggle';
import { sanitizeErrorUI } from 'utils/errorUtil';
import SelectAutocomplete from 'components/v2/autocomplete';
import { useDebounce } from 'utils/uiUtils';
import { 
  getUser, 
  disableUser, 
  enableUser, 
  addUserToGroup, 
  removeUserFromGroup, 
  getProfilesFromUser, 
  updateUserAttributes, 
  getUserAttributes, 
  createUser, 
  findBranch, 
  resetPassword, 
  deleteUser,
  findUserGroups } from 'services/user/service'
  import DataTableReact from 'components/v2/dataTableReact';
  import TabComponent from 'components/v2/tabs';
import TabPanel from 'components/v2/tabPanel';
import { findTechnical } from 'services/technical/service';
import * as userActions from 'store/modules/user/actions';
//style
import { AttrName, Attr, IconContainer} from './styled';
import {Button, ButtonContainer, ActionButton } from 'styles/components'
import { Warning } from 'routes/Route';

export default function Profile({ match }) {
  const message = useSelector(state => state.message.data);
  
  let username = match.params.email;  
  let isEdit = username ? true : false;

  const [user, setUser] = useState();
  const [profiles, setProfiles] = useState([]);
  const [userProfiles, setUserProfiles] = useState([]);
  const [side, setSide] = useState("");
  const [rtpiAllBranches, setRtpiAllBranches] = useState(false);
  const [valueTabs, setValueTabs] = useState(0);
  //select Vendedores
  const [optionsSalesman, setOptionsSalesman] = useState([]);
  const [itemSelectedSalesman, setItemSelectedSalesman] = useState(null);
  const [searchSalesman, setSearchSalesman] = useState("");
  const [isLoadingSalesman, setIsLoadingSalesman] = useState(false);
  //select Filial 
  const [selectedBranchs, setSelectedBranchs] = useState([]);
  const [searchBranch, setSearchBranch] = useState("");
  const [optionsBranch, setOptionsBranch] = useState([]);
  const [itemSelectedBranch, setItemSelectedBranch] = useState(null);
  const [isLoadingBranch, setIsLoadingBranch] = useState(false);
  //select Grupo de Usuários
  const [selectedUserGroup, setSelectedUserGroup] = useState([]);
  const [searchUserGroup, setSearchUserGroup] = useState("");
  const [optionsUserGroup, setOptionsUserGroup] = useState([]);
  const [itemSelectedUserGroup, setItemSelectedUserGroup] = useState(null);
  const [isLoadingUserGroup, setIsLoadingUserGroup] = useState(false);
  //select Técnicos
  const [optionsTechnical, setOptionsTechnical] = useState([]);
  const [itemSelectedTechnical, setItemSelectedTechnical] = useState(null);
  const [searchTechnical, setSearchTechnical] = useState("");
  const [isLoadingTechnical, setIsLoadingTechnical] = useState(false);

  const { setNotWarning } = useContext(Warning);

  const dispatch = useDispatch();

  const debouncedSearchSalesman = useDebounce(searchSalesman);
  const debouncedBranch = useDebounce(searchBranch);
  const debouncedUserGroup = useDebounce(searchUserGroup);
  const debouncedSearchTechnical = useDebounce(searchTechnical);

  useEffect(() => {
    dispatch(userActions.userExist(itemSelectedSalesman?.value?.codigo ? true : false));
  }, [dispatch, itemSelectedSalesman]);

  function handleDisableUser(username) {
    dispatch(startRequest());
    disableUser(username).then(data => {
      loadUser();
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }

  function handleResetPassword(username) {
    dispatch(startRequest());
    resetPassword(username).then(data => {
      loadUser();
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }

  function handleDeleteUser(username) {
    dispatch(startRequest());
    deleteUser(username).then(data => {
      history.goBack();
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }

  function handleEnableUser(username) {
    dispatch(startRequest());
    enableUser(username).then(data => {
      loadUser();
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }

  const loadUser = useCallback(() => {
    if (isEdit) {
      dispatch(startRequest());
      getUser(username).then(data => {
        setUser(data.data);
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, isEdit, username]);

  const loadProfiles = useCallback(() => {
    /*Profiles*/
    dispatch(startRequest());
    getAllProfiles(999).then(data => {
      setProfiles(data.data);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }, [dispatch]);

  const loadUserProfiles = useCallback(() => {
    /*Profiles*/
    if (isEdit) {
      dispatch(startRequest());
      getProfilesFromUser(username).then(data => {
        setUserProfiles(data.data);
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, isEdit, username]);

  const loadUserAttributes = useCallback(() => {
    /*User Attributes*/
    if (isEdit) {
      dispatch(startRequest());
      getUserAttributes(username).then(result => {
          const branchs = result.data.branches || [];
          const userGroups = result.data.userGroups || [];
          setSelectedBranchs([...branchs]);
          setSelectedUserGroup([...userGroups]);
          setRtpiAllBranches(result.data.rtpiAllBranches || false);
        if (result.data?.salesman?.id) {
          setItemSelectedSalesman({
            value: result.data.salesman, label: `${result.data.salesman.id} - ${result.data.salesman.name}`
          });
        }
        if (result.data?.technical?.id) {
          setItemSelectedTechnical({
            value: result.data.technical, label: `${result.data.technical.name}`
          });
        }
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, isEdit, username]);

  function handleAddProfile(profile) {
    if (isEdit) {
      dispatch(startRequest());
      addUserToGroup(username, profile.group_name).then(data => {
        setUserProfiles(data.data);
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    } else {      
      var profileIndex = _.findIndex(profiles, (item) => item.group_name === profile.group_name);
      
      if (profileIndex < 0) {
        setUserProfiles([...userProfiles, profile]);
      } else {
        setUserProfiles([...userProfiles, profiles[profileIndex]]);
      }

      profiles.splice(profileIndex, 1);
      setProfiles([...profiles]);
    }
  }

  function handleRemoveProfile(profile) {
    if (isEdit) {
      dispatch(startRequest());
      removeUserFromGroup(username, profile.group_name).then(data => {
        setUserProfiles(data.data);
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    } else {
      var profileIndex = _.findIndex(userProfiles, (item) => item.group_name === profile.group_name);

      if (profileIndex < 0) {
        setProfiles([...profiles, profile]);
      } else {
        setProfiles([...profiles, userProfiles[profileIndex]]);
      }
      userProfiles.splice(profileIndex, 1);
      setUserProfiles([...userProfiles]);
    }
  }

  function maskProfiles(profiles) {
    return profiles.filter(elem => {
      // if (!userProfiles) return true;
      return userProfiles.findIndex(mod => mod.id === elem.id) < 0;
    });
  }

  const loadTechnical = useCallback((searchValue) => {
    setIsLoadingTechnical(true);
    findTechnical(searchValue).then(result => {
      const technical = result.data.map(element => {
        return { value: element, label: `${element.codigo} - ${element.nome}` }
      });
      setOptionsTechnical(technical);
      setIsLoadingTechnical(false);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      setIsLoadingTechnical(false);
    });
  }, [dispatch]);

  const loadSalesman = useCallback((searchValue) => {
    setIsLoadingSalesman(true);
    findSalesman(searchValue).then(result => {
      const salesmans = result.data.map(element => {
        return { value: element, label: `${element.codigo} - ${element.nome}` }
      });
      setOptionsSalesman(salesmans);
      setIsLoadingSalesman(false);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      setIsLoadingSalesman(false);
    });
  }, [dispatch])

  const loadUserGroup = useCallback((searchValue) => {
    setIsLoadingUserGroup(true);
    findUserGroups(searchValue).then(result => {
      const userGroup = result.data.map(element => {
        return { value: element, label: `${element.description}` }
      });
      setOptionsUserGroup(userGroup);
      setIsLoadingUserGroup(false);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      setIsLoadingUserGroup(false);
    });
  }, [dispatch])

  useEffect(() => {
    if(loadUserGroup) loadUserGroup(debouncedUserGroup)
  },[debouncedUserGroup, loadUserGroup]);

  
  function changeTypeUser(groupName, sidePosition) {
    if(sidePosition === "right") {
      setSide("left")
      handleRemoveProfile({group_name: groupName});
    }else {
      setSide("right")
      handleAddProfile({group_name: groupName})
    }
  }

  function handleSaveClick() {
    setNotWarning();
    if (!isEdit && !validateEmail(email)) {
      dispatch(setMessage({error: true, text: 'E-mail inválido, verifique!'}));
      return;
    }

    if (!isEdit && !name) {
      dispatch(setMessage({error: true, text: 'Nome é obrigatório!'}));
      return;
    }

    const normalizeUserGroups = selectedUserGroup.map(item => ({
      userGroupId: item.id,
    }));
  
    let userAttributes = {
      username: isEdit ? username : email,
      salesman: undefined,
      rtpiAllBranches: rtpiAllBranches || false,
      branches: selectedBranchs,
      userGroups: normalizeUserGroups
    }

    if (itemSelectedSalesman && itemSelectedSalesman.value) {
      userAttributes.salesman = {
        id: itemSelectedSalesman.value.codigo || itemSelectedSalesman.value.id,
        name: itemSelectedSalesman.value.nome || itemSelectedSalesman.value.name,
      }
    }

    if (itemSelectedTechnical && itemSelectedTechnical.value) {
      userAttributes.technical = {
        id: itemSelectedTechnical.value.codigo || itemSelectedTechnical.value.id,
        name: itemSelectedTechnical.value.nome || itemSelectedTechnical.value.name,
      }
    }
    
    dispatch(startRequest());
    if (isEdit) {
      updateUserAttributes(username, userAttributes).then(result => {
        dispatch(setMessage({success: true, text: result.data.message}));
        history.goBack();
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    } else {
      username = email;
      userAttributes.name = name;
      userAttributes.profiles = userProfiles;

      createUser(username, userAttributes).then(result => {
        dispatch(setMessage({success: true, text: result.data.message}));
        history.goBack();
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }

  useEffect(() => {
    loadUser();
    loadProfiles();
    loadUserProfiles();
    loadUserAttributes();
  }, [loadUser, loadProfiles, loadUserProfiles, loadUserAttributes])

  useEffect(() => {
    if(loadSalesman) loadSalesman(debouncedSearchSalesman)
  },[debouncedSearchSalesman, loadSalesman]);

  useEffect(() => {
    if(loadTechnical) loadTechnical(debouncedSearchTechnical)
  },[debouncedSearchTechnical, loadTechnical]);

  const loadBranch = useCallback((searchValue) => {
    setIsLoadingBranch(true);
    findBranch(searchValue).then(result => {
      const branchs = result.data.map(element => {
        element.branchProtheusCode = element.protheusCode;
        return { value: element, label: `${element.branchProtheusCode} - ${element.description}` }
      });
      setOptionsBranch(branchs);
      setIsLoadingBranch(false);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      setIsLoadingBranch(false);
    });
  }, [dispatch])

  function handleBranchInputChange(data, event) {
    if (data) {
      const branchIndex = selectedBranchs.findIndex(item => item.branchProtheusCode === data?.value?.branchProtheusCode);
      setItemSelectedBranch(data);
      if (branchIndex >= 0) return;

      setSelectedBranchs([...selectedBranchs, data.value]); 
    } else {
      setItemSelectedBranch(null);
      loadBranch("");
    }
  }

  function handleUserGroupInputChange(data, event) {
    if (data) {
      const userGroupIndex = selectedUserGroup.findIndex(item => item.id === data?.value?.id);
      setItemSelectedUserGroup(data);
      if (userGroupIndex >= 0) return;

    setSelectedUserGroup([...selectedUserGroup, data.value]); 
    } else {
      setItemSelectedUserGroup(null);
      loadUserGroup("");
    }
  }

  function handleRemoveBranch(branch) {    
    var branchIndex = selectedBranchs.indexOf(branch);
    selectedBranchs.splice(branchIndex, 1);
    setSelectedBranchs([...selectedBranchs]);    
  }

  function handleRemoveUserGroup(userGroup) {    
    var userGroupIndex = selectedUserGroup.indexOf(userGroup);
    selectedUserGroup.splice(userGroupIndex, 1);
    setSelectedUserGroup([...selectedUserGroup]);    
  }

  useEffect(() => {
    if(loadBranch) loadBranch(debouncedBranch)
  },[debouncedBranch, loadBranch]);
  
  const columnsBranchs = [
    {
      maxWidth: "25%",
      name: 'Código',
      selector: 'branchProtheusCode',
      sortable: true,
    },
    {
      maxWidth: "25%",
      name: 'Descrição',
      selector: 'description',
      sortable: true,
    },
    {
      maxWidth: "25%",
      name: 'Rótulo',
      selector: 'descriptionAlias',
      sortable: false,
    },
    {
      maxWidth: "25%",
      style: {justifyContent: "flex-end"},
      cell: row => 
      <>
        <div>
          <IconContainer>
            <TableAction 
            title={"Excluir"} 
            onClick={() => handleRemoveBranch(row)}>
              <DeleteIcon/>
            </TableAction>
          </IconContainer>
        </div>
      </>,
    }
  ];

  const columnsUserGroup = [
    {
      maxWidth: "75%",
      name: 'Descrição',
      selector: 'description',
      sortable: true,
    },
    {
      maxWidth: "25%",
      style: {justifyContent: "flex-end"},
      cell: row => 
      <>
        <div>
          <IconContainer>
            <TableAction 
            title={"Excluir"} 
            onClick={() => handleRemoveUserGroup(row)}>
              <DeleteIcon/>
            </TableAction>
          </IconContainer>
        </div>
      </>,
    }
  ];

  function useInput({ type, placeholder }) {
    const [value, setValue] = useState('');
    const input = <InputText type={type} value={value} placeholder={placeholder} onChange={e => setValue(e.target.value)} />;
    return [value, input];
  }

  const [name, nameInput] = useInput({ type: 'text', placeholder: 'Nome' });
  const [email, emailInput] = useInput({ type: 'email', placeholder: 'E-mail' });

  const tabsMenu = () => {
    return tabs.map((item, index) => (
      <Tab key={index} label={item.name} {...props(index)} />
    ))
  }

  function props(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  const tabs = [
    {id: 1, name: 'Perfis'},
    {id: 2, name: 'Grupo de Usuários'},
    {id: 3, name: 'Filiais - Impressão de Etiquetas'},
  ]

  const handleChange = (event, newValue) => {
    setValueTabs(newValue);
  };

  return (
    <ContentPanel title="Usuário" message={message}>
      {user && <>
        <div style={{display: 'flex'}}>
          { user.enabled ?
            <>
              <ActionButton type="button" onClick={() => handleDisableUser(user.username)}>Desativar usuário</ActionButton>
              <ActionButton type="button" onClick={() => handleResetPassword(user.username)}>Redefinir senha padrão</ActionButton>
            </>
            : 
            <>
              <ActionButton type="button" onClick={() => handleEnableUser(user.username)}>Ativar usuário</ActionButton>
              <ActionButton type="button" onClick={() => handleDeleteUser(user.username)} cancel>Excluir usuário</ActionButton>
            </>
          }
        </div>
        <AttrName>Usuário:</AttrName>
        <Attr>{user.username}</Attr>
        <AttrName>Status:</AttrName>
        <Attr>{user.enabled ? 'Ativo' : 'Inativo'} | {user.status}</Attr>
        <br />
      </>}  
      
      {!isEdit && <>
        {nameInput}
        <br />
        {emailInput}
      </>}

      <br /><br />

      <SelectAutocomplete
        xs={12}
        sm={12}
        value={itemSelectedSalesman}
        label="Vendedor"
        optionSelected={(option, value) => option.label === value.label}
        optionLabel={(option) => option.label}
        onChangeSelected={(event, value) => setItemSelectedSalesman(value)}
        onChange={(event) => setSearchSalesman(event.target.value)}
        options={optionsSalesman}
        name="nutrientGroup"
        isLoading={isLoadingSalesman}
      />
      <br/>
      <SelectAutocomplete
        xs={12}
        sm={12}
        value={itemSelectedTechnical}
        label="Técnico"
        optionSelected={(option, value) => option.label === value.label}
        optionLabel={(option) => option.label}
        onChangeSelected={(event, value) => setItemSelectedTechnical(value)}
        onChange={(event) => setSearchTechnical(event.target.value)}
        options={optionsTechnical}
        name="technical"
        isLoading={isLoadingTechnical}
      />
        
      <br />
      <TabComponent 
        tabs={tabsMenu()} 
        onChangeTabs={handleChange} 
        value={valueTabs}
        variant="fullWidth"
      >
        <>
          <TabPanel value={valueTabs} index={0}>
            {profiles &&             
              <SimpleTransferList
                leftList={maskProfiles(profiles)}
                rightList={userProfiles}
                onChangeList={(groupName, sidePosition) => changeTypeUser(groupName, sidePosition)}
                side={side}
                valueItem={"group_name"}
                labelItem={"name"}
              />
            }
          </TabPanel>

          <TabPanel value={valueTabs} index={1}>
            <SelectAutocomplete
              xs={12}
              sm={12}
              value={itemSelectedUserGroup}
              label="Buscar Grupo de Usuários"
              optionSelected={(option, value) => option.label === value.label}
              optionLabel={(option) => option.label}
              onChangeSelected={(event, value) => handleUserGroupInputChange(value)}
              onChange={(event) => setSearchUserGroup(event.target.value)}
              options={optionsUserGroup}
              name="userGroups"
              isLoading={isLoadingUserGroup}
            />

            <DataTableReact
              noHeader
              columns={columnsUserGroup}
              data={selectedUserGroup}
            />
          </TabPanel>

          <TabPanel value={valueTabs} index={2}>
            <SelectAutocomplete
              xs={12}
              sm={12}
              value={itemSelectedBranch}
              label="Buscar Filial"
              optionSelected={(option, value) => option.label === value.label}
              optionLabel={(option) => option.label}
              onChangeSelected={(event, value) => handleBranchInputChange(value)}
              onChange={(event) => setSearchBranch(event.target.value)}
              options={optionsBranch}
              name="branches"
              isLoading={isLoadingBranch}
            />

            <DataTableReact
              noHeader
              columns={columnsBranchs}
              data={selectedBranchs}
            />
            <br />
            <Toggle
              name="catalogExport"
              checked={rtpiAllBranches}
              onChange={(e) => setRtpiAllBranches(e.target.checked) }
              label="Permitir visualizar todas as filiais"
            /> 
          </TabPanel>
        </>
      </TabComponent>
      
      <ButtonContainer>
        <Button type="button" onClick={handleSaveClick}>Salvar</Button>
      </ButtonContainer>
    </ContentPanel>
  )
}