//external
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//internal
import { deleteComponentGroup, getComponentGroup } from 'services/componentGroup/service';
import history from 'services/history';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import ContentPanel from 'components/ContentPanel';
import { setMessage } from 'store/modules/message/action';
import { sanitizeErrorUI } from 'utils/errorUtil';
//style
import { Attr, AttrName } from './styled';
import { Button, ButtonContainer } from 'styles/components';

export default function ComponentGroupDelete({match}) {
  const ComponentGroupId = match.params.id;
  const message = useSelector(state => state.message.data);
  const [componentGroup, setComponentGroup] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    if (ComponentGroupId) {
      dispatch(startRequest());
      getComponentGroup(ComponentGroupId).then(data => {
        setComponentGroup(data.data);
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, ComponentGroupId]);

  async function handleDelete () {
    dispatch(startRequest());
    deleteComponentGroup(ComponentGroupId).then(data => {
      history.push('/premixLabel/component_group')
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }

  async function handleBack () {
    history.goBack();
  }

  return (
    <>
      <ContentPanel title="Deseja excluir esta matéria prima?" message={message}>
        {componentGroup && <>
          <AttrName>Descrição:</AttrName>
          <Attr>{componentGroup.description}</Attr>
          <AttrName>Ordem:</AttrName>
          <Attr>{componentGroup.order}</Attr>
          <ButtonContainer>
            <br />
            <Button left={true} cancel={true} onClick={handleBack}>
              Voltar
            </Button>
            <Button onClick={handleDelete}>
              Excluir
            </Button>
          </ButtonContainer>
        </>}
      </ContentPanel>
    </>
  )
}