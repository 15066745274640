import api from '../api'

export async function getAllows() {
  return api.get('checklist/allows');
}

export async function getChecklists(numOfRegisters = 10, page = 1, order) {
  const params = {
    order: order ? order : 'description',
  }

  return api.get('checklist', {
    params,
    headers: {
      registerperpage: numOfRegisters,
      page
    }
  });
}

export async function getChecklist(id) {
  return api.get(`checklist/${id}`);
}

export async function getChecklistAnswer(id) {
  return api.get(`checklist/answer/${id}`);
}

export async function getFieldTypes() {
  return api.get('checklist/fieldtypes');
}

export async function postChecklist(checklist) {
  return api.post(`checklist/`, checklist);
}

export async function putChecklist(id, data) {
  return api.put(`checklist/${id}`, data);
}

export async function deleteChecklist(id) {
  return api.delete(`checklist/${id}`);
}