import * as Yup from 'yup';

export const nutrientSchema = Yup.object().shape({
  optimixCode: Yup.string().required('Código é obrigatório.'),
  description: Yup.string().required('Descrição é obrigatória.'),
  unity: Yup.string().required('Unidade é obrigatória.'),
  descriptionAlias: Yup.string().nullable(),
  valueType: Yup.string().oneOf(
    ['Igual', 'Minimo', 'Maximo', 'Minimo e maximo'], 'Tipos de valores é obrigatório.'
  )
});