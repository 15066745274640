//external
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
//internal
import { getAllProfiles, getAllows } from 'services/profiles/service';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import ContentPanel from 'components/ContentPanel';
import { setMessage } from 'store/modules/message/action';
import TableAction from 'components/v2/tableAction';
import { sanitizeErrorUI } from 'utils/errorUtil.js';
import DataTableReact from 'components/v2/dataTableReact/index.js';
import { FilterProfile } from '../index.js';
//style
import { IconContainerProd } from '../styled.js';

export default function Profile() {
  const message = useSelector(state => state.message.data);
  
  const [profiles, setProfiles] = useState();
  const [allows, setAllows] = useState({});
  const [reload, setReload] = useState(0);
  //pagination
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const { filter, setFilter } = useContext(FilterProfile);

  const dispatch = useDispatch();

  const columns = [
    {
      name: 'Nome',
      selector: 'name',
      sortable: true,
      maxWidth: "39%",
    },
    {
      name: 'Ativo',
      selector: 'active',
      sortable: true,
      cell: row => <input type='checkbox' checked={row.active} readOnly />,
      maxWidth: "29%",
    },
    {
      style: {justifyContent: "flex-end"},
      cell: row => 
      <div>
        <IconContainerProd>
          <TableAction 
          title={"Editar"} 
          disabled={!allows.put} 
          to={`/userprofiles/edit/${row.id}`}>
            <EditIcon/>
          </TableAction>
          <TableAction 
          title={"Excluir"} 
          disabled={!allows.delete} 
          to={`/userprofiles/delete/${row.id}`}>
            <DeleteIcon/>
          </TableAction>
        </IconContainerProd>
      </div>
    }
  ];

  const actions = [
    <div style={{display: 'flex'}} key='actionsProfile'>
      <TableAction 
      title={"Adicionar"} 
      disabled={!allows.post} 
      to='/userprofiles/add'>
        <AddIcon/>
      </TableAction>
      <TableAction 
      title={"Atualizar"} 
      onClick={refreshHandler}
      isLink={false}>
        <RefreshIcon/>
      </TableAction>
    </div>
  ];

  function refreshHandler() {
    setReload(reload + 1);
  }

  useEffect(() => {
    dispatch(startRequest());
    getAllProfiles(perPage, filter.page).then(data => {
      setTotalRows(parseInt(data.headers['recordcount']));
      setProfiles(data.data);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
    dispatch(startRequest());
    getAllows().then(data => {
      setAllows(data.data)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }, [dispatch, reload, perPage, filter.page]);

  const handlePerRowsChange = (perPage) => {
    setPerPage(perPage);
  };

  const handlePageChange = (page) => {
    setFilter({...filter, page});
  };

  return (
    <>
      <ContentPanel title="Perfis" message={message} actions={actions}>
        <DataTableReact
          noHeader
          columns={columns}
          data={profiles}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationDefaultPage={filter.page}
        />
      </ContentPanel>
    </>
  )
}