//external
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableAction from 'components/v2/tableAction';
import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import DataTableReact from 'components/v2/dataTableReact';
//internal
import ContentPanel from 'components/ContentPanel';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import { setMessage } from 'store/modules/message/action';
import { sanitizeErrorUI } from 'utils/errorUtil';
import { getAllows, getProductCategoriesSite } from 'services/rtpi/productCategorySite/service';
import { useDebounce } from 'utils/uiUtils';
import GridContainer from 'components/v2/grid';
import InputText from 'components/v2/inputText';
import DataTableLoading from 'components/DataTableLoading';
import { FilterProductCategorySite } from '..';
//style
import { IconContainer } from 'pages/nutrient/list/styled';

export default function ProductCategorySite() {
  const message = useSelector(state => state.message.data);
  const [reload, setReload] = useState(0);
  const [dataProductCategorySite, setDataProductCategorySite] = useState();
  const [allows, setAllows] = useState({});
  //pagination
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sort, setSort] = useState();

  const dispatch = useDispatch();

  const { filter, setFilter } = useContext(FilterProductCategorySite);

  const debouncedSearchProductCategorySite = useDebounce(filter.search);

  const columns = [
    {
      name: 'Descrição',
      selector: 'description',
      sortable: true,
    },
    {
      name: 'Ativo',
      selector: 'active',
      sortable: true,
      cell: row => <input type='checkbox' checked={row.active} readOnly />,
      maxWidth: "29%",
    },
    {
      name: 'Categoria Pai',
      selector: row => row.parent ? row.parent.description : '',
      sortable: true,
    },
    {
      style: {justifyContent: "flex-end"},
      cell: row => 
      <>
        <div>
          <IconContainer>
            <TableAction 
              title={"Editar"} 
              disabled={!allows.put} 
              to={`/premixapp/rtpi_product_category_site/edit/${row.id}`}>
                <EditIcon/>
            </TableAction>
            <TableAction 
              title={"Excluir"} 
              disabled={!allows.delete} 
              to={`/premixapp/rtpi_product_category_site/delete/${row.id}`}>
                <DeleteIcon/>
            </TableAction>
          </IconContainer>
        </div>
      </>,
    }
  ]

  const actions = [
    <div style={{display: 'flex'}} key="actionsProductCategorySite">
      <TableAction 
        title={"Adicionar"} 
        disabled={!allows.post} 
        to='/premixapp/rtpi_product_category_site/add'>
          <AddIcon/>
      </TableAction>
      <TableAction 
        title={"Atualizar"} 
        onClick={refreshHandler}
        isLink={false}>
          <RefreshIcon/>
      </TableAction>
    </div>
  ];

  useEffect(() => {
    dispatch(startRequest());
    getProductCategoriesSite(debouncedSearchProductCategorySite, perPage, filter.page, sort).then(data => {
      setTotalRows(parseInt(data.headers['recordcount']));
      setDataProductCategorySite(data.data);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
    dispatch(startRequest());
    getAllows().then(data => {
      setAllows(data.data)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }, [dispatch, reload, debouncedSearchProductCategorySite, perPage, filter.page, sort, setFilter]);

  function refreshHandler() {
    setReload(reload + 1);
  }

  const handlePerRowsChange = (perPage) => {
    setPerPage(perPage);
  };

  const handlePageChange = (page) => {
    setFilter({...filter, page});
  };

  const handleSort = (column, sortDirection) => {
    setSort(`${column.selector} ${sortDirection}`);
  };

  return (
    <ContentPanel title="Categorias de Produtos do Site" message={message} actions={actions}>
      <GridContainer>
        <InputText
          xs={12}
          name="name"
          label="Pesquisar"
          type="text"
          placeholder="Pesquisar"
          value={filter.search}
          onChange={(e) => setFilter({page: 1, search: e.target.value})}
          hasWarning={false}
        />
      </GridContainer>
      <DataTableReact
        noHeader
        fixedHeader
        columns={columns}
        data={dataProductCategorySite}
        pagination
        paginationServer
        progressComponent={<DataTableLoading />}
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        sortServer
        onSort={handleSort}
        paginationDefaultPage={filter.page}
      />
    </ContentPanel>
  )
}