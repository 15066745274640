import React from 'react';
import { ContentWapper, Title, Line } from './styled'
import Message from '../Message';
import Errors from 'components/Errors';

export default function ContentPanel({children, message, title, actions}) {
  return (
    <ContentWapper style={{margin: "15px 0", height: "100%"}}>
      {title && <>
        <div style={{display: 'flex', flex: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <Title>{title}</Title>

            <div style={{display: 'flex', flex: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
              {actions}
            </div>
        </div>
        <Line />
      </>}
      {message && message.text && <>
        <Message message={message} />
        { message.messageList && Array.isArray(message.messageList) && message.messageList.length > 0 && <>
          <Errors errors={message.messageList} />  
        </>}
        <Line />
      </>}
      {children}
    </ContentWapper>
  )
}