//external
import React, { useCallback, useEffect, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button as MuiButton, Typography, Grid, makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PrintIcon from '@material-ui/icons/Print';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DescriptionIcon from '@material-ui/icons/Description';
//internal
import history from 'services/history';
import { getDocuments, findDocumentTypes } from 'services/document/service';
import { postDocumentFile } from 'services/commission/service';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import ContentPanel from 'components/ContentPanel';
import Message from 'components/Message';
import { setMessage } from 'store/modules/message/action';
import TableAction from 'components/v2/tableAction';
import { sanitizeErrorUI } from 'utils/errorUtil.js';
import DataTableReact from 'components/v2/dataTableReact/index.js';
import GridContainer from 'components/v2/grid';
import { useDebounce } from 'utils/uiUtils';
import InputText from 'components/v2/inputText';
import SelectAutocomplete from 'components/v2/autocomplete';
import DateAndTimePickers from 'components/v2/dateTimePicker';
import { formatStatusDocument } from 'utils/formatUtil';
import { formatDateTime } from 'utils/formatUtil';
import { getFileId } from 'services/files/service.js';
import { showReport } from 'utils/reportUtil';
import { FilterCommission } from '..';
//style
import { IconContainerProd } from '../../profiles/styled';
import { Button, ButtonContainer } from 'styles/components';
import { typeDocument } from '../schemas';

const useStyles = makeStyles((theme) => ({
  MuiSvgIcon: {
    height: '95px',
    cursor: 'pointer'
  }
}));


const CustomStatus = ({ row }) => (
	<div>
		<div 
      data-tag="allowRowEvents"
			style={{ 
        overflow: 'hidden', 
        whiteSpace: 'pre-wrap', 
        textOverflow: 'ellipses',
      }}
    >
      <span style={{
        color: row.status === 'APPROVED' ? "#008000" : row.status === 'REPROVED' ? "#dc3545" : "#ffcc00",
        fontWeight: 'bold',
      }}>
        {formatStatusDocument(row.status)}
      </span>
    </div>
	</div>
);

export default function DocumentCommission() {
  const classes = useStyles();
  const message = useSelector(state => state.message.data);
  const {id, name, me} = useSelector(state => state.user);
  
  const [documents, setDocuments] = useState();  
  const [reload, setReload] = useState(0);
  const [messageError, setMessageError] = useState({
    error: false,
    text: '',
  });
  //pagination
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  //select types
  const [searchTypes, setSearchTypes] = useState("");
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  //document
  const [selectedFile, setSelectedFile] = useState(null);
  const [invoiceId, setInvoiceId] = useState(null);
  //dialog
  const [open, setOpen] = useState(false);

  const { filter, setFilter } = useContext(FilterCommission);

  const debouncedTypes = useDebounce(searchTypes);
  const debouncedSearch = useDebounce(filter.search);

  const dispatch = useDispatch();

  const loadTypes = useCallback((searchValue) => {
    setIsLoading(true)
    findDocumentTypes(searchValue).then(result => {
      const types = result.data.map(element => {
        return { value: element, label: `${element.name}` }
      });
      setOptions(types);
      setIsLoading(false)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      setIsLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    if(loadTypes) loadTypes(debouncedTypes)
  },[debouncedTypes, loadTypes]);

  async function handleBack () {
    history.goBack();
  }

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setSelectedFile(null);
    setInvoiceId(null);
    setOpen(false);
  }

  const columns = [
    {
      name: 'Nome',
      selector: 'name',
      sortable: true,
    },
    // {
    //   name: 'Usuário',
    //   selector: 'username',
    //   sortable: true,
    // },
    // {
    //   name: 'Vendedor',
    //   selector: row => `${row.salesmanId} - ${row.salesmanName}`,
    //   sortable: true,
    // },
    {
      name: 'Status',
      cell: row => <CustomStatus row={row} />,
      sortable: true,
    },
    {
      name: 'Tipo',
      selector: row => row?.DocumentType?.name || '',
      sortable: true,
    },
    {
      name: 'Data',
      selector: 'createdAt',
      sortable: true,
    },
    {
      name: 'Observação',
      selector: 'notes'
    },
    {
      style: {justifyContent: "flex-end"},
      cell: row => 
      <div>
        <IconContainerProd>
          <TableAction 
            title={"Visualizar"}
            to={`/commission/document/edit/${row.id}`}
          >
              <VisibilityIcon />
          </TableAction>
          <TableAction 
            title={"Imprimir"}
            onClick={() => openDocument(row?.File)}
            isLink={false}
          >
            <PrintIcon />
          </TableAction>          
        </IconContainerProd>
      </div>
    }
  ];

  const actions = [
    <div style={{display: 'flex'}} key='actionsDocument'>
      <TableAction 
        title={"Adicionar"} 
        onClick={handleOpen}
        isLink={false}
      >
        <AddIcon/>
      </TableAction>
      <TableAction 
        title={"Atualizar"} 
        onClick={refreshHandler}
        isLink={false}
      >
        <RefreshIcon/>
      </TableAction>
    </div>
  ];

  function refreshHandler() {
    setReload(reload + 1);
  }

  function openDocument(file) {
    if (!file) {
      return;
    }
    
    if (file?.id && file?.fileUuid) {
      dispatch(startRequest());
      getFileId(file.id, file.fileUuid).then(async (response) => {   
        if (response?.data) {
          showReport(response.data)
        }
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }

  useEffect(() => {
    dispatch(startRequest());
    getDocuments(debouncedSearch, filter.status?.value, filter.type?.value?.id, filter?.initialDate, filter?.finalDate, perPage, filter.page, null, true).then(data => {
      setTotalRows(parseInt(data.headers['recordcount']));
      setDocuments(data.data);
      setDocuments(data.data.map(value => {
        value.createdAt = formatDateTime(new Date(value.createdAt));

        return value;
      }));
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, reload, perPage, filter.page, debouncedSearch, filter.type, filter.status, filter.initialDate, filter.finalDate]);

  const handlePerRowsChange = (perPage) => {
    setPerPage(perPage);
  };

  const handlePageChange = (page) => {
    setFilter({...filter, page});
  };

  function handleSelectedType(type) {
    if (type) {
      setFilter({...filter, page: 1, type: type});
    }else {
      setFilter({...filter, page: 1, type: null});
      loadTypes("");
      refreshHandler();
    }
  }

  function handleSelectedStatus(status) {
    if (status) {
      setFilter({...filter, page: 1, status: status});
    }else {
      setFilter({...filter, page: 1, status: null});
      refreshHandler();
    }
  }

  const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
    setMessageError({error: false, text: ''});
	};

  async function postFile() {
    if (!selectedFile) {        
      setMessageError({error: true, text: 'Arquivo é obrigatório.'});        
      return;
    }

    if (!invoiceId) {
      setMessageError({error: true, text: 'Número da NF é obrigatório.'});
      return;
    }

    if (!me) {
      setMessageError({error: true, text: 'Apenas o próprio usuário pode enviar nota fiscal.'});        
      return;
    }

    return new Promise((resolve, reject) => {      
      const data = {
        name: selectedFile.name,
        typeId: typeDocument.NFS_COMISSAO_RC.typeId,
        salesmanId: id,
        salesmanName: name,
        active: true,
        invoiceId: invoiceId,
      }

      dispatch(startRequest());
      postDocumentFile(selectedFile, data).then(data => {
        resolve(data.data);        
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
        reject(err);
      }).finally(() => {
        dispatch(finishRequest());
        handleClose();
        refreshHandler();
      });
    });
  }

  const updateInitalDateField = (date) => {
    setFilter({...filter, initialDate: date});
  }

  const updateFinalDateField = (date) => {
    setFilter({...filter, finalDate: date});
  }

  return (
    <ContentPanel title="Documentos" message={message} actions={actions}>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>          
          <DialogTitle style={{padding: '16px 0px'}} id="alert-dialog-title">
            Selecionar arquivo
          </DialogTitle>          
          <DialogContentText id="alert-dialog-description">
            Selecione um arquivo para enviar.
            Ao enviar o documento selecionado, o mesmo será analisado pelo setor responsável.
          </DialogContentText>           
          <GridContainer>            
            <Grid item xs={12}>
              <Typography color="textPrimary" variant="subtitle1" align="center">
                <div style={{marginLeft: '40%'}} className="img-upload">
                  <label>          
                    <DescriptionIcon className={classes.MuiSvgIcon} fontSize="large" color="action" />          
                    <input 
                      name="file"                     
                      type="file"
                      accept="application/pdf" 
                      onChange={changeHandler}
                    />
                  </label>
                </div>
              </Typography>              
            </Grid>
            <Grid item xs={12}>
              <Typography color="textPrimary" variant="subtitle1" align="center">
                {selectedFile ? `Nome do arquivo: ${selectedFile.name}` : "Nenhum arquivo selecionado"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
            <InputText
              xs={12}
              sm={12}
              name="invoiceId"
              label="Número da NF"
              type="text"
              placeholder="Número da NF"
              value={invoiceId}
              onChange={(e) => setInvoiceId(e.target.value)}
              hasWarning={false}
              required
            />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" align="center">
                <Message message={messageError} />
              </Typography>
            </Grid>
          </GridContainer>
        </DialogContent>        
        <DialogActions>
          <MuiButton onClick={handleClose} color="secondary">
            Cancelar
          </MuiButton>
          <MuiButton onClick={() => postFile()} color="primary" disabled={!invoiceId || String(invoiceId).trim() === ''}>
            Enviar
          </MuiButton>
        </DialogActions>        
      </Dialog>
      <GridContainer>
        <InputText
          xs={12}
          name="search"
          label="Pesquisar"
          type="text"
          placeholder="Pesquisar"
          value={filter.search}
          onChange={(e) => setFilter({...filter, page: 1, search: e.target.value})}
          hasWarning={false}
        />        
        <SelectAutocomplete
          xs={12}
          sm={6}
          value={filter.type}
          label="Buscar Tipo de Documento"
          optionSelected={(option, value) => option.label === value.label}
          optionLabel={(option) => option.label || ''}
          onChangeSelected={(event, value) => handleSelectedType(value)}
          onChange={(event) => setSearchTypes(event.target.value)}
          options={options}
          name="types"
          isLoading={isLoading}
          hasWarning={false}
        />
        <SelectAutocomplete
          xs={12}
          sm={6}
          value={filter.status}
          label="Buscar Status"
          optionSelected={(option, value) => option.label === value.label}
          optionLabel={(option) => option.label || ''}
          onChangeSelected={(event, value) => handleSelectedStatus(value)}
          options={[
            {value: 'PENDING' , label: 'Pendente' },
            {value: 'APPROVED', label: 'Aprovado' },
            {value: 'REPROVED', label: 'Reprovado'},
          ]}
          name="status"
          hasWarning={false}
        />
        <DateAndTimePickers
          xs={12}
          sm={6}
          name="initialDate"
          label="Filtrar por data inicial"
          value={filter.initialDate}
          onChange={updateInitalDateField}
          cancelLabel="Cancelar"
          okLabel="Salvar"
          hasWarning={false}
        />
        <DateAndTimePickers
          xs={12}
          sm={6}
          name="finalDate"
          label="Filtrar por data Final"
          value={filter.finalDate}
          onChange={updateFinalDateField}
          cancelLabel="Cancelar"
          okLabel="Salvar"
          hasWarning={false}
        />
        <DataTableReact
          noHeader
          columns={columns}
          data={documents}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationDefaultPage={filter.page}
        />
      </GridContainer>
      <ButtonContainer>
        <br />
        <Button left={true} cancel={true} onClick={handleBack}>
          Voltar        
        </Button>        
      </ButtonContainer>
    </ContentPanel>
  )
}