import styled from 'styled-components'
import { fundoTopo } from '../../styles/colors'

export const Container = styled.header`
  background: ${fundoTopo};
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 2px 2px 2px 2px rgba(0,0,0,0.3);
  height: 65px;

  img {
    margin: 12px 22px;
    width: 150px;
    height: 52px;
  }
`

export const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  color: white;
  padding-right: 10px;
`

export const Logout = styled.button`
  border: none;
  background: none;
  color: #BBB;
`

export const RightContainer = styled.div`
  display: flex;

  img {
    margin: none;
    width: 55px;
    height: 55px;
    border: solid #FFF 1px;
    margin: 0px;
    margin-right: 10px;
    border-radius: 50%;
  }
`