import axios from 'axios';
import { Auth } from 'aws-amplify';
import history from '../services/history';

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_ENDPOINT,
  timeout: 30000
});

api.interceptors.request.use(
  async config => {    
    if (config.url.includes('/flagprint')) {
      return config;
    }

    try {
      config.headers.authorization = `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`;      
    } catch (err) {
      switch (err) {
        case 'No current user':
          localStorage.clear();
          window.location.replace('/login');
          break;
        default:
          break;
      }
    }

    return config;
  },
  error => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    return response;
  }, 
  function (error) {
    if (error.response) {
      switch (error.response.status) {
        case 401 :
          localStorage.clear();
          window.location.replace('/login');
          break
        case 403 :
          history.push('/403'); //Forbidden
          break
        default :
          break
      }
    }
    // Do something with response error
    return Promise.reject(error);
  }
);

export default api;