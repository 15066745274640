//import 'dotenv/config';

import React from 'react';
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux';

/* AWS Cognito */
import { awsconfig } from './config/awsConfig';
import Amplify from 'aws-amplify';

import './config/ReactotronConfig'

import { GlobalStyle } from './styles/global'

import { store, persistor } from './store';

import Routes from './routes/';

/*Get credential from Logged user*/
Amplify.configure(awsconfig); 

function App() {

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <GlobalStyle />
        <Routes />
      </PersistGate>
    </Provider>
  );
}

export default App;
