//external
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
//internal
import { getAllVersions, getAllows } from 'services/terms/service';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import ContentPanel from 'components/ContentPanel';
import { setMessage } from 'store/modules/message/action';
import TableAction from 'components/v2/tableAction';
import { sanitizeErrorUI } from 'utils/errorUtil.js';
import DataTableReact from 'components/v2/dataTableReact/index.js';
import { FilterTerms } from '../index.js';
import DataTableLoading from 'components/DataTableLoading/index.js';
//style
import { IconContainer } from '../styled.js';

export default function Terms() {
  const message = useSelector(state => state.message.data);
  
  const [termss, setTermss] = useState();
  const [allows, setAllows] = useState({});
  const [reload, setReload] = useState(0);
  //pagination
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sort, setSort] = useState();

  const { filter, setFilter } = useContext(FilterTerms);

  const dispatch = useDispatch();

  const columns = [
    {
      name: 'Url',
      selector: 'url',
      sortable: true,
      maxWidth: "39%",
    },
    {
      name: 'Notas',
      selector: 'notes',
      sortable: true,
      maxWidth: "29%",
    },
    {
      style: {justifyContent: "flex-end"},
      cell: row => 
      <>
        <div>
          <IconContainer>
            <TableAction 
            title={"Editar"} 
            disabled={!allows.put} 
            to={`/terms/edit/${row.id}`}>
              <EditIcon/>
            </TableAction>
            <TableAction 
            title={"Excluir"} 
            disabled={!allows.delete} 
            to={`/terms/delete/${row.id}`}>
              <DeleteIcon/>
            </TableAction>
          </IconContainer>
        </div>
      </>,
    }
  ];

  const actions = [
    <div style={{display: 'flex'}} key='actionsTerms'>
      <TableAction 
      title={"Adicionar"} 
      disabled={!allows.post} 
      to='/terms/add'>
        <AddIcon/>
      </TableAction>
      <TableAction 
      title={"Atualizar"} 
      onClick={refreshHandler}
      isLink={false}>
        <RefreshIcon/>
      </TableAction>
    </div>
  ];

  function refreshHandler() {
    setReload(reload + 1);
  }

  useEffect(() => {
    dispatch(startRequest());
    getAllVersions(perPage, filter.page, sort).then(data => {
      setTotalRows(parseInt(data.headers['recordcount']));
      setTermss(data.data.map(value => {
        return value;
      }));
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
    dispatch(startRequest());
    getAllows().then(data => {
      setAllows(data.data)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }, [dispatch, reload, perPage, filter, sort]);

  const handlePerRowsChange = (perPage) => {
    setPerPage(perPage);
  };

  const handlePageChange = (page) => {
    setFilter({...filter, page});
  };

  const handleSort = (column, sortDirection) => {
    setSort(`${column.selector} ${sortDirection}`);
  };

  return (
    <>
      <ContentPanel title="Termos de uso" message={message} actions={actions}>
        <DataTableReact
          noHeader
          columns={columns}
          data={termss}
          pagination
          paginationServer
          progressComponent={<DataTableLoading />}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          sortServer
          onSort={handleSort}
          paginationDefaultPage={filter.page}
        />
      </ContentPanel>
    </>
  )
}