import styled from 'styled-components'
import InputForm from '../components/Form/Input';
import CheckForm from '../components/Form/Check';
import TextAreaForm from '../components/Form/TextArea';

export const Input = styled(InputForm)`
  margin-bottom: 5px;
`

export const Check = styled(CheckForm)`
  margin-bottom: 5px;
`

export const Button = styled.button`
  /* display: inline-block; */
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;

  color: #fff;
  background-color: ${props => props.disabled ? "#7f7f7f" : props.cancel ? "#dc3545" : props.checkin ? "#008000" : props.checkout ? "#ffcc00" : "#337ab7"};
  border-color: ${props => props.disabled ? "#7f7f7f" : props.cancel ? "#dc3545" : props.checkin ? "#008000" : props.checkout ? "#ffcc00" : "#2e6da4"};

  float: ${props => props.left ? "left" : "right"};
`

export const ActionButton = styled.button`
  /* display: inline-block; */
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  margin: 3px;

  color: #fff;
  background-color: ${props => props.cancel ? "#dc3545" : "#17a2b8"};
  border-color: ${props => props.cancel ? "#dc3545" : "#118496"};

  float: left;
`

export const TextArea = styled(TextAreaForm)`
  margin-bottom: 5px;
`

export const ButtonContainer = styled.div`
  width: 100%;
  display: inline-block;
  padding: 9px 0;
`

export const InputButton = styled.button`
  border: solid #C4C4C3 1px; 
  border-radius: 5px; 
  height: 54px; 
  width: 100%; 
  background-color: white;
`

export const InputLabel = styled.label`
  position: absolute;
  margin: -8px; 
  left: 2%;
  background-color: white; 
  padding: 0px 5px 0px 5px; 
  color: rgba(0, 0, 0, 0.54); 
  fontSize: 1rem;
`

export const InputSpan = styled.span`
  padding: 18.5px 14px; 
  display: flex; 
  color: #c4c4c4;
`

export const Session = styled.div`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 10%;
  background-color: #f04f26;
  position: relative;
  top: 13px;
  left: 2px;
  height: 34px;
`

export const optionsChecklist = styled.div`
  display: flex;
  width: 69.9rem !important; 
  padding: 7px;
`