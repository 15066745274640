import * as Yup from 'yup';

export const reproveDocumentSchema = Yup.object().shape({  
  id: Yup.number().required('Id é obrigatório.'),
  status: Yup.string().required('Status é obrigatório.'),
  reason: Yup.string().required('Motivo é obrigatório'),
});

export const approveDocumentSchema = Yup.object().shape({  
  id: Yup.number().required('Id é obrigatório.'),
  status: Yup.string().required('Status é obrigatório.'),
});
