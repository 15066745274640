import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import { List, ListItem, ListItemText } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  text: {
    paddingBottom: 0,
    paddingTop: 0,
    marginTop: 0,
    marginBottom: 0,
  },
}));

export default function Errors({errorResponse, errors}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Alert severity="error">
        <AlertTitle>Erros</AlertTitle>
        Ocorreram erros ao executar a operação — <strong>verifique!</strong>
        <List dense>
          {errorResponse && Array.isArray(errorResponse) && errorResponse.map((err, idx) => (
            <ListItem className={classes.text} key={idx} id={idx}>
              <ListItemText className={classes.text} primary={`- ${err}`} />
            </ListItem>          
          ))}
          {errors && Array.isArray(errors) && errors.map((err, idx) => (
            <ListItem className={classes.text} key={idx} id={idx}>
              <ListItemText className={classes.text} primary={`- ${err?.message || err}`} />
            </ListItem>          
          ))}
        </List> 
      </Alert>
    </div>
  );
}