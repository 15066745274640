import React, { useEffect, useState } from 'react'

import { Attr, AttrName } from './styled'

import { getPriceTable, deletePriceTable } from '../../../services/priceTable/service'
import { useDispatch, useSelector } from 'react-redux';
import { startRequest, finishRequest } from '../../../store/modules/loading/actions';

import history from '../../../services/history';
import ContentPanel from '../../../components/ContentPanel';
import { ButtonContainer, Button } from '../../../styles/components';
import { setMessage } from '../../../store/modules/message/action';
import { formatDateTime } from '../../../utils/formatUtil';
import { sanitizeErrorUI } from 'utils/errorUtil';
import { getFileId } from 'services/files/service.js';

export default function PriceTableDelete({match}) {
  const priceTableId = match.params.id;
  const message = useSelector(state => state.message.data);
  const [data, setData] = useState();
  const [url, setUrl] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startRequest());
    getPriceTable(priceTableId).then(data => {
      setData(data.data);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }, [dispatch, priceTableId])

  async function handleDelete () {
    dispatch(startRequest());
    deletePriceTable(priceTableId).then(data => {
      history.push('/premixapp/product_price_table')
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }

  async function handleBack () {
    history.goBack();
  }

  useEffect(() => {
    if (data?.File) {
      const {id, fileUuid} = data?.File;
      if (id && fileUuid) {
        dispatch(startRequest());
        getFileId(id, fileUuid).then(async (response) => {           
          const blob = new Blob([response.data], {type: 'application/pdf'});        
          const url = URL.createObjectURL(blob);
          setUrl(url);        
        }).catch(err => {
          dispatch(setMessage(sanitizeErrorUI(err)));
        }).finally(() => {
          dispatch(finishRequest());
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, data?.File]);

  return (
    <>
      <ContentPanel title="Deseja excluir a tabela de preço?" message={message}>
        {data && <>
          <AttrName>Descrição:</AttrName>
          <Attr>{data.description}</Attr>
          <AttrName>Data:</AttrName>
          <Attr>{formatDateTime(data.updatedAt)}</Attr>          
          <AttrName>Status:</AttrName>
          <Attr>{data.active ? 'Ativo' : 'Inativo'}</Attr>
          <br />
          {url && (
            <iframe title="Documento" src={url} width="100%" height="600" />          
          )}
          <ButtonContainer>
            <br />
            <Button left={true} cancel={true} onClick={handleBack}>
              Voltar
            </Button>
            <Button onClick={handleDelete}>
              Excluir
            </Button>
          </ButtonContainer>
        </>}
      </ContentPanel>
    </>
  )
}