import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import { Typography } from '@material-ui/core';
import { Grid } from '@material-ui/core';
//internal
import ContentPanel from 'components/ContentPanel';
import { setMessage } from 'store/modules/message/action';
import { sanitizeErrorUI } from 'utils/errorUtil';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import GridContainer from 'components/v2/grid';
import InputText from 'components/v2/inputText';
import { getScheduleType, postScheduleType, putScheduleType, getChecklists } from 'services/schedule/type/service';
import history from 'services/history';
import { validateForm } from 'components/Form/validate';
import { scheduleSchema } from '../schemas';
import TableAction from 'components/v2/tableAction';
import DataTableReact from 'components/v2/dataTableReact';
import SelectAutocomplete from 'components/v2/autocomplete';
import { useDebounce } from 'utils/uiUtils';
import { Warning } from 'routes/Route';
import CheckboxComponent from 'components/v2/checkbox';
//style
import { Button, ButtonContainer } from 'styles/components';
import { IconContainer } from 'pages/user/list/styled';
import { CheckboxContainer } from './styled';

export default function ScheduleTypeEdit({match}) {
  const ScheduleTypeId = match.params.id;
  const message = useSelector(state => state.message.data);
  const [dataScheduleType, setDataScheduleType] = useState({});
  const [errors, setErrors] = useState([]);
  const [options, setOptions] = useState([]);
  const [searchChecklist, setSearchChecklist] = useState("");
  const [itemSelected, setItemSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [checklists, setChecklists] = useState([]);

  const { setIsWarning, setNotWarning } = useContext(Warning);
  
  const debouncedChecklists = useDebounce(searchChecklist);

  const dispatch = useDispatch();

  const loadChecklists = useCallback((searchValue) => {
    setIsLoading(true)
    getChecklists(searchValue).then(result => {
      const checklists = result.data.map(element => {
        return { value: element, label: `${element.description}` }
      });
      setOptions(checklists);
      setIsLoading(false)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      setIsLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    if(loadChecklists) loadChecklists(debouncedChecklists)
  },[debouncedChecklists, loadChecklists]);

  useEffect(() => {
    if (ScheduleTypeId) {
      dispatch(startRequest());
      getScheduleType(ScheduleTypeId).then(data => {
        const formData = data.data;
        setDataScheduleType({
          description: formData.description,
          id: formData.id,
          hasCustomer: formData.hasCustomer,
          hasProspect: formData.hasProspect,
          hasProspectOrCustomer: formData.hasProspectOrCustomer,
          schedule: formData.schedule,
          todo: formData.todo
        });
        const checklists = formData.checklists || [];
        setChecklists([...checklists])
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, ScheduleTypeId]);

  async function handleSubmit () {
    setNotWarning();

    const errorsList = await validateForm(dataScheduleType, scheduleSchema);
    if (errorsList.length > 0) {
      setErrors(errorsList);
      return
    }

    const objCopy = JSON.parse(JSON.stringify(dataScheduleType));
    objCopy.checklists = checklists;

    if (ScheduleTypeId) {
      dispatch(startRequest());
      putScheduleType(ScheduleTypeId, objCopy).then(data => {
        history.push('/schedule/schedule_type')
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    } else {
      dispatch(startRequest());
      postScheduleType(objCopy).then(data => {
        history.push('/schedule/schedule_type')
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }  
  }

  function handleSelectInputChange(data, event) {
    if (data) {
      const checklistIndex = checklists.findIndex(item => item.checklist.id === data?.value?.id);
      setItemSelected(data);
      if (checklistIndex >= 0) return;

    setChecklists([...checklists, {
      atCheckin: false,
      atCheckout: false,
      required: false,
      checklist: {
        id: data.value.id,
        description: data.value.description
      }
    }]); 
    } else {
      setItemSelected(null);
      loadChecklists("");
    }
  }

  function handleRemoveItem(item) {    
    var checklistIndex = checklists.indexOf(item);
    checklists.splice(checklistIndex, 1);
    setChecklists([...checklists]);    
  }

  const columnsChecklists = [
    {
      maxWidth: "75%",
      name: 'Descrição',
      selector: 'checklist.description',
      sortable: true,
    },
    {
      name: 'Obrigatório',
      selector: 'required',
      sortable: true,
      cell: row => <input type='checkbox' checked={row.required} onChange={() => handleOnChangeCheck(row, 'required')}/>,
      center: true,
      maxWidth: '6%',
    },
    {
      name: 'Checkin',
      selector: 'atCheckin',
      sortable: true,
      cell: row => <input type='checkbox' checked={row.atCheckin} onChange={() => handleOnChangeCheck(row, 'atCheckin')}/>,
      center: true,
      maxWidth: '6%',
    },
    {
      name: 'Checkout',
      selector: 'atCheckout',
      sortable: true,
      cell: row => <input type='checkbox' checked={row.atCheckout} onChange={() => handleOnChangeCheck(row, 'atCheckout')}/>,
      center: true,
      maxWidth: '6%',
    },
    {
      maxWidth: "25%",
      style: {justifyContent: "flex-end"},
      cell: row => 
      <>
        <div>
          <IconContainer>
            <TableAction 
            title={"Excluir"} 
            onClick={() => handleRemoveItem(row)}>
              <DeleteIcon/>
            </TableAction>
          </IconContainer>
        </div>
      </>,
    }
  ];

  function handleOnChangeCheck(item, selectCheck) {
    const index =  checklists.findIndex(i => i.checklist.id === item.checklist.id);
    const newChecklists = [...checklists]
    newChecklists[index][selectCheck] = !newChecklists[index][selectCheck];
    setChecklists(newChecklists);
    setIsWarning();
  }

  const handleCheckbox = (e) => {
    setDataScheduleType({...dataScheduleType, [e.target.name]: e.target.checked});
  };

  return (
    <ContentPanel title="Tipo de Agendamento" message={message}>
      <GridContainer>
        <InputText
          xs={12}
          sm={12}
          required
          name="description"
          label="Descrição"
          type="text"
          value={dataScheduleType.description}
          onChange={(e) => setDataScheduleType({...dataScheduleType, description: e.target.value})}
          errors={errors}
        />

        <CheckboxContainer>
          <Typography style={{width: '22%'}} color="textPrimary">Atributos do Agendamento - </Typography>
          <CheckboxComponent 
            xs={12}
            sm={2}
            checked={dataScheduleType.hasCustomer || false}
            onChange={handleCheckbox}
            name="hasCustomer"
            label="Cliente"
          />
          <CheckboxComponent 
            xs={12}
            sm={2}
            checked={dataScheduleType.hasProspect || false}
            onChange={handleCheckbox}
            name="hasProspect"
            label="Prospect"
          />
          <CheckboxComponent 
            xs={12}
            sm={2}
            checked={dataScheduleType.hasProspectOrCustomer || false}
            onChange={handleCheckbox}
            name="hasProspectOrCustomer"
            label="Cliente/Prospect"
          />
        </CheckboxContainer>  

        <CheckboxContainer>
          <Typography style={{width: '22%'}} color="textPrimary">Método - </Typography>
          <CheckboxComponent 
            xs={12}
            sm={2}
            checked={dataScheduleType.schedule || false}
            onChange={handleCheckbox}
            name="schedule"
            label="Agendamento"
          />
          <CheckboxComponent 
            xs={12}
            sm={2}
            checked={dataScheduleType.todo || false}
            onChange={handleCheckbox}
            name="todo"
            label="Tarefa"
          />
          <Grid item xs={2}></Grid>
        </CheckboxContainer>  

        <div style={{margin: '8px', width: '100%'}}>
          <Grid item xs={12}>
            <SelectAutocomplete
              xs={12}
              sm={12}
              value={itemSelected}
              label="Buscar Checklist"
              optionSelected={(option, value) => option.value.description === value.value.description}
              optionLabel={(option) => option.value.description}
              onChangeSelected={(event, value) => handleSelectInputChange(value)}
              onChange={(event) => setSearchChecklist(event.target.value)}
              options={options}
              name="checklist"
              isLoading={isLoading}
            />

            <DataTableReact
              noHeader
              columns={columnsChecklists}
              data={checklists}
            />
          </Grid>
        </div>
      </GridContainer> 

      <ButtonContainer>
        <Button type="submit" onClick={handleSubmit}>Salvar</Button>
      </ButtonContainer>  
    </ContentPanel>
  )
}