//external
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//internal
import ContentPanel from 'components/ContentPanel';
import { getProductCategorySite, putProductCategorySite, postProductCategorySite, findProductCategorySite, getBackground, getIcon, getColor } from 'services/rtpi/productCategorySite/service';
import { setMessage } from 'store/modules/message/action';
import { sanitizeErrorUI } from 'utils/errorUtil';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import GridContainer from 'components/v2/grid';
import InputText from 'components/v2/inputText';
import Toggle from 'components/v2/toggle';
import SelectAutocomplete from 'components/v2/autocomplete';
import { validateForm } from 'components/Form/validate';
import history from 'services/history';
import { productCategorySiteSchema } from '../schemas';
import { Warning } from 'routes/Route';
import { useDebounce } from 'utils/uiUtils';
//style
import { Button, ButtonContainer } from 'styles/components';
import { MenuItem } from '@material-ui/core';
import InputSelect from 'components/v2/inputSelect';

export default function ProductCategorySiteEdit({match}) {
  const productCategorySiteId = match.params.id;
  const message = useSelector(state => state.message.data);
  const [dataProductCategorySite, setDataProductCategorySite] = useState({
    description: '',
    active: true,
    parentId: null
  });
  const [errors, setErrors] = useState([]);
  // Parent Category Site
  const [selectedParent, setSelectedParent] = useState(null);
  const [searchParent, setSearchParent] = useState("");
  const [optionsParent, setOptionsParent] = useState([]);
  const [isLoadingParent, setIsLoadingParent] = useState(false);

  //Background
  const [rawBackgroundImages, setRawBackgroundImages] = useState([]);
  const [backgroundImages, setBackgroundImages] = useState([]);
  const [backgroundImage, setBackgroundImage] = useState(null);

  //Icons
  const [rawIconImages, setRawIconImages] = useState([]);
  const [iconImages, setIconImages] = useState([]);
  const [verticalIcon, setVerticalIcon] = useState(null);
  const [horizontalIcon, setHorizontalIcon] = useState(null);
  const [simpleIcon, setSimpleIcon] = useState(null);

  //Colors
  const [colors, setColors] = useState([]);
  const [color, setColor] = useState(null);

  const { setNotWarning } = useContext(Warning);

  const debouncedSearchParent = useDebounce(searchParent);

  const dispatch = useDispatch();

  const loadParent = useCallback((searchValue) => {
    setIsLoadingParent(true)
    findProductCategorySite(searchValue).then(result => {
      let categories = [];
      result.data.forEach((element) => {
        if (element.id !== productCategorySiteId) {
          categories.push({ value: element, label: (element.parent?.description ? `${element.parent.description}\\${element.description}` : element.description) });
        }
      });
      setOptionsParent(categories);
      setIsLoadingParent(false)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      setIsLoadingParent(false);
    });
  }, [dispatch, productCategorySiteId])

  useEffect(() => {
    if(loadParent) loadParent(debouncedSearchParent)
  },[debouncedSearchParent, loadParent]);

  useEffect(() => {
    getBackground().then(result => {
      let backgrounds = [];
      setRawBackgroundImages(result.data);
      result.data.forEach((element) => {
        backgrounds.push({ value: element.key, label: element.name });
      });
      setBackgroundImages(backgrounds);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    });
  }, [dispatch]);

  useEffect(() => {
    getIcon().then(result => {
      let icons = [];
      setRawIconImages(result.data);
      result.data.forEach((element) => {
        icons.push({ value: element.key, label: element.name });
      });
      setIconImages(icons);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    });
  }, [dispatch]);

  useEffect(() => {
    getColor().then(result => {
      let colors = [];
      result.data.forEach((element) => {
        colors.push({ value: element.key, label: element.name });
      });
      setColors(colors);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    });
  }, [dispatch]);

  function contentBackgroundImage(index, key) {
    const option = rawBackgroundImages.find(elem => elem.key === key.value);
    return (
      <MenuItem key={index} value={option.key}><img style={{width: 40, marginRight: 10}} src={option.path} alt="option"/>{option.name}</MenuItem>
    )
  }
  function contentIconImage(index, key) {
    const option = rawIconImages.find(elem => elem.key === key.value);
    return (
      <MenuItem key={index} value={option.key}><img style={{width: 40, maxHeight: 40, marginRight: 10}} src={option.path} alt="option"/>{option.name}</MenuItem>
    )
  }
  function contentColor(index, option) {
    return (
      <MenuItem key={index} value={option.value}><div style={{width: 35, height: 35, marginRight: 10, backgroundColor: option.value}} />{option.value}</MenuItem>
    )
  }

  useEffect(() => {
    if (productCategorySiteId) {
      dispatch(startRequest());
      getProductCategorySite(productCategorySiteId).then(data => {
        const formData = data.data
        setDataProductCategorySite({
          id: formData.id,
          active: formData.active,
          description: formData.description,
          parentId: formData.parentId
        });
        if (formData?.parent) {
          setSelectedParent({value: formData.parent, label: formData.parent.description});
        }
        if (formData?.siteBackgroundImage) {
          setBackgroundImage(formData.siteBackgroundImage);
        }
        if (formData?.siteBackgroundColor) {
          setColor(formData.siteBackgroundColor);
        }
        if (formData?.siteVerticalIcon) {
          setVerticalIcon(formData.siteVerticalIcon);
        }
        if (formData?.siteHorizontalIcon) {
          setHorizontalIcon(formData.siteHorizontalIcon);
        }
        if (formData?.siteSimpleIcon) {
          setSimpleIcon(formData.siteSimpleIcon);
        }
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, productCategorySiteId]);

  async function handleSubmit () {
    setNotWarning();
    
    const errorsList = await validateForm(dataProductCategorySite, productCategorySiteSchema);
    if (errorsList.length > 0) {
      setErrors(errorsList);
      return
    }

    const data = {
      ...dataProductCategorySite,
      siteBackgroundImage: backgroundImage,
      siteBackgroundColor: color,
      siteVerticalIcon: verticalIcon,
      siteHorizontalIcon: horizontalIcon,
      siteSimpleIcon: simpleIcon
    }

    if (productCategorySiteId) {
      dispatch(startRequest());
      putProductCategorySite(productCategorySiteId, data).then(data => {
        history.push('/premixapp/rtpi_product_category_site')
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    } else {
      dispatch(startRequest());
      postProductCategorySite(data).then(data => {
        history.push('/premixapp/rtpi_product_category_site')
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }      
  }

  function handleCategoryChange(data) {      
    if (data) {
      setDataProductCategorySite({...dataProductCategorySite, parentId: data.value.id});
      setSelectedParent(data);
    } else {
      setSelectedParent(null);
      loadParent("");
    }   
  }

  return (
    <ContentPanel title='Categoria de Produtos do Site' message={message}>
      <GridContainer>        
        <InputText
          xs={12}
          sm={12}
          required
          name="description"
          label="Descrição"
          type="text"
          value={dataProductCategorySite.description}
          onChange={(e) => setDataProductCategorySite({...dataProductCategorySite, description: e.target.value})}
          errors={errors}
        />
        <Toggle
          xs={12}
          sm={6}
          name="Ativo"
          checked={dataProductCategorySite.active}
          onChange={(e) => setDataProductCategorySite({...dataProductCategorySite, active: e.target.checked})}
          label="Ativo"
        /> 

        <SelectAutocomplete
          xs={12}
          sm={12}
          value={selectedParent}
          label="Buscar categoria pai"
          optionSelected={(option, value) => option.label === value.label}
          optionLabel={(option) => option.label}
          onChangeSelected={(event, value) => handleCategoryChange(value)}
          onChange={(event) => setSearchParent(event.target.value)}
          options={optionsParent}
          name="branch"
          isLoading={isLoadingParent}
        />

        {!!!selectedParent && <>
          <InputSelect
            xs={6}
            sm={6}
            name="backgroundImage"
            label="Imagem de fundo"
            value={backgroundImage}
            onChange={(e) => {setBackgroundImage(e.target.value)}}
            defaultValue={null}
            options={backgroundImages}
            errors={errors}
            contentElement={contentBackgroundImage}
          />

          <InputSelect
            xs={6}
            sm={6}
            name="color"
            label="Cor"
            value={color}
            onChange={(e) => {setColor(e.target.value)}}
            defaultValue={null}
            options={colors}
            errors={errors}
            contentElement={contentColor}
          />

          <InputSelect
            xs={4}
            sm={4}
            name="verticalIcon"
            label="Icone vertical"
            value={verticalIcon}
            onChange={(e) => {setVerticalIcon(e.target.value)}}
            defaultValue={null}
            options={iconImages}
            errors={errors}
            contentElement={contentIconImage}
          />

          <InputSelect
            xs={4}
            sm={4}
            name="horizontalIcon"
            label="Icone horizontal"
            value={horizontalIcon}
            onChange={(e) => {setHorizontalIcon(e.target.value)}}
            defaultValue={null}
            options={iconImages}
            errors={errors}
            contentElement={contentIconImage}
          />

          <InputSelect
            xs={4}
            sm={4}
            name="simpleIcon"
            label="Icone simples"
            value={simpleIcon}
            onChange={(e) => {setSimpleIcon(e.target.value)}}
            defaultValue={null}
            options={iconImages}
            errors={errors}
            contentElement={contentIconImage}
          />
        </>}
      </GridContainer>

      <ButtonContainer>
        <Button type="submit" onClick={handleSubmit}>Salvar</Button>
      </ButtonContainer>   
    </ContentPanel>
  )
}