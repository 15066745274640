import styled from 'styled-components'

export const ActionsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    border: solid #D5D6D7 1px;
    background-color: #FFF;
    padding: 10px;
    box-shadow: 0px 0px 6px #D5D6D7;
`

export const Container = styled.div`
    width: 100%;
    padding-left: 0;
    padding-right: 0;
`

export const IconContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`