export async function validateForm( data, schema) {
  let errors = []
  try { 
    await schema.validate(data, {
      abortEarly: false,
    });

    data = await schema.validateSync(data);
    return [];
  } catch (err) {
    const validationErrors = {};
    if (err.inner && Array.isArray(err.inner)) {
      err.inner.forEach(error => {
        validationErrors[error.path] = error.message;
        errors = [...errors, {
          field: error.path,
          message: error.message
        }]
      });
    }
    return errors;
  }
}