import api from '../api';

export async function findTechnical(name, numOfRegisters = 6) {
  return api.get('technical', {
    params: {
      name,
      order: 'nome'
    },
    headers: {
      registerperpage: numOfRegisters,
      page: 1
    }
  });
}