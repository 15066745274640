//export
import React, { useContext, useEffect, useState } from 'react';
import { MenuItem, Select, FormControl, InputLabel, Grid, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import { getErrorMessage, getIsError } from 'utils/validationUtil';
import { Warning } from 'routes/Route';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: "100%"
  },
  label: {
    zIndex: 0,
  },
}));

export default function InputSelect({xs, sm, onChange, value, name, options, label, errors, disabled, required, contentElement, hasWarning = true}) {
  const classes = useStyles();

  const [stateErrors, setStateErrors] = useState([]);

  const { setIsWarning } = useContext(Warning);

  const onChangeInput = (e) => {
    onChange(e);
    setStateErrors(stateErrors.filter(item => item.field !== name));
    if (hasWarning) {
      setIsWarning();
    }
  }

  useEffect(() => {
    if(Array.isArray(errors) && errors.length > 0) {   
      setStateErrors(errors)
    }
  }, [errors]);

  return (
    <Grid item xs={xs} sm={sm}>
      <FormControl required={required} variant="outlined" className={classes.formControl} error={getIsError(name, stateErrors)}>
        <InputLabel id={name}>{label}</InputLabel>
        <Select
          className={classes.label}
          defaultValue=""
          labelId={name}
          name={name}
          label={label}
          value={value || ''}
          disabled={disabled}
          onChange={(e) => onChangeInput(e)}
          fullWidth         
          inputProps={{
            className: classes.label,
        }} 
        >
          <MenuItem key="-1" value="">
            <em>Selecione...</em>
          </MenuItem>
          {options.map((option, index) => (
            contentElement ? contentElement(index, option) : <MenuItem key={index} value={option}>{option}</MenuItem>
          ))}
        </Select>
        <FormHelperText>{getErrorMessage(name, stateErrors)}</FormHelperText>
      </FormControl>
    </Grid>
  );
}

InputSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onChange: PropTypes.func,
}