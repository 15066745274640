export const awsconfig = {
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_WEB_CLIENT_ID
  },
  Storage: {
    AWSS3: {
      region: process.env.REACT_APP_AWS_REGION,
      bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
      level: 'public'
    }
  }
}