//external
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import RefreshIcon from '@material-ui/icons/Refresh';
//internal
import ContentPanel from 'components/ContentPanel';
import { getCustomers } from 'services/customer/service'
import { useDebounce } from 'utils/uiUtils';
import Attribute from 'components/Attribute';
import DataTableLoading from 'components/DataTableLoading';
//import IconButton from 'components/IconButton';
import { setMessage } from 'store/modules/message/action';
import { sanitizeErrorUI } from 'utils/errorUtil';
import DataTableReact from 'components/v2/dataTableReact';
import InputText from 'components/v2/inputText';
import GridContainer from 'components/v2/grid';
import TableAction from 'components/v2/tableAction';
import { FilterCustomer } from '..';
import { finishRequest, startRequest } from 'store/modules/loading/actions';
//style
import { CustomerDetail } from './styled'
import { IconContainer } from 'pages/user/list/styled';

export default function Profile() {
  const message = useSelector(state => state.message.data);
  const salesmanId = useSelector(state => state.user.id );
  const [customers, setCustomers] = useState();
  const [reload, setReload] = useState(0);
  //Pagination
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sort, setSort] = useState();

  const { filter, setFilter } = useContext(FilterCustomer);

  const debouncedSearchTerm = useDebounce(filter.search);

  const dispatch = useDispatch();

  const columns = [
    {
      name: 'Codigo',
      selector: 'codigo',
      sortable: true,
      width: '120px'
    },
    {
      name: 'Loja',
      selector: 'loja',
      sortable: true,
      width: '50px'
    },
    {
      name: 'Nome',
      selector: 'nome',
      sortable: true,
    },
    {
      style: {justifyContent: "flex-end"},
      cell: row => 
      <>
        <div>
          <IconContainer>
            <TableAction 
            title={"Visualizar"} 
            to={`/schedule/customer/view/${row.codigo}/${row.loja}`}>
              <VisibilityIcon/>
            </TableAction>
            <TableAction 
            title={"Editar"} 
            to={`/schedule/customer/edit/${row.codigo}/${row.loja}`}>
              <EditIcon/>
            </TableAction>
          </IconContainer>
        </div>
      </>,
    }
  ];

  const actions = [
    <div style={{display: 'flex'}} key="actionsComponentGroup">
      <TableAction 
      key='refresh'
      title={"Atualizar"} 
      onClick={refreshHandler}
      isLink={false}>
        <RefreshIcon/>
      </TableAction>
    </div>
  ];

  useEffect(() => {
    if (salesmanId) {
      setLoading(true);
      dispatch(startRequest()); 
      getCustomers(salesmanId, debouncedSearchTerm, perPage, filter.page, sort).then(data => {
        setTotalRows(parseInt(data.headers['recordcount']));
        setCustomers(data.data);
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
        setLoading(false);
      });
    }
  }, [dispatch, reload, salesmanId, debouncedSearchTerm, perPage, filter.page, sort]);

  function refreshHandler() {
    setReload(reload + 1);
  }

  const handlePageChange = (page) => {
    setFilter({...filter, page});
  };

  const handlePerRowsChange = (perPage) => {
    setPerPage(perPage);
  };

  const handleSort = (column, sortDirection) => {
    setSort(`${column.selector} ${sortDirection}`);
  };

  const CustomerDetails = ({ data }) => (
    <CustomerDetail>
      <Attribute attribute="Codigo" value={`${data.codigo} / ${data.loja}`} />
      <Attribute attribute="Razão Social" value={data.nome} />
      <Attribute attribute="Nome da Fazenda" value={data.fazenda} />
      <Attribute attribute="CNPJ" value={data.cnpj} />
      <Attribute attribute="CPF" value={data.cpf} />
      <Attribute attribute="Cidade / UF" value={`${data.cidade}-${data.estado}`} />
    </CustomerDetail>
  );

  return (
    <>
      <ContentPanel message={message} title="Clientes" actions={actions}>   
      <GridContainer>  
        <InputText
          xs={12}
          name="search"
          label="Pesquisar"
          type="text"
          placeholder="Pesquisar"
          value={filter.search}
          onChange={(e) => setFilter({page: 1, search: e.target.value})}
          hasWarning={false}
        />
        </GridContainer>

        <DataTableReact
          noHeader
          fixedHeader
          progressPending={loading}
          progressComponent={<DataTableLoading />}
          title="Clientes"
          columns={columns}
          data={customers}
          expandableRows
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          sortServer
          onSort={handleSort}
          expandableRowsComponent={<CustomerDetails />}
          paginationDefaultPage={filter.page}
        />
      </ContentPanel>
    </>
  )
}