//external
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//internal
import ContentPanel from 'components/ContentPanel';
import { getProductRestriction, putProductRestriction, postProductRestriction } from 'services/productRestriction/service';
import { setMessage } from 'store/modules/message/action';
import { sanitizeErrorUI } from 'utils/errorUtil';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import GridContainer from 'components/v2/grid';
import InputText from 'components/v2/inputText';
import { validateForm } from 'components/Form/validate';
import history from 'services/history';
import { productRestrictionSchema } from '../schemas';
import InputMultiline from 'components/v2/inputMultiline';
import { Warning } from 'routes/Route';
//style
import { Button, ButtonContainer } from 'styles/components';
import Toggle from 'components/v2/toggle';

export default function ProductRestrictionEdit({match}) {
  const productRestrictionId = match.params.id;
  const message = useSelector(state => state.message.data);
  const [dataProductRestriction, setDataProductRestriction] = useState({});
  const [errors, setErrors] = useState([]);

  const { setNotWarning } = useContext(Warning);

  const dispatch = useDispatch();

  useEffect(() => {
    if (productRestrictionId) {
      dispatch(startRequest());
      getProductRestriction(productRestrictionId).then(data => {
        const formData = data.data
        setDataProductRestriction({
          description: formData.description,
          restrictiveText: formData.restrictiveText,
          default: formData.default
        });
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, productRestrictionId]);

  async function handleSubmit () {
    setNotWarning();
    
    const errorsList = await validateForm(dataProductRestriction, productRestrictionSchema);
    if (errorsList.length > 0) {
      setErrors(errorsList);
      return
    }

    if (productRestrictionId) {
      dispatch(startRequest());
      putProductRestriction(productRestrictionId, dataProductRestriction).then(data => {
        history.push('/premixLabel/product_restriction')
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    } else {
      dispatch(startRequest());
      postProductRestriction(dataProductRestriction).then(data => {
        history.push('/premixLabel/product_restriction')
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
      
  }

  return (
    <ContentPanel title='Restrição de Produto' message={message}>
      <GridContainer>
        <InputText
          xs={12}
          sm={12}
          required
          name="description"
          label="Descrição"
          type="text"
          value={dataProductRestriction.description}
          onChange={(e) => setDataProductRestriction({...dataProductRestriction, description: e.target.value})}
          errors={errors}
        />
        <InputMultiline
          xs={12}
          sm={12}
          required
          name="restrictiveText"
          label="Texto restritivo"
          type="text"
          value={dataProductRestriction.restrictiveText}
          onChange={(e) => setDataProductRestriction({...dataProductRestriction, restrictiveText: e.target.value})}
          errors={errors}
        />
        
        <Toggle
          xs={12}
          sm={6}
          name="Restrição padrão"
          checked={dataProductRestriction.default}
          onChange={(e) => setDataProductRestriction({...dataProductRestriction, default: e.target.checked})}
          label="Restrição padrão"
        />
      </GridContainer>

      <ButtonContainer>
        <Button type="submit" onClick={handleSubmit}>Salvar</Button>
      </ButtonContainer>   
    </ContentPanel>
  )
}