import api from '../api'

export async function getAllows() {
  return api.get('nutrient/allows');
}

export async function getNutrients(description, numOfRegisters = 10, page = 1, order) {
  const params = {
    order: order ? order : 'description',
  }
  if (description) {
    params.query = description.trim(); 
  }

  return api.get('nutrient', {
    params,
    headers: {
      registerperpage: numOfRegisters,
      page
    }
  });
}

export async function getNutrient(id) {
  return api.get(`nutrient/optimix/${id}`);
}

export async function putNutrient(id, nutrient) {
  return api.put(`nutrient/optimix/${id}`, nutrient);
}

export async function findNutrient(description, numOfRegisters = 6) {
  return api.get('nutrient', {
    params: {
      query: description,
      order: 'description'
    },
    headers: {
      registerperpage: numOfRegisters,
      page: 1
    }
  });
}