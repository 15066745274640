//external
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RefreshIcon from '@material-ui/icons/Refresh';
//internall
import { getRtpis } from 'services/rtpi/rtpiLabelLogs/service';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import ContentPanel from 'components/ContentPanel';
import DataTableLoading from 'components/DataTableLoading';
import { setMessage } from 'store/modules/message/action';
import { useDebounce } from 'utils/uiUtils';
import GridContainer from 'components/v2/grid';
import InputText from 'components/v2/inputText';
import TableAction from 'components/v2/tableAction';
import { findBranch } from 'services/rtpi/products/service';
import { sanitizeErrorUI } from 'utils/errorUtil';
import SelectAutocomplete from 'components/v2/autocomplete';
import DataTableReact from 'components/v2/dataTableReact';
import { FilterRtpi } from '..';
import { formatDateTime } from 'utils/formatUtil';

export default function Intention() {
  const message = useSelector(state => state.message.data);
  
  const [rtpis, setRtpis] = useState();
  //Pagination
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sort, setSort] = useState();
  const [reload, setReload] = useState(0);
  const [optionsBranch, setOptionsBranch] = useState([]);
  const [searchBranch, setSearchBranch] = useState("");

  const { filter, setFilter } = useContext(FilterRtpi);

  const debouncedSearchTerm = useDebounce(filter.search);
  const debouncedSearchBranch = useDebounce(searchBranch);
  
  const dispatch = useDispatch();

  const loadBranch = useCallback((searchValue) => {
    findBranch(searchValue).then(result => {
      const branchs = result.data.map(element => {
        return { value: element, label: `${element.protheusCode} - ${element.description}` }
      });
      setOptionsBranch(branchs);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    });
  }, [dispatch]);

  useEffect(() => {
    if(loadBranch) loadBranch(debouncedSearchBranch)
  },[debouncedSearchBranch, loadBranch]);

  const columns = [
    {
      maxWidth: "5%",
      width: "5%",
      name: 'Filial',
      selector: 'protheusBranch',
    },
    {
      maxWidth: "10%",
      width: "10%",
      name: 'Código',
      selector: 'protheusCode',
    },
    {
      maxWidth: "5%",
      width: "5%",
      name: 'Revisão',
      selector: 'revision',
    },
    {
      maxWidth: "20%",
      width: "20%",
      name: 'Produto',
      selector: 'productDescription',
    },
    {
      maxWidth: "8%",
      width: "8%",
      name: 'Quant',
      selector: 'qty',
    },
    {
      maxWidth: "10%",
      width: "10%",
      name: 'Lote',
      selector: 'lot',
    },
    {
      maxWidth: "22%",
      name: 'Usuário',
      selector: 'userName',
    },
    {
      maxWidth: "20%",
      name: 'Data/Hora',
      selector: 'createdAt',
    }
  ];

  const actions = [
    <div key='refresh'>
      <TableAction 
      title={"Atualizar"} 
      onClick={refreshHandler}>
        <RefreshIcon/>
      </TableAction>
    </div>
  ];

  function refreshHandler() {
    setReload(reload + 1);
  }

  useEffect(() => {
    setLoading(true);
    dispatch(startRequest());    
    getRtpis(debouncedSearchTerm, perPage, filter.page, sort, filter.branch?.value?.protheusCode).then(data => {
      setTotalRows(parseInt(data.headers['recordcount']));
      setRtpis(data.data);
      setRtpis(data.data.map(value => {
        value.createdAt = formatDateTime(new Date(value.createdAt));

        return value;
      }));
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
      setLoading(false);
    });
  }, [debouncedSearchTerm, dispatch, filter.page, filter.branch, perPage, sort, reload]);

  const handlePageChange = (page) => {
    setFilter({...filter, page});
  };

  const handlePerRowsChange = (perPage) => {
    setPerPage(perPage);
  };

  const handleSort = (column, sortDirection) => {
    setSort(`${column.selector} ${sortDirection}`);
  };

  function handleBranchChange(data) {
    if (data) {
      setFilter({page: 1, branch: data});
    } else {
      setFilter({page: 1, branch: null});
      loadBranch("");
    }   
  }

  return (
    <>
      <ContentPanel message={message} title="RTPI's - Log etiquetas" actions={actions}>
        <div style={{width: '100%', padding: '0 0 10px 0'}}>
          <SelectAutocomplete
            xs={12}
            sm={12}
            value={filter.branch}
            label="Buscar filial"
            optionSelected={(option, value) => option.value.protheusCode === value.value.protheusCode}
            optionLabel={(option) => option.label}
            onChangeSelected={(event, value) => handleBranchChange(value)}
            onChange={(event) => setSearchBranch(event.target.value)}
            options={optionsBranch}
            name="branch"
            hasWarning={false}
          />
        </div>
        <GridContainer>
          <InputText
            xs={12}
            name="search"
            label="Pesquisar"
            type="text"
            placeholder="Pesquisar"
            value={filter.search}
            onChange={(e) => setFilter({...filter, page: 1, search: e.target.value})}
            hasWarning={false}
          />
        </GridContainer>

        <DataTableReact
          progressPending={loading}
          progressComponent={<DataTableLoading />}
          columns={columns}
          data={rtpis}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationDefaultPage={filter.page}
          onSort={handleSort}
        />
      </ContentPanel>
    </>
  )
}