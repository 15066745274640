import styled from 'styled-components'

export const SeparatorTodo = styled.div`
  font-weight: bold;
  font-size: 16px;
  border-left: 1px solid #e2e2e2;
  width: 8px;
`

export const ContentTodo = styled.div`
  display: flex;
  width: 100%;
`

export const SubContent = styled.div`
  width: 50%;
  padding: 16px;
`

export const ContainerPrimarySubContent = styled.div`
  width: 100%;
  background-color: #eef5fb;
  border-radius: 6px;
  padding: 16px;
`

export const ContainerSecondarySubContent = styled.div`
  width: 100%;
  background-color: #f6f6f6;
  border-radius: 6px;
  padding: 16px;
  margin-top: 15px;
`