import React from 'react'
import { MessageContainer } from './styled'

/*message Model:
  {
    error: boolean,
    warn: boolean,
    success: boolean,
    info: boolean,
    message: String
  }
*/

export default function Message({message}) {
  const formatMessage = (message) => {
    switch (message) {
      case 'Request failed with status code 401':
        return 'Refaça login para continuar';
      default:
        return message;
    }
  };

  return (
    <>
      {message && message.text && <MessageContainer error={message.error} warn={message.warn} success={message.success}>
        {formatMessage(message.text)}
      </MessageContainer>
      }
    </>
  )
}