import api from '../api'

export async function getAllows() {
  return api.get('email_template/allows');
}

  export async function getEmailTemplates(numOfRegisters = 10, page = 1, order) {
    return api.get('email_template');
  }

export async function getEmailTemplate(id) {
  return api.get(`email_template/${id}`);
}

export async function postEmailTemplate(emailTemplate) {
  return api.post(`email_template/`, emailTemplate);
}

export async function putEmailTemplate(id, data) {
  return api.put(`email_template/${id}`, data);
}

export async function deleteEmailTemplate(id) {
  return api.delete(`email_template/${id}`);
}