import { takeLatest, call, put, all } from 'redux-saga/effects';

import * as cognito from '../../../services/cognito'

import history from '../../../services/history';

import { loginSuccess, loginFailure, logoutSuccess, logoutFailure, newPasswordRequired } from './actions'
import { startRequest, finishRequest } from '../loading/actions';
import { translate } from '../../../services/translateCognitoErrors';
import { setMessage, clearMessage } from '../message/action';

export function* logout() {
  yield put(startRequest());
  try {
    yield call(cognito.logout);
    
    yield put(logoutSuccess());
    yield put(clearMessage());

    history.push('/');
  } catch (err) {
    yield put(setMessage({error: true, text: translate(err)}));
    yield put(logoutFailure());
  } finally {
    yield put(finishRequest());
  }
}

export function* login({ payload }) {
  yield put(startRequest());
  const { email, password } = payload;
  try {
    const user = yield call(cognito.login, { email, password });
    
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {    
      yield put(newPasswordRequired(user));
      history.push('/change', { email });    
    } else {
      yield put(loginSuccess(user));
      history.push('/');
    }
  } catch (err) {
    let message = {error: true, text: translate(err)};

    if (err.code === 'UserNotConfirmedException') {
      message = {warn: true, text: translate(err)};
      history.push('/confirmaccount', { email, message })
    }

    if (err.code === 'PasswordResetRequiredException') {
      message = {warn: true, text: translate(err)};
      history.push('/forgotpassword', { email, message })
    }

    yield put(setMessage(message));
    yield put(loginFailure());
  } finally {
    yield put(finishRequest());
  }
}

export default all([
  takeLatest('@auth/LOGIN_REQUEST', login),
  takeLatest('@auth/LOGOUT_REQUEST', logout)
]);