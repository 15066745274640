//external
import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from "date-fns/locale/pt-BR";
import { Warning } from 'routes/Route';

export default function DateAndTimePickers({
  name, 
  label, 
  xs, 
  sm, 
  okLabel, 
  cancelLabel, 
  value, 
  onChange, 
  disabled, 
  disablePast,
  hasWarning = true
}) {

  const { setIsWarning } = useContext(Warning);

  const onChangeDateTime = (...props) => {
    onChange(...props);
    if (hasWarning) {
      setIsWarning();
    }
  }

  return (
    <Grid item xs={xs} sm={sm} >
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
        <KeyboardDateTimePicker 
          style={{width: '100%'}}
          disablePast={disablePast}
          name={name}
          label={label}
          value={value} 
          onChange={(...props) => onChangeDateTime(...props)} 
          ampm={false}
          cancelLabel={cancelLabel}
          okLabel={okLabel}
          format="dd/MM/yyyy HH:mm"
          inputVariant="outlined"
          margin="none"
          disabled={disabled}
        />
      </MuiPickersUtilsProvider>
    </Grid>
  );
}