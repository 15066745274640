import api from '../api';

export async function getAllows() {
  return api.get('contact/allows');
}
  
export async function getContacts(query, numOfRegisters = 10, page = 1, order) {
  const params = {
    order: order ? order : 'name',
  }
  if (query) {
    params.query = query.trim(); 
  }

  return api.get('contact', {
    params,
    headers: {
      registerperpage: numOfRegisters,
      page
    }
  });
}

export async function getContact(id) {
  return api.get(`contact/${id}`);
}

export async function findProspect(name, numOfRegisters = 6) {
  
  return api.get('contact/prospect', {
    params: {
      name,
      order: 'name'
    },
    headers: {
      registerperpage: numOfRegisters,
      page: 1
    }
  });
}

export async function findCustomer(codigo, numOfRegisters = 6) {
  return api.get('contact/customer', {
    params: {
      codigo,
      order: 'nome'
    },
    headers: {
      registerperpage: numOfRegisters,
      page: 1
    }
  });
}

export async function getContactQualifier() {
  return api.get('contact/contactQualifier');
}

export async function getContactRole() {
  return api.get('contact/contactRole');
}

export async function postContact(contact) {
  return api.post(`contact/`, contact);
}

export async function putContact(id, data) {
  return api.put(`contact/${id}`, data);
}

export async function deleteContact(id) {
  return api.delete(`contact/${id}`);
}