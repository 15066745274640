import styled from 'styled-components';
import { lighten } from 'polished';
import * as cor from '../../../styles/colors';

export const Container = styled.div`
  height: 100%;
  background: ${cor.fundoLogin};
  background-size: 29px 22px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ContainerButton = styled.div`
  align-items: center;
  margin-top: 16px;
`
export const Content = styled.div`
  width: 90%;
  max-width: 450px;
  text-align: center;
  background-color: ${cor.fundoPainelLogin};
  padding: 25px 18px;
  border: solid ${cor.corBordaLogin} 1px;
  box-shadow: 3px 3px 6px ${cor.corSombraBordaLogin};  

  img {
    width: 70%;
  }

  form {
    display: flex;
    flex-direction: column;
    margin-top: 0px;

    button {
      margin: 5px 0 0;
      height: 44px;
      width: 200px;
      background: ${cor.fundoTopo};
      font-weight: bold;
      color: #fff;
      border: 0px;
      border-radius: 22px;
      font-size: 16px;
      transition: background 0.2s;

      :hover {
        background-color: ${lighten(0.04, cor.fundoTopo)}
      }
    }

    a {
      margin-top: 10px;
      text-decoration: none;
      color: ${cor.fundoTopo};
      cursor: pointer;
    }
  }
`