import storage from 'redux-persist/es/storage'
import { persistReducer } from 'redux-persist'

export default reducers => {
  const persistedReducers = persistReducer({
    key: 'mixApp',
    storage,
    whitelist: ['auth', 'user']
  }, reducers)

  return persistedReducers;
}