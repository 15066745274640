import * as Yup from 'yup';

/* export const checklistSchema = Yup.object().shape({
  description: Yup.string().required('Descrição é obrigatória.'),
  fieldName: Yup.string().required('Nome é obrigatório.'),
  fieldType: Yup.string().required('Informe o tipo do campo'),
  min: Yup.number().integer(),
  max: Yup.number().integer(),
  hasJusify: Yup.boolean(),
}); */

const checklistItem = Yup.object().shape({
  fieldName: Yup.string().required('Informe o nome do campo'),
  fieldType: Yup.string().required('Informe o tipo do campo'),
  required: Yup.boolean().required('Informe se o campo é obrigatório'),
  min: Yup.number().integer().nullable(),
  max: Yup.number().integer().nullable(),
  hasJusify: Yup.boolean().nullable(),
  infoText: Yup.string().nullable()
});

export const checklistSchema = Yup.object().shape({
  description: Yup.string().required('Descrição é obrigatória'),
  items: Yup.array().of(checklistItem).required('Item nao informado')
});