//external
import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
//internal
import Route from '../../routes/Route';
import ProductCategorySiteDelete from './delete';
import ProductCategorySiteEdit from './edit';
import ProductCategorySite from './list';

export const FilterProductCategorySite = createContext({});

export default function Routes() {
  const [filter, setFilter] = useState({
    search: "",
    page: 1,
  });

  let location = useLocation();

  useEffect(() => {
    const split = location?.pathname.split('/');
    if (split[2] !== "rtpi_product_category_site") {
      setFilter({
        search: "",
        page: 1,
      });
    }
  }, [location, setFilter]);

  return (
    <FilterProductCategorySite.Provider value={{ filter, setFilter }}>
      <Route path="/premixapp/rtpi_product_category_site" exact component={ProductCategorySite} isPrivate/>
      <Route path="/premixapp/rtpi_product_category_site/add" exact component={ProductCategorySiteEdit} isPrivate/>
      <Route path="/premixapp/rtpi_product_category_site/edit/:id" exact component={ProductCategorySiteEdit} isPrivate/>
      <Route path="/premixapp/rtpi_product_category_site/delete/:id" exact component={ProductCategorySiteDelete} isPrivate/>
    </FilterProductCategorySite.Provider>
  )
}