import React from 'react';
import { Title, Value } from './styled'

export default function({attribute, value}) {
  return (
    <>
      <Title>
        {attribute}
      </Title>
      <Value>
        {value}
      </Value>
    </>
  )
}