import styled from 'styled-components'
import * as cor from '../../../styles/colors';

export const Input = styled.input`
  margin-top: 8px;
  width: 100%;
  background: #fff;
  border: 0px;
  border-radius: 4px;
  height: 30px;
  padding: 0 15px;
  font-size: 16px;
  color: #666;
  flex: 1;
  border: 1px solid #CCC;

  &:focus {
    border-color: ${props => (props.error ? '#eb4034' : '#87cbff')};
  }

  transition: border-color 0.1s linear;
`

export const IconContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const ContainerList = styled.div`
  width: 50%;
  padding: 3px;
`

export const Container = styled.div`
  height: 100%;
  background: ${cor.fundoLogin};
  background-size: 29px 22px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const AttrName = styled.div`
  padding-top: 5px;
  font-weight: bold;
  font-size: 14;
`
export const Attr = styled.div`
  font-size: 14;
`