//external
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactDOM from 'react-dom';
import CloseIcon from '@material-ui/icons/Close';
//internal
import TableAction from 'components/v2/tableAction';
import { ContainerModal } from 'pages/schedule/list/styled';
import { ContainerDialog } from './styled';

export function showDialog(children, title) {
  const props = {
    title,
    children
  }

  return renderDialog(props);
}

function renderDialog(props) {
  const wrapper = document.body.appendChild(document.createElement('div'));

  const removeDialog = function() {
    ReactDOM.unmountComponentAtNode(wrapper);
    if (document.body.contains(wrapper)) {
      document.body.removeChild(wrapper)
    }
  }

  try {
    ReactDOM.render(
      <div>
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            {props.title ? ( 
              <ContainerDialog>
                <DialogTitle style={{padding: '16px 0px'}} id="alert-dialog-title">{props.title}</DialogTitle>
                <TableAction 
                  key='closeAlert'
                  title={"Fechar"} 
                  onClick={() => removeDialog(true)}
                  isLink={false}>
                  <CloseIcon/>
                </TableAction>
              </ContainerDialog>
            ) : (
              <ContainerModal>
                <TableAction 
                  key='closeAlert'
                  title={"Fechar"} 
                  onClick={() => removeDialog(true)}
                  isLink={false}>
                  <CloseIcon/>
                </TableAction>
              </ContainerModal>
            )}  

            {props.children}
          </DialogContent>
        </Dialog>
      </div>,
      wrapper
    );
  } catch (e) {
    console.error(e);
    throw e;
  }

  return {
    removeDialog
  }
}