import React from 'react'
import Route from '../../routes/Route'

import FixedFieldsEdit from './edit'

export default function Routes() {
  return (
    <>
      <Route path="/premixlabel/rtpi/rtpi_fixed_fields" exact component={FixedFieldsEdit} isPrivate/>
    </>
  )
}