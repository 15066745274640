//external
import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
//internal
import Route from '../../routes/Route';
import Profiles from './list';
import ProfileEdit from './edit';
import ProfileDelete from './delete';

export const FilterProfile = createContext({});

export default function Routes() {
  const [filter, setFilter] = useState({
    search: "",
    page: 1,
  });

  let location = useLocation();

  useEffect(() => {
    const split = location?.pathname.split('/');
    if (split[1] !== "userprofiles") {
      setFilter({
        search: "",
        page: 1,
      });
    }
  }, [location, setFilter]);

  return (
    <FilterProfile.Provider value={{ filter, setFilter }}>
      <Route path="/userprofiles" exact component={Profiles} isPrivate/>
      <Route path="/userprofiles/add" exact component={ProfileEdit} isPrivate/>
      <Route path="/userprofiles/edit/:id" exact component={ProfileEdit} isPrivate/>
      <Route path="/userprofiles/delete/:id" exact component={ProfileDelete} isPrivate/>
    </FilterProfile.Provider>
  )
}