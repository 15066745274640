//external
import 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { getErrorMessage, getIsError } from 'utils/validationUtil';
import { Warning } from 'routes/Route';

export default function InputDate({name, label, value, onChange, xs, sm, required, errors=[], hasWarning = true}) {

  const [stateErrors, setStateErrors] = useState([]);

  const { setIsWarning } = useContext(Warning);

  const onChangeInput = (e) => {
    onChange(e);
    setStateErrors(stateErrors.filter(item => item.field !== name));
    if (hasWarning) {
      setIsWarning();
    }
  }

  useEffect(() => {
    if(Array.isArray(errors) && errors.length > 0) {   
      setStateErrors(errors)
    }
  }, [errors]);
    
  return (
    <Grid item xs={xs} sm={sm}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          style={{width: "100%"}}
          disableToolbar
          name={name}
          format="dd/MM/yyyy"
          label={label}
          value={value || ''}
          onChange={(e) => onChangeInput(e)}
          error={getIsError(name, stateErrors)}
          helperText={getErrorMessage(name, stateErrors)}
          inputVariant="outlined"
          margin="none"
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          InputLabelProps={{shrink: true}}
          required={required}
        />
      </MuiPickersUtilsProvider>
    </Grid>
  )
}

InputDate.defaultProps = {
  value: new Date(),
}