//external
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
//internal
import { getAllVersions, getAllows } from 'services/appVersion/service';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import ContentPanel from 'components/ContentPanel';
import { setMessage } from 'store/modules/message/action';
import { formatDate } from 'utils/formatUtil';
import TableAction from 'components/v2/tableAction';
import { sanitizeErrorUI } from 'utils/errorUtil';
import DataTableReact from 'components/v2/dataTableReact';
import DataTableLoading from 'components/DataTableLoading';
import { FilterAppversion } from '..';
//style
import { IconContainer } from '../styled';

export default function AppVersion() {
  const message = useSelector(state => state.message.data);
  
  const [appVersions, setAppVersions] = useState();
  const [allows, setAllows] = useState({});
  const [reload, setReload] = useState(0);
  //pagination
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sort, setSort] = useState();

  const { filter, setFilter } = useContext(FilterAppversion);

  const dispatch = useDispatch();

  const columns = [
    {
      name: 'Nome do App',
      selector: 'appname',
      sortable: true,
    },
    {
      name: 'Versão',
      selector: 'version',
      sortable: true,
    },
    {
      name: 'Data Limite Android',
      selector: 'androidLimitDate',
      sortable: true,
    },
    {
      name: 'Data Limite IOS',
      selector: 'iosLimitDate',
      sortable: true,
    },
    {
      style: {justifyContent: "flex-end"},
      cell: row => 
      <>
        <div>
          <IconContainer>
            <TableAction 
            title={"Editar"} 
            disabled={!allows.put} 
            to={`/premixapp/appversion/edit/${row.id}`}>
              <EditIcon/>
            </TableAction>
            <TableAction 
            title={"Excluir"} 
            disabled={!allows.delete} 
            to={`/premixapp/appversion/delete/${row.id}`}>
              <DeleteIcon/>
            </TableAction>
          </IconContainer>
        </div>
      </>,
    }
  ];

  const actions = [
    <div style={{display: 'flex'}} key='actionsAppVersion'>
      <TableAction 
      title={"Adicionar"} 
      disabled={!allows.post} 
      to='/premixapp/appversion/add'>
        <AddIcon/>
      </TableAction>
      <TableAction 
      title={"Atualizar"} 
      onClick={refreshHandler}
      isLink={false}>
        <RefreshIcon/>
      </TableAction>
    </div>

  ];

  function refreshHandler() {
    setReload(reload + 1);
  }

  useEffect(() => {
    dispatch(startRequest());
    getAllVersions(perPage, filter.page, sort).then(data => {
      setTotalRows(parseInt(data.headers['recordcount']));
      setAppVersions(data.data.map(value => {
        value.androidLimitDate = formatDate(value.androidLimitDate);
        value.iosLimitDate = formatDate(value.iosLimitDate);

        return value;
      }));
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
    dispatch(startRequest());
    getAllows().then(data => {
      setAllows(data.data)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
    });
  }, [dispatch, reload, perPage, filter, sort]);

  const handlePerRowsChange = (perPage) => {
    setPerPage(perPage);
  };

  const handlePageChange = (page) => {
    setFilter({...filter, page});
  };

  const handleSort = (column, sortDirection) => {
    setSort(`${column.selector} ${sortDirection}`);
  };

  return (
    <ContentPanel title="Versões do app" message={message} actions={actions}>
      <DataTableReact
        noHeader
        columns={columns}
        data={appVersions}
        pagination
        paginationServer
        progressComponent={<DataTableLoading />}
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        sortServer
        onSort={handleSort}
        paginationDefaultPage={filter.page}
      />
    </ContentPanel>
  )
}