//external
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
//internal
import { getProducts, getAllows, findBranch } from 'services/rtpi/products/service';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import ContentPanel from 'components/ContentPanel';
import DataTableLoading from 'components/DataTableLoading';
import { setMessage } from 'store/modules/message/action';
import { useDebounce } from 'utils/uiUtils';
import GridContainer from 'components/v2/grid';
import InputText from 'components/v2/inputText';
import Toggle from 'components/v2/toggle';
import TableAction from 'components/v2/tableAction';
import { sanitizeErrorUI } from 'utils/errorUtil';
import SelectAutocomplete from 'components/v2/autocomplete';
import DataTableReact from 'components/v2/dataTableReact';
import { FilterRtpiProduct } from '..';
//style
import { IconContainer } from './styled';

export default function Component() {
  const message = useSelector(state => state.message.data);
  
  const [products, setProducts] = useState();
  const [allows, setAllows] = useState({});
  const [branch, setBranch] = useState(null);
  const [exported, setExported] = useState(false);
  //Pagination
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sort, setSort] = useState();
  const [reload, setReload] = useState(0);
  const [optionsBranch, setOptionsBranch] = useState([]);
  const [searchBranch, setSearchBranch] = useState("");

  const { filter, setFilter } = useContext(FilterRtpiProduct);

  const debouncedSearchTerm = useDebounce(filter.search);
  const debouncedSearchBranch = useDebounce(searchBranch);
  
  const dispatch = useDispatch();

  const loadBranch = useCallback((searchValue) => {
    findBranch(searchValue).then(result => {
      const branchs = result.data.map(element => {
        return { value: element, label: `${element.protheusCode} - ${element.description}` }
      });
      setOptionsBranch(branchs);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    });
  }, [dispatch]);

  useEffect(() => {
    if(loadBranch) loadBranch(debouncedSearchBranch)
  },[debouncedSearchBranch, loadBranch]);

  const columns = [
    {
      name: 'Código',
      selector: 'protheusCode',
      sortable: true,
    },
    {
      name: 'Filial',
      selector: 'protheusBranch',
      sortable: true,
    },
    {
      name: 'Descrição',
      selector: 'description',
      sortable: true,
    },
    {
      name: 'Rótulo',
      selector: 'descriptionAlias',
      sortable: false,
    },
    {
      style: {justifyContent: "flex-end"},
      cell: row => 
      <>
        <div>
          <IconContainer>
          <TableAction 
          title={"Editar"} 
          disabled={!allows.put} 
          to={`/premixapp/rtpi_product/edit/${row.protheusBranch}/${row.protheusCode}`}>
            <EditIcon/>
          </TableAction>
          </IconContainer>
        </div>
      </>,
    }
  ];

  const actions = [
      <div style={{display: 'flex'}} key="actionsProduct">
        <TableAction 
        title={"Adicionar"} 
        disabled={!allows.post} 
        to='/premixapp/rtpi_product/add'>
          <AddIcon/>
        </TableAction>
        <TableAction 
        title={"Atualizar"} 
        onClick={refreshHandler}
        isLink={false}>
          <RefreshIcon/>
        </TableAction>
      </div>
  ];

  function refreshHandler() {
    setReload(reload + 1);
  }

  useEffect(() => {
    getAllows().then(data => {
      setAllows(data.data)
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    });
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);
    dispatch(startRequest());    
    getProducts(debouncedSearchTerm, exported, branch?.value?.protheusCode, perPage, filter.page, sort).then(data => {
      setTotalRows(parseInt(data.headers['recordcount']));
      setProducts(data.data);
    }).catch(err => {
      dispatch(setMessage(sanitizeErrorUI(err)));
    }).finally(() => {
      dispatch(finishRequest());
      setLoading(false);
    });
  }, [debouncedSearchTerm, exported, dispatch, filter.page, perPage, sort, branch, reload]);

  const handlePageChange = (page) => {
    setFilter({...filter, page});
  };

  const handlePerRowsChange = (perPage) => {
    setPerPage(perPage);
  };

  const handleSort = (column, sortDirection) => {
    setSort(`${column.selector} ${sortDirection}`);
  };

  function handleBranchChange(data) {
    if (data) {
      setBranch(data);
    } else {
      setBranch(null);
      loadBranch("");
    }   
  }

  const handleExportedChange = () => {
    setExported(!exported);
  };

  return (
    <>
      <ContentPanel message={message} title="Produtos" actions={actions}>
        <div style={{width: '100%', padding: '0 0 10px 0'}}>
          <SelectAutocomplete
            xs={12}
            sm={12}
            value={branch}
            label="Buscar filial"
            optionSelected={(option, value) => option.label === value.label}
            optionLabel={(option) => option.label}
            onChangeSelected={(event, value) => handleBranchChange(value)}
            onChange={(event) => setSearchBranch(event.target.value)}
            options={optionsBranch}
            name="branch"
            hasWarning={false}
          />
        </div>
        <GridContainer>
          <InputText
            xs={12}
            name="search"
            label="Pesquisar"
            type="text"
            placeholder="Pesquisar"
            value={filter.search}
            onChange={(e) => setFilter({page: 1, search: e.target.value})}
            hasWarning={false}
          />
        </GridContainer>
        
        <Toggle
          name="exported"
          checked={exported}
          onChange={(e) => handleExportedChange(e.target.checked) }
          label="Exportado"
        />

        <DataTableReact
          noHeader
          fixedHeader
          progressPending={loading}
          progressComponent={<DataTableLoading />}
          columns={columns}
          data={products}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          sortServer
          onSort={handleSort}
          paginationDefaultPage={filter.page}
        />
      </ContentPanel>
    </>
  )
}