//external
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//internal
import ContentPanel from 'components/ContentPanel';
import { setMessage } from 'store/modules/message/action';
import { sanitizeErrorUI } from 'utils/errorUtil';
import { startRequest, finishRequest } from 'store/modules/loading/actions';
import GridContainer from 'components/v2/grid';
import InputText from 'components/v2/inputText';
import history from 'services/history';
import { validateForm } from 'components/Form/validate';
import { getDonorSpecie, postDonorSpecie, putDonorSpecie } from 'services/donorSpecie/service';
import InputMultiline from 'components/v2/inputMultiline';
import { donorSpecieSchema } from '../schemas';
import { Warning } from 'routes/Route';
//style
import { Button, ButtonContainer } from 'styles/components';

export default function DonorSpecieEdit({match}) {
  const donorSpecieId = match.params.id;
  const message = useSelector(state => state.message.data);
  const [dataDonorSpecie, setDataDonorSpecie] = useState({});
  const [errors, setErrors] = useState([]);

  const { setNotWarning } = useContext(Warning);

  const dispatch = useDispatch();

  useEffect(() => {
    if (donorSpecieId) {
      dispatch(startRequest());
      getDonorSpecie(donorSpecieId).then(data => {
        const formData = data.data
        setDataDonorSpecie({
          description: formData.description,
          referenceNumber: formData.referenceNumber,
          id: formData.id,
          text: formData.text
        });
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }
  }, [dispatch, donorSpecieId]);

  async function handleSubmit () {
    setNotWarning();
    
    const errorsList = await validateForm(dataDonorSpecie, donorSpecieSchema);
    if (errorsList.length > 0) {
      setErrors(errorsList);
      return
    }

    if (donorSpecieId) {
      dispatch(startRequest());
      putDonorSpecie(donorSpecieId, dataDonorSpecie).then(data => {
        history.push('/premixLabel/donor_specie')
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    } else {
      dispatch(startRequest());
      postDonorSpecie(dataDonorSpecie).then(data => {
        history.push('/premixLabel/donor_specie')
      }).catch(err => {
        dispatch(setMessage(sanitizeErrorUI(err)));
      }).finally(() => {
        dispatch(finishRequest());
      });
    }  
  }

  return (
    <ContentPanel title="Espécies Doadoras" message={message}>
      <GridContainer>
        <InputText
          xs={12}
          sm={6}
          required
          name="description"
          label="Descrição"
          type="text"
          value={dataDonorSpecie.description}
          onChange={(e) => setDataDonorSpecie({...dataDonorSpecie, description: e.target.value})}
          errors={errors}
        />
        <InputText
          xs={12}
          sm={6}
          required
          name="referenceNumber"
          label="Número de Referência"
          type="text"
          value={dataDonorSpecie.referenceNumber}
          onChange={(e) => setDataDonorSpecie({...dataDonorSpecie, referenceNumber: e.target.value})}
          errors={errors}
        />
        <InputMultiline
          xs={12}
          sm={12}
          required
          name="text"
          label="Texto"
          type="text"
          value={dataDonorSpecie.text}
          onChange={(e) => setDataDonorSpecie({...dataDonorSpecie, text: e.target.value})}
          errors={errors}
        />
      </GridContainer>

      <ButtonContainer>
        <Button type="submit" onClick={handleSubmit}>Salvar</Button>
      </ButtonContainer> 
    </ContentPanel>
  )
}