import * as Yup from 'yup';

export const productSchema = Yup.object().shape({
  protheusBranch: Yup.string().required('Filial é obrigatória.'),
  protheusCode: Yup.string().required('Código é obrigatório.'),
  descriptionAlias: Yup.string().nullable(),
  catalogExport: Yup.bool(),
  categoriesSite: Yup.array().of(Yup.object().shape({
    id: Yup.number().integer().required('Id da categoria é obrigatório.'),
  })),
  siteSeoTitle: Yup.string().nullable(),
  siteMetaDescription: Yup.string().nullable(),
});
