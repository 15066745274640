//external
import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
//internal
import Route from '../../routes/Route';
import Customers from './list';
import CustomerView from './view';
import CustomerEdit from './edit';

export const FilterCustomer = createContext({});

export default function Routes() {
  const [filter, setFilter] = useState({
    search: "",
    page: 1,
  });

  let location = useLocation();

  useEffect(() => {
    const split = location?.pathname.split('/');
    if (split[2] !== "customer") {
      setFilter({
        search: "",
        page: 1,
      });
    }
  }, [location, setFilter]);

  return (
    <FilterCustomer.Provider value={{ filter, setFilter }}>
      <Route path="/schedule/customer" exact component={Customers} isPrivate/>
      <Route path="/schedule/customer/view/:id/:store" exact component={CustomerView} isPrivate/>
      <Route path="/schedule/customer/edit/:id/:store" exact component={CustomerEdit} isPrivate/>
    </FilterCustomer.Provider>
  )
}