import React, {Fragment, useState} from 'react';
import { Grid, Typography } from '@material-ui/core';
import InputText from 'components/v2/inputText';
import InputMultiline from 'components/v2/inputMultiline';
import InputSelect from 'components/v2/inputSelect';
import DatePickers from 'components/v2/datePicker';
import CheckboxComponent from 'components/v2/checkbox';
import ImageUpload from 'components/v2/imageUpload';

export default function ChecklistField({
  id,
  fieldType,
  fieldName,
  required,
  answer,
  errors,
  selectOptions,
  index,
  handleChange,
  handleChecked,
  handleChangeDate,
  handleChangeImage,
  handleChangeImageMultiple,
  handleChangeInputSelect,
  handleChangeJustify,
  removeImage,
  disabled,
}) {
  const [hasJustify, setHasJustify] = useState(answer?.justify);

  if (fieldType === "TEXT") {
    return (
      <Fragment key={fieldName}>
        <InputText 
          xs={12}
          sm={12}
          label={fieldName}
          required={required}
          type="text"
          name={id.toString()}
          onChange={(e) => handleChange(e)}
          value={answer?.value}
          errors={errors}
          disabled={disabled}
        />
      </Fragment>
    );
  } else if (fieldType === "LONGTEXT") {
    return (
      <Fragment key={fieldName}>
        <InputMultiline
          xs={12}
          sm={12}
          label={fieldName}
          required={required}
          type="text"
          name={id.toString()}
          onChange={(e) => handleChange(e)}
          value={answer?.value}
          errors={errors}
          disabled={disabled}
        />
      </Fragment>
    );
  } else if (fieldType === "LABEL") {
    return (
      <Fragment key={fieldName}>
        <Grid item xs={12} sm={6}>
          <Typography color="textPrimary" variant="h6">{fieldName}</Typography>
        </Grid>
      </Fragment>
    );
  } else if (fieldType === "DECIMAL" || fieldType === "INTEGER") {
    return (
      <Fragment key={fieldName}>
        <InputText 
          xs={12}
          sm={12}
          label={fieldName}
          required={required}
          type="number"
          name={id.toString()}
          onChange={(e) => handleChange(e)}
          value={answer?.value}
          errors={errors}
          disabled={disabled}
        />
      </Fragment>
    );
  } else if (fieldType === "DATE") {
    return (
      <Fragment key={fieldName}>
        <DatePickers
          xs={12}
          sm={12}
          label={fieldName}
          name={id.toString()}
          required={required}
          cancelLabel="Cancelar"
          okLabel="Salvar"
          onChange={(value) => handleChangeDate(value, index)}
          value={answer?.value}
          errors={errors}
          disabled={disabled}
        />
      </Fragment>
    );
  } else if (fieldType === "BOOLEAN") {
    return (
      <Fragment key={fieldName}>
        <CheckboxComponent 
          xs={12}
          sm={12}
          name={id.toString()}
          required={required}
          onChange={(e) => handleChecked(e)}
          checked={answer?.value || false}
          errors={errors}
          label={fieldName}
          disabled={disabled}
        />
      </Fragment>
    )
  } else if (fieldType === "SESSION") {
    return (
      <Fragment key={fieldName}>
        <Grid item xs={12} sm={12}>
          <Typography color="textPrimary" variant="h5">{fieldName}</Typography> 
        </Grid> 
      </Fragment>
    );
  } else if (fieldType === "SELECT") {
    return (
      <Fragment key={fieldName}>
        <InputSelect
          xs={12}
          sm={12}
          label={fieldName}
          name={id.toString()}
          required={required}
          color="primary"
          onChange={(event) => {   
            if (Array.isArray(selectOptions)) {
              const option = selectOptions?.find(item => item.option === event?.target?.value);            
              setHasJustify(option?.hasJustify || false);
            }
            handleChangeInputSelect(index, event, id);
          }}
          defaultValue={null}
          value={answer?.value}
          options={selectOptions?.map(elem => elem.option)}
          errors={errors}
          disabled={disabled}
        />        
        {hasJustify && (
          <InputText 
            xs={12}
            sm={12}
            label="Justificativa"            
            type="text"
            name={id.toString()}
            onChange={handleChangeJustify}
            value={answer?.justify}
            errors={errors}
            disabled={disabled}
          />
        )}
      </Fragment>
    );
  } else if (fieldType === "IMAGE") {
    return (
      <Fragment key={fieldName}>                
        <ImageUpload
          id={id}
          fieldName={fieldName}
          name={id.toString()}                    
          onChange={(e) => handleChangeImage(e, id.toString())}
          multiple={false}
          accept="image/*"
          removeImage={removeImage}
          value={answer?.value}
          errors={errors}
          idItem={id}
          disabled={disabled}
        />
      </Fragment>
    );
  } else if (fieldType === "IMAGE_LIST") {
    return (
      <Fragment key={fieldName}>                
        <ImageUpload
          id={id}
          fieldName={fieldName}
          name={id.toString()}                    
          onChange={(e) => handleChangeImageMultiple(e, id.toString())}
          multiple={true}
          accept="image/*"
          removeImage={removeImage}
          value={answer?.value}
          errors={errors}
          idItem={id}
          disabled={disabled}
        />
      </Fragment>
    );
  } else {
    return null;
  } 
}