import api from '../api'

export async function getAllows() {
  return api.get('product_group/allows');
}

export async function getGroups(description, numOfRegisters = 10, page = 1, order) {
  const params = {
    order: order ? order : 'description',
  }
  if (description) {
    params.query = description.trim(); 
  }

  return api.get('product_group', {
    params,
    headers: {
      registerperpage: numOfRegisters,
      page
    }
  });
}

export async function getGroup(groupId) {
  return api.get(`product_group/protheus/${groupId}`);
}

export async function putGroup(groupId, data) {
  return api.put(`product_group/protheus/${groupId}`, data);
}
